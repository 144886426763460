import { Anchor, Box, Image, List } from 'grommet';
import coverImg from '../../images/cover4.jpg';
import Container from '../../components/Container/Container';
import pdfIcon from 'images/pdf.png';
import Partners from 'components/Partners/Partners';
import Banner from 'components/Banner';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const archiveArr = [
  {
    label: `Архив: Критерии отбора в сборную (2013 г.)`,
    link: `https://yadi.sk/i/EOL_gqxGgPwg_w`,
  },
  {
    label: `Архив: Таблица норм и условия их выполнения для присвоения спортивных разрядов 2010-2013 г.г`,
    link: `https://yadi.sk/i/A7RJ5uovvc8CuA`,
  },
  {
    label: `Архив:Критерии отбора в спортивную сборную команду России по триатлону на 2014 год`,
    link: `https://yadi.sk/i/BlGHXeb76J3C8A`,
  },
  {
    label: `Архив:Шкала очков для определения рейтинга российских атлетов на 2014 год`,
    link: `https://yadi.sk/i/M2aXcC5uFvKPqw`,
  },
  {
    label: `Архив: Изменения в критериях отбора на 2014 год`,
    link: `https://yadi.sk/i/yXKxD2TzxqDzLw`,
  },
  {
    label: `Архив: Критерии отбора спортсменов в сборную команду России по триатлону, для участия в Европейских играх 2015 года в Баку`,
    link: `https://yadi.sk/i/-H_f_Kk8nQZL0g`,
  },
  {
    label: `Архив: Порядок оформления въездных виз на международные мероприятия 2015 года`,
    link: `https://yadi.sk/i/AUsXdbzpTaRvaA`,
  },
  {
    label: `Архив: Критерии отбора в спортивную сборную команду России по триатлону на 2016 год`,
    link: `https://yadi.sk/i/EiG3Xkdu_sIkXg`,
  },
  {
    label: `Архив: Положение о Главном тренерском совете сборных команд России по триатлону на 2016 год`,
    link: `https://yadi.sk/i/qxbsSbJ2vJCZ7g`,
  },
  {
    label: `Архив: Критерии отбора спортсменов в сборную команду России по триатлону, для участия в Олимпийских играх 2016 года в Рио-де-Жанейро (проект)`,
    link: `https://yadi.sk/i/QlKb3pk5t6bSfQ`,
  },
  {
    label: `Единая всероссийская спортивная классификация на 2014-2017 годы (ЕВСК)`,
    link: `https://yadi.sk/i/o5SzRnS1kLl6yA`,
  },
  {
    label: `Календарь соревнований Кубка ФТР по триатлону среди юношей и девушек 2003 – 2004 г. р. на 2017 год`,
    link: `https://yadi.sk/i/OdKGFif9iuBAgA`,
  },
  {
    label: `Календарь ВС и МС соревнований на 2017 год`,
    link: `https://yadi.sk/i/J5LedeJT2RXkgw`,
  },
  {
    label: `Система заявки российских спортсменов на международные соревнования и снятия их со стартовых листов (обновлено 23.03.2016)`,
    link: `https://yadi.sk/i/jhJH-_gFu7I-Qg`,
  },
  {
    label: `Положение о межрегиональных и всероссийских официальных спортивных соревнованиях по триатлону на 2017 год`,
    link: `https://yadi.sk/i/Dw6vWx3EOXaMug`,
  },
  {
    label: `Изменения в положении о межрегиональных и всероссийских официальных спортивных соревнованиях по триатлону на 2017 год`,
    link: `https://yadi.sk/i/JO1PY9TUZFhPZA`,
  },
  {
    label: `Основные положения программы развития триатлона в регионах на 2012-2016 г.г.`,
    link: `https://yadi.sk/i/axQqAQy0sye2YA`,
  },
  {
    label: `Шкала очков для определения рейтинга российских атлетов на 2017 год`,
    link: `https://yadi.sk/i/vDfIy0YNV0CdDA`,
  },
  {
    label: `Cписки кандидатов в сборные команды РФ по триатлону на 2017 год`,
    link: `https://yadi.sk/i/IMtDck_uhwkvYg`,
  },
  {
    label: `Правила вида спорта "Триатлон" (приказ Минспорта №743 от 11 августа 2017)`,
    link: `https://yadi.sk/i/JwhRfw0uxqTxLA`,
  },
];

const Archive = () => {
  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />

        <Box
          style={{
            maxWidth: `1190px`,
            marginLeft: `auto`,
            marginRight: `auto`,
          }}
        >
          <List data={archiveArr} pad="medium" border={false}>
            {archiveArr => (
              <Box direction="row-responsive" gap="medium" align="center">
                <Image src={pdfIcon} width={`55`} />
                <Anchor
                  href={archiveArr.link}
                  label={archiveArr.label}
                  style={{
                    fontSize: `16px`,
                    marginTop: `20px`,
                    marginBottom: `20px`,
                  }}
                />
              </Box>
            )}
          </List>
        </Box>

        <Partners />
      </Container>
    </>
  );
};

export default Archive;
