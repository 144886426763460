import { Paragraph, Anchor, Text, Heading, Box } from 'grommet';
import { useLocation } from 'react-router-dom';
import { routes } from '../../const/routes';

const Breadcrumbs = () => {
  const location = useLocation();

  const firstLabel = routes.main.name;
  const secondRoute = Object.values(routes).find(
    r => r.path === location.pathname
  );
  const isSecondMain = secondRoute ? secondRoute.name === firstLabel : false;

  const secondLabel = secondRoute && !isSecondMain ? secondRoute.name : '';

  return (
    <Box
      pad={{ horizontal: 'large', bottom: '32px' }}
      margin={{ bottom: 'large' }}
      background={{ color: `#F8F8F8` }}
      round={`small`}
      gap="xlarge"
    >
      <Box pad={{ top: 'large' }}>
        <Paragraph
          size="small"
          color={`rgba(0, 0, 0, 0.3)`}
          style={{ display: 'flex', flexDirection: 'row', gap: '2%' }}
        >
          <Anchor
            href={'/'}
            color={`rgba(0, 0, 0, 0.3)`}
            label={firstLabel}
            style={{ textDecoration: 'none' }}
          />
          {secondLabel && <Text> &gt; </Text>}
          {secondLabel}
        </Paragraph>
      </Box>
      <Heading
        level={1}
        color="black"
        style={{
          textTransform: `uppercase`,
        }}
      >
        {secondLabel}
      </Heading>
    </Box>
  );
};

export default Breadcrumbs;
