import { Box, Heading } from 'grommet';
import DocumentItem from '../DocumentItem';
import { useEffect } from 'react';
import { childDocuments } from 'store/action/documents';
import { useSelector } from 'react-redux';

const Documents = () => {
  const items = useSelector(state => state.documents.items);

  useEffect(() => {
    childDocuments();
  }, []);
  return (
    <Box>
      <Box flex direction="row" justify="between">
        <Heading
          level={`3`}
          margin={{ top: `18px`, bottom: `18px` }}
          style={{ textTransform: `uppercase` }}
        >
          Документы
        </Heading>
      </Box>
      {items.map(i => (
        <Box
          key={i.public_id}
          flex
          direction="column"
          gap="small"
          margin={{ top: '20px' }}
        >
          <DocumentItem title={i.title} date={i.document_date} link={i.link} />
        </Box>
      ))}
    </Box>
  );
};

export default Documents;
