import { Box, Heading, Paragraph } from 'grommet';
import styled from 'styled-components';

const CustomParagraph = styled(Paragraph)`
  max-width: 100%;
  font-size: 16px;
`;

const InternationalTriaDesc = () => {
  return (
    <Box gap="small" margin={{bottom: `large`}}>
      <Heading level={2} margin={{bottom: `medium`}}>История мирового триатлона</Heading>

      <CustomParagraph>
        Первый официально зарегистрированный триатлон состоялся в Сан-Диего, штат Калифорния, 24 сентября 1974 года.
        Он был организован клубом легкой атлетики Сан-Диего в качестве альтернативы тренировкам на треке. Гонка, которая
        проходила в заливе Мишн в Сан-Диего, состояла из 5,3 миль бега, затем 5 миль на велосипеде и заплыва на 600 ярдов
        в заливе. Всего финишировали 46 спортсменов.
      </CustomParagraph>

      <CustomParagraph>
        Триатлон быстро развивался и в течение нескольких лет стал одним из самых быстрорастущих видов спорта во всем мире.
        Международный олимпийский комитет (МОК) был заинтересован стремительным ростом этого вида спорта и начал обсуждение
        вопроса о включении триатлона в программу Олимпийских игр в 1988 году. Затем в том же году президент МОК Хуан
        Антонио Самаранч организовал встречу в Стокгольме с намерением включить триатлон в программу Олимпийских игр.
        На этой встрече канадец Лес Макдональд был избран президентом рабочего комитета по триатлону, а Стуре Йонассон
        из Швеции — секретарем.
      </CustomParagraph>

      <CustomParagraph>
        Международная федерация триатлона была основана годом позже - 1 апреля 1989 года, на первом конгрессе ITU в Авиньоне,
        Франция. На первом Конгрессе приняли участие 30 национальных федераций. В тот же период велась подготовка к первому
        чемпионату мира по триатлону, который состоялся в Авиньоне в августе этого же года. По итогам конгресса была
        установлена длительность олимпийской дистанции:  1,5 км плавание, 40 км велогонка и 10 км беге.  Также Лес Макдональд
        был избран первым президентом ITU. В первом чемпионате мира по триатлону соревновались более 800 спортсменов из 40 стран.
      </CustomParagraph>

      <CustomParagraph>
        Марисоль Касадо (Испания) была избрана вторым президентом ITU в ноябре 2008 года и стала членом МОК в феврале
        2010 года на 122-й сессии МОК в Ванкувере. ITU сохранял свою штаб-квартиру в Ванкувере, Канада, до 1 января 2014 года.
        После штаб-квартира переместился в Лозанну, Швейцария, где находится МОК.
      </CustomParagraph>

      <CustomParagraph>
        ITU расширился и теперь включает более 170 аффилированных национальных федераций по всему миру. В 1991 году был
        дан старт ежегодной серии этапов Кубка мира. А в 2009 в календарь соревнований были включены гонки серии
        Dextro Energy Triathlon, которые потом были переименованы в мировую серию по триатлону, действующую и сегодня.
      </CustomParagraph>

      <CustomParagraph>
        Триатлон дебютировал на Олимпийских играх на Играх 2000 года в Сиднее, где швейцарка Бриджит МакМахон и канадец
        Саймон Уитфилд завоевали первые олимпийские золотые медали.
      </CustomParagraph>

      <CustomParagraph>
        В Рио-2016 паратриатлон дебютировал на Паралимпийских играх с шестью медалями: трое мужчин и трое женщин.
        В Токио-2020 командная смешанная эстафета впервые была добавлена в программу Олимпийских игр.
      </CustomParagraph>

      <CustomParagraph>
        В октябре 2020 года организация официально приняла новое название World Triathlon, чтобы как можно лучше служить
        развитию спорта во всех его формах. Она продолжает оставаться единственной организацией, которая может присуждать
        титулы чемпионов мира по триатлону.
      </CustomParagraph>

    </Box>
  );
};

export default InternationalTriaDesc;
