import * as types from '../types/regions';

const initialState = {
  regions: [],
};

const regions = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REGIONS:
      return {
        ...state,
        regions: action.payload,
      };

    default:
      return state;
  }
};

export default regions;
