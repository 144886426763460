import { Anchor, Box, Grid, Image, Text } from 'grommet';
import SocialMedia from '../Header/SocialMedia/SocialMedia';
import styled from 'styled-components';
import logoMl from 'images/logo-ml.svg';
import MainLogo from '../Header/MainLogo/MainLogo';
import PaymentLogos from 'images/paymentlogos.png';
// import ToggleLang from '../Header/ToggleLang/ToggleLang';
import Container from 'components/Container/Container';
import { routes } from '../../const/routes';
import React from 'react';

const LinkFooterMenu = styled(Anchor)`
  padding-top: 8px;
  padding-bottom: 8px;
  color: rgba(51, 51, 51, 0.5);
  font-weight: 400;
`;

const Footer = () => {
  return (
    <Container>
      <Grid
        margin={{ bottom: `20px` }}
        columns={{ count: `fit`, size: `280px` }}
        justify="stretch"
        gap={`medium`}
      >
        <Box pad={{ bottom: `22px` }}>
          <Box
            direction="row"
            pad={{ top: `32px`, bottom: `30px` }}
            align="center"
            wrap={true}
            border={{ side: `top`, color: `rgba(51,51,51,0.5)` }}
          >
            <Box margin={{ right: `auto`, bottom: `15px` }}>
              <MainLogo />
            </Box>
            {/*<Box margin={{right: `25px`}}>*/}
            {/*  <ToggleLang />*/}
            {/*</Box>*/}
             <SocialMedia /> 
          </Box>

          {/* <Grid
          columns={{ count: `fit`, size: `216px` }}
          margin={{ bottom: `30px` }}
          gap="10px"
        >
          <Box>
            <Heading level="5" margin={{ bottom: `5px` }}>
              Главная
            </Heading>
            <LinkFooterMenu
              href={`${routes.whatIsTriathlon.path}`}
              label="Что такое триатлон?"
            />
            <LinkFooterMenu
              href={`${routes.whereToStart.path}`}
              label="Как начать?"
            />
            {/*<LinkFooterMenu href="/" label="Инфраструктура и базы"/>*/}
          {/*<LinkFooterMenu href="/" label="Детские школы"/>*/}
          {/* <LinkFooterMenu
              href={`${routes.historyTria.path}`}
              label="История"
            />
            {/*<LinkFooterMenu href="/" label="Блог (Медиа / Фото)"/>*/}
          {/* <LinkFooterMenu
              href={`${routes.coachingStaff.path}`}
              label="Тренерский состав"
            />
            <LinkFooterMenu
              href={`${routes.disciplines.path}`}
              label="Дисциплины"
            />
            <LinkFooterMenu
              href={`${routes.childrenSchools.path}`}
              label="Детские школы"
            />
          </Box>
          <Box>
            <Heading level="5" margin={{ bottom: `5px` }}>
              Сборные
            </Heading>
            <LinkFooterMenu
              href={`${routes.mainCast.path}`}
              label="Основной состав сборной"
            />
            <LinkFooterMenu
              href={`${routes.youthTeam.path}`}
              label="Молодежный состав"
            />
            <LinkFooterMenu
              href={`${routes.juniorTeam.path}`}
              label="Юниорский состав"
            />
            <LinkFooterMenu
              href={`${routes.youthSquad.path}`}
              label="Юношеский состав"
            />
            <LinkFooterMenu
              href={`${routes.winterTriathlon.path}`}
              label="Зимний триатлон"
            />
            <LinkFooterMenu
              href={`${routes.protocols.path}`}
              label="Результаты соревнований"
            />
            <LinkFooterMenu
              href={`${routes.aquathlonTeam.path}`}
              label="Акватлон"
            />
            <LinkFooterMenu
              href={`${routes.teamMiddleDistance.path}`}
              label="Триатлон средняя дистанция"
            />
            <LinkFooterMenu
              href={`${routes.crossTriathlon.path}`}
              label="Триатлон-кросс"
            />
            <LinkFooterMenu
              href={`${routes.teamDuathlon.path}`}
              label="Дуатлон"
            />
          </Box>
          <Box>
            <Heading level="5" margin={{ bottom: `5px` }}>
              Календарь
            </Heading>
            <LinkFooterMenu href={`${routes.calendar.path}/all`} label="Все" />
            <LinkFooterMenu
              href={`${routes.calendar.path}/int`}
              label="Международные события"
            />
            <LinkFooterMenu
              href={`${routes.calendar.path}/ru`}
              label="Российские события"
            />
            <LinkFooterMenu
              href={`${routes.calendar.path}/sups`}
              label="Суперсерия ФТР 2021"
            />
            <LinkFooterMenu
              href="/"
              label="Суперсерия"
              margin={{ bottom: `30px` }}
            /> */}

          {/*<Heading level="5" margin={{bottom: `5px`}}>Партнеры</Heading>*/}
          {/*<LinkFooterMenu href="/" label="Рейтинг корпоративных команд"/>*/}
          {/*<LinkFooterMenu href="/" label="Партнеры ФТР"/>*/}
          {/*<LinkFooterMenu href="/" label="Партнеры региональных федераций"/>*/}
          {/* </Box>
          <Box>
            <Heading level="5" margin={{ bottom: `5px` }}>
              Федерация
            </Heading>
            <LinkFooterMenu href="/management" label="Руководство" />
            <LinkFooterMenu
              href={`${routes.regions.path}`}
              label="Региональные федерации"
            />
            <LinkFooterMenu
              href={`${routes.documents.path}`}
              label="Документы"
            /> */}
          {/*<LinkFooterMenu href="/" label="Рейтинг федераций"/>*/}
          {/* <LinkFooterMenu href={`${routes.contacts.path}`} label="Контакты" />
            <LinkFooterMenu
              href={`${routes.partners.path}`}
              label="Партнеры ФТР"
            />
            <LinkFooterMenu
              href={`${routes.antidoping.path}`}
              label="Антидопинг"
            />
            <LinkFooterMenu
              href={`${routes.superseries.path}`}
              label="Суперсерия ФТР 2021"
            />  */}
          {/*<LinkFooterMenu href="/" label="Для СМИ"/>*/}
          {/*<LinkFooterMenu href="/" label="Как стать судьёй"/>*/}
          {/*<LinkFooterMenu href="/" label="Организовать свой старт"/>*/}
          {/*<LinkFooterMenu href="/" label="Партнеры / Презентация ФТР"/>*/}
          {/*<LinkFooterMenu href="/" label="Комиссии"/>*/}
          {/* </Box> */}
          {/*<Box>*/}
          {/*  <Heading level="5" margin={{bottom: `5px`}}>Триатлон России</Heading>*/}
          {/*  <LinkFooterMenu href="/" label="Спортсмены федерации"/>*/}
          {/*  <LinkFooterMenu href="/" label="Результаты соревнований"/>*/}
          {/*</Box>*/}
          {/* </Grid> */}
          <Box direction="row" flex="grow" justify="between" wrap>
            <Box pad={{ bottom: `medium` }}>
              <Box pad={{ bottom: `small` }}>
                <Text size="12px" color="#333333" pad={{ bottom: `small` }}>
                  Все права защищены 2022 Федерация Триатлона России (с)
                </Text>
              </Box>
              <Box pad={{ bottom: 'medium' }}>
                <Text size="12px" color="#333333" pad={{ bottom: 'small' }}>
                  ИНН 7704123065, ОГРН 1037746009113, ООО «ФТР», 119992, г.
                  Москва, Лужнецкая набережная, д.8
                </Text>
              </Box>
              <Box>
                <Text size="12px" color="#333333" pad={{ bottom: `small` }}>
                  <Anchor href="mailto:office@rustriathlon.ru">
                    office@rustriathlon.ru
                  </Anchor>
                </Text>
              </Box>
            </Box>

            <Box>
              <Box pad={{ bottom: `xsmall` }}>
                <Anchor href={`https://disk.yandex.ru/i/vR_JyGNrtWDhvg`}>
                  Публичная оферта
                </Anchor>
              </Box>
              <Box margin={{ right: `15px`, top: `small` }}>
                {/*<Text margin={{bottom: `8px`}} size="10px" color="#333333">Дизайн и воплощение:</Text>*/}
                <Image src={logoMl} width="131" height="26" />
              </Box>
            </Box>

            {/*<Box height="45px">
            <Image fit="contain" src={PaymentLogos} />
          </Box>*/}
          </Box>
        </Box>
      </Grid>
    </Container>
  );
};

export default Footer;
