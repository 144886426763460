import { Router, Switch, Route } from 'react-router-dom';
import history from './history';
import Main from 'pages/Main/Main';
import News from 'pages/News/News';
import NewsList from 'pages/News/NewsList';
import PartnersList from 'pages/Partners/Partners';
import Management from 'pages/Management/Management';
import MainCast from 'pages/MainCast/MainCast';
import Calendar from 'pages/Calendar/Calendar';
import ErrorPage from 'pages/Error/ErrorPage';
import SuccessPage from 'pages/Success/SuccessPage';
import { routes } from 'const/routes';
// import HistoryTria from './pages/Articles/HistoryTria/HistoryTria';
import whereToStart from './pages/Articles/whereToStart/whereToStart';
import highEducation from './pages/Articles/highEducation/highEducation';
import Disciplines from './pages/Articles/Disciplines/Disciplines';
import WhatIsTriathlon from './pages/Articles/WhatIsTriathlon/WhatIsTriathlon';
import YouthTeam from './pages/YouthTeam/YouthTeam';
import JuniorTeam from './pages/JuniorTeam/JuniorTeam';
import YouthSquad from './pages/YouthSquad/YouthSquad';
import CoachingStaff from './pages/CoachingStaff/CoachingStaff';
import WinterTriathlon from './pages/WinterTriathlon/WinterTriathlon';
import Protocols from './pages/Protocols/Protocols';
import Regions from './pages/Regions/Regions';
import Documents from './pages/Documents/Documents';
import DocumentsPage from './pages/DocumentsPage/DocumentsPage';
import Antidoping from './pages/Antidoping/Antidoping';
import SelectionCriterias from './pages/SelectionCriterias/SelectionCriterias';
import Archive from './pages/Archive/Archive';
import RatingsAthletes from './pages/RatingsAthletes/RatingsAthletes';
import Contacts from './pages/Contacts/Contacts';
import ElectionResultsAnnual from './pages/Articles/ElectionResultsAnnual/ElectionResultsAnnual';
import FtrFvsrCollaboration from './pages/Articles/FtrFvsrCollaboration/FtrFvsrCollaboration';
import IndoorTriathlonJunior from './pages/Articles/IndoorTriathlonJunior/IndoorTriathlonJunior';
import ChampionatTuymenskoyOblasti from './pages/Articles/ChampionatTuymenskoyOblasti/ChampionatTuymenskoyOblasti';
import ChampionatKemerovo2021 from './pages/Articles/ChampionatKemerovo2021/ChampionatKemerovo2021';
import FirstMeetingParatriathlon from './pages/Articles/FirstMeetingParatriathlon/FirstMeetingParatriathlon';
import article10032100 from './pages/Articles/News/news10032100';
import article10032101 from './pages/Articles/News/news10032101';
import article150321 from './pages/Articles/News/news150321';
import article220321 from './pages/Articles/News/news220321';
import article280321 from './pages/Articles/News/news280321';
import article070421 from './pages/Articles/News/news070421';
import article190421 from './pages/Articles/News/news190421';
import article200421 from './pages/Articles/News/news200421';
import ScrollToTop from 'components/System/ScrollToTop';
import AquathlonTeam from './pages/AquathlonTeam/AquathlonTeam';
import TeamMiddleDistance from './pages/TeamMiddleDistance/TeamMiddleDistance';
import CrossTriathlon from './pages/CrossTriathlon/CrossTriathlon';
import TeamDuathlon from './pages/TeamDuathlon/TeamDuathlon';
import OlympicReserve from './pages/OlympicReserve/OlympicReserve';
import ChildrensTriathlon from './pages/ChildrensTriathlon/ChildrensTriathlon';
import License from './pages/License/License';
import Results from 'pages/Results/Results';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Amateurs from 'pages/Amateurs';
import Thanks from './pages/Thanks/Thanks';
import InternationalTriathlon from './pages/InternationalTriathlon/InternationalTriathlon';


const ExternalRedirect = ({ to, ...routeProps }) => {
   return <Route {...routeProps} render={() => window.location = to} />;
};

export default function App() {
  return (
    <Router history={history}>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route exact path={routes.main.path} component={Main} />

        <Route
          path={`${routes.news.path}/:id`}
          render={match => <News match={match} />}
        />

        <Route
          path={`${routes.mainCast.path}/:id`}
          render={match => <News match={match} />}
        />

        {
          // <Route
          //   path={`${routes.calendar.path}/:id`}
          //   render={(match) => <News match={match} />}
          // />
        }

        <Route
          path={`${routes.management.path}/:id`}
          render={match => <News match={match} />}
        />

        <Route exact path={routes.amateurs.path} component={Amateurs} />

        <Route exact path={routes.management.path} component={Management} />
        <Route exact path={routes.mainCast.path} component={MainCast} />
        <Route path={`${routes.calendar.path}/:type`} component={Calendar} />
        <Route exact path={routes.documents.path} component={Documents} />
        <Route
          path={`${routes.documents.path}/:type`}
          component={DocumentsPage}
        />
        <ExternalRedirect exact path={routes.historyTria.path} to={"/history/"} />
        <Route exact path={routes.whereToStart.path} component={whereToStart} />
        <Route
          exact
          path={routes.highEducation.path}
          component={highEducation}
        />
        <Route exact path={routes.disciplines.path} component={Disciplines} />
        <Route
          exact
          path={routes.whatIsTriathlon.path}
          component={WhatIsTriathlon}
        />
        <Route
          exact
          path={routes.whatIsTriathlon.path}
          component={WhatIsTriathlon}
        />
        <Route exact path={routes.youthTeam.path} component={YouthTeam} />
        <Route exact path={routes.juniorTeam.path} component={JuniorTeam} />
        <Route exact path={routes.youthSquad.path} component={YouthSquad} />
        <Route
          exact
          path={routes.aquathlonTeam.path}
          component={AquathlonTeam}
        />
        <Route
          exact
          path={routes.teamMiddleDistance.path}
          component={TeamMiddleDistance}
        />
        <Route
          exact
          path={routes.crossTriathlon.path}
          component={CrossTriathlon}
        />
        <Route exact path={routes.teamDuathlon.path} component={TeamDuathlon} />
        <Route
          exact
          path={routes.coachingStaff.path}
          component={CoachingStaff}
        />
        <Route
          exact
          path={routes.winterTriathlon.path}
          component={WinterTriathlon}
        />
        <Route
          exact
          path={routes.olympicReserve.path}
          component={OlympicReserve}
        />
        <Route exact path={routes.protocols.path} component={Protocols} />
        <Route exact path={routes.regions.path} component={Regions} />
        <Route exact path={routes.antidoping.path} component={Antidoping} />
        {/* <Route exact path={routes.superseries.path} component={Superseries} /> */}
        <Route exact path={routes.results.path} component={Results} />
        <Route
          exact
          path={routes.selectionCriterias.path}
          component={SelectionCriterias}
        />
        <Route exact path={routes.archive.path} component={Archive} />
        <Route
          exact
          path={routes.ratingsAthletes.path}
          component={RatingsAthletes}
        />
        <Route exact path={routes.news.path} component={NewsList} />
        <Route exact path={routes.partners.path} component={PartnersList} />
        <Route exact path={routes.contacts.path} component={Contacts} />
        <Route
          exact
          path={routes.childrensTriathlon.path}
          component={ChildrensTriathlon}
        />
        <Route
          exact
          path={routes.electionResultsAnnual.path}
          component={ElectionResultsAnnual}
        />
        <Route
          exact
          path={routes.ftrFvsrCollaboration.path}
          component={FtrFvsrCollaboration}
        />
        <Route
          exact
          path={routes.indoorTriathlonJunior.path}
          component={IndoorTriathlonJunior}
        />
        <Route
          exact
          path={routes.championatTuymenskoyOblasti.path}
          component={ChampionatTuymenskoyOblasti}
        />
        <Route
          exact
          path={routes.championatKemerovo2021.path}
          component={ChampionatKemerovo2021}
        />
        <Route
          exact
          path={routes.firstMeetingParatriathlon.path}
          component={FirstMeetingParatriathlon}
        />
        <Route
          exact
          path={routes.news10032100.path}
          component={article10032100}
        />
        <Route
          exact
          path={routes.news10032101.path}
          component={article10032101}
        />
        <Route exact path={routes.news150321.path} component={article150321} />
        <Route exact path={routes.news220321.path} component={article220321} />
        <Route exact path={routes.news280321.path} component={article280321} />
        <Route exact path={routes.news070421.path} component={article070421} />
        <Route exact path={routes.news190421.path} component={article190421} />
        <Route exact path={routes.news200421.path} component={article200421} />
        <Route path={routes.internationalTriathlon.path} component={InternationalTriathlon} />
        <Route exact path={routes.license.path} component={License} />
        <Route exact path={routes.error.path} component={ErrorPage} />
        <Route exact path={routes.success.path} component={SuccessPage} />
        <Route path={routes.thanks.path} component={Thanks} />
        {/* <Route path="*">
          <Redirect to={routes.error.path} />
        </Route> */}
      </Switch>
      <Footer />
    </Router>
  );
}
