import sp01 from 'images/team_duathlon/1.png';
import sp02 from 'images/team_duathlon/2.png';
import sp03 from 'images/team_duathlon/3.png';
import sp04 from 'images/team_duathlon/4.png';
import sp05 from 'images/team_duathlon/5.png';
import sp06 from 'images/team_duathlon/6.png';
import sp07 from 'images/team_duathlon/7.png';
import sp08 from 'images/team_duathlon/8.png';
import sp09 from 'images/team_duathlon/9.png';
import sp10 from 'images/team_duathlon/10.png';
import sp11 from 'images/team_duathlon/11.png';
import sp12 from 'images/team_duathlon/12.png';
import sp13 from 'images/team_duathlon/13.png';
import sp14 from 'images/team_duathlon/14.png';
import sp15 from 'images/team_duathlon/15.png';
import sp16 from 'images/team_duathlon/16.png';
import sp18 from 'images/team_duathlon/18.png';
import sp19 from 'images/team_duathlon/19.png';
import sp20 from 'images/team_duathlon/20.png';
import sp21 from 'images/team_duathlon/21.png';
import sp22 from 'images/team_duathlon/22.png';
import sp23 from 'images/team_duathlon/23.png';
import sp24 from 'images/team_duathlon/24.png';
import sp26 from 'images/team_duathlon/26.png';
import sp27 from 'images/team_duathlon/27.png';
import coverImg from '../../images/cover4.jpg';
import { Box, Heading } from 'grommet';
import AthleteCards from '../../components/AthleteCards/AthleteCards';
import styled from 'styled-components';
import Banner from 'components/Banner';
import Container from 'components/Container/Container';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const CustomHeading = styled(Heading)`
  margin-bottom: 35px;
  max-width: 100%;
  text-align: center;
  text-transform: uppercase;
`;

const teamMainArr = [
  {
    id: 0,
    title: `МС`,
    name: `Андреев Игорь Борисович`,
    photo: sp01,
    dateBirth: `25/05/1990`,
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Красноярский край` },
      { id: 1, text: `Организация - "Динамо", УОР-1` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Павлов С.В., Брылев А.А., Гижа А.А., Христофоров А.Н.`,
      },
    ],
  },
  {
    id: 1,
    title: `КМС`,
    name: `Лысенко Роман Олегович`,
    photo: sp02,
    dateBirth: `05/03/1996`,
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Ленинградская область` },
      { id: 1, text: `Организация - ШВСМ по ВВС` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Павлов С.В., Брылев А.А., Гижа А.А.`,
      },
    ],
  },
  {
    id: 2,
    title: `МС`,
    name: `Моисеенко Андрей Вячеславович`,
    photo: sp03,
    dateBirth: `22/10/1994`,
    about: [
      { id: 0, text: `Регион - Москва, Красноярский край` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2` },
      { id: 2, text: `Тренер в настоящий момент - Зуев А.Г., Башун Д.Ю.` },
    ],
  },
  {
    id: 3,
    title: `МС`,
    name: `Селиверстов Денис Сергеевич`,
    photo: sp04,
    dateBirth: `29/04/1992`,
    about: [
      { id: 0, text: `Регион: Санкт-Петербург, Ленинградская область` },
      { id: 1, text: `Организация - КОР No1` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Моторин В.Г., Гижа А.А., Смирнов А.В., Павлов С.В.`,
      },
    ],
  },
  {
    id: 4,
    title: `КМС`,
    name: `Коротич София Александровна`,
    photo: sp05,
    dateBirth: `15/09/1993`,
    about: [
      { id: 0, text: `Регион - Новосибирская область` },
      { id: 1, text: `Организация -Федерация триатлона НСО` },
      { id: 2, text: `Тренер в настоящий момент - Малыхин Н.И.` },
    ],
  },
  {
    id: 5,
    title: `МС`,
    name: `Сухорученкова Евгения Вячеславовна`,
    photo: sp06,
    dateBirth: `25/09/1986`,
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Ленинградская область` },
      { id: 1, text: `Организация - ВС, ШВСМ по ВВС` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Парфенцева Т.М., Гааг Д.В., Гижа А.А., Павлов С.В.`,
      },
    ],
  },
];

const teamYouthArr = [
  {
    id: 0,
    title: `КМС`,
    name: `Макаров Алексей Алексеевич`,
    photo: sp07,
    dateBirth: `22/01/1999`,
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Ленинградская область` },
      { id: 1, text: `Организация - ШВСМ по ВВС` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Павлов С.В., Брылев А.А., Гижа А.А.`,
      },
    ],
  },
  {
    id: 1,
    title: `КМС`,
    name: `Бухтияров Михаил Дмитриевич`,
    photo: sp08,
    dateBirth: `24/09/2000`,
    about: [
      { id: 0, text: `Регион - Ярославская область` },
      { id: 1, text: `Организация - МУ СШОР-4` },
      { id: 2, text: `Тренер в настоящий момент - Покровский А.В.` },
    ],
  },
  {
    id: 2,
    title: `КМС`,
    name: `Надоров Иван Евгеньевич`,
    photo: sp09,
    dateBirth: `07/01/2000`,
    about: [
      { id: 0, text: `Регион - Москва` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2` },
      { id: 2, text: `Тренер в настоящий момент - Мещерякова В.Н.` },
    ],
  },
  {
    id: 3,
    title: `КМС`,
    name: `Сруртдинов Тимур Александрович`,
    photo: sp10,
    dateBirth: `13/05/1997`,
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Ленинградская область` },
      { id: 1, text: `Организация - ШВСМ по ВВС` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Павлов С.В., Брылев А.А., Гижа А.А., Парфенцева Т.М.`,
      },
    ],
  },
  {
    id: 4,
    title: `КМС`,
    name: `Творогов Григорий Александрович`,
    photo: sp11,
    dateBirth: `28/11/2000`,
    about: [
      { id: 0, text: `Регион - Московская область, Щелково` },
      { id: 1, text: `Организация - ФГБУ ГУОР г.Щелково, ГБУ МО "СШОР по МО` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Заусайлов С.А., Заусайлова О.С., Творогова Н.И.`,
      },
    ],
  },
];

const teamJuniorArr = [
  {
    id: 5,
    title: `КМС`,
    name: `Кузьмин Кирилл Николаевич`,
    photo: sp12,
    dateBirth: `02/05/2003`,
    about: [
      { id: 0, text: `Место рождения: Краснодарский край, Белореченск` },
      { id: 1, text: `Организация - ГБУ КК "ЦОП по плаванию"` },
      { id: 2, text: `Тренер в настоящий момент - Цепаева Т.Г., Цепаев А.А.` },
    ],
  },
  {
    id: 6,
    title: `КМС`,
    name: `Семенов Максим Сергеевич`,
    photo: sp13,
    dateBirth: `15/01/2002`,
    about: [
      { id: 0, text: `Регион: Московская область, Щелково` },
      { id: 1, text: `Организация - ФГБУ ГУОР г.Щелково, ГБУ МО "СШОР по МО` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Заусайлов С.А., Заусайлова О.С.`,
      },
    ],
  },
  {
    id: 7,
    title: `КМС`,
    name: `Хабибуллин Дамир Ильдарович`,
    photo: sp14,
    dateBirth: `23/01/2002`,
    about: [
      { id: 0, text: `Регион: Саратовская область` },
      { id: 1, text: `Организация - МУ "ЦСШ"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Мещеряков С.П., Полухин Д.В., Савельев Д.И.`,
      },
    ],
  },
  {
    id: 8,
    title: `КМС`,
    name: `Юшков Иван Дмитриевич`,
    photo: sp15,
    dateBirth: `17/09/2003`,
    about: [
      { id: 0, text: `Регион: Красноярский край, Новосибирская область` },
      { id: 1, text: `Организация - МАУ СШОР "Здоровый мир"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Христофоров А.Н., Малыхин Н.И.`,
      },
    ],
  },
  {
    id: 9,
    title: `1 РАЗРЯД`,
    name: `Секира Ирина Юрьевна`,
    photo: sp16,
    dateBirth: `25/04/2003`,
    about: [
      { id: 0, text: `Регион: Красноярский край` },
      { id: 1, text: `Организация - МАУ СШОР "Здоровый мир"` },
      { id: 2, text: `Тренер в настоящий момент - Башун Д.Ю., Башун Т.В.` },
    ],
  },
];

const squadJuniorArr = [
  {
    id: 0,
    title: `КМС`,
    name: `Титаев Андрей Андреевич`,
    photo: ``,
    dateBirth: `05/05/2005`,
    about: [
      { id: 0, text: `Регион - Саратовская область` },
      { id: 1, text: `Организация - МУ "ЦСШ"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Белоусова К.В., Полухин Д.В., Мещеряков С.П.`,
      },
    ],
  },
];

const teamMainCrossArr = [
  {
    id: 0,
    title: `ЗМС`,
    name: `Васильев Иван Михайлович`,
    photo: sp18,
    dateBirth: `07/09/1984`,
    about: [
      { id: 0, text: `Регион - Тюменская область` },
      { id: 1, text: `Организация - ФТТО` },
      { id: 2, text: `Тренер в настоящий момент - Сушкова Н.А.` },
    ],
  },
  {
    id: 1,
    title: `МС`,
    name: `Евдокимов Алексей Леонидович`,
    photo: sp19,
    dateBirth: `17/01/1987`,
    about: [
      { id: 0, text: `Регион - Кировская область, Верхошижемье` },
      { id: 1, text: `Организация - КРОО "Федерация триатлона"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Андреев П.В., Корчемкин Ю.А., Рудин А.В.`,
      },
    ],
  },
  {
    id: 2,
    title: `КМС`,
    name: `Матвеенко Егор Александрович`,
    photo: sp20,
    dateBirth: `31/03/1995`,
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Организация - СК "Медведь" МАУ СШОР "Здоровый мир"` },
      { id: 2, text: `Тренер в настоящий момент - Башун Д.Ю., Башун Т.В.` },
    ],
  },
  {
    id: 3,
    title: `МС`,
    name: `Минченко Игорь Андреевич`,
    photo: sp21,
    dateBirth: `02/03/1995`,
    about: [
      { id: 0, text: `Регион - Республика Татарстан` },
      { id: 1, text: `Организация - ГАУ ЦСП` },
      { id: 2, text: `Тренер в настоящий момент - Минченко А.Е.` },
    ],
  },
  {
    id: 4,
    title: `МС`,
    name: `Тараканов Кирилл Андреевич`,
    photo: sp22,
    dateBirth: `18/12/1996`,
    about: [
      { id: 0, text: `Регион - Ярославская область` },
      { id: 1, text: `Организация - МУ СШОР No 19, ВС` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Тараканов А.В., Тараканова Ю.Ф.`,
      },
    ],
  },
  {
    id: 5,
    title: `МС`,
    name: `Белозерцева Марина Сергеевна`,
    photo: sp23,
    dateBirth: `18/02/1990`,
    about: [
      { id: 0, text: `Регион - Кировская область, Киров` },
      { id: 1, text: `Организация - КОГАУ ЦСП "Вятка- старт"` },
      { id: 2, text: `Тренер в настоящий момент - Корчемкин Ю.А., Рудин А.В.` },
    ],
  },
];

const teamJuniorCrossArr = [
  {
    id: 0,
    title: `КМС`,
    name: `Выходов Лев Сергеевич`,
    photo: sp24,
    dateBirth: `01/06/2002`,
    about: [
      { id: 0, text: `Регион - Ярославская область` },
      { id: 1, text: `Организация - МУ СШОР No 19` },
      { id: 2, text: `Тренер в настоящий момент - Покровский А.В.` },
    ],
  },
  {
    id: 1,
    title: `КМС`,
    name: `Егоров Данила Витальевич`,
    photo: ``,
    dateBirth: `08/09/2001`,
    about: [
      { id: 0, text: `Регион - Чувашская Республика` },
      { id: 1, text: `Организация - МБУ "СШ им А.И Тихонова" г.Чебоксары` },
      { id: 2, text: `Тренер в настоящий момент - Васильев А.В.` },
    ],
  },
  {
    id: 2,
    title: `КМС`,
    name: `Левашев Иван Сергеевич`,
    photo: sp26,
    dateBirth: `08/12/2002`,
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Организация - СК "Медведь", СШОР "Здоровый мир"` },
      { id: 2, text: `Тренер в настоящий момент - Башун Д.Ю., Башун Т.В.` },
    ],
  },
  {
    id: 3,
    title: `КМС`,
    name: `Привалов Егор Павлович`,
    photo: sp27,
    dateBirth: `05/03/2002`,
    about: [
      { id: 0, text: `Регион - Ярославская область` },
      { id: 1, text: `Организация - МУ СШОР No 19` },
      { id: 2, text: `Тренер в настоящий момент - Константинов В.В.` },
    ],
  },
  {
    id: 4,
    title: `КМС`,
    name: `Садовая Полина Андреевна`,
    photo: ``,
    dateBirth: `14/12/2003`,
    about: [
      { id: 0, text: `Регион - Саратовская область` },
      { id: 1, text: `Организация - СШОР "Надежда Губернии"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Константинов А.С., Брегеда Д.О.`,
      },
    ],
  },
];

const TeamDuathlon = () => {
  return (
    <>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />
        <Box>
          <CustomHeading level={`2`}>Основной состав</CustomHeading>

          <AthleteCards athletesArr={teamMainArr} />
        </Box>
        <Box>
          <CustomHeading level={`2`}>Молодёжный состав</CustomHeading>

          <AthleteCards athletesArr={teamYouthArr} />
        </Box>
        <Box>
          <CustomHeading level={`2`}>Юниорский состав</CustomHeading>

          <AthleteCards athletesArr={teamJuniorArr} />
        </Box>
        <Box>
          <CustomHeading level={`2`}>Юношеский состав</CustomHeading>

          <AthleteCards athletesArr={squadJuniorArr} />
        </Box>

        <Box>
          <CustomHeading level={`2`} style={{ fontSize: `44px` }}>
            Дуатлон-кросс
          </CustomHeading>
          <CustomHeading level={`2`}>Основной состав</CustomHeading>

          <AthleteCards athletesArr={teamMainCrossArr} />
        </Box>
        <Box>
          <CustomHeading level={`2`}>Юниорский состав</CustomHeading>

          <AthleteCards athletesArr={teamJuniorCrossArr} />
        </Box>
      </Container>
    </>
  );
};

export default TeamDuathlon;
