import { Box, List, Text } from 'grommet';
import coverImg from '../../images/cover4.jpg';
import Container from '../../components/Container/Container';
import Partners from '../../components/Partners/Partners';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Banner from 'components/Banner';

const Contacts = () => {
  const contactsArr = [
    { label: `Общероссийская общественная организация` },
    { label: `«Федерация триатлона России»` },
    { label: `Сокращенное наименование: ООО «ФТР»` },
    //{ label: `Телефон: +7 495 637-94-35`},
    { label: `Email: office@rustriathlon.ru` },
    { label: `Партнёрство: partners@rustriathlon.ru` },
    {
      label: `Юридический адрес: 119991, г. Москва, Лужнецкая набережная, д.8 `,
    },
  ];

  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />

        <Box style={{ maxWidth: `1190px` }}>
          <List data={contactsArr} pad="medium" border={false}>
            {contactsArr => (
              <Box direction="row-responsive" gap="medium" align="center">
                <Text>{contactsArr.label}</Text>
              </Box>
            )}
          </List>
        </Box>

        <Partners />
      </Container>
    </>
  );
};

export default Contacts;
