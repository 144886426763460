import { Box, Heading } from 'grommet';
import alypovPhoto from '../../images/Aquathlon/alypov.jpg';
import bryukhankovPhoto from '../../images/Aquathlon/bryukhankov.jpg';
import cherewackiPhoto from '../../images/Aquathlon/cherewacki.jpg';
import dmitrievaPhoto from '../../images/Aquathlon/dmitrieva.png';
import zapatrinaPhoto from '../../images/Aquathlon/zapatrina.png';
import gorshkovPhoto from '../../images/Aquathlon/gorshkov.png';
import gureevaPhoto from '../../images/Aquathlon/gureeva.png';
import meshkovaPhoto from '../../images/Aquathlon/meshkova.png';
import harisovaPhoto from '../../images/Aquathlon/harisova.png';
import styled from 'styled-components';
import AthleteCards from '../../components/AthleteCards/AthleteCards';
import coverImg from '../../images/cover4.jpg';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Container from 'components/Container/Container';
import Banner from 'components/Banner';

const CustomHeading = styled(Heading)`
  margin-bottom: 35px;
  max-width: 100%;
  text-align: center;
  text-transform: uppercase;
`;

const teamMainArr = [
  // {
  //   id: 1,
  //   title: `МСМК`,
  //   name: `Брюханков Александр Александрович`,
  //   photo: bryukhankovPhoto,
  //   dateBirth: '12/04/1987',
  //   about: [
  //     { id: 0, text: `Регион: Пензенская область` },
  //     { id: 1, text: `Организация - ЦСП ПО, ВС` },
  //     { id: 2, text: `Тренер в настоящий момент - Яценко С.Л.` },
  //   ],
  // },
  {
    id: 2,
    title: `КМС`,
    name: `Черевацкий Яков Александрович`,
    photo: cherewackiPhoto,
    dateBirth: '23/11/2000',
    about: [
      { id: 0, text: `Регион: Краснодарский край, Белореченск` },
      { id: 1, text: `Организация - ГБУ КК "ЦОП по плаванию"` },
      { id: 2, text: `Тренер в настоящий момент - Цепаева Т.В.` },
    ],
  },
  {
    id: 3,
    title: `МСМК`,
    name: `Дмитриева Ольга Алексеевна`,
    photo: dmitrievaPhoto,
    dateBirth: '26/06/1981',
    about: [
      { id: 0, text: `Регион - Ленинградская область` },
      { id: 1, text: `Организация - ВС, ШВСМ по ВВС` },
      { id: 2, text: `Тренер в настоящий момент - Яцко А.Н.` },
    ],
  },
  {
    id: 4,
    title: `МСМК`,
    name: `Запатрина Валентина Романовна`,
    photo: zapatrinaPhoto,
    dateBirth: '14/02/1988',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Ленинградская область` },
      { id: 1, text: `Организация - ВС, ШВСМ по ВВС` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Павлов С.В., Гижа А.А., Брылев А.А.`,
      },
    ],
  },
];

const teamJuniorArr = [
  {
    id: 0,
    title: `КМС`,
    name: `Горшков Денис Сергеевич`,
    photo: gorshkovPhoto,
    dateBirth: '08/09/2001',
    about: [
      { id: 0, text: `Регион - Ленинградская область, Волосово` },
      { id: 1, text: `Организация - МКОУ ДО "Волосовская ДЮСШ"` },
      { id: 2, text: `Тренер в настоящий момент - Горшкова И.Ю.` },
    ],
  },
  {
    id: 1,
    title: `КМС`,
    name: `Гуреева Ольга Сергеевна`,
    photo: gureevaPhoto,
    dateBirth: '16/05/2003',
    about: [
      { id: 0, text: `Регион - Волгоградская область` },
      { id: 1, text: `Организация - МБУ СШОР No 16` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Горин М.М., Горина Г.С., Доронин А.А.`,
      },
    ],
  },
  {
    id: 2,
    title: `1 разряд`,
    name: `Мешкова Виктория Сергеевна`,
    photo: meshkovaPhoto,
    dateBirth: '01/12/2003',
    about: [
      { id: 0, text: `Регион - Пензенская область` },
      { id: 1, text: `Организация - ГБУ ДО ПО СШОР водных видов спорта` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Захаров А.В., Брюханкова Т.В.`,
      },
    ],
  },
  {
    id: 3,
    title: `КМС`,
    name: `Харисова Вероника Рустамовна`,
    photo: harisovaPhoto,
    dateBirth: '14/07/2002',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург` },
      { id: 1, text: `Организация - УОР №1` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Титова О.В., Гижа А.А., Логинов О.А.`,
      },
    ],
  },
];

const AquathlonTeam = () => {
  return (
    <div>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />

        <Box>
          <CustomHeading level={`2`}>Основной состав</CustomHeading>

          <AthleteCards athletesArr={teamMainArr} />
        </Box>

        <Box>
          <CustomHeading level={`2`}>Юниоры</CustomHeading>

          <AthleteCards athletesArr={teamJuniorArr} />
        </Box>
      </Container>
    </div>
  );
};

export default AquathlonTeam;
