import newsCover from '../../../images/News/news-cover6.jpg';
import Article from '../Article';
import styled from 'styled-components';
import { Paragraph } from 'grommet';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const FirstMeetingParatriathlon = () => {
  return (
    <Article
      title={`Президент Федерации триатлона Ксения Шойгу провела первую встречу комиссии по паратриатлону`}
      date={`2 МАРТА 2021`}
      coverBack={newsCover}
    >
      <CustomParagraph>
        <strong>
          <em>
            В рамках работы Федерации триатлона России Президент ФТР Ксения Шойгу провела первую встречу комиссии по
            паратриалону, обсудила основные ориентиры для развития и обеспечения необходимым оборудованием паратриатлетов,
            а также вручила значок и удостоверение Мастера спорта России международного класса паратриатлету Михаилу Колмакову.
          </em>
        </strong>
      </CustomParagraph>
      <CustomParagraph>
        <em>
          - Я очень рада, что нам удалось организовать работу комиссии Федерации по паратриатлону. Нам предстоит проделать
          комплексную работу и сейчас важно выстроить правильную, согласованную приоритетность задач.
          В приоритете – поиск спонсоров, разработка единой формы для соревнований и обеспечение паратриатлонистов
          необходимым оборудованием, - отметила президент Федерации триатлона Ксения Шойгу.
        </em>
      </CustomParagraph>
      <CustomParagraph>
        В состав комиссии вошли: Президент ФТР <strong>Ксения Шойгу</strong>, генеральный секретарь ФТР
        <strong>Галина Шиповалова</strong>, главный тренер сборной России по триатлону <strong>Сергей Карачаров</strong>, генеральный
        секретарь федерации лиц с нарушениями
        опорно-двигательного аппарата, руководитель отдела развития спорта высших достижений и обеспечения
        Паралимпийской команды России <strong>Андрей Кочнов</strong>, старший тренер сборной России с поражением опорно-двигательного
        аппарата <strong>Марина Никитина</strong>, параатлет и медиаменеджер <strong>Ярослав Святославский</strong>, глава федерации лиц с нарушениями
        опорно-двигательного аппарата, генеральный секретарь Паралимпийского комитета России <strong>Андрей Строкин</strong>, президент
        Федерации спорта слепых <strong>Лидия Абрамова</strong>, старший тренер сборной команды России по триатлону среди слепых
        <strong>Сергей Ширшов</strong>, член тренерского совета федерации лиц с поражением опорно-двигательного аппарата, один из
        основных разработчиков программы и правил для паратриатлона в классе слабовидящих. <strong>Олег Золотарев</strong>, директор ГГБУ
        Свердловской области «Центр Паралимпийской и Сурдлимпийской подготовки спортивных сборных команд
        Свердловской области «Родник», тренер члена сборной по паратриатлону <strong>Андрей Чекунов</strong>, активист паратриатлона,
        опыт работы над нормативными документами, в судействе и организации мероприятий <strong>Анастасия Головачева</strong>,
        председатель ФТ Курской области, тренер параатлета-колясочника <strong>Олег Кривогузов</strong>.
      </CustomParagraph>
      <CustomParagraph>
        Главными задачами комиссии является формирование общего вектора развития до 2024, выработка стратегии по
        созданию условий для развития паратриатлона и подготовка плана мероприятий по ее реализации, рассмотрение
        широкого спектра вопросов, касающихся развития паратриатлона и донесение позиции комиссии до сведения президиума,
        взаимодействие с организациями, объединениями, прямо или косвенно связанных с паратриатлоном, а также рассмотрение
        поступающих в адрес ФТР обращений, касающихся предмета работы комиссии, а также всех вопросов, касающихся
        паратриатлона внутри ФТР, Федерации спорта лиц с поражениями опорно-двигательного аппарата, Федерацией спорта
        слепых, Паралимпийским комитетом России, Минспортом, ЦСП.
      </CustomParagraph>
      <CustomParagraph>
        <em>- Для нас очень важно взаимодействовать с Федерацией. Мы хотим готовиться к Олимпиаде и выступить на достойном
          уровне, чтобы спортсмены оставались в центре всех наших стратегических планов, как мы делали это всегда,
          и чтобы все наши спортсмены были более заметны,</em> - поделился глава федерации лиц с нарушениями опорно-двигательного
        аппарата, генеральный секретарь Паралимпийского комитета России Андрей Строкин.
      </CustomParagraph>
      <CustomParagraph>
        В рамках заседания комиссии Ксения Шойгу вручила значок и удостоверение Заслуженного Мастера спорта
        Михаилу Колмакову за победы на крупных Российских и Международных соревнованиях. Начиная с 2015 года он выиграл
        чемпионат России, затем — Европы, а потом стал лучшим и на Кубке мира. Новая большая цель — Паралимпийские игры
        в Токио в 2021 году.
      </CustomParagraph>
      <CustomParagraph>
        <b><i>Справка</i></b>
        <br/>
        Первые Всероссийские соревнования по паратриатлону среди опорников состоялись в 2011 году в Нижнем Новгороде.
        На уровне федерального округа такие старты проводятся с 2005 года. В программу Олимпийских игр паратриатлон
        включен начиная с 2016. Сборная России прошла отбор, но не была допущена из-за санкций.
      </CustomParagraph>
      <CustomParagraph>
        Паратриатлеты соревнуются на дистанциях: 750 м плавание, 20 км велосипед (велосипед с ручным приводом/тандем/трехколесный велосипед),
        5 км бег (гонка на колясках). Атлеты последовательно преодолевают дистанцию плавания, велогонки и бега с
        переодеванием в одной или в двух транзитных зонах. Именно на этой дистанции сильнейшие 100 паралимпийцев
        в 6 классах (по 3 у мужчин и женщин) будут бороться за медали на ПИ-2016 в Бразилии.
      </CustomParagraph>
    </Article>
  )
}

export default FirstMeetingParatriathlon;