import { Anchor, Box, Heading, Image, List } from 'grommet';
import coverImg from '../../images/cover4.jpg';
import Container from '../../components/Container/Container';
import pdfIcon from 'images/pdf.png';
import Partners from 'components/Partners/Partners';
import Banner from 'components/Banner';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const criteriaArr21 = [
  {
    label: `Критерии отбора спортсменов сборной команды России по триатлону для участия в централизованной подготовке на 2021 год`,
    link: `https://yadi.sk/i/slpkTFJBVxkkWA`,
  },
  {
    label: `Критерии и условия отбора кандидатов на международные соревнования в 2021 г.`,
    link: `/files/kriterii_otbora.pdf`,
  },
];

const criteriaArr20 = [
  {
    label: `Шкала очков Кубков России и Кубков ФТР`,
    link: `https://yadi.sk/i/cms8Fn7XG-zsgQ`,
  },
  { label: `Олимпийский отбор`, link: `https://yadi.sk/i/QAtbqNZd0F2m6Q` },
  {
    label: `Критерии отбора в основной состав`,
    link: `https://yadi.sk/d/pLwOYftDfYKtiw`,
  },
  {
    label: `Критерии отбора в молодежный состав`,
    link: `https://yadi.sk/i/Em0SF-6jRXC1HA`,
  },
  {
    label: `Критерии отбора в юниорский состав`,
    link: `https://yadi.sk/i/--CpHLmH6G-VSw`,
  },
  {
    label: `Критерии отбора в юношеский состав`,
    link: `https://yadi.sk/i/24AhqPsN6NHbkg`,
  },
];

const criteriaArr19 = [
  { label: `Олимпийский отбор`, link: `https://yadi.sk/i/khYqsOlZXAX_kg` },
  {
    label: `Критерии отбора в основной состав`,
    link: `https://yadi.sk/i/i0EOCNJqfcwenQ`,
  },
  {
    label: `Критерии отбора в молодежный состав`,
    link: `https://yadi.sk/i/95LVDu840DaV8g`,
  },
  {
    label: `Критерии отбора в юниорский состав`,
    link: `https://yadi.sk/i/0OEM8GGiZ1bNdw`,
  },
  {
    label: `Критерии отбора в юношеский состав`,
    link: `https://yadi.sk/i/Z5Bmz4T-B2vRQg`,
  },
  {
    label: `Критерии отбора в эстафетные группы`,
    link: `https://yadi.sk/i/4uh096zHJpkOgg`,
  },
];

const criteriaArr18 = [
  { label: `Шкалы очков-2018`, link: `https://yadi.sk/i/lgCuZHBhTc41Zw` },
  {
    label: `Критерии отбора кандидатов в сборную команду на ставки спортсменов – инструкторов ФГБУ ЦСП по триатлону`,
    link: `https://yadi.sk/i/YdaKCiuPiV8MSw`,
  },
  {
    label: `Критерии отбора кандидатов в юниорский состав спортивной сборной команды России по триатлону`,
    link: `https://yadi.sk/i/0Ie8BZT3HS68rA`,
  },
  {
    label: `Критерии отбора кандидатов в молодежный состав спортивной сборной команды России по триатлону 2018`,
    link: `https://yadi.sk/i/vYwMZmywaA2BxA`,
  },
  {
    label: `Критерии отбора кандидатов в основной состав спортивной сборной команды России по триатлону`,
    link: `https://yadi.sk/i/7RYL0YFCs6T1tA`,
  },
  {
    label: `Критерии отбора на ЮОИ 2018 (Ю16-17)`,
    link: `https://yadi.sk/i/b2wnPuKQNOmjKA`,
  },
  {
    label: `Критерии отбора ПЕ Ю17 на 2018`,
    link: `https://yadi.sk/i/zb6ch4dsNXukfw`,
  },
  {
    label: `Критерии отбора ЧМ, ЧЕ элита на 2018`,
    link: `https://yadi.sk/d/1wTjWnmFIrRSbQ`,
  },
  {
    label: `Шкалы-очков-длинный-триатлон`,
    link: `https://yadi.sk/i/G2GN-buv9lA31g`,
  },
  {
    label: `Рейтинг российских триатлетов`,
    link: `https://yadi.sk/i/Y1CEAvdI9RfwYQ`,
  },
];

const SelectionCriterias = () => {
  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />

        <Box
          style={{
            maxWidth: `1190px`,
            marginLeft: `auto`,
            marginRight: `auto`,
          }}
        >
          <Heading
            level={`1`}
            style={{
              width: `100%`,
              textAlign: `center`,
              textTransform: `uppercase`,
              marginBottom: `30px`,
            }}
          >
            Критерии
          </Heading>
          <Heading
            level={`2`}
            style={{
              width: `100%`,
              maxWidth: `100%`,
              textAlign: `center`,
              textTransform: `uppercase`,
            }}
          >
            2021
          </Heading>
          <List data={criteriaArr21} pad="medium" border={false}>
            {criteriaArr21 => (
              <Box direction="row-responsive" gap="medium" align="center">
                <Image src={pdfIcon} width={`55`} />
                <Anchor
                  href={criteriaArr21.link}
                  label={criteriaArr21.label}
                  style={{
                    fontSize: `16px`,
                    marginTop: `20px`,
                    marginBottom: `20px`,
                  }}
                />
              </Box>
            )}
          </List>

          <Heading
            level={`2`}
            style={{
              width: `100%`,
              maxWidth: `100%`,
              textAlign: `center`,
              textTransform: `uppercase`,
            }}
          >
            2020
          </Heading>
          <List data={criteriaArr20} pad="medium" border={false}>
            {criteriaArr20 => (
              <Box direction="row-responsive" gap="medium" align="center">
                <Image src={pdfIcon} width={`55`} />
                <Anchor
                  href={criteriaArr20.link}
                  label={criteriaArr20.label}
                  style={{
                    fontSize: `16px`,
                    marginTop: `20px`,
                    marginBottom: `20px`,
                  }}
                />
              </Box>
            )}
          </List>

          <Heading
            level={`2`}
            style={{
              width: `100%`,
              maxWidth: `100%`,
              textAlign: `center`,
              textTransform: `uppercase`,
            }}
          >
            2019
          </Heading>
          <List data={criteriaArr19} pad="medium" border={false}>
            {criteriaArr19 => (
              <Box direction="row-responsive" gap="medium" align="center">
                <Image src={pdfIcon} width={`55`} />
                <Anchor
                  href={criteriaArr19.link}
                  label={criteriaArr19.label}
                  style={{
                    fontSize: `16px`,
                    marginTop: `20px`,
                    marginBottom: `20px`,
                  }}
                />
              </Box>
            )}
          </List>

          <Heading
            level={`2`}
            style={{
              width: `100%`,
              maxWidth: `100%`,
              textAlign: `center`,
              textTransform: `uppercase`,
            }}
          >
            2018
          </Heading>
          <List data={criteriaArr18} pad="medium" border={false}>
            {criteriaArr18 => (
              <Box direction="row-responsive" gap="medium" align="center">
                <Image src={pdfIcon} width={`55`} />
                <Anchor
                  href={criteriaArr18.link}
                  label={criteriaArr18.label}
                  style={{
                    fontSize: `16px`,
                    marginTop: `20px`,
                    marginBottom: `20px`,
                  }}
                />
              </Box>
            )}
          </List>
        </Box>

        <Partners />
      </Container>
    </>
  );
};

export default SelectionCriterias;
