import { Box, Button } from 'grommet';
import coverImg from 'images/calendar/mezh.jpg';
import Container from 'components/Container/Container';
import CalendarCurrent from '../../components/Calendar/Calendar';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Banner from 'components/Banner';
import { useParams } from 'react-router-dom';

const Calendar = props => {
  const params = useParams();

  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <Box
          align={`start`}
          direction={`row`}
          gap={`small`}
          margin={{ bottom: `large` }}
          style={{ textAlign: `center` }}
        >
          <Button
            href={`https://www.dropbox.com/sh/qux0td4y3lmcnvb/AABABqHN-vNvhO80xvmzxAMZa?dl=0`}
            primary
            margin={{ top: `small` }}
            label={`Скачать календарь`}
          />
        </Box>

        <CalendarCurrent event_type={params.type} />
      </Container>
    </>
  );
};

export default Calendar;
