import { useState } from 'react';
import { Box, Heading, Button, ResponsiveContext, Image, Text } from 'grommet';
import TroykaImage from 'images/troyka.png';
import styled from 'styled-components';
import TroykaPopup from 'components/Popups/Troyka';

const CustomHeading = styled(Heading)`
  text-transform: uppercase;
`;

const CustomBtn = styled(Button)`
  padding: 6px 24px;
  max-width: 96px;
`;

const CustomParagraph = styled(Text)`
  margin-top: 14px;
  margin-bottom: 14px;
  font-size: 14px;
`;

const Troyka = () => {
  const [isOpenPopup, setOpenPopup] = useState(false);
  const sizes = [`small`, `tablet`,];

  return (
    <div>
      <ResponsiveContext.Consumer>
        {size => (
          <Box
            height={`auto`}
            pad={`small`}
            direction={!sizes.includes(size) ? 'row' : 'column'}
            gap={`large`}
          >
            <Box height="250px" margin={`small`}>
              <Image src={TroykaImage} fit="contain" />
            </Box>
            <Box height="auto" margin={`medium`} width={{max: !sizes.includes(size) ? '50%' : '100%'}}>
              <CustomHeading size={`small`} level={5}>
                Карта "Тройка"
                <br /> Федерации триатлона России
              </CustomHeading>
              <CustomParagraph>
                Коллекционная транспортная карта "Тройка" с изображением всех
                трех дисциплин триатлона.
                <p>Серия ограничена.</p>
              </CustomParagraph>
              <CustomParagraph>
                <b>1500 руб.</b> - включая доставку
              </CustomParagraph>
              <CustomBtn
                type={`submit`}
                primary
                margin={{ top: `medium` }}
                onClick={setOpenPopup}
              >
                Купить
              </CustomBtn>

              {isOpenPopup && <TroykaPopup statePopupHandler={setOpenPopup} />}
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </div>
  );
};

export default Troyka;
