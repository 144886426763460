import { Box } from 'grommet';

const Container = ({ children, overflow }) => {
  return (
    <Box
      width={`1240px`}
      overflow={overflow ? `hidden` : `unset`}
      margin={{ horizontal: `auto` }}
      pad={{ horizontal: `medium` }}
    >
      {children}
    </Box>
  );
};

export default Container;
