import news10032100 from 'images/News/10032100.webp';
import Article from 'pages/Articles/Article';
import styled from 'styled-components';
import { Paragraph } from 'grommet';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const articte10032100 = () => {
  return (
    <Article
      title={`Президент Федерации триатлона России Ксения Шойгу представила план развития ФТР на 2021 год и подписала меморандум о сотрудничестве с ЦСКА`}
      date={`10 МАРТА 2021`}
      coverBack={news10032100}
    >
      <CustomParagraph>
        <b>В пресс-центре МИЦ «Известия» 10 марта состоялась пресс-конференция, посвященная результатам работы ФТР за 3 месяца, дальнейшим планам по развитию триатлона в России и подписанию меморандума о сотрудничестве между ФТР и ЦСКА.</b>
      </CustomParagraph>
      <CustomParagraph>
        В качестве одного из основных направлений развития президент ФТР Ксения Шойгу выделила оценку состояния спортивных баз в регионах с целью выявления тех, которые будут наиболее подходящими для тренировок и проведения соревнований по триатлону. Также уже сейчас начата работа в сфере образовании и повышения квалификации тренерского состава Федерации. Уже в апреле планируется запуск курса для повышения квалификации тренеров по триатлону, разработанный Федерацией совместно с Поволжской Государственной Академией Физической культуры, спорта и туризма. Ксения отметила, что часть обучения будет доступна в онлайн формате для того, чтобы как можно больше желающих смогли пройти обучение.
      </CustomParagraph>
      <CustomParagraph>
        Также президент ФТР отметила, что уже сейчас началось взаимодействие с мировой и европейской федерациями триатлона. Сейчас идет работа над заявками на проведение Чемпионата Европы по триатлону в России и над тем, чтобы собрать всех президентов европейских федераций в России уже в следующем году.
      </CustomParagraph>
      <CustomParagraph>
        «Перед нами стоят амбициозные задачи по множеству аспектов – огромное внимание сейчас уделяется и тренировочным площадкам, и образованию тренерского состава. Очень верю, что работа будет продолжаться также плодотворно, и наши спортсмены уже совсем скоро будут показывать отличные результаты на соревнованиях», - отметила Ксения.
      </CustomParagraph>
      <CustomParagraph>
        В ходе пресс-конференции <b>Ксения Шойгу и начальник Центрального Спортивного Клуба Армии Артем Валерьевич Громов</b> подписали меморандум о сотрудничестве между Федерацией триатлона России и Центральным Спортивных Клубом Армии (ЦСКА), рассказали об основных этапах взаимодействия между организациями.
      </CustomParagraph>
      <CustomParagraph>
        «Такое активное взаимодействие с Федерацией – это яркий пример точек соприкосновения в работе. За такой короткий промежуток времени у нас уже составлена и утверждена дорожная карта, по которой мы спланировали развитие триатлона в вооруженных силах», - поделился начальник ЦСКА Артем Громов.
      </CustomParagraph>
      <CustomParagraph>
        Он также отметил, что в рамках взаимодействия с ФТР по развитию триатлона существует три основные задачи: развитие детско-юношеского спорта, совместная работа над организацией массового спорта и продвижение профессионального спорта.
      </CustomParagraph>
      <CustomParagraph>
        Участниками пресс-конференции также стали члены сборной России по триатлону, которые рассказали о плане подготовки к международным соревнованиям: восьмикратный чемпион мира по зимнему триатлону, заслуженный мастер спорта и участник Чемпионата мира в Андорре <b>Павел Андреев</b>; призёр первенств Европы по триатлону в 2018 и 2019 гг. <b>Валентина Рясова</b>.
      </CustomParagraph>
      <CustomParagraph>
        -«Всегда цели ставили очень высокие, пока что с 2011 года удавалось не проигрывать Чемпионат мира. Сейчас мы завершаем подготовку к соревнованиям, остается неделя до старта зимнего Чемпионата мира в Андорре, и мы вкладываем все силы, чтобы показать максимальный результат», - рассказал Павел.
      </CustomParagraph>
    </Article>
  )
}

export default articte10032100;
