import { useEffect, useState, useRef } from 'react';
import api from 'api';

export const useLoads = (path = `/server/calendar/list`) => {
  const [payload, setPayload] = useState([]);
  const triesRef = useRef(0);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await api.get(path);
        if (response.status === 200) setPayload(response.data);
        else console.error(response);
      } catch (error) {
        console.error(error);
      }
    };

    if (payload.length === 0) loadData();
  }, [path]);

  return [payload];
};
