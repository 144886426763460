import Header from 'components/Header/Header';
import News from 'components/News/News';
import Subscribe from 'components/Subscribe/Subscribe';
import Container from 'components/Container/Container';
import coverImg from 'images/calendar/pageCalendar.jpg';
import Banner from 'components/Banner';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const NewsList = props => {
  return (
    <div>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <News full={true} />

        <Subscribe />
      </Container>
    </div>
  );
};

export default NewsList;
