import coverImg from '../../images/cover4.jpg';
import Container from '../../components/Container/Container';
import Partners from 'components/Partners/Partners';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Banner from 'components/Banner';
import AntidopingTabs from 'components/AntidopingTabs';

const Antidoping = () => {
  return (
    <div>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />
        <AntidopingTabs />
        <Partners />
      </Container>
    </div>
  );
};

export default Antidoping;
