import { Anchor, Heading, Paragraph } from 'grommet';
import styled from 'styled-components';
// import articleImg1 from 'images/articles/igor-vekshin.jpg';
import titleImg from 'images/whatIs.jpg'
import Article from '../Article';
import { routes } from '../../../const/routes';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const CustomUl = styled(`ul`)`
  padding-left: 40px;
  list-style: outside;
`

const WhatIsTriathlon = () => {
  return (
    <Article
      title={`Что такое триатлон`}
      desc={`Как устроен этот вид спорта`}
      coverBack={titleImg}
    >

      <CustomParagraph>
        Триатлон — это многопрофильный вид спорта, состоящий из непрерывного последовательного прохождения участниками трех
        этапов: плавания, велогонки и бега.
      </CustomParagraph>
      <CustomUl>
        <li>
          Плавание происходит в бассейне или на открытой воде (озеро, закрытый водоем или море) и, если вы принимаете
          участие в мероприятии на открытой воде, вам понадобится гидрокостюм. Атрибутами при участии в данном этапе также
          являются плавательные очки и резиновая шапочка на голову.
        </li>
        <li>
          Большинство гонок проходят на дорогах, открытых для других транспортных средств. Если вы новичок в езде на велосипеде,
          было бы полезно попрактиковаться в езде по тихим дорогам, пока не наберетесь уверенности. Велосипедный маршрут вашей
          гонки будет обозначен указателями, и официальные лица будут помогать вам направлять вас. Тем не менее, всегда полезно
          проверить информацию о предстоящем соревновании, чтобы проверить маршрут перед гонкой.
        </li>
        <li>
          Бег после велосипедного этапа может быть для вас непривычным, но по мере того, как вы продолжаете бежать,
          становится легче. На маршруте бега предусмотрены зоны с напитками и едой.
        </li>
      </CustomUl>

      <CustomParagraph>
        <strong>Транзитная зона</strong><br/>
        Профессиональные спортсмены часто называют транзитную зону четвертой дисциплиной в триатлон. Однако для многих
        транзитная зона — это просто место, где все ваши комплекты для плавания, велосипеда и бега хранятся во время гонки.
      </CustomParagraph>
      <CustomParagraph>
        Организаторы предоставляют вам стартовый номер, который необходимо будет иметь на велосипеде и при беге, а также
        чип хронометража, чтобы отсчитывать время от старта до финиша.
      </CustomParagraph>
      <CustomParagraph>
        Вам необходимо поставить велосипед на стойку со своим номером и разложить комплект до начала гонки.
      </CustomParagraph>
      <CustomParagraph>
        Ваш шлем должен быть пристегнут перед перемещением велосипеда, и его нельзя снимать, пока велосипед не будет снова
        установлен в стойку по окончании велогонки.
      </CustomParagraph>
      <CustomParagraph>
        Маршалы и официальное лица мероприятия будут находиться в транзитной зоне, чтобы ответить на любые ваши вопросы.
      </CustomParagraph>

      <Heading level={`3`} margin={{top: `18px`, bottom: `18px`}}>ВОЗМОЖНОСТИ</Heading>

      <CustomParagraph>
        Триатлон состоит из трех дисциплин (плавание, велогонка и бег), которые выполняются одна за другой в непрерывной
        последовательности. Если вы находите конкретную дисциплину сложной физически или практически, тогда вам доступны
        другие варианты мультиспорта.
      </CustomParagraph>
      <CustomParagraph>
        Дуатлон (бег, велосипед, бег), акватлон (плавание, бег) и аквабайк (плавание, велосипед) позволят вам «пропустить»
        конкретную дисциплину, которая вам не подходит в стандартном триатлоне.
      </CustomParagraph>
      <CustomParagraph>
        Вы также можете принять участие в триатлоне как командной эстафете, где один участник будет проходить плавательный
        этап, другой — велогонку, а третий — бег.
      </CustomParagraph>
      <CustomParagraph>
        <strong>Паратриатлон</strong><br/>
        Задумываетесь о Паралимпийских играх или просто хотите стать немного активнее? Триатлон может стать полноценным
        спортом для вас. Существует множество возможностей принять участие, где вы можете просто плавать, участвовать в
        велогонке и бегать.
      </CustomParagraph>
      <CustomParagraph>
        Можно использовать велосипеды-тандемы, трехколесные велосипеды и другие адаптированные велосипеды, а также ручных
        велосипеды и инвалидные коляски для участия в беговом этапе. Если у вас нарушение зрения, вы также можете принять
        участие с гидом, который поддержит вас на дистанции.
      </CustomParagraph>
      <Anchor href={routes.disciplines.path} label={`Узнать про дисциплины`} />
      {
      //   <CustomParagraph style={{color: `red`, fontStyle: `italic`}}>
      //   Если вы думаете, что готовы продвинуться дальше в триатлоне, и вы начинающий паралимпиец, тогда, пожалуйста, свяжитесь
      //   с нашим менеджером по паратриатлону, который поможет вам сделать первые шаги на этом пути.
      // </CustomParagraph>
      
      // <Anchor href={`/`} label={`Связаться`} />
}
      <Heading level={`3`} margin={{top: `18px`, bottom: `18px`}}>ДИСТАНЦИИ</Heading>
      <CustomParagraph>
        Организаторы имеют право выбирать длину дистанции по своему усмотрению, кроме случаев, когда проводятся официальные
        соревнования: чемпионаты мира и Европы, этапы Кубков мира и Европы.
      </CustomParagraph>
      <CustomParagraph>
        В настоящее время под юрисдикцией ITU проводятся соревнования по акватлону (бег, плавание, бег), зимнему триатлону
        (кросс, велокросс, лыжная гонка), по триатлону в закрытых помещениях и дуатлону.
        {
        // <Anchor href={`/`} label={` Свяжитесь со своим региональным главным тренером, чтобы узнать больше.`} />
        }
      </CustomParagraph>
      <CustomParagraph>
        Юноши и юниоры могут соревноваться в Молодежной и Юниорской суперсерии, а также в испытаниях, которые будут отобраны
        в состав своей региональной академической команды.
      {
        // <Anchor href={`/`} label={` Узнайте больше о триатлоне для возрастных групп и о том, как пройти квалификацию.`} />
      }
      </CustomParagraph>
      <CustomParagraph>
        <strong>Существует множество допустимых дистанций для соревнований по триатлону:</strong>
      </CustomParagraph>
      <CustomParagraph>
        8 лет: 50 м (плавание), 800 м (цикл на траве), 600 м (бег) <br/>
        9-10 лет: 150 м (плавание), 2 км (цикл на траве), 1200 м (бег) <br/>
        11-12 лет: 200 м (плавание), 4 км (цикл на траве), 1800 м (бег) <br/>
        13-14 лет: 300 м (плавание), 6 км (цикл на траве), 2400 м (бег) <br/>
      </CustomParagraph>
      <CustomParagraph>
        Молодежь (15-16 лет) может соревноваться на дистанции спринта: 400 м (плавание), 10 км (велосипед), 2,5 км (бег).
      </CustomParagraph>
      <CustomParagraph>
        Юниоры (17-18 лет) могут соревноваться на стандартные дистанции: 1500 м (плавание), 40 км (велосипед), 10 км (бег).
      </CustomParagraph>
      <CustomParagraph>
        Юниоры (19 лет) могут соревноваться на средней дистанции: 1,9 км (плавание), 90 км (велосипед), 21 км (бег).
      </CustomParagraph>
      <CustomParagraph>
        Суперспринт: 300 м (плавание), 8 км (велосипед), 2 км (бег) <br/>
        Спринт: 750 м (плавание), 20 км (велосипед), 5 км (бег)
      </CustomParagraph>
      <CustomParagraph>
        Стандартная (олимпийская) дистанция: 1500 м (плавание), 40 км (велосипед), 10 км (бег) <br/>
        70,3 / средняя / половина дистанции Ironman: 1,9 км (плавание), 90 км (велосипед), 21 км (бег)
      </CustomParagraph>
      <CustomParagraph>
        Полная / длинная / дистанция Ironman: 3,8 км (плавание), 180 км (велосипед), 42 км (бег). <br/>
        Ironman – это триатлон на длинные дистанции, организованный Всемирной корпорацией триатлона (WTC)
      </CustomParagraph>
      <CustomParagraph>
        Дистанция спринт-дуатлона: 5 км (бег), 20 км (велосипед), 2,5 км (бег) <br/>
        Стандартная дистанция дуатлона: 10 км (бег), 40 км (велосипед), 5 км (бег)
      </CustomParagraph>
      <CustomParagraph>
        Дистанция акватлона в бассейне: 400 м (плавание), 5 км (бег) <br/>
        Дистанция акватлона на открытой воде: 750 м (плавание), 5 км (бег) <br/>
        Акватлон на открытой воде на длинные дистанции: 1,5 км (плавание), 10 км (бег) <br/>
      </CustomParagraph>

    </Article>
  )
}

export default WhatIsTriathlon;