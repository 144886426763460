import { Box, Image, ResponsiveContext } from 'grommet';

const Banner = ({ cover }) => {
  return (
    <div>
      <ResponsiveContext.Consumer>
        {size => (
          <Box
            margin={{ bottom: `-5.5%` }}
            height={{ max: `470px`, min: `180px` }}
          >
            <Image src={cover} fit="cover" />
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </div>
  );
};

export default Banner;
