import CoachMorePopup from '../Popups/OlympicCoachMore/OlympicCoachMore';
import {
  Box,
  Button,
  Card,
  Grid,
  Grommet,
  Heading,
  Image,
  Text,
} from 'grommet';
import React from 'react';
import couch1 from '../../images/OlympicReserve/couch1.png';
import couch2 from '../../images/OlympicReserve/couch2.png';
import couch3 from '../../images/OlympicReserve/couch3.png';
import { theme } from 'components/Themes/theme';

const olympicCoach = [
  {
    id: 0,
    title: `Спортивный директор в команде Олимпийский резерв`,
    name: `КИРИЛЛ ГОЛДОВСКИЙ`,
    photo: couch1,
    dateBirth: '21/07/1985',
    placeOfBirth: `г. Москва`,
    education: [
      {
        id: 0,
        title: `ГОУ Московское средне специальное Училище Олимпийского Резерва №2 
      2002-2005 гг.`,
        text: `Педагог по физической культуре и спорту
     `,
      },
      {
        id: 1,
        title: `Российский государственный университет физической культуры, спорта и туризма
      2005-2010 гг.`,
        text: `Тренер, Специальность "Физическая культура и спорт»`,
      },
    ],
    about: [
      {
        id: 0,
        title: `Профессиональный опыт:`,
        text: `Опыт работы тренером с 2010 года;
      Спортсмен ЦСКА, инструктор по спорту высшей квалификации;
      В тренерском составе сборной России, выезжал на старты Первенства Европы среди юниоров и юниорок, юношей и девушек до 23 лет и Чемпионатов Мира и Европы по элите. `,
      },
      {
        id: 1,
        title: `Спортивные достижения:`,
        text: `Мастер спорта по триатлону;
      Мастер спорта по дуатлону;
      3-х кратный Чемпион мира среди военных, CISM (2018); 
      2-х кратный Чемпион России по дуатлону;
      Победитель Первенства Европы в Чехии в командном зачете (2003);
      Неоднократный победитель кубков России;
      Неоднократный победитель и призер Чемпионатов Европы и мира;
      Бронзовый призер Чемпионата России по триатлону (2013);
      Победитель и призер стартов Ironman.`,
      },
    ],
  },
  {
    id: 1,
    title: `Заместитель спортивного директора в команде Олимпийский резерв `,
    name: `ЕВГЕНИЙ ПОЛЯНСКИЙ`,
    photo: couch2,
    dateBirth: '26/07/1985',
    placeOfBirth: `г. Москва`,
    education: [
      {
        id: 0,
        title: `ГОУ Московское средне специальное Училище Олимпийского Резерва №2 
        2002-2005 гг.`,
        text: `Педагог по физической культуре и спорту
     `,
      },
      {
        id: 1,
        title: `Московский Педагогический Государственный Университет
        2006 г.`,
        text: `Педагог по физической культуре и спорту`,
      },
      {
        id: 2,
        title: `Московский социально-педагогический институт
        2006-2010 гг.`,
        text: `Педагог-психолог`,
      },
    ],
    about: [
      // {
      //   id: 0,
      //   title: `Профессиональный опыт:`,
      //   text: ` `,
      // },
      {
        id: 1,
        title: `Спортивные достижения:`,
        text: `Мастер спорта международного класса по триатлону;
        Мастер спорта по дуатлону;
        3-х кратный Победитель Первенства Европы по триатлону;
        Неоднократный победитель Чемпионатов и кубков России.`,
      },
    ],
  },
  {
    id: 2,
    title: `Старший тренер команды Олимпийский резерв`,
    name: `АЛЕКСАНДР НИКУЛЬШИН`,
    photo: couch3,
    dateBirth: '11/05/1967',
    placeOfBirth: 'г. Москва',
    education: [
      {
        id: 0,
        title: `Московская Государственная Академия Физической Культуры 
      1984-2013 гг.`,
        text: `Педагог по физической культуре и спорту
    `,
      },
    ],
    about: [
      {
        id: 0,
        title: `Профессиональный опыт:`,
        text: `Опыт работы тренером с 1993 (лыжные гонки), с 1998 года - триатлон;
        2005-2021 гг. – тренер в Училище Олимпийского Резерва №2, г. Москва; 
        Присвоено звание Тренер высшей категории по триатлону;
        В тренерском составе сборной России, выезжал на старты Первенства Европы среди юниоров и юниорок, юношей и девушек до 23 лет и Чемпионатов Мира и Европы по элите.`,
      },
      {
        id: 1,
        title: `Спортивные достижения:`,
        text: `Мастер спорта по лыжным гонкам (1986);
        Член первой сборной СССР по триатлону (1989);
        Первый чемпион СССР по триатлону в г. Зеленогорск (1990);
        Бронзовый призер Кубка России по горному бегу (1999);
        Победитель и призёр международных соревнований.
        
        Ученики завоевали более 180 медалей на Российских соревнованиях (Чемпионаты и Первенства России). На международных стартах — 28 медалей (Чемпионаты, Первенства, Кубки Европы и Мира).`,
      },
    ],
  },
];

const CoachTab = () => {
  const [isOpenPopup, setOpenPopup] = React.useState(false);
  const [personItem, setPersonItem] = React.useState(null);

  const handleClickBtnMore = person => {
    setPersonItem(person);
    setOpenPopup(true);
  };
  return (
    <Grommet theme={theme}>
      <Box>
        <Heading
          level={`3`}
          margin={{ top: `18px`, bottom: `18px` }}
          style={{ textTransform: `uppercase` }}
        >
          Тренерский состав
        </Heading>

        <Grid
          margin={{ bottom: `96px` }}
          columns={{ count: `fit`, size: `280px` }}
          justify={`center`}
          gap={`medium`}
        >
          {olympicCoach.map(item => (
            <Card
              margin={{ top: 'medium' }}
              width={{ max: `330px` }}
              background={`#F8F8F8`}
              elevation={`none`}
              key={item.id}
              style={{ width: `100%`, height: `auto` }}
            >
              <Box height={{ min: `310px`, max: `320px` }}>
                <Image src={item.photo} fit={`cover`} />
              </Box>
              <Box
                height={`100%`}
                direction={`column`}
                justify={`between`}
                pad={{
                  top: `32px`,
                  right: `21px`,
                  bottom: `40px`,
                  left: `24px`,
                }}
              >
                <Text
                  margin={{ bottom: `58px` }}
                  style={{
                    color: `rgba(0, 0, 0, 0.5)`,
                  }}
                >
                  <Text>{item.title}</Text>
                </Text>
                <Box>
                  <Heading
                    level={3}
                    margin={{ bottom: `32px` }}
                    style={{ textTransform: `uppercase`, lineHeight: `1.2` }}
                  >
                    {item.name}
                  </Heading>
                  <Button
                    style={{
                      backgroundColor: '#DB4C4C',
                      border: 'none',
                      padding: '10px',
                      borderRadius: '30px',
                    }}
                    label={
                      <Text size="14px" weight="normal" color="white">
                        Подробнее
                      </Text>
                    }
                    onClick={() => {
                      handleClickBtnMore(item);
                    }}
                  />
                </Box>
              </Box>
            </Card>
          ))}
        </Grid>

        {isOpenPopup && (
          <CoachMorePopup
            statePopupHandler={setOpenPopup}
            personItem={personItem}
          />
        )}
      </Box>
    </Grommet>
  );
};

export default CoachTab;
