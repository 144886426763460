import { Box, Button, Collapsible, Text } from 'grommet';
import { Down } from 'grommet-icons';
import React from 'react';

export default function CollapsibleCard({ title, text, body, open, onClick }) {
  return (
    <Box>
      <Box>
        <Button
          size="large"
          style={{
            backgroundColor: '#F8F8F8',
            border: 'none',
            textAlign: 'start',
          }}
          onClick={onClick}
          label={
            <Box
              pad={{ horizontal: '', vertical: 'medium' }}
              flex
              direction="column"
              justify="between"
            >
              <Box pad={{ bottom: 'medium' }}>
                <Text size="0.875rem">{text}</Text>
              </Box>
              <Box flex direction="row" justify="between">
                <Text weight="bold" size="medium">
                  {title}
                </Text>
                <Down />
              </Box>
            </Box>
          }
        />
      </Box>

      <Collapsible open={open}>
        <Box round="medium" pad="small" align="center" justify="start">
          <Text
            size="14px"
            style={{
              whiteSpace: 'pre-line',
              lineHeight: '18px',
            }}
          >
            {body}
          </Text>
        </Box>
      </Collapsible>
    </Box>
  );
}
