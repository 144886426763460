import { dispatch } from '../index';
import * as types from '../types/events';
import api from 'api';

export const getAllEvents = async () => {
  try {
    const { data } = await api.get('/server/calendar/list');
    dispatch({ type: types.EVENTS_GET_ALL_ITEMS, payload: data });
  } catch (error) {
    console.log(error);
  }
};
