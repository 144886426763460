import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import events from "./events";
import { documents } from "./documents";
import protocols from "./protocols";
import regions from "./regions";
import partners from "./partners";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    events,
    documents,
    protocols,
    regions,
    partners,
  });

export default rootReducer;
