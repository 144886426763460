import axios from 'axios';

const REACT_APP_API = process.env.REACT_APP_API;
const TIMEOUT = 5000;

export default axios.create({
  baseURL: REACT_APP_API,
  timeout: TIMEOUT,
  responseType: `json`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});
