import DocumentItem from 'components/DocumentItem';
import { Anchor, Box, Heading, Paragraph } from 'grommet';
import { antidoping } from 'mocks/mocks';

const OtherLinks = () => {
  return (
    <Box>
      {antidoping.map(a => (
        <Box key={a.title} flex direction="column" margin={{ bottom: '20px' }}>
          <DocumentItem title={a.title} link={a.path} />
        </Box>
      ))}
    </Box>
  );
};

export default OtherLinks;
