import { Box, Heading } from 'grommet';
import andreevPhoto from '../../images/winterTriathlon/andreev.jpg';
import breguedaPhoto from '../../images/winterTriathlon/bregueda.jpg';
import vasinPhoto from '../../images/winterTriathlon/vasin.jpg';
import zakharovPhoto from '../../images/winterTriathlon/zakharov.png';
import kirillovPhoto from '../../images/winterTriathlon/kirillov.jpg';
import silinPhoto from '../../images/winterTriathlon/silin.jpg';
import yakimovPhoto from '../../images/winterTriathlon/yakimov.jpg';
import belkinpngPhoto from '../../images/winterTriathlon/belkinpng.png';
import ladyashkinaPhoto from '../../images/winterTriathlon/ladyashkina.jpg';
import rogozinaPhoto from '../../images/winterTriathlon/rogozina.png';
import sokolovaPhoto from '../../images/winterTriathlon/sokolovanew.jpeg';
import surikovPhoto from '../../images/winterTriathlon/surikov.png';
import drozdovPhoto from '../../images/winterTriathlon/drozdov.jpg';
import evgrafovPhoto from '../../images/winterTriathlon/evgrafov.png';
import zalavtsevPhoto from '../../images/winterTriathlon/zalavtsev.jpg';
import matrusovPhoto from '../../images/winterTriathlon/matrusov.jpg';
import karpovaPhoto from '../../images/winterTriathlon/karpova.jpg';
import kuznetsovaPhoto from '../../images/winterTriathlon/kuznetsova.png';
import levkovichPhoto from '../../images/winterTriathlon/levkovich.jpg';
import gorbuntsovPhoto from '../../images/winterTriathlon/juniorsTeam/gorbuntsov.png';
import semenovPhoto from '../../images/winterTriathlon/juniorsTeam/semenov.jpg';
import stroevPhoto from '../../images/winterTriathlon/juniorsTeam/stroev.png';
import timerbulatovPhoto from '../../images/winterTriathlon/juniorsTeam/timerbulatov.png';
import fedoseyevPhoto from '../../images/winterTriathlon/juniorsTeam/fedoseyev.png';
import muravyovaPhoto from '../../images/winterTriathlon/juniorsTeam/muravyova.jpg';
import AthleteCards from '../../components/AthleteCards/AthleteCards';
import styled from 'styled-components';
import Banner from 'components/Banner';
import Container from 'components/Container/Container';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import coverImg from '../../images/cover4.jpg';

const CustomHeading = styled(Heading)`
  margin-bottom: 35px;
  max-width: 100%;
  text-align: center;
  text-transform: uppercase;
`;

const teamJuniorArr = [
  {
    id: 0,
    title: `КМС`,
    name: `Горбунцов Леонид Олегович`,
    photo: gorbuntsovPhoto,
    dateBirth: '08/03/2001',
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Тренер в настоящий момент - Башун Д.Ю., Башун Т.В.` },
    ],
  },
  {
    id: 1,
    title: `КМС`,
    name: `Морозов Олег Дмитриевич`,
    photo: ``,
    dateBirth: '13/02/2002',
    about: [
      { id: 0, text: `Регион - Пермский край, Оханск` },
      { id: 1, text: `Организация - "Метеорит"` },
      { id: 2, text: `Тренер в настоящий момент - Мади В.С.` },
    ],
  },
  {
    id: 2,
    title: `КМС`,
    name: `Семенов Владислав Евгеньевич`,
    photo: semenovPhoto,
    dateBirth: '16/03/2001',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Пермский край` },
      { id: 1, text: `Организация - ВС, УОР-1` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Гижа А.А., Титова О.В., Логинов О.А., Парфенцева Т.М.`,
      },
    ],
  },
  {
    id: 3,
    title: `КМС`,
    name: `Строев Даниил Андреевич`,
    photo: stroevPhoto,
    dateBirth: '08/11/2003',
    about: [
      { id: 0, text: `Регион - Пермский край Березники` },
      { id: 1, text: `Организация - МАУ СП СШ "Летающий Лыжник"` },
      { id: 2, text: `Тренер в настоящий момент - Мишанин С.Ю.` },
    ],
  },
  {
    id: 4,
    title: `КМС`,
    name: `Тимербулатов Марат Ильдусович`,
    photo: timerbulatovPhoto,
    dateBirth: '29/94/2002',
    about: [
      { id: 0, text: `Регион - Саратовская область, Калининск` },
      { id: 1, text: `Организация - МБУ ДО "ДЮСШ г. Калининска"` },
      { id: 2, text: `Тренер в настоящий момент - Кулешов Н.В.` },
    ],
  },
  {
    id: 5,
    title: `КМС`,
    name: `Федосеев Игорь Сергеевич`,
    photo: fedoseyevPhoto,
    dateBirth: '25/04/2004',
    about: [
      { id: 0, text: `Регион - Пермский край Березники` },
      { id: 1, text: `Организация - МАУ СП СШ "Летающий Лыжник"` },
      { id: 2, text: `Тренер в настоящий момент - Мишанин С.Ю.` },
    ],
  },
  {
    id: 6,
    title: `КМС`,
    name: `Муравьева Полина Евгеньевна`,
    photo: muravyovaPhoto,
    dateBirth: '10/06/2001',
    about: [
      { id: 0, text: `Регион: Тверская область, Конаково` },
      { id: 1, text: `Организация - Федерация триатлона Тверской области` },
      { id: 2, text: `Тренер в настоящий момент - Муравьева Н.А.` },
    ],
  },
];

const teamArr = [
  {
    id: 0,
    title: `Змс`,
    name: `Андреев Павел Викторович`,
    photo: andreevPhoto,
    dateBirth: '02/12/1983',
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Организация - КГАУ "РЦСП "АЗВС"` },
      { id: 2, text: `Первый тренер в триатлоне - Андреева П.В.` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Андреева П.В., Христофоров А.Н., Малыхин Н.И.`,
      },
    ],
  },
  {
    id: 1,
    title: `ЗМС`,
    name: `Брегеда Дмитрий Олегович`,
    photo: breguedaPhoto,
    dateBirth: '08/11/1987',
    about: [
      { id: 0, text: `Регион - Саратовская область` },
      { id: 1, text: `Организация - Саратовская областная федерация` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Константинов А.С., Мещеряков С.П., Трушин Б.К.`,
      },
    ],
  },
  {
    id: 2,
    title: `Мсмк`,
    name: `Васин Роман Владимирович`,
    photo: vasinPhoto,
    dateBirth: '18/03/1994',
    about: [
      { id: 0, text: `Регион - Ярославская область` },
      { id: 1, text: `Организация - МУ СШОР № 19, ВС` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Васин В.Н., Сапожников В.П., Сурикова Ю.Н.`,
      },
    ],
  },
  {
    id: 3,
    title: `Мс`,
    name: `Захаров Сергей Николаевич`,
    photo: zakharovPhoto,
    dateBirth: '08/04/1996',
    about: [
      { id: 0, text: `Регион - Удмуртская Республика, Можга` },
      { id: 1, text: `Организация - ЦСП` },
      { id: 2, text: `Тренер в настоящий момент - Гизятов Р.Х.` },
    ],
  },
  {
    id: 4,
    title: `Мсмк`,
    name: `Кириллов Евгений Викторович`,
    photo: kirillovPhoto,
    dateBirth: '03/08/1983',
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Организация - КГАУ "РЦСП "АЗВС"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Христофоров А.Н., Мусиенко В.И.`,
      },
    ],
  },
  {
    id: 5,
    title: `Кмс`,
    name: `Силин Алексей Андреевич`,
    photo: silinPhoto,
    dateBirth: '13/11/1983',
    about: [
      { id: 0, text: `Регион - Пермский край, Краснокамск` },
      { id: 1, text: `Организация - "Метеорит"` },
      { id: 2, text: `Тренер в настоящий момент - Плешков С.А.` },
    ],
  },
  {
    id: 6,
    title: `Мсмк`,
    name: `Якимов Павел Александрович`,
    photo: yakimovPhoto,
    dateBirth: '03/07/1992',
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Организация - КГАУ "РЦСП "АЗВС"` },
      { id: 2, text: `Тренер в настоящий момент - Христофоров А.Н.` },
    ],
  },
  {
    id: 7,
    title: `Мсмк`,
    name: `Белкина Надежда Сергеевна`,
    photo: belkinpngPhoto,
    dateBirth: '18/01/1998',
    about: [
      { id: 0, text: `Регион: Ярославская область` },
      { id: 1, text: `Организация - МУ СШОР No 19, ГУ ЯО "РЦСП", МВД` },
      { id: 2, text: `Первый тренер триатлоне - Тараканов А.В.` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Секлетов А.Л., Сапожников В.П., Сурикова Ю.Н.`,
      },
    ],
  },
  {
    id: 8,
    title: `Мс`,
    name: `Ладяшкина Марина Викторовна`,
    photo: ladyashkinaPhoto,
    dateBirth: '26/04/1990',
    about: [
      { id: 0, text: `Регион - Чувашская Республика, Чебоксары` },
      { id: 1, text: `Организация - МБУ " СШ им А.И Тихонова"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Васильев А.В., Кузьмин В.А.`,
      },
    ],
  },
  {
    id: 9,
    title: `МСМК`,
    name: `Рогозина Дарья Андреевна`,
    photo: rogozinaPhoto,
    dateBirth: '14/08/1996',
    about: [
      { id: 0, text: `Регион - Кировская область, Новосибирская область` },
      { id: 1, text: `Организация - КОГАУ ЦСП "Вятка-старт"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Андреев П.В., Чекунов А.А., Корчемкин Ю.А.`,
      },
    ],
  },
  {
    id: 10,
    title: `Мс`,
    name: `Соколова Светлана Вячеславовна`,
    photo: sokolovaPhoto,
    dateBirth: '26/03/1997',
    about: [
      { id: 0, text: `Регион - Республика Татарстан, Альметьевск` },
      { id: 1, text: `Организация - МАУ СОК "Мирас" г. Альметьевска` },
      { id: 2, text: `Тренер в настоящий момент - Яковлев С.В.` },
    ],
  },
  {
    id: 11,
    title: `Змс`,
    name: `Сурикова Юлия Николаевна`,
    photo: surikovPhoto,
    dateBirth: '09/09/1982',
    about: [
      { id: 0, text: `Регион - Ярославская область, Ярославль` },
      { id: 1, text: `Организация - МУ СШОР № 19, ГУ ЯО "РЦПСР", МВД` },
      { id: 2, text: `Первый тренер триатлоне - Сапожников В.П.` },
      { id: 3, text: `Тренер в настоящий момент - Сапожников В.П.` },
    ],
  },
  {
    id: 12,
    title: `Мс`,
    name: `Дроздов Кирилл Андреевич`,
    photo: drozdovPhoto,
    dateBirth: '21/08/1999',
    about: [
      { id: 0, text: `Регион - Кировская область, Кемеровская область` },
      { id: 1, text: `Организация - КРОО "Федерация триатлона"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Андреева П.В., Андреев П.В., Корчемкин Ю.А.`,
      },
    ],
  },
  {
    id: 13,
    title: `Мс`,
    name: `Евграфов Евгений Юрьевич`,
    photo: evgrafovPhoto,
    dateBirth: '28/08/1998',
    about: [
      { id: 0, text: `Регион - Республика Татарстан` },
      { id: 1, text: `Организация - ЦСП Минспорта РТ` },
      { id: 2, text: `Тренер в настоящий момент - Яковлев С.В.` },
    ],
  },
  {
    id: 14,
    title: `МС`,
    name: `Залавцев Иван Евгеньевич`,
    photo: zalavtsevPhoto,
    dateBirth: '01/11/1998',
    about: [
      { id: 0, text: `Регион - Ярославская область` },
      { id: 1, text: `Организация - МУ СШОР №19` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Тараканов А.В., Сапожников В.П., Сурикова Ю.Н.`,
      },
    ],
  },
  {
    id: 15,
    title: `Мс`,
    name: `Матрусов Антон Дмитриевич`,
    photo: matrusovPhoto,
    dateBirth: '08/02/1997',
    about: [
      { id: 0, text: `Регион - Саратовская область, Саратов` },
      { id: 1, text: `Организация - ГБУСО "СШ"НГ"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Брегеда Д.О., Константинов А.С., Кулешов Н.В.`,
      },
    ],
  },
  {
    id: 16,
    title: `Кмс`,
    name: `Карпова Екатерина Владимировна`,
    photo: karpovaPhoto,
    dateBirth: '09/03/2000',
    about: [
      { id: 0, text: `Регион - Саратовская область` },
      { id: 1, text: `Организация - СШОР "Надежда Губерии"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Константинов А.С., Брегеда Д.О., Мещеряков С.П.`,
      },
    ],
  },
  {
    id: 17,
    title: `Мс`,
    name: `Кузнецова Валерия Сергеевна`,
    photo: kuznetsovaPhoto,
    dateBirth: '30/04/1999',
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Организация - СК «Медведь», МАУ СШОР "Здоровый мир"` },
      { id: 2, text: `Тренер в настоящий момент - Христофоров А.Н.` },
    ],
  },
  {
    id: 18,
    title: `Кмс`,
    name: `Левкович Александра Казимировна`,
    photo: levkovichPhoto,
    dateBirth: '13/09/1999',
    about: [
      { id: 0, text: `Регион - Кировская область, Кемеровская область` },
      { id: 1, text: `Организация - КРОО "Федерация триатлона"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Андреева П.В., Андреев П.В., Корчемкин Ю.А.`,
      },
    ],
  },
];

const WinterTriathlon = () => {
  return (
    <>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />
        <Box>
          <CustomHeading level={`2`}>Основной состав</CustomHeading>

          <AthleteCards athletesArr={teamArr} />
        </Box>
        <Box>
          <CustomHeading level={`2`}>Юниорский состав</CustomHeading>

          <AthleteCards athletesArr={teamJuniorArr} />
        </Box>
      </Container>
    </>
  );
};

export default WinterTriathlon;
