import news10032101 from 'images/News/10032101.jpeg';
import Article from 'pages/Articles/Article';
import styled from 'styled-components';
import { Paragraph, Anchor } from 'grommet';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const articte10032100 = () => {
  return (
    <Article
      title={`Обучение по программе профессиональной переподготовки «Тренер в избранном виде спорта. Триатлон»`}
      date={`10 МАРТА 2021`}
      coverBack={news10032101}
    >
      <CustomParagraph>
        <b>Поволжская Государственная Академия Физической культуры, спорта и туризма совместно с Федерацией Триатлона России объявляет набор на обучение по программе профессиональной переподготовки «Тренер в избранном виде спорта. Триатлон»</b>
      </CustomParagraph>
      <CustomParagraph>
        Программа обучения ориентирована на подготовку специалистов в области физической культуры, тренеров, осуществляющих деятельность на различных этапах спортивной подготовки в триатлоне, спортсменов, лиц, не имеющих высшего образования в области ФКиС, всех заинтересованных лиц.
      </CustomParagraph>
      <CustomParagraph>
        Ее основной целью является формирование у слушателей профессиональных компетенций и приобретение новой квалификации, необходимой для профессиональной деятельности в сфере физической культуры и спорта, в частности в сфере триатлона.
      </CustomParagraph>
      <CustomParagraph>
        Основными темами курса станут педагогика и психология в области физкультуры и спорта, ключевые аспекты антидопинговой деятельности, правовые основы и инновации в профессиональной деятельности тренера, физиология человека: общая, возрастная, спортивная. В рамках курса также подразумевается наличие двух модулей «Летний триатлон» и «Зимний триатлон». Программа рассчитана на 272 часа, а общий срок обучения составит 9 недель. Данный период будет разделен на несколько этапов: дистанционное обучение (лекционные и практические занятия), практические занятия (очные), а в завершении курса слушатели должны будут пройти практическую стажировку и подготовить итоговую аттестационную работу.
      </CustomParagraph>
      <CustomParagraph>
        Преподавателями программы станут ведущие практикующие специалисты в области триатлона, в том числе из зарубежных стран, доценты и профессоры Поволжской Государственной Академии Физической культуры, спорта и туризма, кандидаты биологических, медицинских и педагогических наук.
      </CustomParagraph>
      <CustomParagraph>
        По окончанию курса слушатели получат диплом о профессиональной переподготовке установленного ФГБОУ ВО «Поволжская ГАФКСиТ» образца.
      </CustomParagraph>
      <CustomParagraph>
        <b>Обучение по данной программе бесплатно для слушателей.</b>
      </CustomParagraph>
      <CustomParagraph>
        Количество мест для слушателей первой программы ограничено 30 местами. После отправки заявки на попадание на данную программу обучения, будущим слушателям предстоит пройти собеседование с представителями Федерации триатлона России по итогам которого будет вынесено решение о зачислении на курс.
      </CustomParagraph>
      <CustomParagraph>
        Заявки на обучение принимаются по электронной почте: sportacadem.cpk@mail.ru
      </CustomParagraph>
      <CustomParagraph>
        Телефон для связи: 249-90-08
      </CustomParagraph>
      <CustomParagraph>
        Сайт: <Anchor href={`https://cdo.sportacadem.ru/?path=cdo`} label={`"sportacadem.ru/cdo"`} />
      </CustomParagraph>
    </Article>
  )
}

export default articte10032100;
