import img1 from '../../images/InternationalTriathlon/1503985578.jpg';
import img2 from '../../images/InternationalTriathlon/Antonio_Arimany.jpeg';
import img3 from '../../images/InternationalTriathlon/Renato_Bertrandi.png';
import { Box, Button, Card, Grid, Heading, Image, Text } from 'grommet';
import AthletesMorePopup from '../../components/Popups/AthletesMore/AthletesMorePopup';
import styled from 'styled-components';

const management = [
  {
    id: 0,
    photo: img1,
    position: `Президент World Triathlon`,
    name: `Марисоль Касадо`,
    country: `Испания`,
    link: `https://triathlon.org/about/profile/85281`,
  },
  {
    id: 1,
    photo: img2,
    position: `Генеральный секретарь World Triathlon`,
    name: `Антонио Аримани`,
    country: `Испания`,
    link: `https://triathlon.org/about/profile/111632`,
  },
  {
    id: 2,
    photo: img3,
    position: `Президент Europe Triathlon`,
    name: `Ренато Бертранди`,
    country: `Италия`,
  },
];

const CustomButton = styled(Button)`
  font-size: 14px;
  text-align: center;
`;

const ManagementTab = () => {
  return (
    <Grid margin={{bottom: `96px`}} columns={{count: `fit`, size: `280px`}} justify={`center`} gap={`medium`}>

      {management.map(item => (
        <Card width={{max: `330px`}} background={`#F8F8F8`} elevation={`none`} key={item.id} style={{width: `100%`, height: `auto`}}>
          <Box height={{min: `310px`, max: `320px`}}>
            <Image src={item.photo} fit={`cover`} />
          </Box>
          <Box
            height={`100%`}
            direction={`column`}
            justify={`between`}
            pad={{top: `32px`, right: `21px`, bottom: `40px`, left: `24px`}}
          >
            <Text margin={{bottom: `8px`}} style={{textTransform: `uppercase`, color: `rgba(0, 0, 0, 0.5)`}}>
              {item.position} <Text weight={`bold`}>{item.title}</Text>
            </Text>
            <Text margin={{bottom: `58px`}} style={{color: `rgba(0, 0, 0, 0.5)`}}>
              {item.country}
            </Text>
            <Box>
              <Heading level={3} margin={{bottom: `32px`}} style={{textTransform: `uppercase`, lineHeight: `1.2`}}>
                {item.name}
              </Heading>
              <Box style={{minHeight: `36px`}}>
                {item.link && <CustomButton label={`Подробнее`} href={item.link} target="_blank" primary />}
              </Box>
            </Box>
          </Box>
        </Card>
      ))}
    </Grid>
  );
};

export default ManagementTab;
