import React from 'react';
import coverImg from '../../images/OlympicReserve/olympicReserve.png';
import Container from '../../components/Container/Container';
import OlympicTabs from '../../components/OlympicTabs';
import Calendar from '../../components/Calendar/Calendar';
import Banner from 'components/Banner';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const OlympicReserve = () => {
  return (
    <div>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />

        <OlympicTabs />

        <Calendar event_type="all" />
      </Container>
    </div>
  );
};

export default OlympicReserve;
