import { Box, Heading, Image, Paragraph } from 'grommet';
import styled from 'styled-components';
//import articleImg0 from 'images/articles/howto1080p.jpg';
import articleImg0 from 'images/cover4.jpg';
import articleImg1 from 'images/articles/snaps.jpeg';
import articleImg2 from 'images/articles/transit-areas.jpg';
import articleImg3 from 'images/articles/recuperation.jpg';
import Article from '../Article';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const CustomUl = styled(`ul`)`
  padding-left: 40px;
  list-style: outside;
`

const CustomTbody = styled(`tbody`)`
  color: #313131;
  font-size: 18px;
  
  & th {
    padding: 20px;
    width: 100%;
    text-align: left;
    border-left: 2px solid #DADDE0;
    background-color: #E8EAEC;
  }
  
  & th:first-child {
    max-width: 56px;
    color: #777777;
    font-weight: 400;
    text-align: center;
    border-left: none;
  }

  & th:nth-child(2) {
    max-width: 400px;
  }
  
  & tr {
    display: flex;
    border-top: 2px solid #E8EAEC;
  },

  & tr:first-child {
    border-top: none;
  }
  
  & td {
    width: 100%;
    padding: 20px;
  
    background-color: #F8F8F8;
    border-left: 2px solid #E8EAEC;
  }

  & td:first-child {
    max-width: 56px;
    color: #777777;
    text-align: center;
    border-left: none;
  }
  
  & td:nth-child(2) {
    max-width: 400px;
  }

  & tr:first-child th:first-child {
    border-top-left-radius: 24px;
  }

  & tr:first-child th:last-child {
    border-top-right-radius: 24px;
  }

  & tr:last-child td:first-child {
    border-bottom-left-radius: 24px;
  }

  & tr:last-child td:last-child {
    border-bottom-right-radius: 24px;
  }
`

const highEducation = () => {
  return (
    <Article
      title={`ВЫСШЕЕ И ДОПОЛНИТЕЛЬНОЕ ОБРАЗОВАНИЕ`}
      desc={``}
      coverBack={articleImg0}
    >
      <CustomParagraph>
        Подготовка и повышение квалификации тренеров и атлетов по триатлону осуществляется в следующих ВУЗах: 
      </CustomParagraph>
      <CustomUl>
      <li>
        РГУФКСМиТ (Российский государственный университет физической культуры, спорта, молодёжи и туризма), г. Москва
      </li>
      <li>
        НГУФКСиЗ им. Лесгафта (Национальный государственный университет физической культуры, спорта и здоровья имени П.Ф. Лесгафта), г. Санкт-Петербург
      </li>
      <li>
        ПГУФКСиТ (Поволжский государственный университет физической культуры, спорта и туризма), г. Казань
      </li>
      </CustomUl>
      <Heading level={`3`} margin={{top: `18px`, bottom: `18px`}}>ВЫСШЕЕ ОБРАЗОВАНИЕ</Heading>
      <CustomParagraph>
        <strong>1. Российский государственный университет физической культуры, спорта, молодёжи и туризма (г. Москва)</strong>
      </CustomParagraph>
      <CustomParagraph>
      В университете работает отделение триатлона на базе кафедры теории и методики велосипедного спорта. Отделение осуществляет подготовку специалистов по направлению «Тренер по велоспорту и триатлону».
      </CustomParagraph>
      <CustomParagraph>
      <strong>Сроки обучения:</strong> 4 года (бакалавриат)<br /><strong>Форма обучения:</strong> очная и заочная 
      </CustomParagraph>
      <CustomParagraph>
      Также в университете реализуется программа профессиональной переподготовки по направлению «Тренер-преподаватель по избранному виду спорта. Триатлон». Она разработана на основе образовательной программы вуза по направлению «Физическая культура» и ориентирована на тренеров, преподавателей, специалистов в сфере физической культуры и спорта. 
      </CustomParagraph>
      <CustomParagraph>
      <strong>Сроки обучения:</strong> 9 или 18 месяцев<br /><strong>Форма обучения:</strong> очная и очно-заочная 
      </CustomParagraph>
      <CustomParagraph>
      <strong>Адрес:</strong> г. Москва, Сиреневый бульвар, дом 4<br /><strong>Сайт:</strong> <a href="https://sportedu.ru/">sportedu.ru</a>
      </CustomParagraph>

      <CustomParagraph>
        <strong>2. Национальный государственный университет физической культуры, спорта и здоровья имени П.Ф. Лесгафта (г. Санкт-Петербург)</strong>
      </CustomParagraph>
      <CustomParagraph>
      В университете сформировано отделение триатлона на базе кафедры теории и методики велосипедного спорта. Отделение ведет подготовку специалистов высокой профессиональной квалификации, обладающих глубокими теоретическими и прикладными знаниями и компетенциями в области триатлона. Обучение включает в себя постоянные практические занятия. Для обеспечения тренировочного процесса используется база «Локосфинск». 
      </CustomParagraph>
      <CustomParagraph>
      <strong>Сроки обучения:</strong> 4 года (бакалавриат)<br /><strong>Форма обучения:</strong> очная
      </CustomParagraph>
      <CustomParagraph>
      <strong>Адрес:</strong> г. Санкт-Петербург, ул. Декабристов, дом 35<br /><strong>Сайт университета:</strong> <a href="https://lesgaft.spb.ru/">lesgaft.spb.ru</a> 
      </CustomParagraph>

      <Heading level={`3`} margin={{top: `18px`, bottom: `18px`}}>ДОПОЛНИТЕЛЬНОЕ ОБРАЗОВАНИЕ</Heading>
      <CustomParagraph>
        <strong>1. Поволжский государственный университет физической культуры, спорта и туризма (г. Казань)</strong>
      </CustomParagraph>
      <CustomParagraph>
      Совместно с Федерацией Триатлона России университет реализует следую-щие обучающие программы: 
      </CustomParagraph>
      <CustomUl>
      <li>
      Курс профессиональной переподготовки «Тренер в избранном виде спорта. Триатлон». Это базовая программа подготовки тренеров, которая включает в себя такие разделы как педагогика и психология в области физкультуры и спорта, ключевые аспекты антидопинговой деятельности, правовые основы и инновации в профессиональной деятельности тренера, физиология человека. В рамках курса пройдут модули «Летний триатлон» и «Зимний триатлон». Обучение дистанционные занятия (онлайн) и практические (очно). В завер-шении курса слушатели должны будут пройти практическую стажировку и подготовить итоговую аттестационную работу. Начало обучения в апреле
      </li>
      <li>
      Курс повышения тренерской квалификации по дисциплине «Триатлон». Программа рассчитана на опытных тренеров с высшим образованием, же-лающих улучшить свои навыки. Слушателей ждет погружение не только в психологические и физические особенности тренерской деятельности, но и обучение педагогическим, правовым и антидопинговым аспекты спортивной деятельности. В завершении курса слушатели должны будут пройти практи-ческую стажировку и подготовить итоговую аттестационную работу. Начало обучения в сентябре.
      </li>
      </CustomUl>
      <CustomParagraph>
      <strong>Сроки обучения:</strong> 9 недель (272 академических часа)<br /><strong>Форма обучения:</strong> очная и заочная
      </CustomParagraph>
      <CustomParagraph>
      <strong>Адрес:</strong> г. Казань, Деревня Универсиады, дом 35<br /><strong>Сайт университета:</strong> <a href="https://sportacadem.ru/">sportacadem.ru</a> 
      </CustomParagraph>

      <CustomParagraph>
        <strong>2. Курс повышения квалификации судей (г. Москва)</strong>
      </CustomParagraph>
      <CustomParagraph>
      Обучающий семинар «Судья в триатлоне» (2 дня)
      </CustomParagraph>
      <CustomParagraph>
      Курс будет проходить в рамках Всероссийской конференции «Федерации триатлона России» в Москве декабре 2021 года. 
      </CustomParagraph>
      <CustomParagraph>
      <strong>По всем вопросам, связанным с образованием, пишите на почту</strong> <a href="mailto:edu@rustriathlon.ru">edu@rustriathlon.ru</a> 
      </CustomParagraph>


    </Article>
  )
}

export default highEducation;