import { Box, Button, Heading } from 'grommet';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const Thanks = () => {
  const history = useHistory();
  const CustomBtn = styled(Button)`
  padding: 6px 24px;
`;

  return (
    <Box height={'medium'} style={{justifyContent: `center`, alignItems: `center`,}}>
      <Heading>Спасибо за покупку!</Heading>
      <CustomBtn
        type={`submit`}
        primary
        margin={{ top: `medium` }}
        onClick={() => history.push('/')}
      >
        На главную
      </CustomBtn>
    </Box>
  );
}

export default Thanks;
