import Container from 'components/Container/Container';
import React from 'react';
import coverImg from '../../images/cover4.jpg';
import ChildrensTriathlonTab from '../../components/ChildrensTriathlonTab';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Banner from 'components/Banner';

export default function ChildrensTriathlon() {
  return (
    <div>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <ChildrensTriathlonTab />
      </Container>
    </div>
  );
}
