import { Anchor, Box, Button, Card, Heading, Image, Text } from 'grommet';
// import Logo from 'images/leaders/lead1.png';
import styled from 'styled-components';
import Slider from 'components/Slider/Slider';
import Navigations from 'components/Slider/Navigations/Navigations';
import { returnSliderParams } from 'components/Slider/params';
import Container from 'components/Container/Container';
import { routes } from 'const/routes';
import { teamArr } from 'pages/MainCast/MainCast';

const cardsArray = teamArr;
const BoxAbsolute = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;
`;

const styles = {
  container: `container-leaders`,
  wrapperClass: `wrapper-leaders`,
  slideClass: `slide-leaders`,
  nav: `nav-leaders`,
  btnNext: `next-btn-leaders`,
  btnPrev: `prev-btn-leaders`,
};


const Leaders = () => {
  return (
    <Container overflow={true}>
    <br />
      <Box fill margin={{bottom: `72px`}} align={`start`}>
        <Heading margin={{bottom: `72px`}} color={`dark-1`} level={2} size={`medium`} style={{textTransform: `uppercase`}}>Лидеры сборной</Heading>
        <Slider
          styles={styles}
          params={returnSliderParams(styles)}
        >
        <Box fill className={styles.container} align={`start`} style={{position: `relative`}}>
          <Box fill as={`ul`} direction={`row`} className={styles.wrapperClass}>
            {cardsArray.map(el => {
              return (
              <Card
                as={`li`}
                className={styles.slideClass}
                elevation={`none`}
                background={`light-1`}
                basis={`22rem`}
                flex={{shrink: 0}}
                key={el.id}
                pad={{horizontal: `32px`, vertical: `medium`}}
                height={`152px`}
                direction={`row`}
                style={{position: 'relative'}}
              >
                <Box width={`60%`} margin={{right: `6px`}}flex={{shrink: 0}}>
                  <Heading level={5} margin={{right: `6px`}} color={`dark-1`}>{el.name}</Heading>
                  <Text size={`10px`} color={`rgba(0,0,0,0.6)`} margin={{top: `6px`, right: `6px`}}>{el.title.toUpperCase()}</Text>
                  <Anchor size={`xsmall`} color={`dark-1`} href={routes.mainCast.path} margin={{top: `auto`}}>Вся сборная</Anchor>
                </Box>
                <BoxAbsolute margin={{left: `6px`}} fill>
                  <Image fit={`cover`} fill src={el.photo} width='200px' />
                </BoxAbsolute>
              </Card>
              )
            })}
          </Box>
          <Navigations styles={styles} />
        </Box>
        </Slider>
        <Button href={routes.mainCast.path} primary margin={{top: `32px`}}>Смотреть всех участников</Button>
      </Box>
    </Container>
  );
}

export default Leaders;