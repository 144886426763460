import { Anchor, Box, Image, List, Paragraph } from 'grommet';
import coverImg from '../../images/cover4.jpg';
import Container from '../../components/Container/Container';
import pdfIcon from 'images/pdf.png';
import Partners from 'components/Partners/Partners';
import Banner from 'components/Banner';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const protocolsArr = [
  {
    label: `Результаты Чемпионата России, Казань, Дуатлон, 8 мая 2021`,
    link: `https://disk.yandex.ru/i/r0iFI3tRy5vgYQ`,
  },
  {
    label: `Результаты ВС, Казань, юноши и девушки, 8 мая 2021`,
    link: `https://disk.yandex.ru/i/KMt_sV_ya-glYw`,
  },
  {
    label: `Результаты ВС, Казань, юниоры и юниорки, 8 мая 2021`,
    link: `https://disk.yandex.ru/i/KMt_sV_ya-glYw`,
  },
  {
    label: `Результаты 1 этапа Кубка России и всероссийских соревнований по кросс-дуатлону, 23 мая 2021`,
    link: `/files/Петрозаводск_ЭКР_ВС_кросс_дуатлон_23_мая.xlsx`,
  },
  {
    label: `Результаты Березники, мужчины и женщины, от 27 марта 2021`,
    link: `/files/Березники_КР_мужчины_и_женщины_rotated.pdf`,
  },
  {
    label: `Результаты Березники, юниоры, от 27 марта 2021`,
    link: `/files/Березники_юниоры_rotated.pdf`,
  },
  {
    label: `Первенство Республики Татарстан, "Казанский Дуатлон", Казань, 8 мая 2021`,
    link: `/files/20210508_Результаты_Любители.xlsx`,
  },
  {
    label: `Всероссийские соревнования, дуатлон-спринт, Республика Татарстан, Казань, 8 мая 2021`,
    link: `/files/20210508_Результаты.xlsx`,
  },
  {
    label: `Чемпионат Алтайского края, дуатлон-спринт, Алтайский край, с. Алтайское, 3 мая 2021`,
    link: `/files/Дуатлон_Чемп_Алтайск_края_Протокол.xlsx`,
  },
  {
    label: `1-ый этап Кубка России, дуатлон-спринт, Алтайский край, с. Алтайское, 3 мая 2021`,
    link: `/files/Дуатлон_спринт_ЭКР_Алтай_Протокол.xlsx`,
  },
  {
    label: `1-ый этап Кубка России, Всероссийские соревнования по триатлону, Курская область, г. Курчатов, 25 апреля 2021`,
    link: `/files/20210425_results.xlsx`,
  },
  {
    label: `1-ый этап Кубка России, Всероссийские соревнования по триатлону, Курская область, г. Курчатов, 24 апреля 2021`,
    link: `/files/20210424_results.xlsx`,
  },
  {
    label: `3-й этап Кубка России, всероссийские соревнования по зимнему триатлону, Респ. Карелия, г. Петрозаводск, 7 марта 2021`,
    link: `https://docviewer.yandex.ru/view/1130000046077272/?*=panbgJGxeVvQzS92I6yfwQG7d%2FV7InVybCI6InlhLWRpc2stcHVibGljOi8vTFYrMDJEaTNpaFl4N3NKMjVHVFIyMDl2OVFwQU5yRnA4Q2dpb0Nxc1dCZm1TTnlYeGN6dE42NXN4cWZsS05SVitiM25DS0xDVlRKK1NJbmFPVXZ2SFE9PSIsInRpdGxlIjoi0YHQutCw0L3QuNGA0L7QstCw0L0wMDA3LnBkZiIsIm5vaWZyYW1lIjpmYWxzZSwidWlkIjoiMTEzMDAwMDA0NjA3NzI3MiIsInRzIjoxNjE3MDkxNjY3OTI4LCJ5dSI6IjMwMTIyOTExOTE2MTU0NDkwNTgifQ%3D%3D`,
  },
  {
    label: `Первенство России по зимнему триатлону, эстафета, Респ. Татарстан, г. Зеленодольск, 23 февраля 2021`,
    link: `https://disk.yandex.ru/i/BQGpv65KNJxAzg`,
  },
  {
    label: `Первенство России по зимнему триатлону, личные гонки, Респ. Татарстан, г. Зеленодольск, 22 февраля 2021`,
    link: `https://disk.yandex.ru/i/G-c1jhI4X-fXEA`,
  },
  {
    label: `«Зеленодольский триатлон» среди любителей, личные гонки, Респ. Татарстан, г. Зеленодольск, 22 февраля 2021`,
    link: `https://disk.yandex.ru/i/4DA8olq1S-RPww`,
  },
  {
    label: `2-й этап Кубка России, всероссийские соревнования, чемпионат края по зимнему триатлону, Красноярский край, п. Подгорный, 14 февраля 2021`,
    link: `https://disk.yandex.ru/i/iSUc7xeW7c70NQ`,
  },
  {
    label: `Чемпионат и первенство г. Красноярск по зимнему триатлону, Красноярский край, 7 февраля 2021`,
    link: `https://yadi.sk/i/FvwHLWl7ynyWBA`,
  },
  {
    label: `Чемпионат и первенство Тверской области по зимнему триатлону, Тверская область, 30 января 2021`,
    link: `https://disk.yandex.ru/d/Sy5Ye8Rn9DWELg`,
  },
  {
    label: `Чемпионат и первенство г. Казань по зимнему триатлону, Респ. Татарстан, 30 января 2021`,
    link: `https://yadi.sk/i/ncsJ4cxOeGTsQw`,
  },
  {
    label: `Чемпионат и первенство России по зимнему триатлону, эстафеты, Кировская область, Верхошижемье, 24 января 2021`,
    link: `https://yadi.sk/i/v6QY33bmj00YYQ`,
  },
  {
    label: `Первенство России по зимнему триатлону среди любителей, Кировская область, Верхошижемье, 24 января 2021`,
    link: `https://yadi.sk/i/AF53jRMcjabKPQ`,
  },
  {
    label: `Чемпионат и первенство России по зимнему триатлону, Кировская область, Верхошижемье, 23 января 2021`,
    link: `https://yadi.sk/i/zm1Jbp9Sfnxiiw`,
  },
  {
    label: `1-й Этап Кубка России и чемпионат края по зимнему триатлону, г. Кемерово, 16 января 2021`,
    link: `https://yadi.sk/i/GUBgicB-d59n_w`,
  },
  {
    label: `Новогодний дуатлон, Красноярский край, 31 декабря 2020`,
    link: `https://yadi.sk/i/gWWYPE1Oi3TCgw`,
  },
  {
    label: `Общий зачет Кубка России и Кубка ФТР по зимнему триатлону 2020`,
    link: `https://yadi.sk/i/LgSmN5O2-gETnw`,
  },
  {
    label: `Финал Кубка России по зимнему триатлону, ст. Тягун, Алтайский край, 19 декабря 2020`,
    link: `https://yadi.sk/i/SYlnjPhYkhNYIA`,
  },
  {
    label: `Чемпионат и первенство России по кросс-дуатлону, г. Гурьевск, Кемеровская область, 12 декабря 2020`,
    link: `https://yadi.sk/i/aqARkcwo6Otudw`,
  },
  {
    label: `Кубок Федерации триатлона Кузбасса по кросс-дуатлону, г. Гурьевск, Кемеровская область, 12 декабря 2020`,
    link: `https://yadi.sk/i/9Sgv8tmwW9S0mw`,
  },
  {
    label: `Общий зачет Кубка России по триатлону-спринт 2020`,
    link: `https://yadi.sk/i/Zvlp4aBckYfP5Q`,
  },
  {
    label: `Первенство России по дуатлону, эстафета, г. Казань, 4 октября 2020`,
    link: `https://yadi.sk/i/OTHOdH4-kc20Sw`,
  },
  {
    label: `Чемпионат России по дуатлону, эстафета, г. Казань, 4 октября 2020`,
    link: `https://yadi.sk/i/A_2UAJ6UVdou3A`,
  },
  {
    label: `Чемпионат России по дуатлону, г. Казань, 3-4 октября 2020`,
    link: `https://yadi.sk/i/9-CvqiImvMijhg`,
  },
  {
    label: `Первенство России по дуатлону среди юниоров 18-23 лет, г. Казань, 3-4 октября 2020`,
    link: `https://yadi.sk/i/19JbRB1aJlhhFA`,
  },
  {
    label: `Первенство России по дуатлону, г. Казань, 3-4 октября 2020`,
    link: `https://yadi.sk/i/0LBeuB78_NF1dg`,
  },
  {
    label: `Первенство России по дуатлону, любители, г. Казань, 3 октября 2020`,
    link: `https://yadi.sk/i/7xogxbCI0nsunQ`,
  },
  {
    label: `Общий зачет Кубка России по триатлону 2020`,
    link: `https://yadi.sk/i/xYVI-oeWlTeGkQ`,
  },
  {
    label: `Этап Кубка России по кросс-дуатлону, п. Подгорный, Красноярский край, 20 сентября 2020 года`,
    link: `https://yadi.sk/i/_CacXLoHFIHiKQ`,
  },
  {
    label: `Этап Кубка ФТР по кросс-дуатлону, п. Подгорный, Красноярский край, 20 сентября 2020 года`,
    link: `https://yadi.sk/i/JrVFpa4r7jV7RA`,
  },
  {
    label: `Чемпионат Курской области по триатлону (эстафета), г. Курчатов, 20 сентября 2020 года`,
    link: `https://yadi.sk/i/8jGPWWPGszDAeg`,
  },
  {
    label: `Чемпионат Курской области по триатлону, г. Курчатов, 20 сентября 2020 года`,
    link: `https://yadi.sk/i/xxwRq1-_7Tp6WQ`,
  },
  {
    label: `Финал Кубка России по триатлону, г. Курчатов, 20 сентября 2020 года`,
    link: `https://yadi.sk/i/JDU6khqANqD-wg`,
  },
  {
    label: `Всероссийские соревнования по триатлону (Кубок ФТР), г. Курчатов, 20 сентября 2020 года`,
    link: `https://yadi.sk/d/otxqGqmB0OCwLg`,
  },
  {
    label: `Кубок России по дуатлону, г. Красноярск, 6 сентября 2020 года`,
    link: `https://yadi.sk/i/bj13zQoylcyOmA`,
  },
  {
    label: `Кубок ФТР по дуатлону, г. Красноярск, 6 сентября 2020 года`,
    link: `https://yadi.sk/i/6wYqPjRFpocMsw`,
  },
  {
    label: `Чемпионат и первенство Красноярского края по дуатлону, г. Красноярск, 6 сентября 2020 года`,
    link: `https://yadi.sk/i/vHaCEgwSq0bPkA`,
  },
  {
    label: `Общий зачет Кубка России по триатлону 2020 года`,
    link: `https://yadi.sk/i/lN8g1jNribWsqQ`,
  },
  {
    label: `Этап Кубка России по триатлону, пгт Инской, Кемеровская область, 29 августа 2020`,
    link: `https://yadi.sk/i/EC8YVAZm376FoQ`,
  },
  {
    label: `Первенство Красноярского края по кросс-триатлону, г.Красноярск, 23 августа 2020`,
    link: `https://yadi.sk/i/zPRDAt3P0e_RXA`,
  },
  {
    label: `Первенство России по кросс-триатлону среди любителей, г.Красноярск, 23 августа 2020`,
    link: `https://yadi.sk/i/rcV9lwqmRsw1WA`,
  },
  {
    label: `Чемпионат России по кросс-триатлону, г.Красноярск, 23 августа 2020`,
    link: `https://yadi.sk/i/0qJ5DhY7rYDu0Q`,
  },
  {
    label: `Первенство России по акватлону, г.Казань, 22 августа 2020`,
    link: `https://yadi.sk/i/LTNCKX1Sgb8_yw`,
  },
  {
    label: `Чемпионат России по акватлону, г.Казань, 22 августа 2020`,
    link: `https://yadi.sk/i/DMC3I7JgwioWVg`,
  },
  {
    label: `Этап Кубка России по триатлону, г.Дубна, 22 августа 2020`,
    link: `https://yadi.sk/i/7ddhtQZd6XfE3w`,
  },
  {
    label: `Этап Кубка России по триатлону-спринт, г.Пермь, 16 августа 2020`,
    link: `https://yadi.sk/i/FubvCWNFmLRvEw`,
  },
  {
    label: `Чемпионат России по триатлону, эстафета-микс, г.Альметьевск, 9 августа 2020`,
    link: `https://yadi.sk/i/u3xkNFjcCYbEbw`,
  },
  {
    label: `Чемпионат России по триатлону, женщины, г.Альметьевск, 8 августа 2020`,
    link: `https://yadi.sk/i/HxPhUrB-j99QaA`,
  },
  {
    label: `Чемпионат России по триатлону, мужчины, г.Альметьевск, 8 августа 2020`,
    link: `https://yadi.sk/i/0576Jt4un2xKSg`,
  },
  {
    label: `Виртуальный дуатлон, Тверь, 16-18 мая 2020`,
    link: `https://yadi.sk/i/m3U0sYMpaum0IA`,
  },
  {
    label: `Этап Кубка России и Кубка ФТР по зимнему триатлону, Богатые Сабы, Респ.Татарстан, 7 марта 2020`,
    link: `https://yadi.sk/i/YLMXFURtrvm2fQ`,
  },
  {
    label: `Первентсво России по зимнему триатлону (эстафета), Подолино, Ярославская область, 23 февраля 2020`,
    link: `https://yadi.sk/i/lM6rwZDIfQgXMw`,
  },
  {
    label: `Первентсво России по зимнему триатлону (личные гонки), Подолино, Ярославская область, 22 февраля 2020`,
    link: `https://yadi.sk/i/iX7s8orEgfHYpg`,
  },
  {
    label: `Первые Всероссийские зимние спортивные игры среди спортсменов-любителей, г. Ижевск, 22-23 февраля 2020`,
    link: `https://yadi.sk/i/LX98nY5OsGaxow`,
  },
  {
    label: `Третий этап Кубка России, чемпионат и первенство Красноярского края по зимнему триатлону, Подгорный, Красноярский край, 16 февраля 2020`,
    link: `https://yadi.sk/i/BzOddjh23LIaCA`,
  },
  {
    label: `Республиканский фестиваль по зимнему триатлону (эстафета), г.Петрозаводск, Респ. Карелия, 19 января 2020`,
    link: `https://yadi.sk/i/Ds0CyEjmPvIcdg`,
  },
  {
    label: `Республиканский фестиваль по зимнему триатлону (личные гонки), г.Петрозаводск, Респ. Карелия, 19 января 2020`,
    link: `https://yadi.sk/i/30BMn3U9vfwYsw`,
  },
  {
    label: `Чемпионат и первенство России по зимнему триатлону (эстафета), г.Петрозаводск, Респ. Карелия, 19 января 2020`,
    link: `https://yadi.sk/d/TynaRv4jqZ5yLw`,
  },
  {
    label: `Чемпионат и первенство России по зимнему триатлону (личные гонки), г.Петрозаводск, Респ. Карелия, 18 января 2020`,
    link: `https://yadi.sk/d/_YLsIdGkEsifVg`,
  },
  {
    label: `Чемпионат Тверской области по зимнему триатлону, ст. Чуприяновка, Тверская область, 11 января 2020`,
    link: `https://yadi.sk/i/UjtLD2cUGR1xWw`,
  },
  {
    label: `Этап Кубка России, Всероссийские соревнования по зимнему триатлону, пгт. Верхошижемье, Кировская область, 3 января 2020`,
    link: `https://yadi.sk/i/v_xxdsM42oETig`,
  },
  {
    label: `Этап Кубка России, Всероссийские соревнования по зимнему триатлону, д. Подолино, Ярославская область, 21 декабря 2019`,
    link: `https://yadi.sk/i/l19mSZUGr14EIg`,
  },
  {
    label: `Кубок ФТР по триатлону-спринт 2019`,
    link: `https://yadi.sk/i/YUWFKCZvLOIK5Q`,
  },
  {
    label: `Кубок России по триатлону на олимпийской дистанции 2019`,
    link: `https://yadi.sk/i/eFjh_UXhpp8RZA`,
  },
  {
    label: `Кубок России и Кубок ФТР по кросс-дуатлону 2019`,
    link: `https://yadi.sk/d/0dvRqoy0oE8CTg`,
  },
  {
    label: `Кубок ФТР по дуатлону 2019`,
    link: `https://yadi.sk/i/MRBST8qhHex5QQ`,
  },
  {
    label: `Открытый чемпионат и первенство Республики Татарстан по дуатлону-спринт, г.Казань, 13 октября 2019`,
    link: `https://yadi.sk/d/dHpJU2LqTOXr_A`,
  },
  {
    label: `Итоговый рейтинг Кубка России и Кубка ФТР по кросс-триатлону 2019`,
    link: `https://yadi.sk/i/fEYUrHzRa2VMRA`,
  },
  {
    label: `Кубок России по кросс-дуатлону, заключительный этап, г.Железногорск, 21-22 сентября 2019`,
    link: `https://yadi.sk/i/FuqTeZEcp90Kew`,
  },
  {
    label: `Финал Кубка ФТР по кросс-дуатлону, г.Железногорск, 21-22 сентября 2019`,
    link: `https://yadi.sk/i/m_65eDZ_9Q6gNA`,
  },
  {
    label: `Предварительный рейтинг по кросс-дуатлону на 19 сентября 2019`,
    link: `https://yadi.sk/i/Br9BSZMP6AX6kw`,
  },
  {
    label: `Чемпионат и первенство России по дуатлону, г.Красноярск, 7-8 сентября`,
    link: `https://yadi.sk/i/aU0BCFwlFOioZA`,
  },
  {
    label: `Первенство России по дуатлону среди ветеранов, г.Красноярск, 7-8 сентября`,
    link: `https://yadi.sk/i/EBiM293lWtbviw`,
  },
  {
    label: `Чемпионат и первенство России по дуатлону, эстафеты, г.Красноярск, 7-8 сентября`,
    link: `https://yadi.sk/i/DmR56XskVpn9ew`,
  },
  {
    label: `Рейтинг Кубка ФТР по триатлону 2019`,
    link: `https://yadi.sk/i/YA1QfQzeEjl_aQ`,
  },
  {
    label: `Этап Кубка России и Всероссийские соревнования по кросс-дуатлону, г. Можга, 24 августа 2019`,
    link: `https://yadi.sk/i/op2gdwRMvnrEeQ`,
  },
  {
    label: `Этап Кубка России и Всероссийские соревнования по кросс-триатлону, г. Можга, 20 июля 2019`,
    link: `https://yadi.sk/i/CAN5t_lrjTwVLg`,
  },
  {
    label: `Открытые региональные соревнования по триатлону (эстафета) Altai3Race, ОЭЗ "Бирюзовая Катунь", 25 августа`,
    link: `https://yadi.sk/i/FlEBw6nxf0tMNg`,
  },
  {
    label: `Открытые региональные соревнования по триатлону на длинной (полужелезной) дистанции Altai3Race, ОЭЗ "Бирюзовая Катунь", 24 августа`,
    link: `https://yadi.sk/i/eiL9Zmwri0h_nA`,
  },
  {
    label: `Открытые региональное соревнование по триатлону на дистанции спринт Altai Tri Race, ОЭЗ "Бирюзовая Катунь", 24 августа`,
    link: `https://yadi.sk/i/0etxWg9DomBWhQ`,
  },
  {
    label: `Кубок России и финал Кубка ФТР по дуатлону, г.Ярославль, 25 августа 2019`,
    link: `https://yadi.sk/i/eMM7R3ruIYL3Hw`,
  },
  {
    label: `Рейтинг Кубка ФТР по дуатлону 2019`,
    link: `https://yadi.sk/i/1U-uu6XqMZJdpw`,
  },
  {
    label: `Финал Кубка Федерации по кросс-триатлону, г. Миасс, 17 августа 2019`,
    link: `https://yadi.sk/i/YiLaqKysXs6RxQ`,
  },
  {
    label: `Финал Кубка России по кросс-триатлону, г. Миасс, 17 августа 2019`,
    link: `https://yadi.sk/i/VJ6UntD3cKERBA`,
  },
  {
    label: `Рейтинг Кубка ФТР по дуатлону на 21 августа 2019`,
    link: `https://yadi.sk/i/vD1LNJlgI_hT6g`,
  },
  {
    label: `Чемпионат и первенство России по акватлону, г.Чебоксары, 17 августа 2019`,
    link: `https://yadi.sk/i/xfkIz4TAVIS3Pg`,
  },
  {
    label: `Кубок России и Кубок ФТР по кросс-триатлону 2019`,
    link: `https://yadi.sk/i/fEYUrHzRa2VMRA`,
  },
  {
    label: `Финал Кубка Федерации по триатлону-спринт, г.Воткинск, 11 августа 2019`,
    link: `https://yadi.sk/i/tFQJHf7B6eko6w`,
  },
  {
    label: `Кубок России по триатлону-спринт, г.Воткинск, 11 августа 2019`,
    link: `https://yadi.sk/i/oFTDNbOQv6I9sw`,
  },
  {
    label: `IX летняя Спартакиада учащихся (юношеская) России, общекомандный зачет, г.Чебоксары, 7-8 августа 2019`,
    link: `https://yadi.sk/i/zracbOP99mKjLA`,
  },
  {
    label: `IX летняя Спартакиада учащихся (юношеская) России, личное первенство, г.Чебоксары, 7 августа 2019`,
    link: `https://yadi.sk/i/8Frhmg3DPo9Z3w`,
  },
  {
    label: `IX летняя Спартакиада учащихся (юношеская) России, эстафета, г.Чебоксары, 8 августа 2019`,
    link: `https://yadi.sk/i/xp98Af7eJR0BgA`,
  },
  {
    label: `Предварительный рейтинг по триатлону спринт на 8 августа 2019`,
    link: `https://yadi.sk/i/HVOD_2hkAraadg`,
  },
  {
    label: `Открытый чемпионат и первенство Красноярского края по кросс-триатлону, Емельяново, 3-4 августа 2019`,
    link: `https://yadi.sk/i/m1OuNE7q5nd-rA`,
  },
  {
    label: `Этап Кубка России, Всероссийские соревнования по кросс-триатлону, Емельяново, 3-4 августа 2019`,
    link: `https://yadi.sk/i/RmuHcLe6vci4kg`,
  },
  {
    label: `Чемпионат России по триатлону-спринт, г. Нижний Новгород, 3 августа 2019`,
    link: `https://yadi.sk/i/QhPyjn5wu1EtCA`,
  },
  {
    label: `Чемпионат и первенство России среди эстафетных команд, г. Рыбинск, ЦЛС "Демино", 1 августа 2019`,
    link: `https://yadi.sk/i/PnxoJ0erPcaQMA`,
  },
  {
    label: `Всероссийские соревнования по триатлону-спринт, юниоры, г. Дубна, 27 июля 2019`,
    link: `https://yadi.sk/i/fZcY9nMpPYubow`,
  },
  {
    label: `Этап Кубка России, Всероссийские соревнования по кросс-триатлону, г. Можга, 20 июля 2019`,
    link: `https://yadi.sk/i/WMPen58kscaUlg`,
  },
  {
    label: `Финал Кубка России, Всероссийские соревнования, чемпионат и первенство Красноярского края и по триатлону, г. Железногорск,19-20 июля 2019`,
    link: `https://yadi.sk/i/yeHOcNi1NZm0Lw`,
  },
  {
    label: `Всероссийские соревнования по триатлону, девушки/юноши 13-14, 15-17 лет, г. Бронницы,14 июля 2019`,
    link: `https://yadi.sk/i/HgZlR-CKhdWP0Q`,
  },
  {
    label: `Чемпионат России по триатлону на средней дистанции, г. Бронницы,14 июля 2019`,
    link: `https://yadi.sk/i/ZDt08LUB9FScjw`,
  },
  {
    label: `Этап Кубка России и Всероссийские соревнования по кросс-триатлону, г. Ярославль СОК "Подолино",14 июля 2019`,
    link: `https://yadi.sk/i/oIFQupV6VTp3zQ`,
  },
  {
    label: `Первенство России по триатлону, эстафеты-микс, Тюмень, "Жемчужина России", 7 июля 2019`,
    link: `https://yadi.sk/i/PzFzJF9iLQDWvQ`,
  },
  {
    label: `Первенство России по триатлону, Тюмень, "Жемчужина России", 5 июля 2019`,
    link: `https://yadi.sk/i/NunF5R9Jru9woA`,
  },
  {
    label: `Чемпионат и Первенство России, эстафеты-микс, г. Альметьевск, 23 июня 2019`,
    link: `https://yadi.sk/i/nUT-z4izFS_sDA`,
  },
  {
    label: `Первенство России (юноши, юниоры 13-14, 15-17, 16-19), г. Альметьевск, 22 июня 2019`,
    link: `https://yadi.sk/i/ls8N-LI4unV6FA`,
  },
  {
    label: `Чемпионат и Первенство России (элита, молодежь), г. Альметьевск, 22 июня 2019`,
    link: `https://yadi.sk/i/p1hdTSKa27YQyA`,
  },
  {
    label: `Первенство России, Всероссийские соревнования по триатлону, г. Альметьевск, 22 июня 2019`,
    link: `https://yadi.sk/i/ls8N-LI4unV6FA`,
  },
  {
    label: `Чемпионат и Первенство России, Чемпионат и Первенство Кировской области по кросс-триатлону, Кировская обл., пгт Верхошижемье, 16 июня 2019`,
    link: `https://yadi.sk/i/DbhR-vPZfpYsuA`,
  },
  {
    label: `Этап Кубка Росси, Всероссийские соревнования по акватлону, Кировская обл., пгт Верхошижемье, 15 июня 2019`,
    link: `https://yadi.sk/i/WUJ-mGcGy6WwKA`,
  },
  {
    label: `II этап Кубка России и открытый Чемпионат Кемеровской области по триатлону, ПГТ Инской, Беловский городской округ, 15 июня 2019`,
    link: `https://yadi.sk/i/DRWcL-Ia8xAMJw`,
  },
  {
    label: `Этап Кубка Росси, Всероссийские соревнования, Чемпионат и Первенство Кировской области по кросс-дуатлону, Кировская обл., пгт Верхошижемье, 14 июня 2019`,
    link: `https://yadi.sk/i/7APpq_YhelycQA`,
  },
  {
    label: `Этап Кубка России и Всероссийские соревнования по триатлону, г.Бронницы, 9 июня 2019`,
    link: `https://yadi.sk/i/vBSRUU3sbkyeZw`,
  },
  {
    label: `Первенство России по кросс-дуатлону среди любителей, г.Ярославль "Подолино", 9 июня 2019`,
    link: `https://yadi.sk/i/T8PcyoXPdJrnVQ`,
  },
  {
    label: `Первенство России по кросс-дуатлону, г.Ярославль "Подолино", 9 июня 2019`,
    link: `https://yadi.sk/i/l_QtLdtA65zmUg`,
  },
  {
    label: `Чемпионат России по кросс-дуатлону, г.Ярославль "Подолино", 9 июня 2019`,
    link: `https://yadi.sk/i/6L9Ddl5K32DaFg`,
  },
  {
    label: `Этап Кубка России, Всероссийские соревнования по кросс-дуатлону. г.Новосибирск, 25 мая 2019 г.`,
    link: `https://yadi.sk/i/UQGSw8bo1ATn7w`,
  },
  {
    label: `Этап Кубка России и Всероссийские соревнования по дуатлону-спринт, Удмуртская Республика, г.Ижевск, РССК им.Демидова, 25 мая 2019`,
    link: `https://yadi.sk/i/CruzU0U4MuZc0A`,
  },
  {
    label: `Отборочные соревнования на Первенство Европы среди юношей и девушек, г.Казань, 10 мая 2019`,
    link: `https://yadi.sk/i/Ddcq7C6qxOGxeA`,
  },
  {
    label: `Чемпионат России по дуатлону, г.Казань, 11 мая 2019`,
    link: `https://yadi.sk/i/9chq3Un4tJ9QWw`,
  },
  {
    label: `Всероссийские соревнования по дуатлону, г.Казань, 11 мая 2019`,
    link: `Открытое первенство Республики Крым по триатлону, г.Ялта, 6-7 апреля 2019`,
  },
  {
    label: `Итоговый рейтинг Кубка России по зимнему триатлону сезона 2018-2019`,
    link: `https://yadi.sk/i/3ePA_mEzGOSF3Q`,
  },
  {
    label: `Итоговый рейтинг Кубка ФТР по зимнему триатлону сезона 2018-2019`,
    link: `https://yadi.sk/i/XlWJPLWoUntyaQ`,
  },
  {
    label: `Зимний триатлон. Финал Кубка России, Кубка ФТР, г.Петрозаводск, 24 марта 2019`,
    link: `https://yadi.sk/i/Zu3ithCgBc7w9A`,
  },
  {
    label: `Зимний триатлон. этап кубка России, кубка ФТР, первенство НСО, г.Новосибирск, 16 марта 2019`,
    link: `https://yadi.sk/i/eLoGqKi0byazLA`,
  },
  {
    label: `Первенство России по зимнему триатлону, эстафета 2х2, г. Зеленодольск, 2-3 марта 2019`,
    link: `https://yadi.sk/i/AsuP7CcHKpQvLw`,
  },
  {
    label: `Первенство России по зимнему триатлону, г. Зеленодольск, 2-3 марта 2019`,
    link: `https://yadi.sk/i/UlZ_sEnElOPcZg`,
  },
  {
    label: `Открытый чемпионат Красноярского края по зимнему триатлону, г.Красноярск, 16-17 февраля 2019`,
    link: `https://yadi.sk/i/UxjRShq33JDXcA`,
  },
  {
    label: `Третий этап Кубка России по зимнему триатлону, Всероссийские соревнования г.Можга, 26 января 2019`,
    link: `https://yadi.sk/i/EVcVzvJSZevbtQ`,
  },
  {
    label: `Чемпионат и первенство России по зимнему триатлону, СОК Подолино, 20 января 2019 (Эстафета)`,
    link: `https://yadi.sk/i/fn4-hFcsuUyrDw`,
  },
  {
    label: `Первенство России по зимнему триатлону среди любителей, СОК Подолино, 20 января 2019 (Любители)`,
    link: `https://yadi.sk/i/VnuX5wCc7M3mvg`,
  },
  {
    label: `Чемпионат и первенство России по зимнему триатлону, СОК Подолино, 19 января 2019`,
    link: `https://yadi.sk/i/uy1tfUZAdjsDGg`,
  },
  {
    label: `Этап Кубка России, Кубка ФТР, открытый чемпионат Кировской области по зимнему триатлону, г. Верхошижемье, 6 января 2019`,
    link: `https://yadi.sk/i/yCHMkRczHL0DJQ`,
  },
];

const Protocols = () => {
  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <Box
          style={{
            width: `max-content`,
            marginLeft: `auto`,
            marginRight: `auto`,
            marginBottom: `30px`,
          }}
        >
          <Paragraph>Архивы протоколов:</Paragraph>
        </Box>
        <br />
        <Box direction={`row`}>
          <Box
            direction={`row`}
            style={{
              width: `max-content`,
              marginLeft: `auto`,
              marginRight: `auto`,
              marginBottom: `30px`,
            }}
          >
            <Paragraph style={{ marginRight: `10px` }}>
              <Anchor
                href={`https://yadi.sk/d/4JanpU07ytJ85w`}
                label={`2015`}
              />
            </Paragraph>
            <Paragraph style={{ marginRight: `10px` }}>
              <Anchor
                href={`https://yadi.sk/d/huCMo1NnXplIyA`}
                label={`2016`}
              />
            </Paragraph>
            <Paragraph style={{ marginRight: `10px` }}>
              <Anchor
                href={`https://yadi.sk/d/zojd9xDMT34u2g`}
                label={`2017`}
              />
            </Paragraph>
            <Paragraph style={{ marginRight: `10px` }}>
              <Anchor
                href={`https://yadi.sk/d/NJxuHGqyNFtWgg`}
                label={`2018`}
              />
            </Paragraph>
            <Paragraph style={{ marginRight: `10px` }}>
              <Anchor
                href={`https://yadi.sk/d/NJxuHGqyNFtWgg`}
                label={`2019`}
              />
            </Paragraph>
            <Paragraph style={{ marginRight: `10px` }}>
              <Anchor
                href={`https://yadi.sk/d/eeoF0rHPuzHjRw?w=1`}
                label={`2020`}
              />
            </Paragraph>
          </Box>
        </Box>

        <Box
          style={{
            maxWidth: `1190px`,
            marginLeft: `auto`,
            marginRight: `auto`,
          }}
        >
          <List data={protocolsArr} pad="medium" border={false}>
            {protocolsArr => (
              <Box direction="row-responsive" gap="medium" align="center">
                <Image src={pdfIcon} width={`55`} />
                <Anchor
                  href={protocolsArr.link}
                  label={protocolsArr.label}
                  style={{
                    fontSize: `16px`,
                    marginTop: `20px`,
                    marginBottom: `20px`,
                  }}
                />
              </Box>
            )}
          </List>
        </Box>

        <Partners />
      </Container>
    </>
  );
};

export default Protocols;
