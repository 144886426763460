import api from "api";
import { dispatch } from "../index";
import * as types from "../types/partners";

export const sendPartnersData = async (partnersData) => {
  try {
    const response = await api.post("/server/partners/new", partnersData);
    dispatch({ type: types.GET_RESPONSE_DATA, payload: response.data });
  } catch (error) {
    dispatch({ type: types.SET_ERROR, payload: error });
    console.log(error);
  }
};
