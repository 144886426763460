import * as types from '../types/documents';
import api from '../../api';
import { dispatch } from 'store';

export const childDocuments = async () => {
  try {
    const { data } = await api.get('/server/document/type/child');
    dispatch({ type: types.DOCUMENTS_GET_ITEMS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const antidopingDocuments = async () => {
  try {
    const { data } = await api.get('/server/document/type/antidoping');
    dispatch({ type: types.DOCUMENTS_GET_ITEMS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const regionsDocuments = async () => {
  try {
    const { data } = await api.get('/server/document/type/regions');
    dispatch({ type: types.DOCUMENTS_GET_ITEMS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const internationalDocuments = async () => {
  try {
    const { data } = await api.get('/server/document/type/world');
    dispatch({ type: types.DOCUMENTS_GET_ITEMS, payload: data });
  } catch (error) {
    console.log(error);
  }
};
