import coverImg from "../../images/cover4.jpg";
import Container from "../../components/Container/Container";
import Banner from "components/Banner";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import RegionsTriathlonTab from "components/RegionsTabs";

const Regions = () => {
  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <RegionsTriathlonTab />
      </Container>
    </>
  );
};

export default Regions;
