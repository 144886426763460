
import { routes } from 'const/routes';
import { Link } from 'react-router-dom';
import { Box, Text } from 'grommet';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  background-color: #DB4C4C;
  color: white;
  text-decoration: none;
  width: 100%;
  max-width: 10rem;
  border-radius: 1rem;
  min-height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SuccessPage = () => {
  return (
    <Box fill height={{min: `100vh`}} direction={`column`} align={`center`} justify={`center`}>
      <Text size={`medium`} weight={`bold`} margin={{bottom: `medium`}}>Спасибо, ваша заявка принята!</Text>
      <Text size={`medium`} weight={`bold`} margin={{bottom: `medium`}}>Мы отправили письмо с информацией об оплате на вашу почту</Text>
      <Text size={`medium`} weight={`bold`} margin={{bottom: `medium`}}>Ссылка действительна в течении 4 часов</Text>
      <StyledLink to={routes.main.path}>Главная</StyledLink>
    </Box>
  )
}

export default SuccessPage;