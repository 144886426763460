import news280321 from 'images/News/280321.jpeg';
import Article from 'pages/Articles/Article';
import styled from 'styled-components';
import { Paragraph } from 'grommet';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const articte280321 = () => {
  return (
    <Article
      title={`Финал Кубка России по зимнему триатлону и соревнования среди любителей TRI59`}
      date={`28 МАРТА 2021`}
      coverBack={news280321}
    >
      <CustomParagraph>
      <b>27 марта в г. Березники (Пермский край) состоялся Финал Кубка России по зимнему триатлону и соревнования среди любителей TRI59</b>
      </CustomParagraph>
      <CustomParagraph>
      Участниками финала кубка России стали более 100, а в любительских соревнованиях приняли участие триатлонисты со всей страны!
      </CustomParagraph>
      <CustomParagraph>
      В финале Кубка России спортсмены соревновались в категориях «Юноши, девушки 13-14 лет», «Юноши, девушки 15-17 лет», «Юниоры, юниорки 16-19 лет», а также в категории «Мужчины, женщины». 
      </CustomParagraph>
      <CustomParagraph>
      В рамках гонки участники преодолели 3 километра беговой дистанции, 5 километров велогонки и 5 километров лыжной гонки. Для самой младшей категории «Юноши, девушки 13-14 лет» дистанции составили 2 километра бега, 4 км на велосипеде и 3 километра лыжной гонки. Дистанция для любительских соревнований также составила бег – 3 км, велосипед – 5 км и лыжи – 3 км. 
      </CustomParagraph>
      <CustomParagraph>
        <a href ="https://docviewer.yandex.ru/view/1130000050456830/?*=7xjNqX3IotXhR3hL4g0ZWRKkGXV7InVybCI6InlhLWRpc2stcHVibGljOi8vTFYrMDJEaTNpaFl4N3NKMjVHVFIyeWpnOEpsVnBpL2s1SEpaL0ZBaHdrazgzZXlZUjYwVklYdmZjbmVmUHJsbHNMSzJXYndCa1IvL3FmbVZIb1BpbHc9PSIsInRpdGxlIjoiMjAyMTAzMjgucGRmIiwibm9pZnJhbWUiOmZhbHNlLCJ1aWQiOiIxMTMwMDAwMDUwNDU2ODMwIiwidHMiOjE2MTcxMjIzMDM3NDIsInl1IjoiNDMzNTUwNDgwMTU1MDc1NDg3MSJ9">
        С результатами финала Кубка России и любительских забегов вы можете ознакомиться уже сейчас.
        </a>
      </CustomParagraph>
      <CustomParagraph>
      Поздравляем победителей! 
      </CustomParagraph>
    </Article>
  )
}

export default articte280321;
