import { Card } from 'grommet';
import Book from './Book';
import Troyka from './Troyka';

export default function Sale() {
  return (
    <Card
      margin={{ bottom: 'large' }}
      pad={`medium`}
      background={`light-1`}
      elevation={`none`}
      gap={`large`}
    >
      <Book />
      <Troyka />
    </Card>
  );
}
