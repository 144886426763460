import {
  Anchor,
  Box,
  Card,
  Grid,
  Heading,
  Paragraph,
  ResponsiveContext,
  Text,
} from 'grommet';
import Container from 'components/Container/Container';
import { columns } from 'grid-layout/layout';
import { ReactComponent as ArrowRight } from 'images/svg/arrow-right.svg';
import Calendar from 'components/Calendar/Calendar';
import { useContext } from 'react';
import { newsArr } from 'components/News/News';

const Article = ({ children, coverBack, title, desc, date }) => {
  const size = useContext(ResponsiveContext);
  return (
    <div>
      <Box className={`news__page`}>
        <Box
          as="section"
          background={{
            image: `url(${coverBack})`,
            color: `rgba(0, 0, 0, 0.5)`,
          }}
          style={{ backgroundBlendMode: `multiply` }}
          pad={{ top: `xlarge`, bottom: `xlarge` }}
          height={{ min: size === `small` ? `500px` : `740px` }}
        >
          <Box
            flex={{ grow: 1 }}
            width={`1240px`}
            pad={{ horizontal: `medium` }}
            margin={{ horizontal: `auto` }}
          >
            <Box
              direction="row"
              align="center"
              justify="between"
              margin={{ bottom: `large` }}
            >
              <Text size={`medium`} margin={{ right: `medium` }}>
                {date}
              </Text>
              {
                //<Button primary color="#FFFFFF">Поделиться</Button>
              }
            </Box>
            <Heading level={1} margin={{ top: `auto` }} size={`medium`}>
              {title}
            </Heading>
            {size !== `small` && (
              <Paragraph
                margin={{ top: `medium` }}
                style={{ maxWidth: `800px` }}
              >
                {desc}
              </Paragraph>
            )}
          </Box>
        </Box>

        <Container>
          <Box
            as={`article`}
            style={{
              fontSize: `16px`,
              paddingTop: `20px`,
              paddingBottom: `20px`,
            }}
          >
            {children}
          </Box>

          <Box fill margin={{ bottom: `72px` }}>
            <Heading level={2} size={`32px`} margin={{ bottom: `large` }}>
              Читайте также
            </Heading>

            <ResponsiveContext.Consumer>
              {size => (
                <Grid fill gap={`medium`} columns={columns[size]} as={`ul`}>
                  {newsArr.slice(0, 2).map(el => (
                    <Card
                      key={el.id}
                      as={`li`}
                      elevation={`none`}
                      background={{ color: `light-1` }}
                      height={`170px`}
                      pad={{ vertical: `medium`, horizontal: `34px` }}
                    >
                      <Text size={`12px`}>{el.date}</Text>
                      <Anchor
                        size={`medium`}
                        color={`dark-1`}
                        margin={{ top: `auto` }}
                        icon={<ArrowRight />}
                        reverse
                        label={el.title}
                      ></Anchor>
                    </Card>
                  ))}
                </Grid>
              )}
            </ResponsiveContext.Consumer>
          </Box>
        </Container>

        <Calendar event_type="all" />
      </Box>
    </div>
  );
};

export default Article;
