import AmateursTriathlonTab from 'components/Amateurs';
import Banner from 'components/Banner';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Container from 'components/Container/Container';
import coverImg from '../../images/cover4.jpg';

export default function Amateurs() {
  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <AmateursTriathlonTab />
      </Container>
    </>
  );
}
