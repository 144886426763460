import akhmetshinaPhoto from '../../images/youthTeam/akhmetshina.jpg';
import baskakovaPhoto from '../../images/youthTeam/baskakova.jpg';
import zapasnayaPhoto from '../../images/youthTeam/zapasnaya.jpg';
import lichtorowiczPhoto from '../../images/youthTeam/lichtorowicz.jpg';
import malakhovaPhoto from '../../images/youthTeam/malakhova.jpg';
import muravyovaPhoto from '../../images/youthTeam/muravyova.jpg';
import nagornayaPhoto from '../../images/youthTeam/nagornaya.jpg';
import spitsinaPhoto from '../../images/youthTeam/spitsina.jpg';
import suslovaPhoto from '../../images/youthTeam/suslova.jpg';
import chukseevaPhoto from '../../images/youthTeam/chukseeva.jpg';
import zakharovPhoto from '../../images/youthTeam/zakharov.jpg';
import kaverzinPhoto from '../../images/youthTeam/kaverzin.png';
import klimenkoPhoto from '../../images/youthTeam/klimenko.jpg';
import kovalenkoPhoto from '../../images/youthTeam/kovalenko.jpg';
import kondrashovPhoto from '../../images/youthTeam/kondrashov.jpg';
import krivetsPhoto from '../../images/youthTeam/krivets.jpg';
import kuznetsovPhoto from '../../images/youthTeam/kuznetsov.jpg';
import kuznechevskikhPhoto from '../../images/youthTeam/kuznechevskikh.jpg';
import mironovPhoto from '../../images/youthTeam/mironov.jpg';
import coverImg from '../../images/cover4.jpg';
import AthleteCards from '../../components/AthleteCards/AthleteCards';
import Banner from 'components/Banner';
import Container from 'components/Container/Container';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const teamArr = [
  {
    id: 0,
    title: `Мс`,
    name: `Ахметшина Виктория Руслановна`,
    photo: akhmetshinaPhoto,
    dateBirth: '23/12/1999',
    about: [
      { id: 0, text: `Регион - Краснодарский край, Белореченск` },
      { id: 1, text: `Организация - ГБУ КК "ЦОП по плаванию"` },
      { id: 2, text: `Тренер в настоящий момент - Цепаева Т.Г., Цепаев А.А.` },
    ],
  },
  {
    id: 1,
    title: `Мс`,
    name: `Баскакова Татьяна Павловна`,
    photo: baskakovaPhoto,
    dateBirth: '03/08/2000',
    about: [
      { id: 0, text: `Регион - Ростовская область, Санкт-Петербург` },
      { id: 1, text: `Организация - РО УОР, ШВСМ ПО ВВС` },
      { id: 2, text: `Первый тренер в триатлоне - Коноваленко В.В.` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Коноваленко В.В., Заикин М.Ю., Гижа А.А., Титова О.В.`,
      },
    ],
  },
  {
    id: 2,
    title: `Кмс`,
    name: `Запасная Кристина Юрьевна`,
    photo: zapasnayaPhoto,
    dateBirth: '09/01/1998',
    about: [
      { id: 0, text: `Регион - Москва` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Фетисов А.Е., Бутков Д.А., Кераев Ф.К.`,
      },
    ],
  },
  {
    id: 3,
    title: `Мс`,
    name: `Лихторович Татьяна Сергеевна`,
    photo: lichtorowiczPhoto,
    dateBirth: '16/01/1999',
    about: [
      { id: 0, text: `Регион - Ростовская область, Санкт-Петербург` },
      { id: 1, text: `Организация - РО УОР, ШВСМ ПО ВВС` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Коноваленко В.В., Заикин М.Ю., Гижа А.А., Титова О.В.`,
      },
    ],
  },
  {
    id: 4,
    title: `Кмс`,
    name: `Малахова Надежда Алексеевна`,
    photo: malakhovaPhoto,
    dateBirth: '22/03/1999',
    about: [
      { id: 0, text: `Регион - Ярославская область` },
      { id: 1, text: `Организация - МУ СШОР No4` },
      { id: 2, text: `Первый тренер в триатлоне - Творогова Н. И.` },
      { id: 3, text: `Тренер в настоящий момент - Покровский А.В.` },
    ],
  },
  {
    id: 5,
    title: `Кмс`,
    name: `Муравьева Евгения Александровна`,
    photo: muravyovaPhoto,
    dateBirth: '05/01/2000',
    about: [
      { id: 0, text: `Регион - Москва` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Никульшин А.В., Пахомова М.С.`,
      },
    ],
  },
  {
    id: 6,
    title: `Кмс`,
    name: `Нагорная Валерия Евгеньевна`,
    photo: nagornayaPhoto,
    dateBirth: '09/05/2000',
    about: [
      { id: 0, text: `Регион - Волгоградская область, Санкт-Петербург` },
      { id: 1, text: `Организация - МБУ СШОР No 16` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Горин М.М., Горина Г.С., Доронин А.А., Гааг Д.В.`,
      },
    ],
  },
  {
    id: 7,
    title: `Мс`,
    name: `Спицина Екатерина Михайловна`,
    photo: spitsinaPhoto,
    dateBirth: '',
    about: [
      { id: 0, text: `Регион - Москва` },
      { id: 1, text: `Организация - ГБПОУ МСС УОР№2` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Рогатин А.Е., Мещерякова В.Н., Антошина И.Н.`,
      },
    ],
  },
  {
    id: 8,
    title: `Кмс`,
    name: `Суслова (Сруртдинова) Вероника Александровна`,
    photo: suslovaPhoto,
    dateBirth: '13/05/1997',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Ленинградская область` },
      { id: 1, text: `Организация - ШВСМ по ВВС` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Титова О.В., Гижа А.А., Гааг Д.В., Павлов С.В.`,
      },
    ],
  },
  {
    id: 9,
    title: `Кмс`,
    name: `Чуксеева Екатерина Сергеевна`,
    photo: chukseevaPhoto,
    dateBirth: '13/07/2001',
    about: [
      { id: 0, text: `Регион - Пермский край, Березники` },
      { id: 1, text: `Организация - МАУ СП СШ Летающий Лыжник` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Мишанин С.Ю., Струнина Н.Г.`,
      },
    ],
  },
  {
    id: 10,
    title: `Кмс`,
    name: `Захаров Дмитрий Дмитриевич`,
    photo: zakharovPhoto,
    dateBirth: '12/02/2000',
    about: [
      { id: 0, text: `Регион - Пензенская область` },
      { id: 1, text: `Организация - ГАОУ ПО УОР ПО` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Захаров А.В., Брюханкова Т.В.`,
      },
    ],
  },
  {
    id: 11,
    title: `Мс`,
    name: `Каверзин Даниил Владимирович`,
    photo: kaverzinPhoto,
    dateBirth: '03/03/1999',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Ленинградская область` },
      { id: 1, text: `Организация - УОР No1` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Павлов С.В., Брылев А.А., Гижа А.А., Парфенцева Т.М.`,
      },
    ],
  },
  {
    id: 12,
    title: `Мс`,
    name: `Клименко Георгий Викторович`,
    photo: klimenkoPhoto,
    dateBirth: '03/10/1999',
    about: [
      { id: 0, text: `Регион - Ростовская область, Санкт-Петербург` },
      { id: 1, text: `Организация - ГБУ РО "СШОР-5", ВС, ШВСМ ПО ВВС` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Коноваленко В.В., Заикин М.Ю., Гижа А.А., Парфенцева Т.М.`,
      },
    ],
  },
  {
    id: 13,
    title: `Кмс`,
    name: `Коваленко Богдан Александрович`,
    photo: kovalenkoPhoto,
    dateBirth: '28/03/1999',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Ленинградская область` },
      { id: 1, text: `Организация - ШВСМ по ВВС, ВС` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Павлов С.В., Брылев А.А., Гижа А.А., Парфенцева Т.М.`,
      },
    ],
  },
  {
    id: 14,
    title: `Мс`,
    name: `Кондрашов Даниил Дмитриевич`,
    photo: kondrashovPhoto,
    dateBirth: '',
    about: [
      { id: 0, text: `02/04/2000` },
      { id: 1, text: `Регион - Саратовская область , Санкт-Петербург` },
      { id: 2, text: `Организация - МУ "ЦСШ", ГБУ "СОЦСП"` },
      { id: 3, text: `Первый тренер в триатлоне - Полухин Д. В.` },
      {
        id: 4,
        text: `Тренер в настоящий момент - Полухин Д.В., Савельев Д.И., Гижа А.А.`,
      },
      { id: 4, text: `Хобби - музыка` },
    ],
  },
  {
    id: 15,
    title: `Мс`,
    name: `Кривец Илья Андреевич`,
    photo: krivetsPhoto,
    dateBirth: '05/12/1999',
    about: [
      { id: 0, text: `Регион - Саратовская область, Санкт-Петербург` },
      { id: 1, text: `Организация - МУ "ЦСШ", ГБУ "СОЦСП"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Полухин Д.В., Савельев Д.И., Титова О.В.`,
      },
    ],
  },
  {
    id: 16,
    title: `Кмс`,
    name: `Кузнецов Дмитрий Алексеевич`,
    photo: kuznetsovPhoto,
    dateBirth: '24/06/1997',
    about: [
      { id: 0, text: `Регион - Московская область, Фрязино` },
      { id: 1, text: `Организация - ГБУ МО "СШОР по ЛВС"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Заусайлов С.А., Заусайлова О.С.`,
      },
    ],
  },
  {
    id: 17,
    title: `Кмс`,
    name: `Кузнечевских Олег Юрьевич`,
    photo: kuznechevskikhPhoto,
    dateBirth: '24/08/1999',
    about: [
      { id: 0, text: `Регион - Тюменская область` },
      { id: 1, text: `Организация - ВС` },
      { id: 2, text: `Тренер в настоящий момент - Сушкова Н.А., Казарян А.С.` },
    ],
  },
  {
    id: 18,
    title: `Кмс`,
    name: `Миронов Иван Андреевич`,
    photo: mironovPhoto,
    dateBirth: '03/07/1997',
    about: [
      { id: 0, text: `Регион - Московская область, Фрязино` },
      { id: 1, text: `Организация - ГБУ МО "СШОР по ЛВС"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Заусайлов С.А., Заусайлова О.С.`,
      },
    ],
  },
  {
    id: 19,
    title: `Кмс`,
    name: `Самохин Павел Александрович`,
    photo: ``,
    dateBirth: '06/06/1997',
    about: [
      { id: 0, text: `Регион - Москва` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Мещерякова В.Н., Бутков Д.А.`,
      },
    ],
  },
];

const YouthTeam = () => {
  return (
    <>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />
        <AthleteCards athletesArr={teamArr} />
      </Container>
    </>
  );
};

export default YouthTeam;
