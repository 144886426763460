import news190421 from 'images/News/190421.jpeg';
import Article from 'pages/Articles/Article';
import styled from 'styled-components';
import { Paragraph } from 'grommet';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const articte190421 = () => {
  return (
    <Article
      title={`Открытие отделения по направлению «Триатлон»`}
      date={`19 Апреля 2021`}
      coverBack={news190421}
    >
      <CustomParagraph>
      <b>Национальный Государственный университет им П.Ф. Лесгафта в Санкт-Петербурге совместно с Федерацией триатлона России объявляет об открытии отделения по направлению «Триатлон».</b>
      </CustomParagraph>
      <CustomParagraph>
      <i>Данное отделение будет сформировано на базе кафедры теории и методики велосипедного спорта. Старт первого курса запланирован на 1 сентября 2021 года.</i>
      </CustomParagraph>
      <CustomParagraph>
      Планируется, что первый курс студентов будет набран из профессиональных триатлетов. Основной целью данного отделения станет подготовка специалистов высокой профессиональной квалификации, обладающих глубокими теоретическими и прикладными знаниями и компетенциями в области триатлона. 
      </CustomParagraph>
      <CustomParagraph>
      <i>«Безусловно, задача по развитию системы образования для кадров в сфере триатлона является одной из самых ключевых задач для Федерации. Мы благодарны коллегам из НГУ им. Лесфгата за то, что они поддержали нашу идею, и теперь нам предстоит большая работа по подготовке, чтобы с 1 сентября первые студенты начали получать полноценное высшее образование в сфере триатлона»,</i> – прокомментировала открытие отделения президент Федерации триатлона России Ксения Шойгу. 
      </CustomParagraph>
      <CustomParagraph>
      Напомним, ранее на базе Поволжской Государственной Академии Спорта в Казани стартовали курсы профессиональной переподготовки «Тренер в избранном виде спорта. Триатлон». Основными темами курса стали педагогика и психология в области физкультуры и спорта, ключевые аспекты антидопинговой деятельности, правовые основы и инновации в профессиональной деятельности тренера, физиология человека. 
      </CustomParagraph>
      <CustomParagraph>
      Преподавателями курса выступают ведущие практикующие специалисты в области триатлона, а также доценты и профессоры Поволжской Государственной Академии Физической, кандидаты биологических, медицинских и педагогических наук.
      </CustomParagraph>
    </Article>
  )
}

export default articte190421;
