import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Grommet } from 'grommet';
import { theme } from 'components/Themes/theme';
import './index.css';
import App from './App.jsx';
import './i18n';
import { Provider } from 'react-redux';
import { store } from './store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback="loading">
        <Grommet theme={theme}>
          <App />
        </Grommet>
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
