import React from 'react';
import { Box, Tab, Tabs, ResponsiveContext, Heading } from 'grommet';
import Container from '../Container/Container';

const MonthTabs = ({ tabs, onUpdate }) => {
  const size = React.useContext(ResponsiveContext);

  return (
    <Container>
      <Box
        direction="column"
        style={{
          width: `max-content`,
          marginLeft: `auto`,
          marginRight: `auto`,
        }}
      >
        <Tabs onActive={onUpdate}>
          {tabs.map(tab => (
            <Tab
              margin={{
                bottom: size !== `small` ? `large` : `medium`,
              }}
              title={
                <Heading
                  level={4}
                  color="text"
                  style={{ textTransform: 'capitalize' }}
                >
                  {tab.label}
                </Heading>
              }
              key={tab.id}
            ></Tab>
          ))}
        </Tabs>
      </Box>
    </Container>
  );
};

export default MonthTabs;
