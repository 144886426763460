import {Anchor, Box, Card, Grid, Heading, Image, Paragraph} from "grommet";
import logo from 'images/secondary-logo.svg';
import infoImage1 from 'images/AddInfo/AddInfo-1.jpg';
import infoImage2 from 'images/AddInfo/AddInfo-2.jpg';
import infoImage3 from 'images/AddInfo/AddInfo-3.jpg';
import infoImage4 from 'images/AddInfo/AddInfo-4.jpg';
import { routes } from 'const/routes';

const addInfoArray = [
  {
    id: 0,
    title: `Как начать?`,
    description: `Всё самое важное о подготовке к триатлону`,
    image: infoImage1,
    link: routes.whereToStart.path
  },
  // {
  //   id: 1,
  //   title: `Суперсерия`,
  //   description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
  //   image: infoImage2,
  // },
  // {
  //   id: 2,
  //   title: `Видео`,
  //   description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
  //   image: infoImage3,
  // },
  {
    id: 2,
    title: `История триатлона`,
    description: `От первых соревнований до сегодняшнего дня`,
    image: infoImage4,
    link: routes.historyTria.path
  }
];

const AddInfo = () => {
  return (
    <Box margin={{bottom: `70px`}}>
      <Heading
        color={`dark-1`}
        level={2} size={`medium`}
        margin={{bottom: `44px`}}
        style={{textTransform: `uppercase`}}>Дополнительная информация</Heading>

      <Grid columns={{count: 'fit', size: '330px'}} gap="24px">
        {addInfoArray.map(item => (
          <Card justify="between" key={item.id} pad="34px" background={{image: `url("${item.image}")`, color: `rgba(0, 0, 0, 0.4)`}} style={{color: `white`, backgroundBlendMode: `multiply`}} >
            {
            <Image
              src={logo}
              width="0" height="0"
              margin={{bottom: `195px`}}
              alt="Логотип триатлона"/>
            }
            <Heading
              level={3}
              margin={{bottom: `5px`}}
              style={{textTransform: `uppercase`}}
            >{item.title}</Heading>
            <Paragraph margin={{bottom: `45px`}}>{item.description}</Paragraph>
            <Anchor href={item.link} color="white" weight={500} label="Читать далее"/>
          </Card>
        ))}
      </Grid>
    </Box>
  )
}

export default AddInfo;