import { Anchor, Box, Heading, Image, List } from 'grommet';
import coverImg from '../../images/cover4.jpg';
import Container from '../../components/Container/Container';
import pdfIcon from 'images/pdf.png';
import Partners from 'components/Partners/Partners';
import Banner from 'components/Banner';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const ratingsArr = [
  {
    label: `Общий зачет Кубка России и Кубка ФТР по зимнему триатлону 2020`,
    link: `https://yadi.sk/i/LgSmN5O2-gETnw`,
  },
  {
    label: `Общий зачет Кубка России по триатлону-спринт 2020`,
    link: `https://yadi.sk/i/Zvlp4aBckYfP5Q`,
  },
  {
    label: `Общий зачет Кубка России по триатлону 2020`,
    link: `https://yadi.sk/i/xYVI-oeWlTeGkQ`,
  },
];

const RatingsAthletes = () => {
  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />

        <Box
          style={{
            maxWidth: `1190px`,
            marginLeft: `auto`,
            marginRight: `auto`,
          }}
        >
          <Heading
            level={`2`}
            style={{
              width: `100%`,
              maxWidth: `100%`,
              textAlign: `center`,
              textTransform: `uppercase`,
            }}
          >
            2020
          </Heading>
          <List data={ratingsArr} pad="medium" border={false}>
            {ratingsArr => (
              <Box direction="row-responsive" gap="medium" align="center">
                <Image src={pdfIcon} width={`55`} />
                <Anchor
                  href={ratingsArr.link}
                  label={ratingsArr.label}
                  style={{
                    fontSize: `16px`,
                    marginTop: `20px`,
                    marginBottom: `20px`,
                  }}
                />
              </Box>
            )}
          </List>
        </Box>

        <Partners />
      </Container>
    </>
  );
};

export default RatingsAthletes;
