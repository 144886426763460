import news150321 from 'images/News/100days.JPG';
import Article from 'pages/Articles/Article';
import styled from 'styled-components';
import { Paragraph } from 'grommet';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const articte150321 = () => {
  return (
    <Article
      title={`Президент Федерации триатлона России Ксения Шойгу представила план развития ФТР на 2021 год и подписала меморандум о сотрудничестве с ЦСКА`}
      date={`15 МАРТА 2021`}
      coverBack={news150321}
    >
      <CustomParagraph>
      <i>Среди основных результатов: обновление системы образования тренеров по триатлону, старт работы комиссий при Федерации, формирование первых баз для подготовки спортсменов по всей России, подписание соглашений о сотрудничестве с Центральным спортивным клубом Армии и Федерацией велоспорта России и финализация формата Суперсерии при партнерстве с телеканалом Матч ТВ.</i>
      </CustomParagraph>
      <CustomParagraph>
      14 марта 2021 года – важная отметка для всего состава Федерации триатлона России – именно сегодня исполняется ровно 100 дней со дня вступления в должность нового состава президиума и принятия обновлённой стратегии развития триатлона в России. Важная отметка для того, чтобы подвести первые итоги работы. В этой статье мы выделим основные результаты, которых удалось добиться Федерации за этот период.
      </CustomParagraph>
      <CustomParagraph>
      <b>Обновление системы образования тренеров по триатлону</b><br />
Одним из важнейших пунктов этого списка является обновление системы образования тренерского состава. В этом году на базе Национального государственного университета физической культуры, спорта и здоровья им. П.Ф. Лесгафта (Санкт-Петербург) на кафедре теории и методики велосипедного спорта откроется отделение триатлона, где будут готовить профессиональных тренеров, а в Казани на базе Поволжской государственной академии физической культуры, спорта и туризма уже с апреля стартует программа профессиональной переподготовки тренеров по триатлону, на которую уже идет отбор слушателей. 
      </CustomParagraph>
      <CustomParagraph>
      <b>Комиссии при Федерации триатлона России</b><br />
Также нельзя упустить из внимания запуск работы 6 комиссий при ФТР: 
<br />
-Комиссия по детскому триатлону, в рамках которой обсуждаются вопросы безопасности детских стартов, создание детских спортивных лагерей с упором на триатлон, запуск объединенных детских стартов;
<br />
-Комиссия по любительскому спорту, в состав которой вошли организаторы крупнейших триатлонных стартов России;
<br />
-Комиссия по внесению изменений в федеральные стандарты, в рамках которой ведется работа для детей, взрослых и тренеров. В частности, работа над снижением возраста, с которого можно начинать заниматься триатлоном, а также работа над изменением набора необходимого инвентаря и оборудования для спортивных школ;
<br />
-Комиссия по антидопингу – важнейшая комиссия, учитывая новостную повестку вокруг наших олимпийских сборных. Задача – выстроить максимально эффективную и прозрачную систему работы антидопинговых институтов;
<br />
-Комиссия по паратриатлону, задача которой – собрать всех заинтересованных на одной площадке и в первую очередь систематизировать работу в области паратриатлона, создать площадку для диалога;
<br />
-Комиссия по популяризации триатлона, которая состоит из представителей крупнейших федеральных СМИ и представителей блогерского комьюнити. Основная задача комиссии – обеспечить максимальное освещение триатлона на всех возможных площадках.
      </CustomParagraph>
      <CustomParagraph>
      <b>Формирование первых баз для подготовки спортсменов</b><br />
Важнейшим этапом работы ФТР за эти 100 дней также стала и разработка технических заданий по реконструкции подготовительных баз по триатлону в Крыму (Севастополь), Сочи и Ленинградской области (Токсово). Например, на базе в Токсово уже с 2022 года будут проходить тренировочные сборы и проводиться зимние и летние лагеря для детей, занимающихся триатлоном. 
      </CustomParagraph>
      <CustomParagraph>
      <b>Подписание сотрудничества с ЦСКА</b><br />
Среди итогов можно выделить и подписание соглашения о сотрудничестве с Центральным спортивным клубом армии, что стало важнейшим шагом для увеличения количества спортивных объектов для занятий триатлоном по все стране. 
      </CustomParagraph>
      <CustomParagraph>
      <b>Подписание сотрудничества с Федерацией велоспорта России.</b><br />
Подписание соглашения о сотрудничестве с Федерацией велосипедного спорта России – важный шаг для развития триатлона. Ведь оно предусматривает начало взаимодействия между тренерскими составами сборных, обмен опытом в сфере изучения медицинских показателей, сбор и изучение аналитики физического состояния спортсменов, а также взаимодействие в сфере спортивного законодательства. Более того, планируется провести комплексную работу по подготовке базы учебно-методических материалов для спортсменов. Следующие шаги ФТР в этом направлении – начало взаимодействия с Федерацией плавания и с Федерацией легкой атлетики!
      </CustomParagraph>
      <CustomParagraph>
      <b>Запуск Суперсерии</b><br />
За 100 дней работы Федерации удалось финализировать формат Суперсерии – серии триатлонных стартов по всей России, основной задачей которой является объединение профессионального и любительского триатлона на одной площадке и привлечение внимания зрителей по всей стране, чтобы поставить триатлон в один ряд с самыми зрелищными видами спорта! Специально для реализации этой цели информационным партнером Суперсерии стал МАТЧ-ТВ
      </CustomParagraph>
    </Article>
  )
}

export default articte150321;