import React from 'react';
import coverImg from '../../images/cover4.jpg';
import Container from '../../components/Container/Container';
import Partners from 'components/Partners/Partners';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Banner from 'components/Banner';
import Protocols from 'components/Protocols';

const Results = () => {
  return (
    <>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />
        <Protocols />
        <Partners />
      </Container>
    </>
  );
};

export default Results;
