import { useState } from 'react';
import { Box, Card, Heading, Paragraph, Form, FormField, TextInput, Button, ResponsiveContext } from 'grommet';
import { NameSpace } from 'const/namespace';
import styled from 'styled-components';

const CustomHeading = styled(Heading)`
  text-transform: uppercase;
`;

const CustomParagraph = styled(Paragraph)`
  max-width: 448px;
`;

const CustomBtn = styled(Button)`
  padding: 6px 12px;
`;


const Subscribe = () => {
  const [value, setValue] = useState({'subscribe-email': ``});

  return (
    <ResponsiveContext.Consumer>
      { size => (
        <Card
          margin={{bottom: `72px`}}
          pad={{horizontal: `large`, vertical: `38px`}}
          background={`light-1`}
          direction={size !== NameSpace.desktop1900 ? `column` : `row`}
          align={size !== NameSpace.desktop1900 ? `start` : `center`}
          elevation={`none`}
          wrap
          gap={[NameSpace.mobile].includes(size) ? `large` : `medium`}
        >
          <Box margin={{right: `auto`}}>
            <CustomHeading level={3} size={`medium`} margin={{bottom: `8px`}}>Подпишитесь на наши новости</CustomHeading>
            <CustomParagraph size={`small`}>Следите за последними новостями в Российском триатлоне! Получайте новостные уведомления себе на почту!</CustomParagraph>
          </Box>
          <Box margin={{right: `64px`}}>
            <Form
              value={value}
              onChange={nextValue => setValue(nextValue)}
              id={`subscribe-form`}
              onSubmit={ ({value}) => {
                console.log(value);
                setValue({'subscribe-email': ``});
              } }
            >
              <FormField
                size={`12px`}
                width={`384px`}
                margin={{}}
                label="Ваш e-mail:"
                htmlFor={`subscribe-email`}
                name={`subscribe-email`}>
                <TextInput defaultValue={value} size={`medium`} placeholder="Введите email" id={`subscribe-email`} name={`subscribe-email`} />
              </FormField>
            </Form>
          </Box>
          <CustomBtn type={`submit`} primary form={`subscribe-form`} margin={size === NameSpace.desktop1900 ? {top: `medium`} : {}}>Подписаться на рассылку</CustomBtn>
        </Card>
      )}
    </ResponsiveContext.Consumer>
  );
}

export default Subscribe;