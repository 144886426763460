import Container from 'components/Container/Container';
import {
  Box,
  Grommet,
  ResponsiveContext,
  Tab,
  Tabs,
  Text,
  Image,
  Anchor,
  Button,
} from 'grommet';
import React from 'react';
import ChildrenSchools from './ChildrenSchools';
import ChildrensLeague from './ChildrensLeague';
import DescriptionTab from './DescriptionTab';
import Calendar from 'components/Calendar/Calendar';
import FAQTab from './FAQTab';
import FTRChildLogo from '../../images/children/FTRChildLogo.png';
import Documents from './Documents';

const labelTabs = [
  {
    id: 0,
    label: 'Описание проекта',
    content: <DescriptionTab />,
  },
  {
    id: 1,
    label: 'Детская лига',
    content: <ChildrensLeague />,
  },
  { id: 2, label: 'Детские школы', content: <ChildrenSchools /> },
  {
    id: 3,
    label: 'Календарь',
    content: <Calendar event_type={'child'} />,
  },
  {
    id: 4,
    label: 'Документы',
    content: <Documents />,
  },
  {
    id: 5,
    label: 'FAQ',
    content: <FAQTab />,
  },
];

const theme = {
  global: {
    colors: {
      brand: `#DB4C4C`,
      focus: `transparent`,
    },
  },
  tab: {
    margin: `0 16px`,
    pad: {
      bottom: `16px`,
      horizontal: 'small',
    },
    background: 'transparent',
    border: {
      side: `bottom`,
      size: `small`,
      color: {
        dark: ``,
        light: `#D8D8D8`,
      },
      active: {
        color: {
          light: `#DB4C4C`,
        },
      },
      hover: {
        color: {
          light: `#DB4C4C`,
        },
      },
    },
    color: '#D8D8D8',
    hover: {
      color: '#DB4C4C',
    },
    active: {
      background: 'transparent',
      color: '#DB4C4C',
    },
  },
};

export default function ChildrensTriathlonTab() {
  const [activeTab, setActiveTab] = React.useState(0);

  const activeID = labelTabs[activeTab].id;

  const activeContent = labelTabs.find(tab => tab.id === activeID);
  return (
    <Grommet theme={theme}>
      <ResponsiveContext.Consumer>
        {size => (
          <Box>
            <Box
              style={{
                width: `20rem`,
                marginLeft: `auto`,
                marginRight: `auto`,
                marginBottom: `3rem`,
              }}
            >
              <Image src={FTRChildLogo} />
            </Box>
            <Box
              direction="column"
              style={{
                width: `max-content`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
            >
              <Tabs onActive={setActiveTab}>
                {labelTabs.map(tab => (
                  <Tab
                    margin={{
                      bottom: size !== `small` ? `large` : `medium`,
                    }}
                    title={
                      <Text size="medium" weight="bold">
                        {tab.label}
                      </Text>
                    }
                    key={tab.id}
                  ></Tab>
                ))}
              </Tabs>
            </Box>

            <Container>
              <Box
                style={{
                  marginBottom: `50px`,
                }}
              >
                <Box>{activeContent.content}</Box>
                <Box align="start" flex justify="start">
                  <Button
                    type="button"
                    style={{
                      backgroundColor: `#db4c4c`,
                      padding: `6px 32px`,
                      borderRadius: `100px`,
                      fontSize: `12px`,
                      marginTop: `3rem`,
                    }}
                  >
                    <Anchor
                      href={`https://rustriathlon.ru/files/childcalendar2022.pdf`}
                      color={`white`}
                      style={{ textDecoration: 'none', fontWeight: 'normal' }}
                    >
                      Скачать календарь
                    </Anchor>
                  </Button>
                </Box>
              </Box>
            </Container>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}
