import { useContext, useState } from 'react';
import {
  Box,
  Layer,
  Button,
  Heading,
  Text,
  ResponsiveContext,
  Image,
} from 'grommet';
import { FormClose } from 'grommet-icons';
import styled from 'styled-components';
import iconClose from 'images/svg/icon-close.svg';
import styles from './Licence.module.scss';

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-top-right-radius: 0.625rem;
  background-color: #db4c4c;

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 26px;
    height: 26px;
    background-image: url('${iconClose}');
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const LicencePopup = ({ closePopup, licence }) => {
  const [delivery, setDelivery] = useState(0);
  const size = useContext(ResponsiveContext);

  const handleChangeSelect = e => {
    setDelivery(Number(e.target.value));
  };

  return (
    <Layer
      full
      plain={false}
      background={`transparent`}
      onClickOutside={closePopup}
      onEsc={closePopup}
    >
      <Box
        direction={`row`}
        height={{ min: `100%` }}
        justify={`center`}
        background={`rgba(0, 0, 0, 0.3)`}
        align={`start`}
        pad={size === `small` ? `0` : { vertical: `medium` }}
        overflow={`auto`}
      >
        <Box
          width={size === `small` ? `100%` : `60%`}
          background={`white`}
          round={size === `small` ? { size: `0` } : { size: `0.625rem` }}
          pad={`large`}
          style={{ position: `relative` }}
        >
          <CloseButton
            icon={<FormClose color={`#DB4C4C`} />}
            onClick={closePopup}
            style={size === `small` ? { borderRadius: 0 } : null}
          />
          <Box width="medium" height={`auto`} margin={{ bottom: `medium` }}>
            <Image src={licence.img} fit="cover" />
          </Box>
          <Heading level={2} margin={{ bottom: `medium` }}>
            Регистрация лицензии Федерации Триатлона России
          </Heading>
          <Text size={`small`}>
            Особенно внимательно заполняйте эти поля. Следите за тем, чтобы тут
            были указаны именно ВАШИ контактные данные.
          </Text>
          <br />
          <Text size={`small`}>
            Эти данные будут сопоставлены с уже имеющимися у нас и если в нашей
            базе будет обнаружен указанный Вами e-mail, телефон или ФИО, то
            часть данных мы возьмем из имеющейся базы.
          </Text>
          <Heading level={3} margin={{ top: `small` }}>
            Тип выбранной лицензии: {licence.title} {licence.subtitle}
          </Heading>
          <Heading level={3} margin={{ bottom: `small` }}>
            Стоимость: {licence.price} руб.
          </Heading>

          <form
            className={styles.form}
            method="post"
            enctype="multipart/form-data"
            accept-charset="utf-8"
            action={process.env.REACT_APP_API + `/server/license`}
          >
            <div className={styles.wrap}>
              <label className={styles.labelFile}>
                <span>Фото на лицензию в формате 1Х1 (квадрат) *</span>
                <input
                  type="file"
                  name="file"
                  className={styles.file}
                  required
                />
              </label>
              {licence.name === `day` && (
                <>
                  <Text>Дата действия лицензии</Text>
                  <input
                    className={styles.input}
                    type="date"
                    name="valid_date"
                    pattern="\d{1,2}/\d{1,2}/\d{4}"
                    placeholder="Дата действия лицензии*"
                    required
                  />
                </>
              )}
              <input
                className={styles.input}
                type="text"
                name="fio_ru"
                placeholder="Фамилия Имя Отчество*"
                required
              />
              <input
                className={styles.input}
                type="text"
                name="fio_en"
                placeholder="Фамилия Имя на Английском*"
                required
              />
              <Text>Дата рождения</Text>
              <input
                className={styles.input}
                type="date"
                name="b_date"
                pattern="\d{1,2}/\d{1,2}/\d{4}"
                placeholder="Дата рождения*"
                required
              />
              <input
                className={styles.input}
                type="text"
                name="city"
                placeholder="Укажите Ваш город проживания*"
                required
              />
              <input
                className={styles.input}
                type="tel"
                name="phone"
                placeholder="Ваш телефон*"
                required
              />
              <input
                className={styles.input}
                type="email"
                name="email"
                placeholder="Ваш E-mail*"
                required
              />
              <input
                className={styles.input}
                type="text"
                name="passport"
                placeholder="Серия и номер паспорта или свидетельства о рождении*"
                required
              />
              <input
                className={styles.input}
                type="text"
                name="passport_source"
                placeholder="Кем выдан*"
                required
              />
              <Text>Дата выдачи документа</Text>
              <input
                className={styles.input}
                type="date"
                name="passport_date"
                pattern="\d{1,2}/\d{1,2}/\d{4}"
                placeholder="Дата выдачи документа*"
                required
              />
              <input
                className={styles.input}
                type="text"
                name="p_address"
                placeholder="Адрес по прописке*"
                required
              />
            </div>

            <Text margin={{ vertical: `1rem` }} style={{ display: `block` }}>
              Экстренная связь: введите данные контактного лица с которым можно
              связаться в случае экстренной ситуации (родственник, близкий друг)
            </Text>

            <div className={styles.wrap}>
              <input
                className={styles.input}
                type="text"
                name="e_name"
                placeholder="Имя*"
                required
              />
              <input
                className={styles.input}
                type="tel"
                name="e_phone"
                placeholder="Телефон*"
                required
              />
              <select
                className={styles.select}
                name="delivery_method"
                value={delivery}
                onChange={handleChangeSelect}
              >
                <option value="0">
                  Самовывоз из офиса ФТР (Москва, Лужнецкая наб, д.8)
                  (бесплатно)
                </option>
                <option value="0.0">
                  Доставка не требуется - только для участников с One Day
                  лицензией
                </option>
                <option value="500">
                  Доставка курьером в пределах МКАД (500 руб.)
                </option>
                <option value="950">
                  Доставка почтой (в города России) (950 руб.)
                </option>
              </select>
              <input
                className={styles.input}
                type="text"
                name="delivery_address"
                placeholder="Адрес доставки"
              />
              <label className={styles.textareaLabel} htmlFor="comment">
                Примечания / Комментарий
              </label>
              <textarea
                id="comment"
                name="delivery_comment"
                className={styles.textarea}
                rows="5"
              />
              <div className={styles.checkboxWrap}>
                <input
                  id="chb"
                  type="checkbox"
                  className={`${styles.checkbox} visually-hidden`}
                  required
                />
                <label className={styles.labelCheckbox} htmlFor="chb">
                  Нажимая на кнопку, я принимаю условия{' '}
                  <a href="/">Пользовательского соглашения.*</a>
                </label>
              </div>
            </div>
            <div className={styles.costWrap}>
              <span>К оплате:</span>
              <span className={styles.cost}>
                &nbsp;{licence.price + delivery} руб.
              </span>
            </div>
            <Button
              fill={true}
              style={{ textAlign: `center` }}
              primary
              type={`submit`}
            >
              Отправить
            </Button>
            <input hidden type="text" name="format" value={licence.name} />
          </form>
        </Box>
      </Box>
    </Layer>
  );
};

export default LicencePopup;
