import { Anchor, Box, Image } from 'grommet';
// import { FacebookOption, Instagram } from 'grommet-icons';
import iconVk from 'images/triathlon_vk.svg';
import iconTelega from 'images/triathlon_telegram.svg';

const SocialMedia = () => {
  return (
    <Box direction="row" align="center">
{/*      <Anchor
        href="https://www.instagram.com/rustriathlon/"
        icon={<Instagram size="18px" color="#DB4C4C" />}
        target={`_blank`}
        rel={`noopener noreferrer`}
      />*/}
      <Anchor
        href="https://vk.com/rustriathlon"
        icon={
          <Image src={iconVk} style={{ maxWidth: `24px` }} alt="Вконтакте" />
        }
        target={`_blank`}
        rel={`noopener noreferrer`}
      />
      <Anchor
        href="https://t.me/rustriathlon"
        icon={
          <Image src={iconTelega} style={{ maxWidth: `24px` }} alt="Телеграм" />
        }
        target={`_blank`}
        rel={`noopener noreferrer`}
      />
{/*      <Anchor
        href="https://www.facebook.com/RussianTriathlonFederation"
        icon={<FacebookOption size="22px" color="#DB4C4C" />}
        target={`_blank`}
        rel={`noopener noreferrer`}
      />*/}
      {/*<Anchor*/}
      {/*  href="https://youtube.com/"*/}
      {/*  icon={<Youtube color="#DB4C4C" />}*/}
      {/*/>*/}
    </Box>
  );
};

export default SocialMedia;
