import Input from 'components/Input/Input';
import {
  Box,
  Heading,
  Button,
  Text,
  Anchor,
  Grommet,
  Grid,
  FormField,
  FileInput,
  ResponsiveContext,
} from 'grommet';
import React from 'react';
import ErrorPopup from 'components/Popups/OlympicReservePopup/ErrorPopup';
import api from '../../api';
import FormPopup from 'components/Popups/OlympicReservePopup/FormPopup';
import { theme } from 'components/Themes/theme';
import { Form } from 'react-final-form';
import Checkbox from 'components/CheckBox/CheckBox';
import DateInput from 'components/DateInput/DateInput';

const ApplicationTab = () => {
  const size = React.useContext(ResponsiveContext);
  const [showPopup, setShowPopup] = React.useState(false);
  const [errorPopup, setErrorPopup] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [filename, setFilename] = React.useState('');

  const onSubmit = async (formState, form) => {
    try {
      const { accept_terms, passport, ...formData } = formState;
      if (!accept_terms) return;

      let response = await api.post(
        '/server/olympic/application',
        JSON.stringify({
          ...formData,
          b_date: new Date(formState.b_date).toISOString(),
          // passport_valid: new Date(formState.passport_valid).toISOString(),
          height: Number(formState.height),
          weight: Number(formState.weight),
          filename: filename,
        })
      );
      if (response.status === 200) {
        setShowPopup(true);
        form.restart();
        setFiles([]);
      }
    } catch (error) {
      console.log(error);
      setErrorPopup(true);
    }
  };

  const uploadFile = async event => {
    event.preventDefault();
    if (files.length > 0) {
      deleteFile(files);

      setFiles([]);
    }

    const file = event.target.files[0];

    let formData = new FormData();
    formData.append('file', file);

    let response = await api.post('/server/olympic/application/file', formData);

    if (response.status === 200) {
      if (files.length === 0) {
        setFilename(response.data.filename)
        setFiles([response, ...files]);
      } else {
        setFiles([response]);
      }
    }
  };

  const deleteFile = async filenames => {
    for (let filename of filenames) {
      await api.delete(`/server/olympic/application/file`, filename);
    }
    setFiles([]);
  };

  return (
    <Grommet theme={theme}>
      <Box>
        <Heading
          level={`3`}
          margin={{ top: `18px`, bottom: `38px` }}
          style={{ textTransform: `uppercase` }}
        >
          Заявка на участие
        </Heading>

        <Form onSubmit={onSubmit}>
          {({ handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                columns={{ count: 'fit', size: '200px' }}
                pad={{ top: 'medium' }}
                style={{
                  maxWidth: '1040px',
                  borderRadius: '30px',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <Box
                  direction="column"
                  width="100%"
                  pad={{ left: 'large', right: 'large', bottom: 'large' }}
                  style={{ maxWidth: '450px' }}
                >
                  <Input
                    label="ФИО на Русском"
                    placeholder="Иванов Иван Иванович"
                    name="name"
                    type="text"
                    required
                  />
                  <DateInput
                    label="Дата рождения"
                    name="b_date"
                    placeholder="дд/мм/гггг"
                    required
                  />
                  <Input label="Ваш телефон" name="phone" type="tel" required />
                  <Input
                    label="Квалификация (разряд, звание)"
                    name="qualification"
                    type="text"
                    required
                  />
                  <Input
                    label="Первый вид спорта"
                    name="first_sport"
                    type="text"
                    required
                  />
                  <Input
                    label="ФИО тренера (если есть)"
                    name="trainer_name"
                    type="text"
                    required={!!values.trainer_contact}
                  />
                  <Input
                    label="Рост в см"
                    name="height"
                    type="number"
                    required
                  />
                  {/*<Box margin="30px 5px 30px 0px">
                    <Checkbox
                      name="passport"
                      label={
                        <Text size="14px" weight="bolder">
                          Наличие загранпаспорта{' '}
                          {!!values.passport_valid && '*'}
                        </Text>
                      }
                      style={{ borderColor: '#3d3d3d' }}
                      required={!!values.passport_valid}
                    />
                  </Box>*/}
                </Box>
                <Box
                  direction="column"
                  width="100%"
                  pad={{ left: 'large', right: 'large', bottom: 'large' }}
                  style={{ maxWidth: '450px' }}
                >
                  {/*<Input
                    label="ФИО на Английском"
                    placeholder="Ivanov Ivan Ivanovich"
                    name="name_en"
                    type="text"
                    required
                  />*/}
                  <Input
                    label="Укажите Ваш город проживания"
                    name="city_name"
                    type="text"
                    required
                  />
                  <Input
                    label="Ваш E-mail"
                    placeholder="ivanov@gmail.com"
                    name="email"
                    type="email"
                    required
                  />
                  <Input
                    label="Лучший результат (личный рекорд)"
                    name="best_result"
                    type="text"
                    placeholder="Вид спорта, дистанция, результат"
                    required
                  />
                  <Input
                    label="Спортивный стаж"
                    name="experience"
                    type="text"
                    required
                  />

                  <Input
                    label="Телефон, E-mail тренера (если есть)"
                    name="trainer_contact"
                    required={!!values.trainer_name}
                  />

                  <Input
                    label="Вес в кг"
                    name="weight"
                    type="number"
                    required
                  />
                  {/*<DateInput
                    label="Срок действия паспорта"
                    name="passport_valid"
                    placeholder="дд/мм/гггг"
                    required={!!values.passport}
                  />*/}

                  <Input
                    label="Ссылка на видео о прохождении дистанции"
                    name="video"
                    type="text"
                  />
                </Box>
              </Grid>

              <Grid
                columns={{ count: 'fit', size: '200px' }}
                margin={{ top: '36px' }}
                style={{
                  maxWidth: '1040px',
                  borderRadius: '30px',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <Box direction="column" width="100%" pad="large">
                  <FormField
                    label="Протокол результатов"
                    name="filename"
                    htmlFor="input-id"
                    margin="0"
                    required
                    width="100%"
                    style={{
                      border: '1px solid',
                      borderRadius: '4px',
                      borderColor: 'rgb(68, 68, 68, 0.5)',
                    }}
                  >
                    <FileInput
                      id="filename"
                      name="filename"
                      onChange={uploadFile}
                      value={files.length > 0 ? files[0].filename : ''}
                      messages={{
                        browse: 'Выбрать файл',
                        dropPrompt: ' ',
                      }}
                      style={{ padding: '3px' }}
                    />
                  </FormField>
                  <Button
                    // label={`Файл ${id + 1}`}
                    onClick={() => deleteFile(files)}
                    style={{
                      textAlign: 'center',
                      width: '200px',
                      fontWeight: 'normal',
                      color: '#FFFFFF',
                      backgroundColor: '#DB4C4C',
                      borderRadius: '4px',
                      marginTop: '20px',
                      display: 'block',
                      marginLeft: 'auto',
                    }}
                    disabled={files.length === 0}
                  >
                    <Text
                      size={size === `small` ? '12px' : '14px'}
                      style={{ paddingTop: '30px', paddingBottom: '30px' }}
                    >
                      Удалить
                    </Text>
                  </Button>
                </Box>
              </Grid>

              {/*<Box
                size="medium"
                background="#F8F8F8"
                style={{
                  maxWidth: '1040px',
                  borderRadius: '30px',
                  margin: '36px 0',
                }}
              >
                <Box pad={{ top: 'large', left: 'large', right: 'large' }}>
                  <Text size="large" weight="bold">
                    Экстренная связь
                  </Text>

                  <Text size="small" weight="normal">
                    Введите данные контактного лица, с которым можно связаться в
                    случае экстренной ситуации (родственник, близкий друг)
                  </Text>
                </Box>

                <Grid
                  columns={{ count: 'fit', size: '200px' }}
                  pad={{ top: 'medium' }}
                  style={{
                    maxWidth: '1040px',
                    borderRadius: '30px',
                    backgroundColor: '#F8F8F8',
                  }}
                >
                  <Box
                    direction="column"
                    width="100%"
                    pad={{ left: 'large', right: 'large', bottom: 'large' }}
                    style={{ maxWidth: '450px' }}
                  >
                    <Input
                      label="ФИО"
                      name="emergency_name"
                      type="text"
                      required
                    />
                  </Box>
                  <Box
                    direction="column"
                    width="100%"
                    pad={{ left: 'large', right: 'large', bottom: 'large' }}
                    style={{ maxWidth: '450px' }}
                  >
                    <Input
                      label="Телефон"
                      name="emergency_phone"
                      type="tel"
                      required
                    />
                  </Box>
                </Grid>
              </Box>*/}

              <Box margin="30px 5px">
                <Checkbox
                  name="accept_terms"
                  label={
                    <Text size="14px" weight="normal">
                      Нажимая на кнопку, я принимаю условия{' '}
                      <Anchor>Пользовательского соглашения</Anchor>*
                    </Text>
                  }
                  required
                />
              </Box>

              <Button
                width="100%"
                type="submit"
                disabled={submitting}
                label={
                  <Text size="small" color="white">
                    Отправить заявку
                  </Text>
                }
                style={{
                  backgroundColor: '#DB4C4C',
                  border: 'none',
                  padding: '10px 50px',
                  borderRadius: '30px',
                }}
              />

              <Text margin={{ left: 'small' }} size="small">
                * — отмечены поля обязательные для заполнения
              </Text>
            </form>
          )}
        </Form>
      </Box>

      {showPopup && <FormPopup statePopupHandler={setShowPopup} />}
      {errorPopup && <ErrorPopup statePopupHandler={setErrorPopup} />}
    </Grommet>
  );
};

export default ApplicationTab;
