import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import { Field } from 'react-final-form';
import { Text, TextInput } from 'grommet';

const validateRequired = value => (value ? undefined : 'Обязательное');
const composeValidators =
  (...validators) =>
  value =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export default function DateInput({
  name,
  label,
  required,
  validate,
  placeholder,
}) {
  const validators = [];
  if (required) {
    validators.push(validateRequired);
  }
  if (validate) {
    validators.push(validate);
  }
  return (
    <Field
      name={name}
      required={required}
      validate={composeValidators(...validators)}
      render={({ input, meta }) => {
        const error = meta.touched && meta.error;
        return (
          <div>
            <Text
              size="14px"
              weight="bold"
              style={{
                margin: '16px 0',
                display: 'block',
                whiteSpace: 'nowrap',
              }}
            >
              {label} {required ? '*' : ''}
            </Text>
            <DatePicker
              {...input}
              selected={input.value}
              customInput={<TextInput />}
              placeholderText={placeholder}
            />
            {error && (
              <Text size="10px" color="#DB4C4C">
                {error}
              </Text>
            )}
          </div>
        );
      }}
    />
  );
}
