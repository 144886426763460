import { Text, MaskedInput } from "grommet";
import React from "react";
import { Field } from "react-final-form";

export const phoneMask = [
  { fixed: "+7 (" },

  {
    length: 3,
    regexp: /^[0-9]{1,3}$/,
    placeholder: "xxx",
  },
  { fixed: ")" },
  { fixed: " " },
  {
    length: 3,
    regexp: /^[0-9]{1,3}$/,
    placeholder: "xxx",
  },
  { fixed: "-" },
  {
    length: 4,
    regexp: /^[0-9]{1,4}$/,
    placeholder: "xxxx",
  },
];

const validateRequired = (value) => (value ? undefined : "Обязательное");
const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export default function Input({
  name,
  type,
  label,
  required,
  placeholder,
  validate,
}) {
  const isTel = type === "tel";
  const validators = [];
  if (required) {
    validators.push(validateRequired);
  }
  if (validate) {
    validators.push(validate);
  }
  return (
    <Field
      name={name}
      required={required}
      validate={composeValidators(...validators)}
      render={({ input, meta }) => {
        const error = meta.touched && meta.error;
        return (
          <div>
            <Text
              size="14px"
              weight="bold"
              style={{
                margin: "16px 0",
                display: "block",
                whiteSpace: "nowrap",
              }}
            >
              {label} {required && "*"}
            </Text>

            <MaskedInput
              plain={false}
              type={type}
              mask={isTel ? phoneMask : undefined}
              placeholder={placeholder}
              {...input}
            />
            {error && (
              <Text size="10px" color="#DB4C4C">
                {error}
              </Text>
            )}
          </div>
        );
      }}
    />
  );
}
