import Container from '../Container/Container';
import { Box, CheckBox, Heading, Select, FormField, ResponsiveContext } from 'grommet';
import MonthTabs from './MonthTabs';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { months } from '../../const/months';
import CalendarEvents from './CalendarEvents';
import { getAllEvents } from 'store/action/events';
import { useSelector } from 'react-redux';
import { category, distance, event_subtype, event_type_filter, status } from '../../utils/const';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { routes } from '../../const/routes';

const CustomFormField = styled(FormField)`
  width: 15%;
  min-width: 145px;
  border-bottom: 1px solid #d8d8d8;
  
  @media (max-width: 580px) {
    width: auto;
    min-width: 0;
  }

  @media (max-width: 450px) {
    width: 100%;
  }

  & button {
    font-size: 18px;
    font-weight: 500;
    color: black;
    border: 0;
  }
`;

const CustomSelect = styled(Select)`
  &::placeholder {
    color: #000000;
    opacity: 1;
  }
`;

const Option = React.memo(({ value, selected }) => (
  <Box direction="row" gap="small" align="center" pad="xsmall">
    <CheckBox tabIndex="-1" checked={selected} onChange={() => {}} />{value}
  </Box>
));

const getOptions = (list) => {
  return list.map((item) => item.name);
};

const statusOptions = getOptions(status);
const subtypeOptions = getOptions(event_subtype);
const distanceOptions = getOptions(distance);
const categoryOptions = getOptions(category);
const typeOptions = getOptions(event_type_filter);

const Calendar = ({ event_type }) => {
  const size = useContext(ResponsiveContext);
  const location = useLocation();
  const calendarRoute = routes.calendar.path;
  const currentUrl = location.pathname.slice(0, calendarRoute.length);
  const sizes = [`small`, `tablet`,];
  const [selectedMonth, setSelectedMonth] = React.useState(months[0]);
  const [filter, setFilter] = useState({
    status: [],
    eventSubtype: [],
    distance: [],
    category: [],
    type: [],
  });

  const filterStatusItems = filter.status.map((item) => status[item].value);
  const filterSubtypeItems = filter.eventSubtype.map((item) => event_subtype[item].value);
  const filterDistanceItems = filter.distance.map((item) => distance[item].value);
  const filterCategoryItems = filter.category.map((item) => category[item].value);
  const filterTypeItems = filter.type.map((item) => event_type_filter[item].value);

  const events = useSelector(state => state.events.items);

  const eventsParamFilter = events.filter(i =>
    event_type !== 'all' ? i.event_type.includes(event_type) : true
  );

  const filterCheck = (filterItems, eventItems) => {
    return ((Array.isArray(eventItems) && filterItems.some(item => eventItems.includes(item))) || (filterItems.length === 0) || filterItems.includes('all'));
  };

  const eventsFilter = eventsParamFilter.filter(i =>
    filterCheck(filterStatusItems, i.status) &&
    filterCheck(filterSubtypeItems, i.event_subtype) &&
    filterCheck(filterDistanceItems, i.distance) &&
    filterCheck(filterCategoryItems, i.category) &&
    filterCheck(filterTypeItems, i.event_type)
  );

  React.useEffect(() => {
    getAllEvents();
  }, []);

  const filteredMonths = months.filter(month => {
    const hasEvents = events.some(event =>
      moment(event.start_date, 'YYYY-MM-DD').isSame(month.value, 'M')
    );

    return hasEvents || month.value === null;
  });

  const handleCheckedFilter = (evt, values, filterName) => {
    const optionName = evt.option;
    const { selected: nextSelected } = evt

    if (optionName === 'Все') {
      if (!filter[filterName].includes(0)) {
        const filteredOptions = values.map((v, i) => i);
        setFilter({...filter, [filterName]: filteredOptions})
      } else {
        setFilter({...filter, [filterName]: []})
      }
    } else {
      setFilter({...filter, [filterName]: nextSelected})
    }
  };

  return (
    <Container overflow={true}>
      <Box margin={{ bottom: '72px' }}>
        <Heading
          style={{ textTransform: `uppercase` }}
          level={2}
          size={`medium`}
          color={`dark-1`}
          margin={{ bottom: `large`, top: `xsmall` }}
        >
          Календарь мероприятий
        </Heading>

        <MonthTabs
          onUpdate={id => setSelectedMonth(filteredMonths[id])}
          tabs={filteredMonths}
        />

        {currentUrl === calendarRoute && (
          <Box margin={{bottom: !sizes.includes(size) ? '126px' : '40px', horizontal: 'auto' }} direction="row" gap="25px" wrap>
            <CustomFormField>
              <CustomSelect
                multiple
                closeOnChange={false}
                placeholder="Статус"
                selected={filter.status}
                options={statusOptions}
                dropHeight="medium"
                valueLabel="Статус"
                onChange={(evt) => handleCheckedFilter(evt, status, 'status')}
              >
                {(option, index) => (
                  <Option value={option} selected={filter.status.indexOf(index) !== -1} />
                )}
              </CustomSelect>
            </CustomFormField>
            <CustomFormField>
              <CustomSelect
                multiple
                closeOnChange={false}
                placeholder="Вид"
                selected={filter.eventSubtype}
                options={subtypeOptions}
                dropHeight="medium"
                valueLabel="Вид"
                onChange={(evt) => handleCheckedFilter(evt, event_subtype, 'eventSubtype')}
              >
                {(option, index) => (
                  <Option value={option} selected={filter.eventSubtype.indexOf(index) !== -1} />
                )}
              </CustomSelect>
            </CustomFormField>
            <CustomFormField>
              <CustomSelect
                multiple
                closeOnChange={false}
                placeholder="Дистанция"
                selected={filter.distance}
                options={distanceOptions}
                dropHeight="medium"
                valueLabel="Дистанция"
                onChange={(evt) => handleCheckedFilter(evt, distance, 'distance')}
              >
                {(option, index) => (
                  <Option value={option} selected={filter.distance.indexOf(index) !== -1} />
                )}
              </CustomSelect>
            </CustomFormField>
            <CustomFormField>
              <CustomSelect
                multiple
                closeOnChange={false}
                placeholder="Категория"
                selected={filter.category}
                options={categoryOptions}
                dropHeight="medium"
                valueLabel="Категория"
                onChange={(evt) => handleCheckedFilter(evt, category, 'category')}
              >
                {(option, index) => (
                  <Option value={option} selected={filter.category.indexOf(index) !== -1} />
                )}
              </CustomSelect>
            </CustomFormField>
            <CustomFormField>
              <CustomSelect
                multiple
                closeOnChange={false}
                placeholder="Тип"
                selected={filter.type}
                options={typeOptions}
                dropHeight="medium"
                valueLabel="Тип"
                onChange={(evt) => handleCheckedFilter(evt, event_type_filter, 'type')}
              >
                {(option, index) => (
                  <Option value={option} selected={filter.type.indexOf(index) !== -1} />
                )}
              </CustomSelect>
            </CustomFormField>
          </Box>
        )}

        <CalendarEvents
          events={eventsFilter}
          // image={Image}
          selectedMonth={selectedMonth}
        />
      </Box>
    </Container>
  );
};

export default Calendar;
