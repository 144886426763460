import galushkinaPhoto from '../../images/youthSquad/galushkina.jpg';
import zakharovaPhoto from '../../images/youthSquad/zakharova.jpg';
import nikitinaPhoto from '../../images/youthSquad/nikitina.jpg';
import nikulPhoto from '../../images/youthSquad/nikul.jpg';
import popovaPhoto from '../../images/youthSquad/popova.png';
import rusakPhoto from '../../images/youthSquad/rusak.jpg';
import sysoevaPhoto from '../../images/youthSquad/sysoeva.jpg';
import firsovaPhoto from '../../images/youthSquad/firsova.png';
import chizhikovaPhoto from '../../images/youthSquad/chizhikova.png';
import shirokovaPhoto from '../../images/youthSquad/shirokova.png';
import bastrakovPhoto from '../../images/youthSquad/bastrakov.png';
import garipovPhoto from '../../images/youthSquad/garipov.png';
import dubrovinPhoto from '../../images/youthSquad/dubrovin.jpg';
import karpeevPhoto from '../../images/youthSquad/karpeev.jpg';
import krylovskoyPhoto from '../../images/youthSquad/krylovskoy.png';
import kuzminPhoto from '../../images/youthSquad/kuzmin.png';
import nefedovPhoto from '../../images/youthSquad/nefedov.jpg';
import smoothPhoto from '../../images/youthSquad/smooth.png';
import spiridonovPhoto from '../../images/youthSquad/spiridonov.png';
import noimagePhoto from '../../images/no-image.jpg';
import AthleteCards from '../../components/AthleteCards/AthleteCards';
import coverImg from '../../images/cover4.jpg';
import Container from 'components/Container/Container';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Banner from 'components/Banner';

const teamArr = [
  {
    id: 0,
    title: `Кмс`,
    name: `Галушкина Анна Андреевна`,
    photo: galushkinaPhoto,
    dateBirth: '24/02/2005',
    about: [
      { id: 0, text: `Регион: Ярославская область, Рыбинск` },
      { id: 1, text: `Организация - МАУ СШОР "ТЕМП"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Васильева О.В., Васильев М.А.`,
      },
    ],
  },
  {
    id: 1,
    title: `Кмс`,
    name: `Захарова Дарья Алексеевна`,
    photo: zakharovaPhoto,
    dateBirth: '12/05/2005',
    about: [
      { id: 0, text: `Регион: Волгоградская область, Санкт-Петербург` },
      { id: 1, text: `Организация - МБУ СШОР No 16` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Горин М.М., Горина Г.С., Доронин А.А., Гааг Д.В.`,
      },
    ],
  },
  {
    id: 2,
    title: `Кмс`,
    name: `Никитина Виктория Игоревна`,
    photo: nikitinaPhoto,
    dateBirth: '22/01/2003',
    about: [
      { id: 0, text: `Регион: Краснодарский край` },
      { id: 1, text: `Организация - ГБУ КК "ЦОП по плаванию"` },
      { id: 2, text: `Тренер в настоящий момент - Цепаева Т.Г.Цепаев А.А.` },
    ],
  },
  {
    id: 3,
    title: `Кмс`,
    name: `Никул Елизавета Дмитриевна`,
    photo: nikulPhoto,
    dateBirth: '30/07/2003',
    about: [
      { id: 0, text: `Регион: Новосибирская область` },
      { id: 1, text: `Организация - НУ(К)ОР` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Малыхин Н.И., Малыхина В.В.`,
      },
    ],
  },
  {
    id: 4,
    title: `Кмс`,
    name: `Попова Полина Ильинична`,
    photo: popovaPhoto,
    dateBirth: '07/04/2004',
    about: [
      { id: 0, text: `Регион: Москва, Новосибирская область` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Бутков Д.А., Генералова О.Н.`,
      },
    ],
  },
  {
    id: 5,
    title: `Кмс`,
    name: `Русак Александра Андреевна`,
    photo: rusakPhoto,
    dateBirth: '21/06/2005',
    about: [
      { id: 0, text: `Регион: Краснодарский край, п.Родники` },
      { id: 1, text: `Организация - ГБУ КК "ЦОП по плаванию"` },
      { id: 2, text: `Тренер в настоящий момент - Цепаева Т.Г., Цепаев А.А.` },
    ],
  },
  {
    id: 6,
    title: `Кмс`,
    name: `Сысоева Инна Игоревна`,
    photo: sysoevaPhoto,
    dateBirth: '29/06/2004',
    about: [
      { id: 0, text: `Регион: Краснодарский край, Белореченск` },
      { id: 1, text: `Организация - ГБУ КК "ЦОП по плаванию"` },
      { id: 2, text: `Тренер в настоящий момент - Сысоев И.В.` },
    ],
  },
  {
    id: 7,
    title: `Кмс`,
    name: `Фирсова Дарья Александровна`,
    photo: firsovaPhoto,
    dateBirth: '06/02/2004',
    about: [
      { id: 0, text: `Регион: Московская область, Фрязино` },
      { id: 1, text: `Организация - ГБУ МО "СШОР по ЛВС"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Заусайлов С.А., Заусайлова О.С.`,
      },
    ],
  },
  {
    id: 8,
    title: `Кмс`,
    name: `Чижикова Светлана Ивановна`,
    photo: chizhikovaPhoto,
    dateBirth: '19/07/2004',
    about: [
      { id: 0, text: `Регион: Красноярский край` },
      { id: 1, text: `Организация - МАУ СШОР "Здоровый мир"` },
      { id: 2, text: `Тренер в настоящий момент - Башун Д.Ю., Башун Т.В.` },
    ],
  },
  {
    id: 9,
    title: `Кмс`,
    name: `Широкова Елена Викторовна`,
    photo: shirokovaPhoto,
    dateBirth: '16/05/2004',
    about: [
      { id: 0, text: `Регион: Саратовская область` },
      { id: 1, text: `Организация - МУ "ЦСШ"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Мещеряков С.П., Полухин Д.В., Савельев Д.И.`,
      },
    ],
  },
  {
    id: 10,
    title: `Кмс`,
    name: `Бастраков Михаил Александрович`,
    photo: bastrakovPhoto,
    dateBirth: '24/03/2003',
    about: [
      { id: 0, text: `Регион: Москва, Новосибирская область` },
      { id: 1, text: `Организация: ГБПОУ МСС УОРNo2` },
      { id: 2, text: `Тренер в настоящий момент - Антошина И.Н.` },
    ],
  },
  {
    id: 11,
    title: `Кмс`,
    name: `Гарипов Аскар Азатович`,
    photo: garipovPhoto,
    dateBirth: '08/05/2004',
    about: [
      { id: 0, text: `Регион: Москва` },
      { id: 1, text: `Организация: ГБПОУ МСС УОРNo2` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Фетисов А.Е., Бутков Д.А., Генералова О.Н.`,
      },
    ],
  },
  {
    id: 12,
    title: `Кмс`,
    name: `Дубровин Илья Алексеевич`,
    photo: dubrovinPhoto,
    dateBirth: '24/03/2004',
    about: [
      { id: 0, text: `Регион: Краснодарский край п.Родники` },
      { id: 1, text: `Организация: ГБУ КК "ЦОП по плаванию"` },
      { id: 2, text: `Тренер в настоящий момент - Цепаева Т.Г., Цепаев А.А.` },
    ],
  },
  {
    id: 13,
    title: `Кмс`,
    name: `Карпеев Дмитрий Андреевич`,
    photo: karpeevPhoto,
    dateBirth: '19/02/2005',
    about: [
      { id: 0, text: `Регион: Краснодарский край, Белореченск` },
      { id: 1, text: `Организация: ГБУ КК "ЦОП по плаванию"` },
      { id: 2, text: `Тренер в настоящий момент - Цепаева Т.Г., Цепаев А.А.` },
    ],
  },
  {
    id: 14,
    title: `Кмс`,
    name: `Крыловской Михаил Денисович`,
    photo: krylovskoyPhoto,
    dateBirth: '13/10/2005',
    about: [
      { id: 0, text: `Регион: Ростовская область` },
      { id: 1, text: `Организация: ГБУ РО "СШОР-5"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Коноваленко В.В., Заикин М.Ю., Погорелов А.А.`,
      },
    ],
  },
  {
    id: 15,
    title: `Кмс`,
    name: `Кузьмин Ярослав Артемович`,
    photo: kuzminPhoto,
    dateBirth: '23/08/2003',
    about: [
      { id: 0, text: `Ярославская область, Рыбинск` },
      { id: 1, text: `Организация: МАУ СШОР "ТЕМП"` },
      { id: 2, text: `Тренер в настоящий момент - Ивушин А.М.` },
    ],
  },
  {
    id: 16,
    title: `Кмс`,
    name: `Нефедов Дмитрий Андреевич`,
    photo: nefedovPhoto,
    dateBirth: '19/06/2003',
    about: [
      { id: 0, text: `Регион: Волгоградская область` },
      { id: 1, text: `Организация: МБУ СШОР No 16` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Горин М.М., Горина Г.С., Доронин А.А.`,
      },
    ],
  },
  {
    id: 17,
    title: `Кмс`,
    name: `Смус Владимир Андреевич`,
    photo: smoothPhoto,
    dateBirth: '31/03/2005',
    about: [
      { id: 0, text: `Регион: Ростовская область` },
      { id: 1, text: `Организация: МБУ СШ "Гребной канал Дон"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Сташевский С.И., Ткаченко П.А.`,
      },
    ],
  },
  {
    id: 18,
    title: `Кмс`,
    name: `Спиридонов Антон Александрович`,
    photo: spiridonovPhoto,
    dateBirth: '14/01/2004',
    about: [
      { id: 0, text: `Регион: Саратовская область` },
      { id: 1, text: `Организация: МУ "ЦСШ"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Мещеряков С.П., Полухин Д.В., Савельев Д.И.`,
      },
    ],
  },
  {
    id: 19,
    title: `Кмс`,
    name: `Черноусов Михаил Маркович`,
    photo: noimagePhoto,
    dateBirth: '01/10/2003',
    about: [
      { id: 0, text: `Регион: Ярославская область, Рыбинск` },
      { id: 1, text: `Организация: МАУ СШОР "ТЕМП", ГУ ЯО "РЦСП"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Васильева О.В., Васильев М.А.`,
      },
    ],
  },
];

const YouthSquad = () => {
  return (
    <>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />
        <AthleteCards athletesArr={teamArr} />
      </Container>
    </>
  );
};

export default YouthSquad;
