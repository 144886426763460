import {
  Box,
  List,
  Paragraph,
  ResponsiveContext,
  Tab,
  Tabs,
  Text,
} from 'grommet';
import DocumentItem from '../DocumentItem';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getAllProtocols from 'store/action/protocols';

const yearTabs = [
  {
    label: 2022,
  },
  {
    label: 2021,
  },
  {
    label: 2020,
  },
  {
    label: 2019,
  },
  {
    label: 2018,
  },
  {
    label: 2017,
  },
  {
    label: 2016,
  },
  {
    label: 2015,
  },
];

const Protocols = () => {
  const [activeTab, setActiveTab] = useState(0);
  const protocols = useSelector(state => state.protocols.items);

  useEffect(() => {
    getAllProtocols();
  }, []);

  const activeYear = yearTabs[activeTab].label;

  const activeProtocols = protocols.filter(
    p => new Date(p.document_date).getFullYear() === activeYear
  );

  return (
    <div>
      <ResponsiveContext.Consumer>
        {size => (
          <Box>
            <Box
              style={{
                width: `max-content`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
            >
              <Paragraph>Архивы протоколов:</Paragraph>
            </Box>
            <br />
            <Box
              direction={`row`}
              style={{
                width: `max-content`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
            >
              <Tabs onActive={setActiveTab}>
                {yearTabs.map(year => (
                  <Tab
                    title={<Text size="large">{year.label}</Text>}
                    key={year.label}
                  ></Tab>
                ))}
              </Tabs>
            </Box>
            <br />
            <List
              data={activeProtocols}
              border={false}
              margin={{ top: '20px' }}
            >
              {protocol => (
                <DocumentItem
                  title={protocol.title}
                  date={protocol.document_date}
                  link={protocol.link}
                />
              )}
            </List>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </div>
  );
};

export default Protocols;
