import { dispatch } from '../index';
import * as types from '../types/regions';
import api from 'api';

export const getAllRegions = async () => {
  try {
    const { data } = await api.get('/server/branches/list');
    dispatch({ type: types.GET_REGIONS, payload: data });
  } catch (error) {
    console.log(error);
  }
};
