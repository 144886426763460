import Article from '../Article';
import styled from 'styled-components';
import { Paragraph } from 'grommet';
import newsCover from 'images/News/news-cover1.jpg';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const ElectionResultsAnnual = () => {
  return (
    <Article
      title={`Генеральный секретарь ФТР Галина Шиповалова переизбрана в состав Исполкома Europe Triathlon`}
      date={`7 МАРТА 2021`}
      coverBack={newsCover}
    >
      <CustomParagraph>
        6 марта в режиме видео конференции состоялась Генеральная Ассамблея Европейского Союза триатлона (European Triathlon), в рамках которого проводилось голосование в состав руководства ET.
      </CustomParagraph>
      <CustomParagraph>
        Президентом Европейского союза был вновь переизбран Ренато Бертранди. Вице-президентами стали Мартин Бридейк (Нидерланды), Крис Китчен (Ирландия). Казначей - Алисия Гарсия (Испания).
      </CustomParagraph>
      <CustomParagraph>
        Генеральный секретарь Федерации триатлона России Галина Шиповалова была переизбрана в состав Исполнительного комитета ET на второй срок! Поздравляем!
      </CustomParagraph>
      <CustomParagraph>
        Также в состав исполнительного совета были избраны Мария Черковская-Тарасевич, Хервиг Грабнер, Паскаль Саламин, Ола Сильдал.
      </CustomParagraph>
    </Article>
  )
}

export default ElectionResultsAnnual;