import news220321 from 'images/News/220321.jpeg';
import Article from 'pages/Articles/Article';
import styled from 'styled-components';
import { Paragraph } from 'grommet';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const articte220321 = () => {
  return (
    <Article
      title={`Сборная России по зимнему триатлону завоевала больше всех медалей на Чемпионате мира в Андорре`}
      date={`22 МАРТА 2021`}
      coverBack={news220321}
    >
      <CustomParagraph>
        <b>Российские триатлонисты показали отличный результат на трассе вблизи городка Сан-Жулиа-де-Лориа, заняв первое место по количеству медалей на чемпионате мира по зимнему триатлону, обогнав конкурентов из 11 стран мира. </b>
      </CustomParagraph>
      <CustomParagraph>
      <i>Российские триатлонисты показали отличный результат на трассе вблизи городка Сан-Жулиа-де-Лориа, заняв первое место по количеству медалей на чемпионате мира по зимнему триатлону, обогнав конкурентов из 11 стран мира. </i>
      </CustomParagraph>
      <CustomParagraph>
      20-21 марта на территории одного из пиренейских горнолыжных склонов в Андорре состоялся чемпионат мира по зимнему триатлону, на который заявились сильнейшие спортсмены со всей планеты. 
      <br />Помимо сборной России, участниками соревнований стали триатлонисты из Чехии, Норвегии, Франции, Италии, Испании, Австрии, Германии, Португалии, Великобритании, Андорры и Румынии. 
      </CustomParagraph>
      <CustomParagraph>
        20 марта Чемпионат открыли забеги мужских и женских сборных, а также забеги категории до 23 лет.
      </CustomParagraph>
      <CustomParagraph>
        В первый день соревнований 38-летний триатлонист Павел Андреев завоевал второе место, уступив только спортсмену из Норвегии Гансу Кристиану Тюнгезвику. Третье место занял итальянец Джюзеппе Ламастра. 
      </CustomParagraph>
      <CustomParagraph>
      <i>«Ничуть не расстроился, даже рад! У меня были определенные задачи обыграть ребят, своих конкурентов. Очень здорово, что в зимнем триатлоне появляются новые лица, такие как свеженький норвежец. На сегодняшний день это очень сильный спортсмен. Счастлив, что есть, куда стремиться. Отличная мотивация для меня продолжать бороться. Не унываем!»,</i> – прокомментировал свой результат Андреев.
      </CustomParagraph>
      <CustomParagraph>
      Среди женщин спортсменка Анна Медведева заняла третье место после того, как норвежка Эдизабет Свём была дисквалифицирована за то, что не отстояла штрафное время перед завершением гонки. Первое место заняла итальянка Сандра Майерхофер, а второе – австрийка Романа Славинеч.
      </CustomParagraph>
      <CustomParagraph>
      Важными для российского зимнего триатлона стали и результаты наших молодых спортсменов в категории до 23 лет – весь пьедестал первенства мира среди девушек завоевали спортсменки из России (Белкина Надежда – 1 место, Ольга Черемисинова – 2 место, Кузнецова Валерия – 3 место). В первенстве мира среди мужчин до 23 лет за Россией 2 и 3 место (Евгений Евграфов – 2 место, Иван Залавцев – 3 место). 
      </CustomParagraph>
      <CustomParagraph>
      <i>«Сегодня среди мужчин мы вторые, третьи среди женщин, а наши молодые спортсмены в категории до 23 лет и юниоры также показали отличные результаты! Это был действительно очень тяжелый старт, поэтому я искренне поздравляю всю нашу сборную. Уже сейчас мы видим, что у зимнего триатлона в России не только достойное настоящее, но и отличное будущее!»,</i> – прокомментировала выступление спортсменов президент Федерации триатлона России Ксения Шойгу
      </CustomParagraph>
      <CustomParagraph>
        Завершился первый день соревнований результативным выступлением наших юниоров Ярослава Куриленка и Анастасии Непомилуевой, которые заняли третьи места на пьедестале в забегах среди спортсменов в категории 18-19 лет.
      </CustomParagraph>
      <CustomParagraph>
      Второй день Чемпионата оказался не менее успешным для сборной России, которая показала достойные результаты в эстафете: бронза у команды Анны Медведевой и Евгения Уляшева и серебро у наших юниоров Анастасии Непомилуевой и Ярослава Куриленка.
      </CustomParagraph>
      <CustomParagraph>
      Итогом выступления сборной России на чемпионате мира по зимнему триатлону оказался набор из 11 медалей, при этом ни один из форматов соревнований не остался для наших ребят без пьедестала. Второе место по количеству медалей заняла сборная Норвегии с 7 медалями, а третье место осталось за сборной Италии с 4 медалями.
      </CustomParagraph>
      <CustomParagraph>
      Зимний триатлон – одна из новейших дисциплин в календаре Всемирного триатлона, состоящее из бега, горного велосипеда и беговых лыж. Спортсмены проходят около 8 км бега по снегу, 14 км на горном велосипеде, а затем 12 км по снегу на беговых лыжах.
      </CustomParagraph>
    </Article>
  )
}

export default articte220321;
