export const routes = {
  main: {
    path: `/`,
    name: `Главная`,
  },
  news: {
    path: `/news`,
    name: `Новости`,
  },
  partners: {
    path: `/partners`,
    name: `Партнеры`,
  },
  management: {
    path: `/management`,
    name: `Руководство`,
  },
  mainCast: {
    path: `/main-cast`,
    name: `Основной состав сборной`,
  },
  calendar: {
    path: `/calendarPage`,
    name: `Календарь мероприятий`,
  },
  calendarall: {
    path: `/calendarPage/all`,
    name: `Все мероприятия`,
  },
  calendarekp: {
    path: `/calendarPage/ekp`,
    name: `Единый календарный план`,
  },
  calendarru: {
    path: `/calendarPage/ru`,
    name: `Любительские старты`,
  },
  calendarchild: {
    path: `/calendarPage/child`,
    name: `Детская лига`,
  },
  calendarchildstart: {
    path: `/calendarPage/childstart`,
    name: `Детские старты`,
  },
  calendarseries4: {
    path: `/calendarPage/series4`,
    name: `Серия 4-х колец`,
  },
  calendarsups: {
    path: `/calendarPage/sups`,
    name: `Суперсерия`,
  },
  // historyTria: {
  //   path: `/historyTria`,
  //   name: `История триатлона`,
  // },
  historyTria: {
    path: `/history`,
    name: `История триатлона`,
  },
  whereToStart: {
    path: `/whereToStart`,
    name: `С чего начать?`,
  },
  highEducation: {
    path: `/highEducation`,
    name: `С чего начать?`,
  },
  disciplines: {
    path: `/disciplines`,
    name: `Дисциплины`,
  },
  whatIsTriathlon: {
    path: `/WhatIsTriathlon`,
    name: `Что такое триатлон?`,
  },
  youthTeam: {
    path: `/youth-team`,
    name: `Молодёжный состав`,
  },
  juniorTeam: {
    path: `/junior-team`,
    name: `Юниорский состав`,
  },
  youthSquad: {
    path: `/youth-squad`,
    name: `Юношеский состав`,
  },
  aquathlonTeam: {
    path: `/aquathlon-team`,
    name: `Акватлон`,
  },
  teamMiddleDistance: {
    path: `/team-middle-distance`,
    name: `Триатлон средняя дистанция`,
  },
  crossTriathlon: {
    path: `/cross-triathlon`,
    name: `Триатлон кросс`,
  },
  teamDuathlon: {
    path: `/team-duathlon`,
    name: `Дуатлон`,
  },
  olympicReserve: {
    path: `/olympic-reserve`,
    name: `Олимпийский резерв`,
  },
  coachingStaff: {
    path: `/coaching-staff`,
    name: `Тренерский состав`,
  },
  winterTriathlon: {
    path: `/winter-triathlon`,
    name: `Зимний триатлон`,
  },
  protocols: {
    path: `/protocols`,
    name: `Протоколы`,
  },
  regions: {
    path: `/regions`,
    name: `Региональные федерации`,
  },
  documents: {
    path: `/documents`,
    name: `Документы`,
  },
  selectionCriterias: {
    path: `/selection-criterias`,
    name: `Критерии отбора`,
  },
  archive: {
    path: `/archive`,
    name: `Архив документов ФТР`,
  },
  ratingsAthletes: {
    path: `/ratings-athletes`,
    name: `Рейтинги спортсменов`,
  },
  contacts: {
    path: `/contacts`,
    name: `Контакты`,
  },
  childrensTriathlon: {
    path: `/childrens-triathlon`,
    name: `Детский триатлон`,
  },
  electionResultsAnnual: {
    path: `/election-results-annual`,
    name: `Генеральный секретарь ФТР Галина Шиповалова переизбрана в состав Исполкома Europe Triathlon`,
  },
  ftrFvsrCollaboration: {
    path: `/ftr-fvsr-collaboration`,
    name: `Президент ФТР Ксения Шойгу и президент ФВСР Вячеслав Екимов подписали меморандум о сотрудничестве`,
  },
  indoorTriathlonJunior: {
    path: `/indoor-triathlon-junior`,
    name: `Детский индор-триатлон пройдет в Crocus Fitness 13 марта`,
  },
  championatTuymenskoyOblasti: {
    path: `/championat-tuymenskoy-oblasti`,
    name: `Чемпионат Тюменской области по зимнему триатлону состоится 13 марта`,
  },
  championatKemerovo2021: {
    path: `/championat-kemerovo-2021`,
    name: `Чемпионат России по триатлону состоится в Кемеровской области в июле 2021 года`,
  },
  firstMeetingParatriathlon: {
    path: `/first-meeting-paratriathlon`,
    name: `Президент Федерации триатлона Ксения Шойгу провела первую встречу комиссии по паратриатлону`,
  },
  news10032100: {
    path: `/news10032100`,
    name: `Президент Федерации триатлона России Ксения Шойгу представила план развития ФТР на 2021 год`,
  },
  news10032101: {
    path: `/news10032101`,
    name: `Обучение по программе профессиональной переподготовки «Тренер в избранном виде спорта. Триатлон»`,
  },
  news150321: {
    path: `/news150321`,
    name: `Ровно 100 дней назад обновленный состав президиума Федерации триатлона России начал свою работу`,
  },
  news220321: {
    path: `/news220321`,
    name: `Сборная России по зимнему триатлону завоевала больше всех медалей на Чемпионате мира в Андорре`,
  },
  news280321: {
    path: `/news280321`,
    name: `Финал Кубка России по зимнему триатлону и соревнования среди любителей TRI59`,
  },
  news070421: {
    path: `/news070421`,
    name: `Окончен набор слушателей на обучение «Тренер в избранном виде спорта. Триатлон»`,
  },
  news190421: {
    path: `/news190421`,
    name: `Открытие отделения по направлению «Триатлон»`,
  },
  news200421: {
    path: `/news200421`,
    name: `Президиумом ФТР утверждены критерии и условия отбора кандидатов на международные соревнования в 2021 году`,
  },
  antidoping: {
    path: `/antidoping`,
    name: `Антидопинг`,
  },
  superseries: {
    path: `/superseries`,
    name: `Суперсерия ФТР 2021`,
  },
  error: {
    path: `/404`,
    name: `Ошибка`,
  },
  success: {
    path: `/success`,
    name: `Спасибо, ваша заявка принята!`,
  },
  license: {
    path: `/license`,
    name: `Лицензия`,
  },
  results: {
    path: `/results`,
    name: `Результаты`,
  },
  amateurs: {
    path: `/amateurs`,
    name: `Любители`,
  },
  internationalTriathlon: {
    path: `/international-triathlon`,
    name: `Международный триатлон`,
  },
  thanks: {
    path: `/thanks`,
    name: `Спасибо за покупку`,
  },
};
