import {
  Paragraph,
  Heading,
  Text,
  Card,
  CardBody,
  CardHeader,
  Box,
  Grid,
} from 'grommet';
import React from 'react';
import styled from 'styled-components';
import secondary1 from 'images/OlympicReserve/secondary1.png';
import secondary2 from 'images/OlympicReserve/secondary2.png';
import ornament from 'images/OlympicReserve/ornament.png';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`;

const P = styled(Paragraph)`
  max-width: 100%;
  font-size: 16px;
`;

const DescriptionTab = () => {
  return (
    <Box>
      <Heading
        level={`3`}
        margin={{ top: `18px`, bottom: `18px` }}
        style={{ textTransform: `uppercase` }}
      >
        Описание проекта
      </Heading>
      <CustomParagraph>
        Целевая программа подготовки команды Олимпийского резерва направлена на
        повышение конкурентоспособности российского триатлона на международной
        арене и вхождение России в группу стран-лидеров мирового триатлона. Для
        этого разработаны современные формы и методы подготовки молодых
        спортсменов. Программа рассчитана для детей в возрасте от 12 до 16 лет включительно.
      </CustomParagraph>
      <CustomParagraph>
        <Text style={{ fontWeight: 'bold' }}>
          Программа подготовки реализуется в двух направлениях и предусматривает
          работу как с «дальним», так и с «ближним» резервом сборной команды
          России по триатлону:
        </Text>
      </CustomParagraph>
      <Grid
        margin={{ bottom: `26px`, top: `16px` }}
        columns={{ count: `fit`, size: `280px` }}
        justify="stretch"
        gap="medium"
      >
        <Box
          background={{
            image: `url(${ornament})`,
            size: `contain`,
            position: 'right ',
          }}
        >
          <Card
            width={{ max: '684px' }}
            height="300px"
            elevation="xsmall"
            background={{
              image: `url(${secondary1})`,
              size: `contain`,
              position: 'right 10px top',
            }}
          >
            <CardHeader style={{ fontWeight: 'bold' }} pad="medium">
              <h2>Юношеский состав</h2>
            </CardHeader>
            <CardBody
              pad={{ right: 'medium', left: 'medium', bottom: 'medium' }}
            >
              <P style={{ maxWidth: `300px` }}>
                Рассчитана на профессиональную подготовку юных спортсменов от 12
                до 14 лет.
              </P>
            </CardBody>
          </Card>
        </Box>

        <Box
          background={{
            image: `url(${ornament})`,
            size: `contain`,
            position: 'right ',
          }}
        >
          <Card
            width={{ max: '684px' }}
            height="300px"
            elevation="xsmall"
            background={{
              image: `url(${secondary2})`,
              size: `contain`,
              position: 'right 10px top',
            }}
          >
            <CardHeader style={{ fontWeight: 'bold' }} pad="medium">
              <h2>Юниорский состав</h2>
            </CardHeader>
            <CardBody
              pad={{ right: 'medium', left: 'medium', bottom: 'medium' }}
            >
              <P style={{ maxWidth: `300px` }}>
                Рассчитана на профессиональную подготовку спортсменов от 15 до
                16 лет (имеющих потенциал для улучшения спортивных результатов и
                требующих адаптации к «взрослому» триатлону).
              </P>
            </CardBody>
          </Card>
        </Box>
      </Grid>
      {/* <CustomParagraph>
        <Text style={{ fontWeight: 'bold' }}>
          Рассчитана на профессиональную подготовку спортсменов от 15 до 16 лет
          (имеющих потенциал для улучшения спортивных результатов и требующих
          адаптации к «взрослому» триатлону).
        </Text>
      </CustomParagraph> */}
      <CustomParagraph>
        <Text style={{ fontWeight: 'bold' }}>Основные задачи программы:</Text>
      </CustomParagraph>
      <P>
        <span style={{ color: 'red', fontWeight: 'bolder' }}>✓ </span>Отбор
        наиболее перспективных спортсменов, соответствующих минимальным
        нормативам по плаванию и бегу, для подготовки в разряд спортсменов
        мирового уровня;
      </P>{' '}
      <P>
        <span style={{ color: 'red', fontWeight: 'bolder' }}>✓ </span>
        Подготовка перспективных юных атлетов в своих возрастных группах для
        участия в региональных российских соревнованиях, первенствах Европы и
        мира;
      </P>
      <P>
        <span style={{ color: 'red', fontWeight: 'bolder' }}>✓ </span>
        Подготовка ближайшего резерва основного состава сборной команды России
        для участия в чемпионатах Европы и мира, Олимпийских играх;
      </P>{' '}
      <P>
        <span style={{ color: 'red', fontWeight: 'bolder' }}>✓ </span>Помощь
        личным тренерам в составлении многолетних/годовых планов подготовки
        спортсменов с учетом их возраста;{' '}
      </P>{' '}
      <P>
        <span style={{ color: 'red', fontWeight: 'bolder' }}>✓ </span>
        Привлечение талантливых спортсменов из смежных видов спорта (плавание,
        велосипед, бег);{' '}
      </P>{' '}
      <P>
        <span style={{ color: 'red', fontWeight: 'bolder' }}>✓ </span>
        Профессиональная переподготовка тренеров (чьи спортсмены попали в
        команду);
      </P>{' '}
      <P>
        <span style={{ color: 'red', fontWeight: 'bolder' }}>✓ </span>
        Обеспечение обратной связи для спортсмена по уровню подготовки, технике
        плавания и бега для дальнейшего планирования программы тренировок;{' '}
      </P>{' '}
      <P>
        <span style={{ color: 'red', fontWeight: 'bolder' }}>✓ </span>
        Возможность участвовать в отборе как самостоятельно, так и с тренером
        (подача заявки). Не у всех есть тренер по триатлону.
      </P>
    </Box>
  );
};

export default DescriptionTab;
