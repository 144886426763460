import { Box, Button } from 'grommet';
import { FormNext, FormPrevious } from 'grommet-icons';
import styled from 'styled-components';

const CustomNavBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: -30px;
  width: calc(100% + 60px);
  height: 100%;
  transform: translateY(-50%);
  max-width: none;
  pointer-events: none;
`;

const CustomBtn = styled(Button)`
  padding: 3px;
  font-size: 0;
  background-color: white;
  pointer-events: all;

  &:hover {
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
`;

export default function Navigations({styles}) {
  return (
    <CustomNavBox direction={`row`} justify={`between`} className= {styles.nav}>
      <CustomBtn
        className={styles.btnPrev}
        icon={<FormPrevious color={`dark-1`} />}
        a11yTitle={`Предыдущий слайд`}
      />
      <CustomBtn
        className={styles.btnNext}
        icon={<FormNext color={`dark-1`} />}
        a11yTitle={`Следующий слайд`}
      />
    </CustomNavBox>
  );
}

