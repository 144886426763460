import Subscribe from "components/Subscribe/Subscribe";
import Container from "components/Container/Container";
import coverImg from "images/calendar/pageCalendar.jpg";
import Banner from "components/Banner";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import PartnersTab from "components/Partners/PartnersTab";
// import { useEffect, useState } from 'react';
// import API from 'api';

// import Sandbox from 'components/Sandbox/Sandbox';

const PartnersList = (props) => {
  // const [files, setFiles] = useState([]);
  // const [isLoading, setLoading] = useState(false);
  // const [isError, setError] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true); // set loading

  //     try {
  //       const response = await API.get(`/server/main/1`);
  //       // setFiles(response.data);
  //       console.log(response);
  //     } catch (error) {
  //       setError(true);
  //     }

  //     setLoading(false); // remove loading
  //   }

  //   fetchData();
  // }, [setLoading, setError, setFiles]);

  return (
    <div>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <PartnersTab />
        <Subscribe />
      </Container>

      {/* <Sandbox /> */}
    </div>
  );
};

export default PartnersList;
