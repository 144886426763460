import News from 'components/News/News';
import Container from 'components/Container/Container';
import Leaders from 'components/Leaders/Leaders';
import Calendar from 'components/Calendar/Calendar';
import MainSlider from 'components/MainSlider/MainSlider';
import AddInfo from './AddInfo/AddInfo';
import Sale from 'components/Sale';

const Main = props => {
  return (
    <div>
      <MainSlider />
      <Container>
        <News />
        <Sale />
        <AddInfo />
      </Container>
      <Leaders />
      <Calendar event_type={`all`} />
    </div>
  );
};

export default Main;
