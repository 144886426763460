import { Box, Heading } from 'grommet';
import coverImg from '../../images/articles/trener.png';
import Container from '../../components/Container/Container';
import Calendar from '../../components/Calendar/Calendar';
import styled from 'styled-components';
import Banner from 'components/Banner';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const CustomTbody = styled(`tbody`)`
  color: #313131;
  font-size: 18px;

  & th {
    padding: 20px;
    width: 100%;
    text-align: left;
    border-left: 2px solid #dadde0;
    background-color: #e8eaec;
  }

  & th:first-child {
    max-width: 56px;
    color: #777777;
    font-weight: 400;
    text-align: center;
    border-left: none;
  }

  & tr {
    display: flex;
    border-top: 2px solid #e8eaec;
  }

  & tr:first-child {
    border-top: none;
  }

  & td {
    width: 100%;
    padding: 20px;

    background-color: #f8f8f8;
    border-left: 2px solid #e8eaec;
  }

  & td:first-child {
    max-width: 56px;
    color: #777777;
    text-align: center;
    border-left: none;
  }

  & tr:first-child th:first-child {
    border-top-left-radius: 24px;
  }

  & tr:first-child th:last-child {
    border-top-right-radius: 24px;
  }

  & tr:last-child td:first-child {
    border-bottom-left-radius: 24px;
  }

  & tr:last-child td:last-child {
    border-bottom-right-radius: 24px;
  }
`;

const CustomOl = styled(`ol`)`
  padding-left: 200px;
  font-size: 20px;

  & li {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const CoachingStaff = () => {
  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />

        <Box style={{ overflow: `auto` }}>
          {/* <table style={{minWidth: `780px`}}>
            <CustomTbody>
            <tr>
              <th>№</th>
              <th>Имя, фамилия</th>
              <th>Организация</th>
              <th>Курс пройден</th>
            </tr>
            <tr>
              <td>1</td>
              <td>Бутков Дмитрий</td>
              <td>ГБПОУ МСС УОР №2 г. Москвы</td>
              <td>18-21 мая 2017</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Васин Владимир</td>
              <td>МУДОД «СДЮСШОР №19», г. Ярославль</td>
              <td>18-21 мая 2017</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Елисеев Арсений</td>
              <td>David Warden Coaching</td>
              <td>18-21 мая 2017</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Кириллов Евгений</td>
              <td>КГАУ РЦСО «Академия зимних видов спорта», г. Красноярск / Triatleta</td>
              <td>18-21 мая 2017</td>
            </tr>
              <tr>
                <td>5</td>
                <td>Крестин Денис</td>
                <td>David Warden Coaching</td>
                <td>18-21 мая 2017</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Легостаев Олег</td>
                <td>МАУ ДО ДЮСШ г.Чайковский</td>
                <td>18-21 мая 2017</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Мещеряков Валентин</td>
                <td>Циклон</td>
                <td>18-21 мая 2017</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Навроцкий Григорий</td>
                <td>David Warden Coaching</td>
                <td>18-21 мая 2017</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Новиков Анатолий</td>
                <td>Минское областное средняя школа училище олимпийского резерва	</td>
                <td>18-21 мая 2017</td>
              </tr>
              <tr>
                <td>10</td>
                <td>Носарев Дмитрий</td>
                <td>Циклон</td>
                <td>18-21 мая 2017</td>
              </tr>
              <tr>
                <td>11</td>
                <td>Озаренков Александр</td>
                <td>David Warden Coaching</td>
                <td>18-21 мая 2017</td>
              </tr>
              <tr>
                <td>12</td>
                <td>Ошурков Иван</td>
                <td>World class</td>
                <td>18-21 мая 2017</td>
              </tr>
              <tr>
                <td>13</td>
                <td>Пименов Вячеслав</td>
                <td>Циклон</td>
                <td>18-21 мая 2017</td>
              </tr>
              <tr>
                <td>13</td>
                <td>Калинин Александр</td>
                <td>лично</td>
                <td>25-29 октября 2017</td>
              </tr>
              <tr>
                <td>14</td>
                <td>Коноплев Алексей</td>
                <td>Санкт-Петербургское государственное образовательное бюджетное учреждение дополнительного облразования детей Специализированная детско-юношескаяспортивная школа олимпийского резерва Кировского района С.Петербург</td>
                <td>25-29 октября 2017</td>
              </tr>
              <tr>
                <td>15</td>
                <td>Бауэр Роман</td>
                <td>I❤️Supersport</td>
                <td>25-29 октября 2017</td>
              </tr>
              <tr>
                <td>16</td>
                <td>Малыхин Никита</td>
                <td>МАО ДО ДЮСШ "кедр"</td>
                <td>31 мая - 3 июня 2018</td>
              </tr>
              <tr>
                <td>17</td>
                <td>Малыхина Виктория</td>
                <td>МАУ ДО ДЮСШ "кедр" г. Томска</td>
                <td>31 мая - 3 июня 2018</td>
              </tr>
              <tr>
                <td>18</td>
                <td>Брегеда Дмитрий</td>
                <td>государственное бюджетное учреждение Саратовской области "Спортивная школа "Надежда Губернии"</td>
                <td>31 мая - 3 июня 2018</td>
              </tr>
              <tr>
                <td>19</td>
                <td>Брегеда Татьяна</td>
                <td>Государственное бюджетное учреждение Саратовской области "Спортивная школа "Надежда Губернии"</td>
                <td>31 мая - 3 июня 2018</td>
              </tr>
              <tr>
                <td>20</td>
                <td>Кадочников Александр</td>
                <td>Муниципальное бюджетное учреждение"Отдел физической культуры,спорта и молодежной политики ГО г.Агидель РБ"</td>
                <td>31 мая - 3 июня 2018</td>
              </tr>
            </CustomTbody>
          </table> */}

          <Box pad={{ top: `15px`, bottom: `15px` }}>
            <Heading
              level={`3`}
              style={{
                maxWidth: `100%`,
                textAlign: `center`,
                paddingTop: `30px`,
                paddingBottom: `15px`,
              }}
            >
              ТРЕНЕРЫ, СОСТОЯЩИЕ НА СТАВКЕ ФГБУ ЦСП ЯВЛЯЮТСЯ СЕРТИФИЦИРОВАННЫМИ
            </Heading>

            <CustomOl>
              <li>
                Карачаров Сергей Анатольевич <strong>(главный тренер)</strong>
              </li>
              <li>
                Кунцевич Татьяна Валерьевна{' '}
                <strong>(начальник спортивной сборной команды)</strong>
              </li>
              <li>
                Никитин Виталий Олегович{' '}
                <strong>(начальник спортивной сборной команды)</strong>
              </li>
              <li>
                Зуев Алексей Геннадьевич{' '}
                <strong>(старший тренер спортивной сборной команды)</strong>
              </li>
              <li>
                Гааг Дмитрий Владимирович{' '}
                <strong>(старший тренер юниорки, девушки)</strong>
              </li>
              <li>
                Абысова Ирина Алексеевна <strong>(тренер плавание)</strong>
              </li>
              <li>
                Гудалов Андрей Владимирович <strong>(старший тренер)</strong>
              </li>
              <li>
                Бородин Сергей Николаевич{' '}
                <strong>(тренер спортивной сборной команды)</strong>
              </li>

              <li>Морозов Сергей Васильевич</li>
              <li>Горин Михаил Михайлович</li>
              <li>
                Шорец Мария Сергеевна{' '}
                <strong>(специалист спортивной сборной команды)</strong>
              </li>
            </CustomOl>
          </Box>

          <Box style={{ overflow: `auto` }}>
            <Heading
              level={`3`}
              style={{
                maxWidth: `100%`,
                textAlign: `center`,
                paddingTop: `30px`,
                paddingBottom: `15px`,
              }}
            >
              СПИСОК ТРЕНЕРОВ, ПРОСЛУШАВШИХ УЧЕБНЫЙ КУРС ФТР
            </Heading>
            <table style={{ minWidth: `780px` }}>
              <CustomTbody>
                <tr>
                  <th>№</th>
                  <th>Имя, фамилия</th>
                  <th>Организация</th>
                  <th>Курс пройден</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Зыкова Наталья</td>
                  <td>СДЮСШОР №4 г.Ярославль</td>
                  <td>18-21 мая 2017</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Дашкевич Константин</td>
                  <td>David Warden Coaching</td>
                  <td>18-21 мая 2017</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Залогина Виктория</td>
                  <td>"Планета Фитнес - Казань"</td>
                  <td>18-21 мая 2017</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Кудрина Наталья</td>
                  <td>"Планета Фитнес - Казань"</td>
                  <td>18-21 мая 2017</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Пахомова Марина</td>
                  <td>ГБПОУ МСС УОР №2 г. Москвы</td>
                  <td>18-21 мая 2017</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Пономарев Константин</td>
                  <td>СДЮСШОР «Дельфин» Тула</td>
                  <td>18-21 мая 2017</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Резепов Станислав</td>
                  <td>Федерация триатлона Калининградской области</td>
                  <td>18-21 мая 2017</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Сергеева Татьяна</td>
                  <td>Детско-юношеская спортивная школа "АКВАТИКС"</td>
                  <td>18-21 мая 2017</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Туленов Руслан</td>
                  <td>Федерация триатлона Кыргызской Республики</td>
                  <td>18-21 мая 2017</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Ханадеев Андрей</td>
                  <td>Федерация триатлона Кыргызской Республики</td>
                  <td>18-21 мая 2017</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>Шевалье Алексей</td>
                  <td>Детско-юношеская спортивная школа "АКВАТИКС"</td>
                  <td>18-21 мая 2017</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>Ивушин Александр</td>
                  <td>Спортивная школа олимпийского резерва "ТЕМП"</td>
                  <td>25-29 октября 2017</td>
                </tr>
                <tr>
                  <td>13</td>
                  <td>Алексеев Анатолий</td>
                  <td>
                    Муниципальное автономное учреждение дополнительного
                    образования "Детско-юношеская спортивная школа" городского
                    округа город Агидель
                  </td>
                  <td>25-29 октября 2017</td>
                </tr>
                <tr>
                  <td>14</td>
                  <td>Бородич Олег</td>
                  <td>Лично</td>
                  <td>25-29 октября 2017</td>
                </tr>
                <tr>
                  <td>15</td>
                  <td>Кукрус Валентина</td>
                  <td>клуб Angry Boys Sports</td>
                  <td>25-29 октября 2017</td>
                </tr>
                <tr>
                  <td>16</td>
                  <td>Растрыгин Павел</td>
                  <td>Лично</td>
                  <td>25-29 октября 2017</td>
                </tr>
                <tr>
                  <td>17</td>
                  <td>Шиянов Виталий</td>
                  <td>Академия Плавания</td>
                  <td>25-29 октября 2017</td>
                </tr>
                <tr>
                  <td>18</td>
                  <td>Поплавский Кирилл</td>
                  <td>Лично</td>
                  <td>25-29 октября 2017</td>
                </tr>
                <tr>
                  <td>19</td>
                  <td>Корчёмкин Юрий</td>
                  <td>Федерация триатлона Кировской области</td>
                  <td>31 мая - 3 июня 2018</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>Рудин Андрей</td>
                  <td>Федерация триатлона Кировской области</td>
                  <td>31 мая - 3 июня 2018</td>
                </tr>
                <tr>
                  <td>21</td>
                  <td>Буковец Артем</td>
                  <td>
                    Общественное объединение «Белорусская федерация триатлона
                  </td>
                  <td>31 мая - 3 июня 2018</td>
                </tr>
                <tr>
                  <td>22</td>
                  <td>Рулькевич Кристина</td>
                  <td>
                    Минская областная комплексная специализированная
                    детско-юношеская школа олимпийского резерва(основное место
                    работы),Минская Государственное областная школа-училище
                    олимпийского резерва.(совместитель)
                  </td>
                  <td>31 мая - 3 июня 2018</td>
                </tr>
                <tr>
                  <td>23</td>
                  <td>Буянов Дмитрий</td>
                  <td>Федерация Триатлона Новосибирской области</td>
                  <td>31 мая - 3 июня 2018</td>
                </tr>
                <tr>
                  <td>24</td>
                  <td>Яковлев Сергей</td>
                  <td>
                    ГБУ КРАСНОДАРСКОГО КРАЯ "Центр Олимпийской подготовки по
                    плаванию"
                  </td>
                  <td>31 мая - 3 июня 2018</td>
                </tr>
              </CustomTbody>
            </table>
          </Box>
        </Box>

        <Calendar event_type="all" />
      </Container>
    </>
  );
};

export default CoachingStaff;
