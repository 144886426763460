import { Box, Button } from 'grommet';
import DocumentItem from '../../DocumentItem';
import { useEffect } from 'react';
import { antidopingDocuments } from 'store/action/documents';
import { useSelector } from 'react-redux';

const AntidopingDocuments = () => {
  const items = useSelector(state => state.documents.items);

  useEffect(() => {
    antidopingDocuments();
  }, []);
  return (
    <Box>
      <Box
        align={`start`}
        direction={`row`}
        gap={`small`}
        margin={{ bottom: `large` }}
        style={{ textAlign: `center` }}
      >
        <Button
          href={`https://rusada.triagonal.net/`}
          primary
          margin={{ top: `small` }}
          label={`Обучение`}
        />

        <Button
          href={`https://list.rusada.ru/`}
          primary
          margin={{ top: `small` }}
          label={`Проверить лекарство`}
        />
      </Box>
      {items.map(i => (
        <Box
          key={i.public_id}
          flex
          direction="column"
          margin={{ bottom: '20px' }}
        >
          <DocumentItem title={i.title} date={i.document_date} link={i.link} />
        </Box>
      ))}
    </Box>
  );
};

export default AntidopingDocuments;
