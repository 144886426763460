import { Box, Button, Card, Grid, Heading, Image, Text } from 'grommet';
import { useState } from 'react';
import AthletesMorePopup from '../Popups/AthletesMore/AthletesMorePopup';

const AthleteCards = ({athletesArr}) => {
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [personItem, setPersonItem] = useState(null);

  const handleClickBtnMore = (person) => {
    setPersonItem(person);
    setOpenPopup(true);
  }

  return (
    <Grid margin={{bottom: `96px`}} columns={{count: `fit`, size: `280px`}} justify={`center`} gap={`medium`}>

      {athletesArr.map(item => (
        <Card width={{max: `330px`}} background={`#F8F8F8`} elevation={`none`} key={item.id} style={{width: `100%`, height: `auto`}}>
          <Box height={{min: `310px`, max: `320px`}}>
            <Image src={item.photo} fit={`cover`} />
          </Box>
          <Box
            height={`100%`}
            direction={`column`}
            justify={`between`}
            pad={{top: `32px`, right: `21px`, bottom: `40px`, left: `24px`}}
          >
            <Text margin={{bottom: `58px`}} style={{textTransform: `uppercase`, color: `rgba(0, 0, 0, 0.5)`}}>
              Спортивное звание: <Text weight={`bold`}>{item.title}</Text>
            </Text>
            <Box>
              <Heading level={3} margin={{bottom: `32px`}} style={{textTransform: `uppercase`, lineHeight: `1.2`}}>
                {item.name}
              </Heading>
              <Button label={`Подробнее`} onClick={() => {handleClickBtnMore(item)}} primary />
            </Box>
          </Box>
        </Card>
      ))}

      {isOpenPopup && <AthletesMorePopup statePopupHandler={setOpenPopup} personItem={personItem} />}
    </Grid>
  )
}

export default AthleteCards;