import { Box, ResponsiveContext } from 'grommet'; // Heading,Image, Paragraph
// import styled from 'styled-components';
import Container from 'components/Container/Container';
// import { NameSpace } from 'const/namespace';
import 'pages/News/News.scss';
import renderHTML from 'react-render-html';
// import Quote from 'components/News/Quote/Quote';
// import newsImage from 'images/News/news-post1.jpg';
// import newsImage2 from 'images/News/news-post2.jpg';
// import smallImage from 'images/News/small-post.jpg';


// const CustomParagraph = styled(Paragraph)`
//   max-width: 920px;
//   line-height: 1.4;
//   margin-bottom: 40px;

//   @media(max-width: 768px) {
//     margin-bottom: 24px;
//   }
// `;

// const sizeParagraph = {
//   large: `large`,
//   medium: `medium`,
//   [NameSpace.desktop1200]: `medium`,
//   [NameSpace.tablet]: `medium`,
//   small: `small`,
// };


const Content = ({text}) => {
  return (
    <ResponsiveContext.Consumer>
      { size => (
      <Box margin={{top: `large`}}>
        <Container>
          <Box margin={{bottom: `large`}} className={`news__page`}>
            { renderHTML(text) }
          {/* <CustomParagraph size={sizeParagraph[size]}>
            Федеральный трибунал Швейцарии — последняя инстанция для протестов, согласно международной антидопинговой системе. И то апеллировать там можно лишь по очень ограниченному числу причин. Случаев, когда такая апелляция была бы успешной, вообще с ходу не вспомнить. Обычно поход в трибунал — это такой жест отчаяния. Притом что истец почти всегда отдает себе отчет: шансы на успех равны примерно нулю.</CustomParagraph>

          <CustomParagraph size={sizeParagraph[size]}>И вот китайцу Сунь Яну удалось совершить чудо: не только на плавательной дорожке, где он выиграл три олимпийских золотых медали, но и в суде. Трибунал не согласился с решением CAS о восьмилетней дисквалификации и вернул дело на рассмотрение. Теперь у Сунь Яна есть теоретический шанс выступить даже на Олимпийских играх в Токио этим летом.</CustomParagraph>

          <Box width={`1040px`} height={`345px`} margin={{bottom: `large`}}>
            <Image fit={`cover`} src={newsImage} />
          </Box>

          <Quote />
          <CustomParagraph size={sizeParagraph[size]}>И вот китайцу Сунь Яну удалось совершить чудо: не только на плавательной дорожке, где он выиграл три олимпийских золотых медали, но и в суде. Трибунал не согласился с решением CAS о восьмилетней дисквалификации и вернул дело на рассмотрение. Теперь у Сунь Яна есть теоретический шанс выступить даже на Олимпийских играх в Токио этим летом.</CustomParagraph> */}
          </Box>
        </Container>

        {/* <Box height={`740px`} margin={{bottom: `large`}}>
          <Image fit={`cover`} src={newsImage2} />
        </Box> */}

        {/* <Container>
          <CustomParagraph size={sizeParagraph[size]}>И вот китайцу Сунь Яну удалось совершить чудо: не только на плавательной дорожке, где он выиграл три олимпийских золотых медали, но и в суде. Трибунал не согласился с решением CAS о восьмилетней дисквалификации и вернул дело на рассмотрение. Теперь у Сунь Яна есть теоретический шанс выступить даже на Олимпийских играх в Токио этим летом.</CustomParagraph>

          <Box fill direction={size !== `small` && size !== NameSpace.desktop1200 ? `row` : `column`} gap={`medium`}>
            <Box width={`330px`} height={`210px`} flex={{shrink: 0}}>
              <Image fit={`cover`} src={smallImage}></Image>
            </Box>
            <Box>
              <Heading level={3} size={`24px`} fill margin={{bottom: `medium`}}>Может, России пойти в трибунал вслед за китайцем?</Heading>
              <CustomParagraph size={sizeParagraph[size]}>Возникает вопрос: может, теперь и России стоит пойти в трибунал, чтобы оспорить двухлетнее отстранение от выступлений на Олимпиадах и чемпионатах мира под своим флагом? Пример китайца показывает, что все далеко не так безнадежно, как когда-то казалось.</CustomParagraph>
            </Box>
          </Box>

          <CustomParagraph size={sizeParagraph[size]}>Наверняка наши швейцарские адвокаты сейчас активно занялись изучением всех открытых социальных сетей арбитров CAS. И если где-то обнаружатся высказывания, хотя бы отдаленно высмеивающие Россию или русских, — у нас все шансы на успех. Хотя, конечно, сложно в такое поверить.</CustomParagraph>

          <CustomParagraph size={sizeParagraph[size]}>
            Но уж точно арбитры CAS будут теперь куда внимательнее в медиаполе. Если непростительную ошибку допустил бывший министр иностранных дел далеко не самой маленькой страны — можете себе представить, что можно найти в профилях у всех остальных? Неудивительно, если теперь CAS в принципе запретит своим арбитрам иметь соцсети. Вот только необходимости повторно рассмотреть дело Сунь Яна это уже не отменит.
          </CustomParagraph>

        </Container> */}

      </Box>
    )}
    </ResponsiveContext.Consumer>
  )
}

export default Content;
