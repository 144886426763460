import newsCover from '../../../images/News/news-cover5.jpg';
import Article from '../Article';
import styled from 'styled-components';
import { Anchor, Box, Paragraph } from 'grommet';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const CustomLink = styled(Anchor)`
  display: inline-block;
  background-color: #DB4C4C;
  color: #f8f8f8;
  text-decoration: none;
  border-radius: 28px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 14px 38px;
  font-size: 12px;
  line-height: 2;
  transition: box-shadow 0.2s ease;
  letter-spacing: 0.5px;

  &:focus, &:hover {
    box-shadow: 0 0 2px 2px #DB4C4C;
  }
`

const ChampionatKemerovo2021 = () => {
  return (
    <Article
      title={`Чемпионат России по триатлону состоится в Кемеровской области в июле 2021 года`}
      date={`3 МАРТА 2021`}
      coverBack={newsCover}
    >
      <CustomParagraph>
        В период со 2 по 5 июля 2021 года в п.г.т. Инской, Беловского городского округа, Кемеровской области состоятся
        чемпионат и первенство России по триатлону среди элиты и юниоров.
      </CustomParagraph>
      <CustomParagraph>
        Также в рамках основного чемпионата пройдут: <br/>
        - первенство России по триатлону среди любителей <br/>
        - клубный чемпионат России среди любительских эстафетных команд (каждый из 4-х участников выполняют дистанцию супер-спринт)
      </CustomParagraph>
      <CustomParagraph>
        <em>К участию приглашаются спортсмены-любители старше 18 лет.</em>
      </CustomParagraph>
      <CustomParagraph>
        Дистанция соревнований для элиты и любителей на личных гонках составит 1,5 км плавание, 40 км велогонка и 10 км
        бег (стандартная олимпийская). Для юниоров - 750 м плавание, 20 км велогонка и 5 км бег (спринт).
      </CustomParagraph>
      <CustomParagraph>
        Подробное описание старта и регистрация на сайте организаторов – Федерации триатлона Кемеровской области <Anchor href={`https://triathlon42.ru`} label={`triathlon42.ru`} />
      </CustomParagraph>
      <Box direction={`row`} justify={`between`} width={{max: `800px`}} margin={{left: `auto`, right: `auto`}} pad={{top: `30px`, bottom: `30px`}}>
        <CustomLink href={`http://triathlon42.ru/`} label={`Регистрация для любителей на клубный чемпионат`} />
      </Box>
    </Article>
  )
}

export default ChampionatKemerovo2021;