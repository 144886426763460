import { Anchor, Paragraph } from 'grommet';

export default function Contacts() {

  const contact = {
    email: `antidoping@rustriathlon.ru`,
    title: `Первый вице-президент`,
    name: `Заволокина Олеся Юрьевна`,
  };

  return (
    <>
      <Paragraph fill>
        {contact.title} - {contact.name}{' '}
        <Anchor href={`mailto:` + contact.email}>{contact.email}</Anchor>
      </Paragraph>
    </>
  );
}
