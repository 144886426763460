import gorevaPhoto from '../../images/juniorTeam/goreva.jpg';
import zhuldybinaPhoto from '../../images/juniorTeam/zhuldybina.jpg';
import kiselmanPhoto from '../../images/juniorTeam/kiselman.jpg';
import malyshevaPhoto from '../../images/juniorTeam/malysheva.png';
import nechaevaPhoto from '../../images/juniorTeam/nechaeva.png';
import sisonenkoPhoto from '../../images/juniorTeam/sisonenko.png';
import stetsenkoPhoto from '../../images/juniorTeam/stetsenko.jpg';
import chernobakhtovaPhoto from '../../images/juniorTeam/chernobakhtova.png';
import afoninPhoto from '../../images/juniorTeam/afonin.jpg';
import vysotskyPhoto from '../../images/juniorTeam/vysotsky.png';
import grigorievPhoto from '../../images/juniorTeam/grigoriev.png';
import zaitsevPhoto from '../../images/juniorTeam/zaitsev.jpg';
import koloborodovPhoto from '../../images/juniorTeam/koloborodov.png';
import krivaltsevichPhoto from '../../images/juniorTeam/krivaltsevich.png';
import kuzikhinPhoto from '../../images/juniorTeam/kuzikhin.png';
import mahlinPhoto from '../../images/juniorTeam/mahlin.png';
import sorokinPhoto from '../../images/juniorTeam/sorokin.jpg';
import chernyaevPhoto from '../../images/juniorTeam/chernyaev.png';
import coverImg from '../../images/cover4.jpg';
import AthleteCards from '../../components/AthleteCards/AthleteCards';
import Banner from 'components/Banner';
import Container from 'components/Container/Container';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const teamArr = [
  {
    id: 0,
    title: `Кмс`,
    name: `ГОРЕВА АННА ИВАНОВНА`,
    photo: gorevaPhoto,
    dateBirth: '30/09/2002',
    about: [
      { id: 0, text: `Регион: Москва, Новосибирская область` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2, СК ДЮСШ` },
      { id: 2, text: `Тренер в настоящий момент - Антошина И.Н.` },
    ],
  },
  {
    id: 1,
    title: `Кмс`,
    name: `Жулдыбина Ирина Максимовна`,
    photo: zhuldybinaPhoto,
    dateBirth: '15/11/2001',
    about: [
      { id: 0, text: `Регион - Москва` },
      { id: 1, text: `Организация - ГБПОУ МСС УОР№2` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Антошина И.Н., Генералова О.Н.`,
      },
    ],
  },
  {
    id: 2,
    title: `Кмс`,
    name: `Кисельман Милена Константиновна`,
    photo: kiselmanPhoto,
    dateBirth: '28/03/2002',
    about: [
      { id: 0, text: `Регион: Москва` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Антошина И.Н., Генералова О.Н.`,
      },
    ],
  },
  {
    id: 3,
    title: `Кмс`,
    name: `Малышева Алина Алексеевна`,
    photo: malyshevaPhoto,
    dateBirth: '30/05/2002',
    about: [
      { id: 0, text: `Регион: Пензенская область` },
      { id: 1, text: `Организация - ГБУ ДО ПО СШОР водных видов спорта` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Захаров А.В., Брюханкова Т.В.`,
      },
    ],
  },
  {
    id: 4,
    title: `Кмс`,
    name: `Нечаева Анастасия Игоревна`,
    photo: nechaevaPhoto,
    dateBirth: '24/07/2002',
    about: [
      { id: 0, text: `Регион: Москва` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2` },
      { id: 2, text: `Тренер в настоящий момент - Генералова О.Н.` },
    ],
  },
  {
    id: 5,
    title: `Кмс`,
    name: `Прасолова Татьяна Юрьевна`,
    photo: ``,
    dateBirth: '07/06/2003',
    about: [
      { id: 0, text: `Регион - Москва` },
      { id: 1, text: `Организация - ДЮСШ "АКВАТИКС"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Шевалье А.Ю., Самсонов И.А.`,
      },
    ],
  },
  {
    id: 6,
    title: `Кмс`,
    name: `Сизоненко Полина Владимировна`,
    photo: sisonenkoPhoto,
    dateBirth: '19/07/2001',
    about: [
      { id: 0, text: `Регион - Краснодарский край, Белореченск` },
      { id: 1, text: `Организация - ГБУ КК "ЦОП по плаванию"` },
      { id: 2, text: `Тренер в настоящий момент - Цепаев А.А., Цепаев А.А.` },
    ],
  },
  {
    id: 7,
    title: `Кмс`,
    name: `Стеценко Алевтина Алексеевна`,
    photo: stetsenkoPhoto,
    dateBirth: '16/09/2001',
    about: [
      { id: 0, text: `Регион - Московская область, Щелково` },
      { id: 1, text: `Организация - ГБУ МО "СШОР" по ЛВС, ГУОР г.Щелково` },
      { id: 2, text: `Первый тренер триатлоне - Цепаева Т.В., Цепаев А.А.` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Заусайлов С.А., Заусайлова О.С.`,
      },
    ],
  },
  {
    id: 8,
    title: `Кмс`,
    name: `Чернобахтова Юлия Александровна`,
    photo: chernobakhtovaPhoto,
    dateBirth: '29/10/2002',
    about: [
      { id: 0, text: `Регион: Москва` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Антошина И.Н., Генералова О.Н.`,
      },
    ],
  },
  {
    id: 9,
    title: `Кмс`,
    name: `Шерстнёва Мария Алексеевна`,
    photo: ``,
    dateBirth: '04/04/2001',
    about: [
      { id: 0, text: `Регион: Пензенская область, Республика Марий Эл` },
      { id: 1, text: `Организация - ГАОУ ПО УОР ПО` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Захаров А.В., Брюханкова Т.В.`,
      },
    ],
  },
  {
    id: 10,
    title: `Кмс`,
    name: `Афонин Никита Петрович`,
    photo: afoninPhoto,
    dateBirth: '23/08/2001',
    about: [
      { id: 0, text: `Регион - Саратовская область , Санкт-Петербург` },
      { id: 1, text: `Организация - МУ "ЦСШ", ГБУ "СОЦСП"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Мещеряков С.П., Полухин Д.В., Савельев Д.И.`,
      },
    ],
  },
  {
    id: 11,
    title: `Кмс`,
    name: `Высоцкий Марк Александрович`,
    photo: vysotskyPhoto,
    dateBirth: '19/02/2002',
    about: [
      { id: 0, text: `Регион: Москва` },
      { id: 1, text: `Организация: ГБПОУ МСС УОРNo2` },
      { id: 2, text: `Тренер в настоящий момент - Бутков Д.А., Антошина И.Н.` },
    ],
  },
  {
    id: 12,
    title: `Кмс`,
    name: `Григорьев Александр Сергеевич`,
    photo: grigorievPhoto,
    dateBirth: '11/09/2001',
    about: [
      { id: 0, text: `Организация - ВС, УОР No1` },
      {
        id: 1,
        text: `Тренер в настоящий момент - Павлов С.В., Брылев А.А., Гижа А.А.,`,
      },
      { id: 2, text: `Парфенцева Т.М.` },
    ],
  },
  {
    id: 13,
    title: `Кмс`,
    name: `Зайцев Федор Алексеевич`,
    photo: zaitsevPhoto,
    dateBirth: '20/02/2001',
    about: [
      { id: 0, text: `Регион - Саратовская область , Санкт-Петербург` },
      { id: 1, text: `Организация - МУ "ЦСШ", ГБУ "СОЦСП"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Полухин Д.В., Савельев Д.И., Титова О.В.`,
      },
    ],
  },
  {
    id: 14,
    title: `Мс`,
    name: `Колобородов Денис Олегович`,
    photo: koloborodovPhoto,
    dateBirth: '18/06/2001',
    about: [
      { id: 0, text: `Регион - Волгоградская область, Санкт-Петербург` },
      { id: 1, text: `Организация - МБУ СШОР № 16` },
      { id: 2, text: `Первый тренер в триатлоне - Горин М.М.` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Горин М.М., Горина Г.С., Доронин А.А., Гааг Д.В.`,
      },
    ],
  },
  {
    id: 15,
    title: `Кмс`,
    name: `Кривальцевич Роман Борисович`,
    photo: krivaltsevichPhoto,
    dateBirth: '26/03/2001',
    about: [
      { id: 0, text: `Регион - Москва` },
      { id: 1, text: `Организация - ГБПОУ МСС УОР №2` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Фетисов А.Е., Бутков Д.А., Генералова О.Н.`,
      },
    ],
  },
  {
    id: 16,
    title: `Кмс`,
    name: `Кузихин Олег Валерьевич`,
    photo: kuzikhinPhoto,
    dateBirth: '06/10/2001',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Саратовская область` },
      { id: 1, text: `Организация - "Динамо", КОР №1` },
      { id: 2, text: `Первый тренер в триатлоне - Васильев А.Г.` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Титова О.В., Гижа А.А., Васильев А.Г., Мазжухин И.В.`,
      },
    ],
  },
  {
    id: 17,
    title: `Кмс`,
    name: `Махлин Иван Дмитриевич`,
    photo: mahlinPhoto,
    dateBirth: '31/08/2001',
    about: [
      { id: 0, text: `Регион - Москва` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Никульшин А.В., Пахомова М.С.`,
      },
    ],
  },
  {
    id: 18,
    title: `Кмс`,
    name: `Сорокин Павел Дмитриевич`,
    photo: sorokinPhoto,
    dateBirth: '27/07/2002',
    about: [
      { id: 0, text: `Регион: Санкт-Петербург , Саратовская область` },
      { id: 1, text: `Организация: "Динамо", УОР-1` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Титова О.В., Гижа А.А, Логинов О.А.,Петров Г.Г.`,
      },
    ],
  },
  {
    id: 19,
    title: `Кмс`,
    name: `Черняев Глеб Владимирович`,
    photo: chernyaevPhoto,
    dateBirth: '05/09/2002',
    about: [
      { id: 0, text: `Регион: Санкт-Петербург, Ростовская область` },
      { id: 1, text: `Организация: ВС, УОР №1` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Гааг Д.В., Коноваленко В.В., Парфенцева Т.М.`,
      },
    ],
  },
];

const JuniorTeam = () => {
  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <AthleteCards athletesArr={teamArr} />
      </Container>
    </>
  );
};

export default JuniorTeam;
