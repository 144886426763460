import newsCover from '../../../images/News/news-cover3.jpg';
import Article from '../Article';
import styled from 'styled-components';
import { Anchor, Box, Paragraph } from 'grommet';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const CustomLink = styled(Anchor)`
  display: inline-block;
  background-color: #DB4C4C;
  color: #f8f8f8;
  text-decoration: none;
  border-radius: 28px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 14px 38px;
  font-size: 12px;
  line-height: 2;
  transition: box-shadow 0.2s ease;
  letter-spacing: 0.5px;

  &:focus, &:hover {
    box-shadow: 0 0 2px 2px #DB4C4C;
  }
`

const IndoorTriathlonJunior = () => {
  return (
    <Article
      title={`Детский индор-триатлон пройдет в Crocus Fitness 13 марта`}
      date={`4 МАРТА 2021`}
      coverBack={newsCover}
    >
      <CustomParagraph>
        Федерация триатлона России и «Крокус-Фитнес»приглашают вас на детский индор-триатлон!
      </CustomParagraph>
      <CustomParagraph>
        Место: <strong>«Крокус-Фитнес» на Ленинградке</strong> (Москва, ул. Черняховского, 19)
      </CustomParagraph>
      <CustomParagraph>
        Дата и время: <strong>13 марта, время старта - 11:00</strong>
      </CustomParagraph>
      <CustomParagraph>
        Участие могу принять дети <strong>2009−2010 г.р.</strong> ростом не ниже <strong>145 см</strong> (такое условие
        связано с минимальной высотой седла ватт-байка. Дети, ростом ниже 145 см не смогут достать до педалей).
        Допускается участие детей <strong>2011 и 2012 г.р.</strong> при условии роста от 145 см.
      </CustomParagraph>
      <CustomParagraph>
        <strong>Лимит участников: 42 человека.</strong>
      </CustomParagraph>
      <CustomParagraph>
        <b><i>Формат старта:</i></b> <br/>
        Плавание: 2 мин (100-130 м)<br/>
        T1: 3 мин<br/>
        Ватт-байк: 6 мин (3-4 км)<br/>
        T2: 3 мин<br/>
        Беговая дорожка: 4 мин (1-1,2 км)<br/>
      </CustomParagraph>
      <CustomParagraph>
        Коэффициент для расчёта результата: 8/1/2 результат в метрах умножается на коэффициент (плавание х 8, вело х 1, бег х 2). <br/>
        Педали на стационарном байке: с одной стороны крепление spd, с другой для кроссовка.
      </CustomParagraph>
      <CustomParagraph>
        <strong>Расписание:</strong><br/>
        Регистрация в 10:00 <br/>
        Старт в 11:00 <br/>
      </CustomParagraph>
      <CustomParagraph>
        В каждой волне участвует 7 человек (в бассейне 4 дорожки: по два человека на трех дорожках и один человек на
        одной дорожке). Состав волн формируется на основании заявочного результата. Заявочным результатом считается
        лучший результат в плавании на 100 м.
      </CustomParagraph>
      <CustomParagraph>
        Стартовый взнос: 1300 руб.
      </CustomParagraph>
      <CustomParagraph>
        <Anchor href={`https://crocusfitness.com/leningradskiy/`} label={`Сайт клуба`} />
      </CustomParagraph>
      <CustomParagraph>
        <Anchor href={`https://goo-gl.ru/vTkY4`} label={`Заявиться на старт`} />
      </CustomParagraph>
      <Box direction={`row`} justify={`between`} width={{max: `800px`}} margin={{left: `auto`, right: `auto`}}>
        <CustomLink href={`https://disk.yandex.ru/i/Q_bi1dKhCYAiig`} label={`Положения`} />
        <CustomLink href={`https://disk.yandex.ru/i/Q_bi1dKhCYAiig`} label={`Регистрация на старт`} />
      </Box>
    </Article>
  )
}

export default IndoorTriathlonJunior;