import { Anchor, Box, Paragraph } from 'grommet';
import React from 'react';
import styled from 'styled-components';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`;

const P = styled(Paragraph)`
  max-width: 100%;
  font-size: 16px;
  line-height: 30px;
`;

export default function DescriptionTab() {
  return (
    <Box gap="medium">
      <Box>
        <CustomParagraph>Уважаемые спортсмены!</CustomParagraph>
        <CustomParagraph>
          Российские спортсмены-любители могут принимать участие на серии стартов IRONMAN под нейтральным флагом, за исключением двух чемпионатов мира IRONMAN и IRONMAN 70.3 в 2022 году.
        </CustomParagraph>
        <CustomParagraph>
          Федерация триатлона России при содействии Минспорта России продолжает оказывать поддержку нашим спортсменам и их сопровождающим при пересечении российской границы для участия в доступных зарубежных соревнованиях, указанных ниже:
        </CustomParagraph>
        <P>• Ironman Дрезден 70.3 - 31 июля </P>
        <P>• Ironman 70.3 Маастрихт - 6 августа </P>
        <P>• Ironman 70.3 Гдыня - 7 августа </P>
        <P>• Ironman 70.3 Ирландия - 13 августа </P>
        <P>• Ironman Куопио - 13 августа </P>
        <P>• Ironman 70.3 Астана - 14 августа </P>
        <P>• Ironman 70.3 Виши - 20 августа </P>{' '}
        <CustomParagraph>
          Для оказания поддержки при пересечении российской границы при выезде
          на зарубежные соревнования необходимо предоставить подтверждение
          регистрации на старт на почту{' '}
          <Anchor href="agegroup@rustriathlon.ru">
            agegroup@rustriathlon.ru
          </Anchor>
        </CustomParagraph>
        <CustomParagraph>
          Обращаем внимание, что организаторы IRONMAN Таллин не допускают россиян к участию в своих соревнованиях. В связи с локальным запретом организаторов, рекомендуем каждому спортсмену-любителю, планирующему участвовать в IRONMAN, получить подтверждение от организаторов о допуске к старту самостоятельно.
        </CustomParagraph>
        <CustomParagraph>
          Для участия в серии соревнований XTERRA каждому российскому зарегистрированному на гонку спортсмену, необходимо получить подтверждение от локальных организаторов, потому что каждая гонка XTERRA регулируется федерацией триатлона страны, в которой проходит гонка.
        </CustomParagraph>
        <CustomParagraph>
          Следуя рекомендациям МОК и World Triathlon организаторы стартов Challenge Family не допускают к участию российских профессиональных спортсменов и любителей. Для всех зарегистрированных в настоящее время спортсменов, организаторы отменяют все регистрации и должны вернуть вступительные взносы.{' '}
        </CustomParagraph>
        <CustomParagraph>
          Будем ждать ваши вопросы по любительскому направлению деятельности ФТР
          на нашем почтовом ящике{' '}
          <Anchor href="agegroup@rustriathlon.ru">
            agegroup@rustriathlon.ru
          </Anchor>
        </CustomParagraph>
        <CustomParagraph>Будем рады помочь!</CustomParagraph>
      </Box>
    </Box>
  );
}
