import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { internationalDocuments } from '../../store/action/documents';
import { Box } from 'grommet';
import DocumentItem from '../../components/DocumentItem';

const DocumentsTab = () => {
  const items = useSelector(state => state.documents.items);

  useEffect(() => {
    internationalDocuments();
  }, []);

  return (
    <Box gap="medium">
      {items.map(i => (
        <Box
          key={i.public_id}
          flex
          direction="column"
          margin={{ bottom: '20px' }}
        >
          <DocumentItem title={i.title} date={i.document_date} link={i.link} />
        </Box>
      ))}
    </Box>
  );
};

export default DocumentsTab;
