import {
  Box,
  Button,
  ResponsiveContext,
  Layer,
  TextInput,
  Anchor,
  TextArea,
  Text,
  CheckBox,
} from 'grommet';
import styled from 'styled-components';
import { FormClose } from 'grommet-icons';
import iconClose from 'images/svg/icon-close.svg';

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  border-top-right-radius: 12px;
  background-color: #db4c4c;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-52%, -51%);
    display: block;
    width: 26px;
    height: 26px;
    background-image: url('${iconClose}');
    background-repeat: no-repeat;
    background-position: center;
    content: '';
  }
`;

const BookPopup = ({ statePopupHandler }) => {
  const handleClosePopup = () => {
    statePopupHandler(false);
  };
  return (
    <div>
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            onClickOutside={handleClosePopup}
            onEsc={handleClosePopup}
            full={true}
            plain={false}
            background={`transparent`}
            animation={`fadeIn`}
            position={`center`}
          >
            <Box
              overflow={`auto`}
              height={{ min: `auto` }}
              background={`rgba(0, 0, 0, 0.3)`}
              align={`center`}
              style={{ position: `relative`, cursor: `default` }}
              onClick={handleClosePopup}
            >
              <Box
                height={{ min: `auto` }}
                background={`#FFFFFF`}
                onClick={e => e.stopPropagation()}
                style={{
                  width: `100%`,
                  maxWidth: `500px`,
                  borderRadius: `12px`,
                  position: `relative`,
                  cursor: `default`,
                }}
              >
                <Box margin={`medium`}>
                  <Box
                    direction={`row`}
                    justify={`end`}
                    style={{ zIndex: `999` }}
                  >
                    <CloseButton
                      icon={<FormClose color={`#DB4C4C`} />}
                      onClick={handleClosePopup}
                    />
                  </Box>
                  <form
                    action={process.env.REACT_APP_API + `/server/book`}
                    method="post"
                    // enctype="multipart/form-data"
                    accept-charset="utf-8"
                  >
                    <Box>
                      <Box margin={{ top: 'large', bottom: 'medium' }}>
                        <Text size="medium" weight="bold">
                          Оформление книги «Триатлон. История»:{' '}
                        </Text>
                      </Box>
                      {/*                      <Box margin={{ bottom: 'small' }}>
                        <Text size="small" weight="bold">
                          Ваш вопрос или предложение:
                        </Text>
                      </Box>*/}
                    </Box>
                    <Box
                      direction="row-responsive"
                      style={{ maxWidth: '750px' }}
                    >
                      <Box
                        pad="small"
                        direction="row-responsive"
                        fill="horizontal"
                      >
                        <TextInput
                          placeholder="Ваш Имя"
                          name="name"
                          type="text"
                          style={{ height: '50px' }}
                        />
                      </Box>
                    </Box>
                    <Box
                      direction="row-responsive"
                      style={{ maxWidth: '750px' }}
                    >
                      <Box
                        pad="small"
                        direction="row-responsive"
                        fill="horizontal"
                      >
                        <TextInput
                          placeholder="Ваш email"
                          name="email"
                          type="email"
                          style={{ height: '50px' }}
                        />
                      </Box>
                      <Box
                        pad="small"
                        direction="row-responsive"
                        fill="horizontal"
                      >
                        <TextInput
                          placeholder="Контактный телефон"
                          name="phone"
                          type="tel"
                          style={{ height: '50px' }}
                        />
                      </Box>
                    </Box>
                    <Box
                      direction="row-responsive"
                      style={{ maxWidth: '750px' }}
                    >
                      <Box
                        pad="small"
                        direction="row-responsive"
                        fill="horizontal"
                      >
                        <TextArea
                          placeholder="Введите адрес доставки"
                          name="address"
                          style={{ height: '200px' }}
                        />
                      </Box>
                    </Box>
                    <Box margin="30px 5px">
                      <CheckBox
                        name="accept_terms"
                        label={
                          <Text size="14px" weight="normal">
                            Нажимая на кнопку, я принимаю условия{' '}
                            <Anchor>Пользовательского соглашения</Anchor>*
                          </Text>
                        }
                        required
                      />
                    </Box>

                    <Box direction="row">
                      <Button
                        label={
                          <Text size="small" color="white">
                            Оплатить
                          </Text>
                        }
                        type={`submit`}
                        primary
                        style={{
                          backgroundColor: '#DB4C4C',
                          border: 'none',
                          padding: '10px 50px',
                          borderRadius: '30px',
                        }}
                      />

                      <Text margin={{ left: 'small' }} size="small">
                        * — отмечены поля обязательные для заполнения
                      </Text>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    </div>
  );
};

export default BookPopup;
