import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grid,
  Heading,
  Image,
  Paragraph,
  ResponsiveContext,
  Text,
} from 'grommet';
import { useContext, useState } from 'react';
import Container from '../../components/Container/Container';
import Calendar from '../../components/Calendar/Calendar';
import coverImg from 'images/cover1.jpg';
import coverImg3 from 'images/cover3.jpg';
import kseniaPhoto from 'images/management/ksenia2.jpg';
import olesyaPhoto from 'images/management/olesya.jpg';
import peteshovPhoto from 'images/management/peteshov.jpg';
import teplovPhoto from 'images/management/teplov.jpg';
import shubinaPhoto from 'images/management/shubina.jpg';
import shipovalovaPhoto from 'images/management/shipovalova.webp';
// import mileevPhoto from 'images/management/mileev.jpg';
// import ivanovPhoto from 'images/management/ivanov.jpg';
import ibragimPhoto from 'images/management/ibragim.jpeg';
import bryuhankovPhoto from 'images/management/bryuhankov.jpg';
import musienkoPhoto from 'images/management/musienko.jpg';
import zykovaPhoto from 'images/management/zykova.jpg';
import yakovlevPhoto from 'images/management/yakovlev.jpg';
import poselenovPhoto from 'images/management/poselenov.jpg';
import PersonMorePopup from '../../components/Popups/PersonMore/PersonMorePopup';

import Partners from 'components/Partners/Partners';
import Banner from 'components/Banner';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const managementArr = [
  {
    id: 0,
    position: `Президент федерации триатлона`,
    name: `Шойгу Ксения Сергеевна`,
    photo: kseniaPhoto,
    popupCover: coverImg3,
    dateBirth: '10.01.1991',
    education: `Окончила МГИМО(У) МИД России по специальности «Международные экономические отношения». Эксперт в области иностранных прямых инвестиций.`,
    about: `Избрана президентом общероссийской общественной организации "Федерация триатлона России" на очередной отчетно-выборной конференции 5 декабря 2020 года.`,
    profActivity: [
      {
        id: 0,
        text: `2012-2013 год - Помощник заместителя руководителя «Федеральной служба по экологическому, технологическому и атомному надзора (Ростехнадзор)»`,
      },
      {
        id: 1,
        text: `2013-2018 год - Старший аналитик департамента проектного и структурного финансирования банка «Газпромбанк»`,
      },
      {
        id: 2,
        text: `С 2013 года - Руководитель "Лиги Героев"`,
      },
      {
        id: 3,
        text: `С 2018 - Советник главы Торгово-промышленной палаты`,
      },
      {
        id: 4,
        text: `C 2019 года – Заместитель председателя Инвестиционного комитета целевого некоммерческого фонда по развитию туристско-рекреационного кластера города Кронштадта`,
      },
      {
        id: 5,
        text: `C 2020 года – управляющий партнер венчурного фонда Sistema SmartTech`,
      },
    ],
  },
  {
    id: 1,
    position: `Первый вице-президент`,
    name: `Заволокина Олеся Юрьевна`,
    photo: olesyaPhoto,
    dateBirth: 'дата',
    education: `Образование`,
    about: `Текст`,
    profActivity: [
      {
        id: 0,
        text: `2005`,
      },
      {
        id: 1,
        text: `2008`,
      },
      {
        id: 2,
        text: `20011`,
      },
      {
        id: 3,
        text: `20013`,
      },
    ],
  },
  {
    id: 2,
    position: `вице-президент`,
    name: `Петешов Михаил Юрьевич`,
    photo: peteshovPhoto,
    dateBirth: 'дата',
    education: `Образование`,
    about: `Текст`,
    profActivity: [
      {
        id: 0,
        text: `2005`,
      },
      {
        id: 1,
        text: `2008`,
      },
      {
        id: 2,
        text: `20011`,
      },
      {
        id: 3,
        text: `20013`,
      },
    ],
  },
  {
    id: 3,
    position: `вице-президент`,
    name: `Теплов Олег Владимирович`,
    photo: teplovPhoto,
    dateBirth: 'дата',
    education: `Образование`,
    about: `Текст`,
    profActivity: [
      {
        id: 0,
        text: `2005`,
      },
      {
        id: 1,
        text: `2008`,
      },
      {
        id: 2,
        text: `20011`,
      },
      {
        id: 3,
        text: `20013`,
      },
    ],
  },
  {
    id: 4,
    position: `вице-президент`,
    name: `Шубина Виктория Николаевна`,
    photo: shubinaPhoto,
    dateBirth: 'дата',
    education: `Образование`,
    about: `Текст`,
    profActivity: [
      {
        id: 0,
        text: `2005`,
      },
      {
        id: 1,
        text: `2008`,
      },
      {
        id: 2,
        text: `20011`,
      },
      {
        id: 3,
        text: `20013`,
      },
    ],
  },
  /*  {
    id: 5,
    position: `вице-президент`,
    name: `МИЛЕЕВ АЛЕКСАНДР ВЛАДИЛЕНОВИЧ`,
    photo: mileevPhoto,
    dateBirth: 'дата',
    education: `Образование`,
    about: `Текст`,
    profActivity: [
      {
        id: 0,
       text: `2005`,
      },
      {
       id: 1,
        text: `2008`,
      },
      {
        id: 2,
        text: `20011`,
      },
      {
        id: 3,
        text: `20013`,
      },
    ],
  }, 
  {
    id: 6,
    position: `ГЕНЕРАЛЬНЫЙ СЕКРЕТАРЬ / ЧЛЕН ИСПОЛКОМА ETU`,
    name: `ШИПОВАЛОВА ГАЛИНА СЕРГЕЕВНА`,
    photo: shipovalovaPhoto,
    dateBirth: 'дата',
    education: `Образование`,
    about: `Текст`,
    profActivity: [
      {
        id: 0,
        text: `2005`,
      },
      {
        id: 1,
        text: `2008`,
      },
      {
        id: 2,
        text: `20011`,
      },
      {
        id: 3,
        text: `20013`,
      },
    ],
  }, */
  {
    id: 7,
    position: `Генеральный секретарь, Исполнительный директор`,
    name: `КАСИМОВ ИБРАГИМ `,
    photo: ibragimPhoto,
    dateBirth: 'дата',
    education: `Образование`,
    about: `Текст`,
    profActivity: [
      {
        id: 0,
        text: `2005`,
      },
      {
        id: 1,
        text: `2008`,
      },
      {
        id: 2,
        text: `20011`,
      },
      {
        id: 3,
        text: `20013`,
      },
    ],
  },
  {
    id: 8,
    position: `Член Президиума`,
    name: `МУСИЕНКО ВЛАДИМИР ИВАНОВИЧ`,
    photo: musienkoPhoto,
    dateBirth: 'дата',
    education: `Образование`,
    about: `Текст`,
    profActivity: [
      {
        id: 0,
        text: `2005`,
      },
      {
        id: 1,
        text: `2008`,
      },
      {
        id: 2,
        text: `20011`,
      },
      {
        id: 3,
        text: `20013`,
      },
    ],
  },
  {
    id: 9,
    position: `Член Президиума`,
    name: `ЗЫКОВА НАТАЛЬЯ ИВАНОВНА`,
    photo: zykovaPhoto,
    dateBirth: 'дата',
    education: `Образование`,
    about: `Текст`,
    profActivity: [
      {
        id: 0,
        text: `2005`,
      },
      {
        id: 1,
        text: `2008`,
      },
      {
        id: 2,
        text: `20011`,
      },
      {
        id: 3,
        text: `20013`,
      },
    ],
  },
  {
    id: 10,
    position: `Член Президиума`,
    name: `ЯКОВЛЕВ АЛЕКСАНДР ВАЛЕРЬЕВИЧ`,
    photo: yakovlevPhoto,
    dateBirth: 'дата',
    education: `Образование`,
    about: `Текст`,
    profActivity: [
      {
        id: 0,
        text: `2005`,
      },
      {
        id: 1,
        text: `2008`,
      },
      {
        id: 2,
        text: `20011`,
      },
      {
        id: 3,
        text: `20013`,
      },
    ],
  },
  {
    id: 11,
    position: `Член Президиума`,
    name: `ПОСЕЛЁНОВ ПАВЕЛ АЛЕКСАНДРОВИЧ`,
    photo: poselenovPhoto,
    dateBirth: 'дата',
    education: `Образование`,
    about: `Текст`,
    profActivity: [
      {
        id: 0,
        text: `2005`,
      },
      {
        id: 1,
        text: `2008`,
      },
      {
        id: 2,
        text: `20011`,
      },
      {
        id: 3,
        text: `20013`,
      },
    ],
  },
];

const Management = () => {
  const size = useContext(ResponsiveContext);
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [personId, setPersonId] = useState('');
  const sizes = [`small`, `tablet`, `desktop1200`];

  const handleClickBtnMore = id => {
    setPersonId(id);
    setOpenPopup(true);
  };

  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />

        <Box>
          <Grid
            columns={{
              count: `fit`,
              size: `${
                size === `medium` || size === `large` ? `500px` : `280px`
              }`,
            }}
            gap={`24px`}
            margin={{ bottom: `24px` }}
          >
            <Card
              direction={!sizes.includes(size) ? `row` : `column`}
              background={`#F8F8F8`}
              elevation={`none`}
            >
              <Box
                width={{ min: `250px`, max: `643px` }}
                height={!sizes.includes(size) ? `auto` : `480px`}
              >
                <Image
                  src={kseniaPhoto}
                  fit={`cover`}
                  style={{ objectPosition: `center top` }}
                />
              </Box>
              <Box
                height={{ min: `410px` }}
                direction={`column`}
                justify={`between`}
                pad={{
                  top: `36px`,
                  right: `40px`,
                  bottom: `16px`,
                  left: `52px`,
                }}
                width={{ max: `749px` }}
              >
                <Text
                  margin={{ bottom: `20px` }}
                  style={{
                    textTransform: `uppercase`,
                    color: `rgba(0, 0, 0, 0.5)`,
                  }}
                >
                  Президент федерации триатлона
                </Text>
                <Box>
                  <Heading
                    level={2}
                    margin={{ bottom: `16px` }}
                    style={{
                      textTransform: `uppercase`,
                      lineHeight: `1.2`,
                      fontSize: `38px`,
                    }}
                  >
                    Шойгу Ксения Сергеевна
                  </Heading>
                  <Paragraph
                    margin={{ bottom: `20px` }}
                    color={`rgba(51, 51, 51, 0.5)`}
                    style={{
                      width: `100%`,
                      maxWidth: `890px`,
                      fontSize: `16px`,
                    }}
                  >
                    {managementArr[0].about}
                  </Paragraph>

                  <Box margin={{ bottom: `20px` }} height={{ min: `auto` }}>
                    <Box
                      direction={`row`}
                      style={{ width: `100%`, maxWidth: `366px` }}
                    >
                      <Heading
                        level={`4`}
                        margin={{ bottom: `24px`, right: `16px` }}
                        style={{ fontSize: `16px` }}
                      >
                        Дата рождения:
                      </Heading>
                      <Paragraph style={{ fontSize: `16px` }}>
                        {managementArr[0].dateBirth}
                      </Paragraph>
                    </Box>

                    <Box direction={`row`}>
                      <Heading
                        level={`4`}
                        margin={{ bottom: `24px`, right: `36px` }}
                        style={{ fontSize: `16px` }}
                      >
                        Образование:
                      </Heading>
                      <Paragraph style={{ maxWidth: `100%`, fontSize: `16px` }}>
                        {managementArr[0].education}
                      </Paragraph>
                    </Box>
                  </Box>

                  <Box height={{ min: `auto` }}>
                    <Heading
                      level={`4`}
                      margin={{ bottom: `24px` }}
                      style={{ fontSize: `16px` }}
                    >
                      Профессиональная деятельность:
                    </Heading>
                    {managementArr[0].profActivity.map(item => (
                      <Paragraph
                        margin={{ bottom: `24px` }}
                        style={{
                          width: `100%`,
                          maxWidth: `890px`,
                          fontSize: `16px`,
                        }}
                        key={item.id}
                      >
                        {item.text}
                      </Paragraph>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Card>

            {/*<Card*/}
            {/*  direction={size === `medium` || size === `large` ? `row` : `column`}*/}
            {/*  background={`#F8F8F8`} elevation={`none`}*/}
            {/*>*/}
            {/*  <Box width={{min: `250px`}} height={size === `medium` || size === `large` ? `auto` : `480px`}>*/}
            {/*    <Image src={kseniaPhoto} fit={`cover`} style={{objectPosition: `center top`}} />*/}
            {/*  </Box>*/}
            {/*  <Box*/}
            {/*    height={{min: `410px`}}*/}
            {/*    direction={`column`}*/}
            {/*    justify={`between`}*/}
            {/*    pad={{top: `56px`, right: `45px`, bottom: `40px`, left: `24px`}}*/}
            {/*    width={{max: `354px`}}*/}
            {/*  >*/}
            {/*    <Text margin={{bottom: `58px`}} style={{textTransform: `uppercase`, color: `rgba(0, 0, 0, 0.5)`,}}>*/}
            {/*      Президент федерации триатлона*/}
            {/*    </Text>*/}
            {/*    <Box>*/}
            {/*      <Heading level={2} margin={{bottom: `32px`}} style={{textTransform: `uppercase`, lineHeight: `1.2`}}>*/}
            {/*        Шойгу Ксения Сергеевна*/}
            {/*      </Heading>*/}
            {/*      <Button*/}
            {/*        label={`Подробнее`}*/}
            {/*        onClick={handleClickBtnMore}*/}
            {/*        primary*/}
            {/*      />*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*</Card>*/}

            {/*<Card*/}
            {/*  direction={size === `medium` || size === `large` ? `row` : `column`}*/}
            {/*  background={`#F8F8F8`} elevation={`none`}*/}
            {/*>*/}
            {/*  <Box width={{min: `250px`}} height={size === `medium` || size === `large` ? `auto` : `480px`}>*/}
            {/*    <Image src={olesyaPhoto} fit={`cover`} style={{objectPosition: `center top`}} />*/}
            {/*  </Box>*/}
            {/*  <Box*/}
            {/*    height={{min: `410px`}}*/}
            {/*    direction={`column`}*/}
            {/*    justify={`between`}*/}
            {/*    pad={{top: `56px`, right: `45px`, bottom: `40px`, left: `24px`}}*/}
            {/*    width={{max: `354px`}}*/}
            {/*  >*/}
            {/*    <Text margin={{bottom: `58px`}} style={{textTransform: `uppercase`, color: `rgba(0, 0, 0, 0.5)`}}>*/}
            {/*      Первый вице-президент*/}
            {/*    </Text>*/}
            {/*    <Box>*/}
            {/*      <Heading level={2} margin={{bottom: `32px`}} style={{textTransform: `uppercase`, lineHeight: `1.2`}}>*/}
            {/*        Заволокина Олеся Юрьевна*/}
            {/*      </Heading>*/}
            {/*      <Button label={`Подробнее`} primary />*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*</Card>*/}
          </Grid>

          <Grid columns={{ count: `fit`, size: `280px` }} gap={`medium`}>
            {managementArr.slice(1).map(item => (
              <Card background={`#F8F8F8`} elevation={`none`} key={item.id}>
                <CardBody>
                  <Box height="350px">
                    <Image
                      src={item.photo}
                      style={{ borderRadius: '20px 20px 0 0' }}
                      fit="cover"
                    />
                  </Box>

                  <CardFooter align="start" direction="column" pad="30px 20px">
                    <Text
                      margin={{ bottom: `medium` }}
                      style={{
                        textTransform: `uppercase`,
                        color: `rgba(0, 0, 0, 0.5)`,
                      }}
                    >
                      {item.position}
                    </Text>
                    <CardHeader
                      style={{
                        textTransform: `uppercase`,
                        lineHeight: `1.2`,
                        fontWeight: 'bolder',
                        fontSize: 'large',
                      }}
                    >
                      {item.name}
                      {/*<Button label={`Подробнее`} onClick={() => {handleClickBtnMore(item.id)}} primary /> */}
                    </CardHeader>
                  </CardFooter>
                </CardBody>
              </Card>
            ))}
          </Grid>
        </Box>

        <Partners />

        <Calendar event_type="all" />
      </Container>

      {isOpenPopup && (
        <PersonMorePopup
          statePopupHandler={setOpenPopup}
          array={managementArr}
          personId={personId}
        />
      )}
    </>
  );
};

export default Management;
