import astafievPhoto from '../../images/crossTeam/astafiev.jpg';
import kozlowskiPhoto from '../../images/crossTeam/kozlowski.jpg';
import bashunPhoto from '../../images/crossTeam/bashun.jpg';
import coverImg from '../../images/cover4.jpg';
import grebenevPhoto from '../../images/crossTeam/grebenev.jpg';
import zabrodinPhoto from '../../images/crossTeam/zabrodin.jpg';
import bayazitovPhoto from '../../images/crossTeam/bayazitov.jpg';
import yeremeevPhoto from '../../images/crossTeam/yeremeev.png';
import kudryashovPhoto from '../../images/crossTeam/kudryashov.jpg';
import kellerPhoto from '../../images/crossTeam/keller.jpg';
import nashchokinPhoto from '../../images/crossTeam/nashchokin.png';
import selivanovPhoto from '../../images/crossTeam/selivanov.png';
import yakubchikPhoto from '../../images/crossTeam/yakubchik.jpg';
import AthleteCards from '../../components/AthleteCards/AthleteCards';
import { Box, Heading } from 'grommet';
import styled from 'styled-components';
import Banner from 'components/Banner';
import Container from 'components/Container/Container';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const CustomHeading = styled(Heading)`
  margin-bottom: 35px;
  max-width: 100%;
  text-align: center;
  text-transform: uppercase;
`;

const teamMainArr = [
  {
    id: 0,
    title: `МС`,
    name: `Астафьев Константин Валерьевич`,
    photo: astafievPhoto,
    dateBirth: '26/11/1985',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург` },
      { id: 1, text: `Организация - ШВСМ по водным видам спорта` },
      { id: 2, text: `Тренер в настоящий момент - Гижа А.А., Гудалов А.В.` },
    ],
  },
  {
    id: 1,
    title: `МС`,
    name: `Козловский Артем Александрович`,
    photo: kozlowskiPhoto,
    dateBirth: '14/05/1996',
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Организация - МАУ СШОР "Здоровый мир"` },
      { id: 2, text: `Тренер в настоящий момент - Башун Д.Ю., Башун Т.В.` },
    ],
  },
  {
    id: 2,
    title: `КМС`,
    name: `Соловьев Артем Сергеевич`,
    photo: ``,
    dateBirth: '28/10/2001',
    about: [
      { id: 0, text: `Регион - Челябинская область, Озерск` },
      { id: 1, text: `Организация - КРОО "Федерация триатлона"` },
      { id: 2, text: `Тренер в настоящий момент - Вичужанин В.А.` },
    ],
  },
  {
    id: 3,
    title: `МС`,
    name: `Башун Татьяна Владимировна`,
    photo: bashunPhoto,
    dateBirth: '04/12/1987',
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Организация - КГАУ РЦСП "АЛВС"` },
      { id: 2, text: `Тренер в настоящий момент - Башун Д.Ю.` },
    ],
  },
  {
    id: 4,
    title: `МС`,
    name: `Нечаева Василиса Владимировна`,
    photo: ``,
    dateBirth: '01/01/2001',
    about: [
      { id: 0, text: `Регион - Свердловская область, Новоуральск` },
      { id: 1, text: `Организация - МБУ "СК Кедр" НГО` },
      { id: 2, text: `Тренер в настоящий момент - Майрыгин В.М.` },
    ],
  },
];

const teamYouthArr = [
  {
    id: 0,
    title: `1 разряд`,
    name: `Гребенев Виталий Дмитриевич`,
    photo: grebenevPhoto,
    dateBirth: '17/04/2001',
    about: [
      { id: 0, text: `Регион - Кировская область, Верхошижемье` },
      { id: 1, text: `Организация - КРОО "Федерация триатлона"` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Андреев П.В., Корчемкин Ю.А., Рудин А.В.`,
      },
    ],
  },
  {
    id: 1,
    title: `КМС`,
    name: `Забродин Никита Юрьевич`,
    photo: zabrodinPhoto,
    dateBirth: '19/04/1999',
    about: [
      { id: 0, text: `Регион - Республика Татарстан` },
      { id: 1, text: `Организация - ГАУ ЦСП` },
      { id: 2, text: `Тренер в настоящий момент - Минченко А.Е.` },
    ],
  },
];

const teamJuniorArr = [
  {
    id: 0,
    title: `КМС`,
    name: `Баязитов Руслан Маратович`,
    photo: bayazitovPhoto,
    dateBirth: '13/03/2001',
    about: [
      { id: 0, text: `Регион - Республика Татарстан` },
      { id: 1, text: `Организация - ГАУ ЦСП` },
      { id: 2, text: `Тренер в настоящий момент - Минченко А.Е.` },
    ],
  },
  {
    id: 1,
    title: `1 разряд`,
    name: `Еремеев Егор Олегович`,
    photo: yeremeevPhoto,
    dateBirth: '26/07/2004',
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Организация - МАУ СШОР "Здоровый мир"` },
      { id: 2, text: `Тренер в настоящий момент - Христофоров А.Н.` },
    ],
  },
  {
    id: 2,
    title: `1 разряд`,
    name: `Келлер Сергей Сергеевич`,
    photo: kellerPhoto,
    dateBirth: '02/10/2002',
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Организация - МАУ СШОР "Здоровый мир"` },
      { id: 2, text: `Тренер в настоящий момент - Христофоров А.Н.` },
    ],
  },
  {
    id: 3,
    title: `КМС`,
    name: `Кудряшов Артур Андреевич`,
    photo: kudryashovPhoto,
    dateBirth: '25/12/2003',
    about: [
      { id: 0, text: `Регион - Республика Татарстан` },
      { id: 1, text: `Организация - ГАУ ЦСП` },
      { id: 2, text: `Тренер в настоящий момент - Минченко А.Е.` },
    ],
  },
  {
    id: 4,
    title: `КМС`,
    name: `Нащокин Глеб Константинович`,
    photo: nashchokinPhoto,
    dateBirth: '03/10/2001',
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Организация - МАУ СШОР "Здоровый мир"` },
      { id: 2, text: `Тренер в настоящий момент - Христофоров А.Н.` },
    ],
  },
  {
    id: 5,
    title: `КМС`,
    name: `Селиванов Егор Сергеевич`,
    photo: selivanovPhoto,
    dateBirth: '18/10/2001',
    about: [
      { id: 0, text: `Регион - Чувашская Республика` },
      {
        id: 1,
        text: `Организация - АУ " СШОР No3" Минспорта Чувашской Республики`,
      },
      {
        id: 2,
        text: `Тренер в настоящий момент - Васильев А.В., Димитриев В.Г.`,
      },
    ],
  },
  {
    id: 6,
    title: `1 разряд`,
    name: `Скрипка Данил Александрович`,
    photo: ``,
    dateBirth: '04/04/2003',
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Организация - МАУ СШОР "Здоровый мир"` },
      { id: 2, text: `Тренер в настоящий момент - Христофоров А.Н.` },
    ],
  },
  {
    id: 7,
    title: `1 разряд`,
    name: `Чижикова Светлана Ивановна`,
    photo: ``,
    dateBirth: '19/07/2004',
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Организация - МАУ СШОР "Здоровый мир"` },
      { id: 2, text: `Тренер в настоящий момент - Башун Д.Ю., Башун Т.В.` },
    ],
  },
  {
    id: 8,
    title: `1 разряд`,
    name: `Якубчик Милана Романовна`,
    photo: yakubchikPhoto,
    dateBirth: '',
    about: [
      { id: 0, text: `Регион - Красноярский край` },
      { id: 1, text: `Организация - КГАУ РЦСП "АЗВС"` },
      { id: 2, text: `Тренер в настоящий момент - Христофоров А.Н.` },
    ],
  },
];

const CrossTriathlon = () => {
  return (
    <div>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />
        <Box>
          <CustomHeading level={`2`}>Основной состав</CustomHeading>

          <AthleteCards athletesArr={teamMainArr} />
        </Box>
        <Box>
          <CustomHeading level={`2`}>Молодёжный состав</CustomHeading>

          <AthleteCards athletesArr={teamYouthArr} />
        </Box>
        <Box>
          <CustomHeading level={`2`}>Юниорский состав</CustomHeading>

          <AthleteCards athletesArr={teamJuniorArr} />
        </Box>
      </Container>
    </div>
  );
};

export default CrossTriathlon;
