import { Anchor, Box, Button, Layer, Paragraph } from 'grommet';
import { FormClose } from 'grommet-icons';
import styled from 'styled-components';
import iconClose from 'images/svg/icon-close.svg';

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  border-top-right-radius: 12px;
  background-color: #DB4C4C;
  
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-52%, -51%);
    display: block;
    width: 26px;
    height: 26px;
    background-image: url("${iconClose}");
    background-repeat: no-repeat;
    background-position: center;
    content: '';
  }
`;

const LicensePolicyPopup = ({statePopupHandler}) => {

  const handleClosePopup = () => {
    statePopupHandler(false);
  }

  return (
    <Layer onClickOutside={handleClosePopup} onEsc={handleClosePopup} full plain={false} background={`transparent`}>
      <Box
        overflow={`auto`}
        height={{min: `auto`}}
        background={`rgba(0, 0, 0, 0.3)`} align={`center`}
        style={{position: `relative`, cursor: `default`}}
        onClick={handleClosePopup}
      >
        <Box
          height={{min: `auto`}}
          background={`#FFFFFF`}
          onClick={(e) => e.stopPropagation()}
          style={{width: `100%`, maxWidth: `760px`, borderRadius: `12px`, position: `relative`, cursor: `default`}}
          pad={`55px`}
        >
          <Box direction={`row`} justify={`end`} style={{zIndex: `999`}}>
            <CloseButton icon={<FormClose color={`#DB4C4C`} />} onClick={handleClosePopup} />

          </Box>
            <Paragraph style={{marginBottom: `24px`, fontWeight: `900`, textAlign: `center`}} fill>
              Полис "Туризм" обеспечивает получение медицинской помощи при расстройствах здоровья в клиниках страны
              пребывания. Риск "Страхование медицинских, медико-транспортных и иных расходов" включает:
            </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            <strong>Медицинскую помощь</strong> - амбулаторную, стационарную, скорую и неотложную медицинскую помощь, в том числе
            консультативную, диагностическую и лечебную, необходимую для купирования неотложного медицинского состояния
            и подготовки Застрахованного к транспортировке, в том числе медицинской, в страну постоянного проживания
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            <strong>Неотложную медицинскую помощь при возникновении обострении и/или осложнения хронического заболевания
            или заболевания, имевшегося до начала действия договора страхования или до начала поездки.</strong>
            Покрываются медицинские услуги по купированию угрозы жизни Застрахованного. Лимит ответственности по этому
            виду помощи составляет 10% от страховой суммы, предусмотренной договором страхования для этого Застрахованного.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            <strong>Медико-транспортную помощь</strong> организует Сервисным центром, с использованием специализированного
            медицинского или обычного транспорта, в том числе авиационного.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            <strong>Возвращение Застрахованного в страну постоянного проживания</strong>, если Застрахованный не вернулся
            своевременно в страну постоянного проживания по причине страхового случая.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            <>Посмертную репатриацию</> – транспортировку тела, посмертных останков в страну постоянного проживания,
            если смерть Застрахованного наступила в результате страхового случая.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            <strong>Связь с сервисным центром</strong> – расходы на телефонные разговоры с Сервисным Центром по страховому случаю.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            <>Стоматологическая помощь</> - экстренная болеутоляющая стоматологическая помощь при остром воспалении
            или травме естественного зуба и окружающих тканей.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            Покрытие по каждому виду определяется страховой суммой полиса.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            Стоимость полиса зависит от выбранной суммы покрытия (лимита возмещения), количества дней поездки,
            выбранной страны, возраста застрахованного, включения спортивных и иных дополнительных категорий рисков.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            Примеры стоимости полиса с учетом включения спорта <strong>ТРИАТЛОН</strong>:
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            1.Выезд в Европейские страны, срок 10 дней, 1 человек 30 лет, покрытие 35 000 евро, без франшизы.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            Стоимость полиса – <b>21 евро</b>.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            2.Выезд в США, срок 10 дней, 1 человек 30 лет, покрытие 35 000 долларов, без франшизы.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            Стоимость полиса – <b>39 долларов</b>.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            3.Выезд в Европейские страны, срок пребывания за рубежом до 90 дней на одну поездку, без ограничения
            поездок в течение 1 года, 1 человек 30 лет, покрытие 35 000 евро, без франшизы.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            Стоимость – <b>136 евро</b>.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            4.Выезд в США срок пребывания за рубежом до 90 дней на одну поездку, без ограничения поездок в течение
            1 года, 1 человек 30 лет, покрытие 35 000 долларов, без франшизы.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            Стоимость – <b>254 доллара</b>.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            Предусмотрены скидки при одновременном страховании коллективов от 10 человек и более.
          </Paragraph>

          <Paragraph style={{marginBottom: `24px`}} fill>
            По вопросу оформления данного полиса связывайтесь по телефону: <Anchor href={`tel:+73912198801`} label={`+7 (391) 219-88-01`} /> - Логинова Полина.
          </Paragraph>

          <Button onClick={handleClosePopup} label={`Ок`} primary />
        </Box>
      </Box>
    </Layer>
  )
}

export default LicensePolicyPopup;