import {
  Box,
  ResponsiveContext,
  Anchor,
  Button,
  Layer,
  Accordion,
  AccordionPanel,
  Menu,
} from 'grommet';
import { Menu as IconMenu, FormClose } from 'grommet-icons';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { routes } from 'const/routes';
import MainLogo from './MainLogo/MainLogo';

const CustomLink = styled(NavLink)`
  color: #444;
  padding: 14px;
  font-size: 16px;
  text-decoration: none;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.33);

  &:hover {
    color: #444444b0;
  }
`;

const ModalMenuItem = styled(AccordionPanel)`
  font-family: 'Gotham Pro', sans-serif;
`;

const ModalMenuSubItem = styled(Anchor)`
  padding: 8px 20px;
  font-size: 16px;
  color: #444444;
  font-family: 'Gotham Pro', sans-serif;
`;

const MenuNavLink = styled(NavLink)`
  text-decoration: none;
  font-size: 12px;
  color: #444444;
  font-family: 'Gotham Pro', sans-serif;

  &:hover {
    text-decoration: underline;
  }
`;

const CustomAnchor = styled(NavLink)`
  font-weight: 600;
  color: #db4c4c;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const CustomExternalLink = styled('a')`
  font-weight: 600;
  color: #db4c4c;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const AnchorMobile = styled(NavLink)`
  padding: 14px;
  font-size: 16px;
  font-weight: 600;
  color: #db4c4c;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
`;

const MenuItems = [
  {
    name: `Главная`,
    array: [
      {
        path: routes.news.path,
        name: `Новости`,
      },
      {
        path: routes.whereToStart.path,
        name: `Как начать?`,
      },
      {
        path: routes.historyTria.path,
        name: `История`,
      },
      {
        path: routes.coachingStaff.path,
        name: `Тренерский состав`,
      },
      {
        path: routes.disciplines.path,
        name: `Дисциплины`,
      },
      {
        path: routes.highEducation.path,
        name: `Образование`,
      },
      {
        path: routes.olympicReserve.path,
        name: `Олимпийский резерв`,
      },
    ],
  },
  {
    name: `Сборные`,
    array: [
      {
        path: routes.mainCast.path,
        name: `Основной состав сборной`,
      },
      {
        path: routes.youthTeam.path,
        name: `Молодежный состав`,
      },
      {
        path: routes.juniorTeam.path,
        name: `Юниорский состав`,
      },
      {
        path: routes.youthSquad.path,
        name: `Юношеский состав`,
      },
      {
        path: routes.winterTriathlon.path,
        name: `Зимний триатлон`,
      },
      // {
      //   path: routes.protocols.path,
      //   name: `Результаты соревнований`,
      // },
      {
        path: routes.aquathlonTeam.path,
        name: `Акватлон`,
      },
      {
        path: routes.teamMiddleDistance.path,
        name: `Триатлон средняя дистанция`,
      },
      {
        path: routes.crossTriathlon.path,
        name: `Кросс-триатлон`,
      },
      {
        path: routes.teamDuathlon.path,
        name: `Дуатлон`,
      },
    ],
  },
  {
    name: `Календарь`,
    array: [
      {
        path: `${routes.calendar.path}/all`,
        name: `Все`,
      },
      {
        path: `${routes.calendar.path}/ekp`,
        name: `Единый календарный план`,
      },
      {
        path: `${routes.calendar.path}/ru`,
        name: `Любительские старты`,
      },
      {
        path: `${routes.calendar.path}/child`,
        name: `Детская лига`,
      },
      {
        path: `${routes.calendar.path}/childstart`,
        name: `Детские старты`,
      },
      {
        path: `${routes.calendar.path}/series4`,
        name: `Серия 4-х колец`,
      },
      {
        path: `${routes.calendar.path}/sups`,
        name: `Суперсерия`,
      },
    ],
  },
  {
    name: `Федерация`,
    array: [
      {
        path: routes.management.path,
        name: `Руководство`,
      },
      {
        path: routes.regions.path,
        name: `Региональные федерации`,
      },
      {
        path: routes.documents.path,
        name: `Документы`,
      },
      {
        path: routes.contacts.path,
        name: `Контакты`,
      },
      {
        path: routes.partners.path,
        name: `Партнеры ФТР`,
      },
    ],
  },

  // {
  //   name: `Суперсерия`,
  //   array: [{
  //     path: routes.superseries.path,
  //     name: `Суперсерия 2021`,
  //   }],
  // },
];

const Header = () => {
  const size = useContext(ResponsiveContext);

  const [isShowMobileMenu, setMobileMenu] = useState(false);

  return (
    <Box
      flex
      direction="row"
      style={{ fontSize: '12px' }}
      pad={{ horizontal: 'medium', vertical: 'xsmall' }}
      margin={{ horizontal: `auto` }}
      width={{ max: '1240px' }}
    >
      <Box flex direction="row" gap="small">
        <Box alignSelf="center">
          <MainLogo />
        </Box>
        {size !== 'tablet' && size !== 'small' ? (
          <Box flex direction="row" style={{ fontSize: '12px' }}>
            {MenuItems.map(menu => (
              <Menu
                key={menu.name}
                label={menu.name}
                size={`12px`}
                dropProps={{ align: { top: `bottom`, left: `left` } }}
                items={menu.array.map(el => {
                  return {
                    label: <MenuNavLink to={el.path}>{el.name}</MenuNavLink>,
                    onClick: () => {},
                  };
                })}
              />
            ))}
            <Box flex direction="row" align="center" gap="3%">
              <MenuNavLink to={routes.childrensTriathlon.path}>
                Дети
              </MenuNavLink>
              <MenuNavLink to={routes.internationalTriathlon.path}>Международный</MenuNavLink>
              <MenuNavLink to={routes.antidoping.path}>Антидопинг</MenuNavLink>
              <MenuNavLink to={routes.results.path}>Результаты</MenuNavLink>
              <MenuNavLink to={routes.license.path}>Лицензия</MenuNavLink>
              <CustomExternalLink href={'https://www.rusada.ru/doping-control/investigations/report-about-doping/'} target="_blank" rel="noopener">Сообщить о допинге</CustomExternalLink>
            </Box>
          </Box>
        ) : (
          <Box
            margin={{ left: 'auto', right: '16px' }}
            justify="center"
            pad={{ top: '12px' }}
          >
            <Button
              icon={<IconMenu />}
              onClick={() => {
                setMobileMenu(true);
              }}
            />
          </Box>
        )}
      </Box>

      {isShowMobileMenu && (
        <Layer full={true}>
          <Box pad={{ horizontal: '16px' }} direction="row" justify="end">
            <Button
              icon={<FormClose />}
              onClick={() => {
                setMobileMenu(false);
              }}
            />
          </Box>

          <Accordion pad="16px">
            {MenuItems.map(el => (
              <ModalMenuItem key={el.name} pad="10px" label={el.name}>
                {el.array.map(arr => (
                  <ModalMenuSubItem
                    key={arr.name}
                    href={arr.path}
                    label={arr.name}
                    onClick={() => {
                      setMobileMenu(false);
                    }}
                  />
                ))}
              </ModalMenuItem>
            ))}

            <CustomLink
              to={routes.internationalTriathlon.path}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Международный
            </CustomLink>
            <CustomLink
              to={routes.childrensTriathlon.path}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Дети
            </CustomLink>
            <CustomLink
              to={routes.antidoping.path}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Антидопинг
            </CustomLink>
            <CustomLink
              to={routes.results.path}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Результаты
            </CustomLink>
            <CustomLink
              to={routes.license.path}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Лицензия
            </CustomLink>
            <AnchorMobile to={''}>Сообщить о допинге</AnchorMobile>
          </Accordion>
        </Layer>
      )}
    </Box>
  );
};

export default Header;
