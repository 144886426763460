import { Link } from 'react-router-dom';
import { Box, Paragraph } from 'grommet';
import { routes } from 'const/routes';
import coverImg from 'images/cover4.jpg';
import Container from 'components/Container/Container';
import styled from 'styled-components';
import Banner from 'components/Banner';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Partners from 'components/Partners/Partners';

export const StyledLink = styled(Link)`
  color: rgb(219 76 76);
  font-weight: 600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledParagraph = styled(Paragraph)`
  position: relative;

  &:last-child {
    &::after {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 2px;
    height: 80%;
    transform: translateY(-50%);
    background-color: currentColor;
    opacity: 0.6;
  }
`;

export const documentsLinks = [
  { link: `main`, title: `Основные документы` },
  { link: `criterias`, title: `Критерии отбора` },
  // { link: `protocol`, title: `Протоколы соревнований` },
  { link: `archive`, title: `Архив документов` },
  { link: `ratings`, title: `Рейтинги спортсменов` },
  { link: `triathlonteka`, title: `Триатлонотека` },
  { link: `erai`, title: `ППК ЕРАИ` },
];

const Documents = () => {
  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />

        <Box direction={`row`}>
          <Box
            direction={`row`}
            width={`max-content`}
            margin={{ left: `auto`, right: `auto` }}
          >
            {documentsLinks.map(link => (
              <StyledParagraph
                key={link.title}
                style={{ paddingRight: `10px`, marginRight: `10px` }}
              >
                <StyledLink to={`${routes.documents.path}/${link.link}`}>
                  {link.title}
                </StyledLink>
              </StyledParagraph>
            ))}
          </Box>
        </Box>

        <Partners />
      </Container>
    </>
  );
};

export default Documents;
