import Banner from '../../components/Banner';
import coverImg from "../../images/cover4.jpg";
import Container from '../../components/Container/Container';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import InternationalTriaDesc from './InternationalTriaDesc';
import InternationalTriaTabs from './InternationalTriaTabs';

const InternationalTriathlon = () => {
  return (
    <>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />
        <InternationalTriaDesc />
        <InternationalTriaTabs />
      </Container>
    </>
  );
}

export default InternationalTriathlon;
