import coverImg from 'images/cover6.jpg';
import coverCard1 from 'images/license-cover1.jpg';
import coverCard2 from 'images/license-cover2.jpg';
import coverCard3 from 'images/license-cover3.jpg';
import logo1 from 'images/partnerLogos/partnerLogo19.png';
import logo2 from 'images/partnerLogos/partnerLogo20.png';
import logo3 from 'images/partnerLogos/partnerLogo21.png';
import logo4 from 'images/partnerLogos/partnerLogo22.png';
import logo5 from 'images/discountLogos/discountLogo1.png';
import logo6 from 'images/discountLogos/discountLogo2.png';
import logo7 from 'images/discountLogos/discountLogo3.png';
import logo8 from 'images/discountLogos/discountLogo4.png';
import logo9 from 'images/discountLogos/discountLogo5.png';
import logo10 from 'images/discountLogos/discountLogo6.png';
import logo11 from 'images/discountLogos/discountLogo7.png';
import logo12 from 'images/discountLogos/discountLogo8.png';
import logo13 from 'images/discountLogos/discountLogo9.png';
import logo14 from 'images/discountLogos/discountLogo10.png';
import logo15 from 'images/discountLogos/discountLogo11.png';
import logo16 from 'images/discountLogos/discountLogo12.png';
import logo17 from 'images/discountLogos/discountLogo13.svg';
import logo18 from 'images/discountLogos/discountLogo14.png';
import Container from 'components/Container/Container';
import {
  Anchor,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grid,
  Heading,
  Image,
  Paragraph,
  ResponsiveContext,
  Text,
} from 'grommet';
import styled from 'styled-components';
import LicensePolicyPopup from 'components/Popups/LicensePolicy/LicensePolicy';
import LicensePopup from 'components/Popups/Licence/Licence';
import { useContext, useState } from 'react';
import Banner from 'components/Banner';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import LicenseImg from 'images/licence/license.webp';
import LicenseChildImg from 'images/licence/license_child.webp';

const licenses = [
  // {
  //   id: 0,
  //   title: `Start`,
  //   name: `start`,
  //   subtitle: `Любительская (AGE GROUP)`,
  //   desc: `Подходит для участия в стартах IRONMAN (AGE GROUP), CHALLENGE FAMILY и международных стартах формата ITU`,
  //   price: 3000,
  //   footnote: `Действует 1 год с момента оплаты`,
  //   insurance: `100 000`,
  // },
  // {
  //   id: 1,
  //   title: `Medium`,
  //   name: `medium`,
  //   subtitle: `Любительская (AGE GROUP)`,
  //   desc: `Подходит для участия в стартах IRONMAN (AGE GROUP), CHALLENGE FAMILY и международных стартах формата ITU`,
  //   price: 4000,
  //   footnote: `Действует 1 год с момента оплаты`,
  //   insurance: `300 000`,
  // },
  {
    id: 2,
    title: `Maximum`,
    name: `maximum`,
    subtitle: `Любительская (AGE GROUP)`,
    desc: `Подходит для участия в стартах IRONMAN (AGE GROUP), CHALLENGE FAMILY и международных стартах формата ITU`,
    price: 5000,
    footnote: `Действует 1 год с момента оплаты`,
    insurance: `500 000`,
    img: LicenseImg
  },
  {
    id: 3,
    title: `ONE DAY`,
    name: `day`,
    subtitle: `Любительская (AGE GROUP)`,
    desc: `Действует только в день регистрации на мероприятие`,
    price: 1000,
    footnote: `Действует 1 сутки`,
    insurance: `500 000`,
    img: LicenseImg
  },
  {
    id: 4,
    title: `Детская`,
    name: `child`,
    subtitle: `Детская лицензия триатлета`,
    desc: `Подходит для участия на всех российских стартах. Возраст с 5 лет до 17 лет.`,
    price: 2500,
    footnote: `Действует 1 год с момента оплаты`,
    insurance: `500 000`,
    img: LicenseChildImg
  },
  {
    id: 5,
    title: `Детская ONE DAY`,
    name: `childday`,
    subtitle: `Детская лицензия триатлета`,
    desc: `Подходит для участия на всех российских стартах. Возраст с 5 лет до 17 лет.`,
    price: 500,
    footnote: `Действует 1 сутки`,
    insurance: `500 000`,
    img: LicenseChildImg
  },
];

const sales = [
  {
    id: 0,
    logo: logo5,
    widthLogo: 88,
    title: `TYR Sport Inc. — один из крупнейших в мире производителей товаров для плавания, триатлона и активного отдыха.`,
    desc: `Всем предъявителям лицензии Федерации триатлона России TYR SPORT предоставляет скидку в размере 12% на все ассортимент.`,
    discount: `12`,
    footnote: `На всю продукцию`,
    link: `https://tyr.ru/`,
  },
  {
    id: 1,
    logo: logo6,
    widthLogo: 88,
    title: `Магазин КАНТ — это 20 профессиональных магазинов и крупнейшая дилерская сеть на рынке спортивных товаров в России.`,
    desc: `Для держателей лицензии Федерации триатлона России предоставляется скидка по специальному промокоду.`,
    discount: `до 20`,
    footnote: `На весь ассортимент в магаизнах КАНТ`,
    link: `https://www.kant.ru/`,
  },
  {
    id: 2,
    logo: logo7,
    widthLogo: 88,
    title: `PROSWIM - предоставляет скидки в размере 10% на весь ассортимент сети магазинов Proswim и сайта Proswim.ru при предъявлении лицензий федерации Триатлона России.`,
    desc: ``,
    discount: `10`,
    footnote: `На весь ассортимент сети розничных магазинов и интернет-магазина`,
    link: `https://www.proswim.ru/`,
  },
  {
    id: 3,
    logo: logo8,
    widthLogo: 88,
    title: `Компания ВЕЛОСПОРТ предоставит скидку на приобретение и услуги: Bikefit.ru, CEP, Assos, Shimano - 10%, услуга мастерская - 15%`,
    desc: ``,
    discount: `10`,
    footnote: `На комплексное тестирование`,
    link: `hhttp://velosport.ru/lab/`,
  },
  {
    id: 4,
    logo: logo9,
    widthLogo: 88,
    title: `Всем предъявителям лицензии федерации триатлона России ТМ POLAR предоставляет скидку до 10% на весь ассортимент.`,
    desc: `Скидки не суммируются и не действуют на товары, участвующие в акции.`,
    discount: `до 10`,
    footnote: `На весь ассортимент TM POLAR`,
    link: `https://shoppolar.ru/`,
  },
  {
    id: 5,
    logo: logo10,
    widthLogo: 88,
    title: `Всем предъявителям лицензии федерации триатлона России, магазин PROVELO предоставляет скидку до 10% на весь ассортимент.`,
    desc: ``,
    discount: `10`,
    footnote: `На весь ассортимент в магазине PROVELO`,
    link: `https://www.provelo.ru/`,
  },
  // { id: 6,
  //   logo: logo11,
  //   widthLogo: 88,
  //   title: `Тренировки по триатлону, бегу, плаванию, велоспорту, лыжам, легкой атлетике и ОФП.`,
  //   desc: `Всем предъявителям лицензии федерации триатлона России федеральная система спортивной подготовки ЦИКЛОН предоставляет скидку 10% на все свои услуги.`,
  //   discount: `10`,
  //   footnote: `На занятия в школе бега ЦиклON`,
  //   link: `http://cycleon.ru/`, },
  {
    id: 7,
    logo: logo12,
    widthLogo: 88,
    title: `Лаборатория бега Runlab — это сеть магазинов спортивной экипировки для бега и циклических видов спорта с экспертным подходом`,
    desc: ``,
    discount: `10`,
    footnote: `Скидка 10% для всех держателей лицензий`,
    link: `https://www.runlab.ru/`,
  },
  {
    id: 8,
    logo: logo13,
    widthLogo: 88,
    title: `IRONSTAR объединяет профессионалов и любителей циклических видов спорта.`,
    desc: `Для всех держателей лицензии Федерации триатлона России IRONSTAR предоставляет скидку в размере 7% на покупку стартового слова.`,
    discount: `7`,
    footnote: `Скидка 7% на покупку стартового слота`,
    link: `https://iron-star.com/event/`,
  },
  {
    id: 9,
    logo: logo14,
    widthLogo: 88,
    title: `MySportExpert - делает спорт безопасным.`,
    desc: `Для всех держателей лицензии Федерации триатлона России MySportExpert предоставляет скидку на каждую услугу в размере 10%.`,
    discount: `10`,
    footnote: `Скидка 10% на каждую услугу в MySportExpert`,
    link: `https://mysportexpert.ru/`,
  },
  {
    id: 10,
    logo: logo15,
    widthLogo: 88,
    title: `Science in Sport (SiS) — профессиональное спортивное питание из Великобритании для спорта на выносливость уровня Олимпийскихи Сборных национальных команд.`,
    desc: ``,
    discount: `10`,
    footnote: `Скидка 10% на весь весь ассортимент Science In Sport (SiS)`,
    link: `http://partners.gel4u.ru/`,
  },
  {
    id: 11,
    logo: logo16,
    widthLogo: 88,
    title: `«Детско-юношеская спортивная школа «АКВАТИКС» получила свое название от английского слова «AQUATICS», что означает «водные виды спорта».`,
    desc: ``,
    discount: `10`,
    footnote: `Скидка 10% на занятия Вашего ребенка в школе АКВАТИКС`,
    link: `http://aquatics-sport.com/o-nashey-shkole`,
  },
  {
    id: 12,
    logo: logo17,
    back: `gray`,
    widthLogo: 118,
    title: `Главная задача RAVETAPE – помочь каждому выкладываться на полную, двигаться без страха и боли. И неважно, кто вы: профессиональный спортсмен, любитель или тот, кто не занимается активно спортом. Мы создали RAVETAPE по новым стандартам, с учетом запросов топовых атлетов. При этом наши тейпы невероятно просты в использовании.`,
    desc: ``,
    discount: `20`,
    footnote: `Скидка 20% на всю продукцию`,
    link: `https://ravetape.ru/`,
  },
  {
    id: 13,
    logo: logo18,
    // back: `gray`,
    widthLogo: 118,
    title: `Перкуссионный массажёр Flow - надежный и эффективный помощник в восстановлении атлетов. Снизить риск получения травм, улучшить мобильность суставов, восстановиться и убрать боль и дискомфорт в мышцах - в этом задача Flow.`,
    desc: ``,
    discount: `10`,
    footnote: `Скидка 10% на всю продукцию`,
    link: `https://flowrecovery.ru/`,
  },
];

const LicenseCard = styled(Card)`
  padding: 55px 32px;
  min-height: 700px;
  background-color: #f8f8f8;
  border-radius: 24px;
  box-shadow: none;
`;

const License = () => {
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [licencePopup, setLicencePopup] = useState(false);
  const [licence, setLicence] = useState(null);
  const size = useContext(ResponsiveContext);

  const bread = {
    first: `Главная`,
    second: `Лицензия`,
  };

  const handlePopupLicence = licence => {
    setLicence(licence);
    setLicencePopup(prev => !prev);
  };

  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <Grid
          columns={{ count: `fit`, size: `348px` }}
          gap={`24px`}
          margin={{ bottom: `80px` }}
        >
          {licenses.map(el => (
            <LicenseCard key={el.id}>
              <CardHeader
                direction={`column`}
                align={`start`}
                gap={`0`}
                margin={{ bottom: `72px` }}
              >
                <Heading
                  style={{
                    fontSize: `42px`,
                    textTransform: `uppercase`,
                    marginBottom: `0`,
                  }}
                  color={`#000`}
                >
                  {el.title}
                </Heading>
                <Text
                  style={{
                    fontSize: `12px`,
                    textTransform: `uppercase`,
                    fontWeight: `500`,
                  }}
                  color={`#333`}
                >
                  {el.subtitle}
                </Text>
              </CardHeader>

              <CardBody>
                <Paragraph style={{ fontWeight: `500`, marginBottom: `16px` }}>
                  Включена страховка от несчастных случаев, действительна в 
                  день проведения мероприятия по всему миру <br />с покрытием до{' '}
                  <Text color={`brand`} style={{ fontWeight: `900` }}>
                    {el.insurance} &#8381;
                  </Text>
                </Paragraph>
                <Paragraph size={`small`}>{el.desc}</Paragraph>
              </CardBody>

              <CardFooter direction={`column`} align={`start`} gap={`0`}>
                <Text
                  style={{
                    textTransform: `uppercase`,
                    fontWeight: `500`,
                    marginBottom: `16px`,
                  }}
                  size={`16px`}
                  color={`#333`}
                >
                  Цена:
                </Text>
                <Text
                  style={{ fontWeight: `900`, marginBottom: `16px` }}
                  size={`42px`}
                  color={`#333`}
                >
                  {el.price} &#8381;
                </Text>
                <Text
                  style={{ marginBottom: `32px` }}
                  size={`small`}
                  color={`rgba(0, 0, 0, 0.3)`}
                >
                  {el.footnote}
                </Text>
                <Button
                  onClick={() => handlePopupLicence(el)}
                  primary
                  label={`Оформить лицензию`}
                  style={{
                    padding: `10px`,
                    width: `100%`,
                    textAlign: `center`,
                    rel: `noopener noreferrer`,
                  }}
                />
              </CardFooter>
            </LicenseCard>
          ))}
        </Grid>

        <Heading
          level={`2`}
          color={`#333`}
          margin={{ bottom: `large` }}
          style={{ textTransform: `uppercase` }}
        >
          Лицензия позволит участвовать
        </Heading>
        <Card
          direction={size !== `small` ? `row` : `column`}
          style={{
            backgroundColor: `#F8F8F8`,
            borderRadius: `24px`,
            position: `relative`,
            marginBottom: `32px`,
          }}
        >
          <Box
            width={{ min: `300px` }}
            height={size !== `small` ? `auto` : `250px`}
          >
            <Image
              src={logo1}
              style={{ position: `absolute`, top: `28px`, left: `36px` }}
            />
            <Image src={coverCard1} fit={`cover`} style={{ height: `auto` }} />
          </Box>
          <Box pad={{ top: `48px`, bottom: `32px`, horizontal: `40px` }}>
            <Heading
              size={`32px`}
              color={`#333`}
              style={{
                marginBottom: `24px`,
                textTransform: `uppercase`,
                fontWeight: `900`,
              }}
            >
              Лицензия для участия в стартах триатлона IronMan
            </Heading>
            <Paragraph style={{ marginBottom: `24px`, fontWeight: `500` }} fill>
              Для участия в IRONMAN на любой дистанции необходима лицензия.
            </Paragraph>
            <Paragraph style={{ marginBottom: `80px`, fontSize: `16px` }} fill>
              Если у Вас нет нашей лицензии, Вам необходимо купить однодневную
              лицензию у нас на сайте. Стоимость составит 1000р.
            </Paragraph>
            <Grid columns={[`40%`, `flex`]}>
              <Anchor
                href={`http://ironman.com/events`}
                label={`Список стартов IRONMAN`}
                color={`#000`}
                target="_blank"
                rel="noopener noreferrer"
              />
              <Anchor
                href={`https://ironman.com/races`}
                label={`ironman.com/races`}
                color={`#000`}
                target="_blank"
                rel="noopener noreferrer"
              />
            </Grid>
          </Box>
        </Card>

        <Card
          direction={size !== `small` ? `row` : `column`}
          style={{
            backgroundColor: `#F8F8F8`,
            borderRadius: `24px`,
            position: `relative`,
            marginBottom: `32px`,
          }}
        >
          <Box
            width={{ min: `300px` }}
            height={size !== `small` ? `auto` : `250px`}
          >
            <Image
              src={logo2}
              style={{ position: `absolute`, top: `28px`, left: `36px` }}
            />
            <Image src={coverCard2} fit={`cover`} style={{ height: `100%` }} />
          </Box>
          <Box pad={{ top: `48px`, bottom: `32px`, horizontal: `40px` }}>
            <Heading
              size={`32px`}
              color={`#333`}
              style={{
                marginBottom: `24px`,
                textTransform: `uppercase`,
                fontWeight: `900`,
              }}
            >
              Участвуйте в Официальных стартах формата ITU
            </Heading>
            <Paragraph style={{ marginBottom: `80px`, fontSize: `16px` }} fill>
              TU - Международный союз триатлона. Соревнования проходящие под его
              эгидой являются престижнейшими соревнованиями по триатлону в мире.
              Именно на конгрессе ITU была утверждена Олимпийская дистанция и
              под его эгидой впервые был проведен чемпионат мира по триатлону.
            </Paragraph>
            <Paragraph style={{ marginBottom: `80px`, fontSize: `16px` }} fill>
              <Text style={{ fontWeight: `500` }}>
                Имея лицензию Федерации Триатлона России
              </Text>{' '}
              Вы вместе с профессиональными спортсменами сможете заявиться на
              международные соревнования по триатлону, проходящие под эгидой ITU
              и выступить за любительскую национальную сборную России по
              триатлону.
            </Paragraph>
            <Grid columns={[`40%`, `flex`]}>
              <Anchor
                href={`https://www.triathlon.org/events`}
                label={`Список стартов ITU`}
                color={`#000`}
                target="_blank"
                rel="noopener noreferrer"
              />
              <Anchor
                href={`https://www.triathlon.org`}
                label={`www.triathlon.org`}
                color={`#000`}
                target="_blank"
                rel="noopener noreferrer"
              />
            </Grid>
          </Box>
        </Card>

       {/* <Card
          direction={size !== `small` ? `row` : `column`}
          style={{
            backgroundColor: `#F8F8F8`,
            borderRadius: `24px`,
            position: `relative`,
            marginBottom: `80px`,
          }}
        >
          <Box
            width={{ min: `300px` }}
            height={size !== `small` ? `auto` : `250px`}
          >
            <Image
              src={logo3}
              style={{ position: `absolute`, top: `28px`, left: `36px` }}
            />
            <Image src={coverCard3} fit={`cover`} style={{ height: `100%` }} />
          </Box>*/}
          {/*<Box pad={{ top: `48px`, bottom: `32px`, horizontal: `40px` }}>
            <Heading
              size={`32px`}
              color={`#333`}
              style={{
                marginBottom: `24px`,
                textTransform: `uppercase`,
                fontWeight: `900`,
              }}
            >
              Стань участником серии стартов challenge
            </Heading>
            <Paragraph style={{ marginBottom: `24px`, fontSize: `16px` }} fill>
              Challenge Family — один из основных организаторов стартов по
              триатлону в Европе с главным стартом в немецком городе Рот. Именно
              здесь в 2016 году самое быстрое на данный момент время в истории
              железного триатлона показал немец Ян Фродено — 7:35:39
            </Paragraph>
            <Paragraph style={{ marginBottom: `24px`, fontSize: `16px` }} fill>
              Challenge Family - это ярчайшие соревнования по триатлону в самых
              живописных местах планеты и огромным количеством участников
            </Paragraph>
            <Paragraph style={{ marginBottom: `75px`, fontSize: `16px` }} fill>
              <Text style={{ fontWeight: `500` }}>
                Имея лицензию Федерации Триатлона России
              </Text>{' '}
              Вы сможете принять в них участие и представить Россию в составе
              национальной любительской сборной.
            </Paragraph>
            <Grid columns={[`40%`, `flex`]}>
              <Anchor
                href={`http://www.challenge-family.com/races/`}
                label={`Список стартов Challenge Triathlon`}
                color={`#000`}
                target="_blank"
                rel="noopener noreferrer"
              />
              <Anchor
                href={`https://www.challenge-family.com`}
                label={`www.challenge-family.com`}
                color={`#000`}
                target="_blank"
                rel="noopener noreferrer"
              />
            </Grid>
          </Box>*/}
        {/*</Card>*/}

        <Heading
          level={`2`}
          color={`#333`}
          margin={{ bottom: `large` }}
          style={{ textTransform: `uppercase` }}
        >
          Лицензия Фтр = страховка
        </Heading>
{/*        <div
          style={{
            columns: `${size !== 'small' ? '2 auto' : '1 auto'}`,
            columnGap: `80px`,
            marginBottom: `40px`,
          }}
        >
          <Paragraph color={`#453F3F`} style={{ marginBottom: `24px` }} fill>
            При оформлении любительской лицензии ФТР, спортсмену оформляется
            страховой полис на срок действия документа в компании "РЕСО-
            Гарантия".
          </Paragraph>
          <Paragraph color={`#453F3F`} style={{ marginBottom: `24px` }} fill>
            Базовая страховка (включена в лицензию по умолчанию) имеет страховое
            покрытие до 100.000 рублей. Страховое покрытие можно увеличить на
            любую сумму при оформлении лицензии.
          </Paragraph>
          <Paragraph color={`#453F3F`} style={{ marginBottom: `24px` }} fill>
            Территория страхового покрытия - весь мир, кроме территорий, в
            которых объявлено чрезвычайное положение, либо проводятся боевые
            действия.
          </Paragraph>
          <Paragraph color={`#453F3F`} style={{ marginBottom: `24px` }} fill>
            Страховая защита действует 24 часа в сутки во время участия
            застрахованных лиц в физкультурных и спортивных мероприятиях по
            следующему виду спорта: триатлон, велоспорт, плавание, легкая
            атлетика, лыжный спорт, биатлон.
          </Paragraph>
          <Paragraph color={`#453F3F`} style={{ marginBottom: `24px` }} fill>
            Номер страхового полиса будет отображен на лицензии при изготовлении
            карты спортсмена.
          </Paragraph>
          <Paragraph color={`#453F3F`} style={{ marginBottom: `24px` }} fill>
            При наступлении страхового случая Застрахованный подаёт Страховщику
            заявление на выплату страхового обеспечения.
          </Paragraph>
          <Paragraph color={`#453F3F`} style={{ marginBottom: `24px` }} fill>
            Страховщик производит выплату в течение 7 банковских дней.
          </Paragraph>
        </div>*/}

{/*        <Box
          direction={size !== `small` ? `row` : `column`}
          gap={`32px`}
          align={`center`}
          pad={{ vertical: `45px`, horizontal: `72px` }}
          style={{
            backgroundColor: `#F8F8F8`,
            borderRadius: `24px`,
            position: `relative`,
            marginBottom: `80px`,
          }}
        >
          <Box
            style={{ marginRight: `86px`, width: `330px`, minWidth: `auto` }}
          >
            <Image src={logo4} />
          </Box>
          <Button
            href={`https://yadi.sk/i/1VhQk2AYAOr4vw`}
            label={`Скачать договор страхования`}
            style={{
              width: `100%`,
              textAlign: `center`,
              rel: `noopener noreferrer`,
            }}
            target={`_blank`}
            primary
          />
          <Button
            label={`Полис «Туризм»`}
            onClick={() => setOpenPopup(true)}
            style={{ width: `100%` }}
            primary
          />
        </Box>*/}

        <Heading
          level={`2`}
          color={`#333`}
          margin={{ bottom: `large` }}
          style={{ textTransform: `uppercase` }}
        >
          Пользуйтесь привилегиями
        </Heading>
        <Grid
          columns={{ count: `fit`, size: `250px` }}
          gap={`24px`}
          margin={{ bottom: `80px` }}
        >
          {sales.map(el => (
            <Card
              key={el.id}
              style={{
                backgroundColor: `#F8F8F8`,
                borderRadius: `24px`,
                boxShadow: `none`,
                maxWidth: `450px`,
              }}
              pad={`32px`}
            >
              <Box
                margin={{ bottom: `26px` }}
                pad={`xsmall`}
                height={{ min: `60px` }}
                background={el.back || `transparent`}
                style={{ width: `max-content`, borderRadius: `17px` }}
                justify={'center'}
              >
                <Image src={el.logo} width={el.widthLogo} />
              </Box>
              <Heading
                level={3}
                size={`16px`}
                style={{ fontWeight: `500px`, marginBottom: `16px` }}
              >
                {el.title}
              </Heading>
              <Paragraph
                style={{
                  fontSize: `12px`,
                  marginBottom: `16px`,
                  lineHeight: `1.2`,
                }}
                color={`#333`}
              >
                {el.desc}
              </Paragraph>
              <Box
                style={{
                  marginTop: `auto`,
                  marginBottom: `32px`,
                  minHeight: `65px`,
                }}
              >
                <Text
                  style={{ textTransform: `uppercase`, fontWeight: `700` }}
                  color={`brand`}
                >
                  Скидка {el.discount}%
                </Text>
                <Text size={`12px`}>{el.footnote}</Text>
              </Box>
              <Button
                href={el.link}
                primary
                label={`На сайт`}
                style={{
                  width: `100%`,
                  textAlign: `center`,
                  rel: `noopener noreferrer`,
                }}
                target={`_blank`}
              />
            </Card>
          ))}
        </Grid>
      </Container>

      {isOpenPopup && <LicensePolicyPopup statePopupHandler={setOpenPopup} />}
      {licencePopup && (
        <LicensePopup closePopup={handlePopupLicence} licence={licence} />
      )}
    </>
  );
};

export default License;
