import {
  Anchor,
  Box,
  Grid,
  Heading,
  Image,
  ResponsiveContext,
  Text,
} from "grommet";
import { useContext } from "react";
import { partnerLogosArr } from "../../mocks/mocks";
import { mainPartnerLogos } from "../../mocks/mocks";

const Partners = () => {
  const size = useContext(ResponsiveContext);

  let count = 5;
  let width;
  if (size === "medium") {
    count = 4;
    width = "140";
  }
  if (size === "small") {
    count = 2;
    width = "120";
  }

  return (
    <Box pad={{ vertical: "66px" }} gap="66px">
      <Heading level={2} style={{ textTransform: `uppercase` }}>
        Партнёры
      </Heading>
      <Grid columns={{ count: `fit`, size: `200px` }} gap={{ row: `90px` }}>
        <Box gap="100px">
          <Grid
            columns={{ count: `fit`, size: `200px` }}
            gap={{ row: `80px`, column: `24px` }}
          >
            {mainPartnerLogos.map((item) => (
              <Box justify={`center`} key={item.id} align={`center`}>
                <Anchor
                  color={`black`}
                  href={item.path}
                  target={`_blank`}
                  rel={`noopener noreferrer`}
                >
                  <Image
                    src={item.logo}
                    width={item.width}
                    height={item.height}
                  />
                </Anchor>
                <Box pad="small" align="center">
                  <Text
                    textAlign="center"
                    size="small"
                    weight="bold"
                    color="black"
                  >
                    {item.title}
                  </Text>
                </Box>
              </Box>
            ))}
          </Grid>
          <Grid
            columns={{
              count: count,
              size: "100px",
            }}
            gap="small"
          >
            {partnerLogosArr.map((item) => (
              <Box
                justify={`center`}
                align={`center`}
                key={item.id}
                elevation={`none`}
              >
                <Image
                  src={item.logo}
                  width={size !== "large" ? width : item.width}
                  height={item.height}
                />
              </Box>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export default Partners;
