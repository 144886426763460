import api from 'api';
import { dispatch } from 'store';
import * as types from '../types/protocols';

const getAllProtocols = async () => {
  try {
    const { data } = await api.get('/server/document/type/protocol');
    dispatch({ type: types.PROTOCOLS_GET_ALL_ITEMS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export default getAllProtocols;
