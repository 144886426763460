import abrosimovaPhoto from '../../images/mainCast/abrosimova.jpg';
import golofeevaPhoto from '../../images/mainCast/golofeeva.jpg';
import gorbunovaPhoto from '../../images/mainCast/gorbunova.jpg';
import danilovaPhoto from '../../images/mainCast/danilova.jpg';
import zhizhinaPhoto from '../../images/mainCast/zhizhina.jpg';
import razarenovaPhoto from '../../images/mainCast/razarenova.jpg';
import isakovaPhoto from '../../images/mainCast/isakova.jpg';
import vasilievPhoto from '../../images/mainCast/vasiliev.jpg';
import polyanskayaPhoto from '../../images/mainCast/polyanskaya.jpg';
import ryasovaPhoto from '../../images/mainCast/ryasova.png';
import antipovPhoto from '../../images/mainCast/antipov.jpg';
import vasilievDPhoto from '../../images/mainCast/vasiliev-d.jpg';
import kozlovDPhoto from '../../images/mainCast/kozlov.jpg';
import myrzinDPhoto from '../../images/mainCast/myrzin.jpg';
import polanskyDPhoto from '../../images/mainCast/polansky.jpg';
import polanskyIDPhoto from '../../images/mainCast/polansky-i.jpg';
import ponomarevPhoto from '../../images/mainCast/ponomarev.jpg';
import prasolovPhoto from '../../images/mainCast/prasolov.jpg';
// import turbaevskyPhoto from '../../images/mainCast/turbaevsky.jpg';
import coverImg from '../../images/cover4.jpg';
import AthleteCards from '../../components/AthleteCards/AthleteCards';
import Container from 'components/Container/Container';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Banner from 'components/Banner';

export const teamArr = [
  {
    id: 0,
    title: `Мсмк`,
    name: `Абросимова Анастасия Александровна`,
    photo: abrosimovaPhoto,
    dateBirth: '17/07/1990',
    about: [
      { id: 0, text: `Регион - Москва, Красноярский край` },
      {
        id: 1,
        text: `Организация - ГБПОУ МСС УОР№2, КГАУ РЦСП АЛВС ФСО - ЦСКА`,
      },
      { id: 2, text: `Первый тренер в триатлоне - Зуев А.Г.` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Зуев А.Г., Парфенцева Т.М., Рожков Г.Н.`,
      },
      { id: 4, text: `Хобби - кулинария` },
    ],
  },
  {
    id: 1,
    title: `Мс`,
    name: `Голофеева Юлия Сергеевна`,
    photo: golofeevaPhoto,
    dateBirth: '25/12/1996',
    about: [
      { id: 0, text: `Регион - Москва, Новосибирская область` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2, ЦОП КОР-3 ФСО - ЦСКА` },
      { id: 2, text: `Первый тренер в триатлоне - Яценко С.Л.` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Генералова О.Н., Фетисов А.Е.`,
      },
      { id: 4, text: `Хобби - чтение литературы` },
    ],
  },
  {
    id: 2,
    title: `Мс`,
    name: `Горбунова Анастасия Олеговна`,
    photo: gorbunovaPhoto,
    dateBirth: '17/05/1994',
    about: [
      { id: 0, text: `Регион - Пензенская область, Санкт-Петербург` },
      { id: 1, text: `Организация - ЦСП ПО, ШВСМ ВВС` },
      { id: 2, text: `Первый тренер в триатлоне - Горбунов О.Б.` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Горбунов О.Б., Титова О.В., Цыганов В.Н.`,
      },
    ],
  },
  {
    id: 4,
    title: `Мс`,
    name: `Жижина Елизавета Владимировна`,
    photo: zhizhinaPhoto,
    dateBirth: '24/05/1998',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Ярославская область` },
      { id: 1, text: `Организация - УОР-1` },
      { id: 2, text: `Первый тренер в триатлоне - Васильев М.А.` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Гижа А.А., Титова О.В., Гааг Д.В.`,
      },
    ],
  },
  {
    id: 5,
    title: `Мс`,
    name: `Исакова Диана Александровна`,
    photo: isakovaPhoto,
    dateBirth: '05/02/1999',
    about: [
      { id: 0, text: `Регион - Москва` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Фетисов А.Е., Бутков Д.А., Генералова О.Н.`,
      },
    ],
  },
  {
    id: 6,
    title: `Мс`,
    name: `Полянская Анастасия Александровна`,
    photo: polyanskayaPhoto,
    dateBirth: '22/12/1993',
    about: [
      { id: 0, text: `Регион - Москва, Красноярский край` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2` },
      { id: 2, text: `Первый тренер в триатлоне - Антошина И.Н.` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Фетисов А.Е., Мусиенко В.И.`,
      },
    ],
  },
  {
    id: 7,
    title: `Мсмк`,
    name: `Разаренова Александра Германовна`,
    photo: razarenovaPhoto,
    dateBirth: '17/07/1990',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Саратов` },
      { id: 1, text: `Организация - "Динамо", УОР № 1` },
      { id: 2, text: `Первый тренер в триатлоне - Васильев А.Г.` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Титова О.В., Мажухин И.А., Гижа А.А., Логинов О.А.`,
      },
      { id: 4, text: `Хобби - нумизматика, просмотр советских детективов` },
    ],
  },
  {
    id: 8,
    title: `Мс`,
    name: `Рясова Валентина Александровна`,
    photo: ryasovaPhoto,
    dateBirth: '24/05/1998',
    about: [
      { id: 0, text: `Регион - Ростовская область, Санкт-Петербург` },
      { id: 1, text: `Организация: ГБУ РО "СШОР-5", ШВСМ ПО ВВС` },
      { id: 2, text: `Первый тренер триатлоне - Коноваленко В.В.` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Коноваленко В.В., Заикин М.Ю., Гааг Д.В., Парфенцева Т.М.`,
      },
      { id: 4, text: `Хобби - маникюр, рукоделие, рисование по номерам` },
    ],
  },
  {
    id: 9,
    title: `Мс`,
    name: `Антипов Григорий Алексеевич`,
    photo: antipovPhoto,
    dateBirth: '13/12/1999',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Москва` },
      { id: 1, text: `Организация - ШВСМ по ВВС` },
      { id: 2, text: `Тренер в настоящий момент - Зуев А.Г., Гааг Д.В.` },
    ],
  },
  {
    id: 11,
    title: `Мсмк`,
    name: `Васильев Денис Михайлович`,
    photo: vasilievDPhoto,
    dateBirth: '13/03/1989',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Ярославская область` },
      { id: 1, text: `Организация - ШВСМ по ВВС` },
      { id: 2, text: `ФСО - ВС` },
      { id: 3, text: `Первый тренер триатлоне - Васильев И.М.` },
      {
        id: 4,
        text: `Тренер в настоящий момент - Васильев И.М., Сапожников В.П., Парфенцева Т.М.`,
      },
    ],
  },
  {
    id: 12,
    title: `Мс`,
    name: `Козлов Антон Сергеевич`,
    photo: kozlovDPhoto,
    dateBirth: '11/04/1991',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Ленинградская область` },
      { id: 1, text: `Организация - Динамо, УОР No1` },
      { id: 2, text: `Первый тренер в триатлоне - Шульгин` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Гижа А.А., Титова О.В., Логинов О.А., Павлов С.В.`,
      },
    ],
  },
  {
    id: 13,
    title: `Кмс`,
    name: `Мырзин Сергей Игоревич`,
    photo: myrzinDPhoto,
    dateBirth: '04/03/1996',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Саратовская область` },
      { id: 1, text: `Организация - Динамо, УОР No1` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Титова О.В., Логинов О.А., Савельев Д.И., Парфенцева Т.М.`,
      },
    ],
  },
  {
    id: 14,
    title: `Змс`,
    name: `Полянский Дмитрий Андреевич`,
    photo: polanskyDPhoto,
    dateBirth: '19/11/1986',
    about: [
      { id: 0, text: `Регион - Москва, Красноярский край` },
      { id: 1, text: `Организация - ГБПОУ МСС УОР№2, КГАУ"РЦСП АЛВС"` },
      { id: 2, text: `Первый тренер в триатлоне - Христофоров А.Н.` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Багазеев А.К., Васильев И.М., Васильев Д.М., Христофоров А.Н.`,
      },
      {
        id: 4,
        text: `Хобби - книги, история родной земли, компьютерные технологии и игры, шахматы`,
      },
    ],
  },
  // {
  //   id: 15,
  //   title: `Мсмк`,
  //   name: `Полянский Игорь Андреевич`,
  //   photo: polanskyIDPhoto,
  //   dateBirth: '16/01/1990',
  //   about: [
  //     { id: 0, text: `Регион - Москва, Красноярскский край` },
  //     { id: 1, text: `Организация - ГБПОУ МСС УОР№2, КГАУ"РЦСП АЛВС"` },
  //     { id: 2, text: `Первый тренер в триатлоне - Христофоров А.Н.` },
  //     { id: 3, text: `Тренер в настоящий момент - Васильев И.М., Васильев Д.М., Христофоров А.Н., Парфенцева Т.М.` },
  //     { id: 4, text: `Хобби - компьютерные игры` },
  //   ],
  // },
  {
    id: 17,
    title: `Мс`,
    name: `Прасолов Илья Алексеевич`,
    photo: prasolovPhoto,
    dateBirth: '02/08/1994',
    about: [
      { id: 0, text: `Регион - Москва, Новосибирская область` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2, ЦОП КОР-3` },
      { id: 2, text: `ФСО - ЦСКА` },
      { id: 3, text: `Первый тренер в триатлоне - Пикалов О.А.` },
      {
        id: 4,
        text: `Тренер в настоящий момент - Фетисов А.Е., Парфенцева Т.М., Павлов С.В.`,
      },
      { id: 4, text: `Хобби - чтение различной литературы` },
    ],
  },
];

const MainCast = () => {
  return (
    <>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />
        <AthleteCards athletesArr={teamArr} />
      </Container>
    </>
  );
};

export default MainCast;
