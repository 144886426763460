import news070421 from 'images/News/070421.jpeg';
import Article from 'pages/Articles/Article';
import styled from 'styled-components';
import { Paragraph } from 'grommet';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const articte070421 = () => {
  return (
    <Article
      title={`Окончен набор слушателей на обучение «Тренер в избранном виде спорта. Триатлон»`}
      date={`07 Апреля 2021`}
      coverBack={news070421}
    >
      <CustomParagraph>
        <b>Окончен набор слушателей на обучение по программе профессиональной переподготовки «Тренер в избранном виде спорта. Триатлон»</b>
      </CustomParagraph>
      <CustomParagraph>
      <i>На программу заявилось более 80 человек со всей страны. Некоторые из них уже имели профессиональный опыт тренерской работы, какая-то часть только выступал на любительских стартах. Всего на программу отобрано 25 слушателей.</i>
      </CustomParagraph>
      <CustomParagraph>
      Процесс отбора состоял из двух этапов: анкетирование и собеседование с представителями Федерации триатлона России, на котором основные вопросы касались мотивации претендентов стать тренером в области триатлона. Также представители Федерации опрашивали потенциальных слушателей о том, как они намерены использовать навыки, полученные в результате обучения на программе переподготовки.
      </CustomParagraph>
      <CustomParagraph>
      Большинство слушателей, отвечая на вопросы в рамках собеседования, отмечали, что их основной мотивацией является общее развитие триатлона в своем регионе. Появление в городах сертифицированных тренеров – это первый шаг к тому, чтобы начали появляться новые спортсмены, новые любители и энтузиасты триатлона. Что касается дальнейших планов после завершения программы, слушатели особенно выделяли, что хотят посвятить себя подготовке юных триатлетов, а также созданию своих любительских триатлонных клубов.
      </CustomParagraph>
      <CustomParagraph>
      Следует отметить, что несмотря на то, что льготные места от Федерации триатлона России уже заняты, по-прежнему сохраняется возможность прослушать курс от ведущих специалистов за счет средств организации, где культивируется триатлон (региональные федерации, ассоциации, триатлонные клубы), или за счет собственных средств. На данный момент, уже 5 слушателей оценили пользу данной программы и зарегистрировались сверх квоты Федерации триатлона России.
      </CustomParagraph>
      <CustomParagraph>
      Напомним, программа профессиональной переподготовки «Тренер в избранном виде спорта. Триатлон» стартует с 12 апреля. Основными темами курса станут педагогика и психология в области физкультуры и спорта, ключевые аспекты антидопинговой деятельности, правовые основы и инновации в профессиональной деятельности тренера, физиология человека: общая, возрастная, спортивная. В рамках курса также подразумевается наличие двух модулей «Летний триатлон» и «Зимний триатлон». Программа рассчитана на 272 часа, а общий срок обучения составит 9 недель. Данный период будет разделен на несколько этапов: дистанционное обучение (лекционные и практические занятия), практические занятия (очные), а в завершении курса слушатели должны будут пройти практическую стажировку и подготовить итоговую аттестационную работу.
      </CustomParagraph>
      <CustomParagraph>
      Преподавателями программы станут ведущие практикующие специалисты в области триатлона, в том числе из зарубежных стран, доценты и профессоры Поволжской Государственной Академии Физической культуры, спорта и туризма, кандидаты биологических, медицинских и педагогических наук.
      </CustomParagraph>
    </Article>
  )
}

export default articte070421;
