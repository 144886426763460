import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Paragraph,
  ResponsiveContext,
  Grid,
  Card,
} from 'grommet';
import { routes } from 'const/routes';
import Container from 'components/Container/Container';
import Calendar from 'components/Calendar/Calendar';
import Content from 'components/News/Content/Content';
import { ReactComponent as ArrowRight } from 'images/svg/arrow-right.svg';
import api from 'api';
import history from '../../history';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import { columns } from 'grid-layout/layout';
// import mainBackground from 'images/News/newsPreview.jpg';
// import Author from 'components/Author/Author';
// import CardsFormat from 'components/News/CardFormat/CardFormat';
// import TableNews from 'components/News/TableNews/TableNews';

const CustomLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  font-size: 1.125rem;
  line-height: 1.333;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const filteredNewsById = (news, id) => {
  return news.filter(n => n.part !== id);
};

const News = ({ match }) => {
  const size = useContext(ResponsiveContext);
  const [data, setData] = useState(null);
  const [allNews, setAllNews] = useState(null);
  const { id } = match.match.params;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/server/one/news/${id}`);
        if (response.data) setData(response.data);
        else history.replace(routes.error.path);
      } catch (error) {
        history.replace(routes.error.path);
      }
    };

    if (data === null) fetchData();
  }, [id, data]);

  useEffect(() => {
    const loadAllNews = async () => {
      try {
        const response = await api.get(`/server/list/news`);
        if (response.status === 200) setAllNews(response.data);
        else console.error(response);
      } catch (error) {
        console.error(error);
      }
    };

    if (allNews === null) loadAllNews();
  }, [allNews]);

  // console.log(allNews);
  if (data === null) return null;
  return (
    <Box>
      <Box
        as="section"
        background={{
          image: `url(${data.picture})`,
          color: `rgba(0, 0, 0, 0.5)`,
        }}
        style={{ backgroundBlendMode: `multiply` }}
        pad={{ top: `xlarge`, bottom: `xlarge` }}
        height={{ min: size === `small` ? `500px` : `740px` }}
      >
        <Box
          flex={{ grow: 1 }}
          width={`1240px`}
          pad={{ horizontal: `medium` }}
          margin={{ horizontal: `auto` }}
        >
          <Box
            direction="row"
            align="center"
            justify="between"
            margin={{ bottom: `large` }}
          >
            <Text size={`medium`} margin={{ right: `medium` }}>
              {data.public_date}
            </Text>
            {
              //<Button primary color="#FFFFFF">Поделиться</Button>
            }
          </Box>
          <Heading level={1} margin={{ top: `auto` }} size={`medium`}>
            {data.title}
          </Heading>
          {size !== `small` && (
            <Paragraph margin={{ top: `medium` }} style={{ maxWidth: `800px` }}>
              {data.subtitle}
            </Paragraph>
          )}
        </Box>
      </Box>

      <Content text={data.text} />

      <Container>
        {/* <CardsFormat title={`Дистанции:`} /> */}

        {/* <TableNews title={`15 января, пятница – день приезда`} /> */}

        {/* <Author /> */}

        {Array.isArray(allNews) && allNews.length > 0 && (
          <Box fill margin={{ bottom: `72px` }}>
            <Heading level={2} size={`32px`} margin={{ bottom: `large` }}>
              Читайте также
            </Heading>

            <ResponsiveContext.Consumer>
              {size => (
                <Grid
                  fill
                  gap={`medium`}
                  columns={{ count: `fit`, size: `400px` }}
                  as={`ul`}
                >
                  {filteredNewsById(allNews, id)
                    .slice(-2)
                    .map(news => (
                      <Card
                        key={news.part}
                        as={`li`}
                        elevation={`none`}
                        background={{ color: `light-1` }}
                        height={`170px`}
                        pad={{ vertical: `medium`, horizontal: `34px` }}
                        justify={`between`}
                      >
                        <Text size={`12px`}>{news.public_date}</Text>
                        <CustomLink to={`/news/${news.part}`}>
                          <Text
                            size={size === `small` ? `small` : `medium`}
                            style={{ maxWidth: `36rem` }}
                          >
                            {news.title}
                          </Text>
                          <ArrowRight
                            style={{ flexShrink: 0, marginLeft: `1rem` }}
                          />
                        </CustomLink>
                      </Card>
                    ))}
                </Grid>
              )}
            </ResponsiveContext.Consumer>
          </Box>
        )}
      </Container>

      <Calendar />
    </Box>
  );
};

export default News;
