import { useState } from 'react';
import { Box, Heading, Button, ResponsiveContext, Image, Text } from 'grommet';
import BookImage from 'images/book.png';
import styled from 'styled-components';
import BookPopup from 'components/Popups/Book';

const CustomHeading = styled(Heading)`
  text-transform: uppercase;
`;

const CustomBtn = styled(Button)`
  padding: 6px 24px;
  max-width: 96px;
`;

const CustomParagraph = styled(Text)`
  margin-top: 14px;
  margin-bottom: 14px;
  font-size: 14px;
`;

const Book = () => {
  const [isOpenPopup, setOpenPopup] = useState(false);
  const sizes = [`small`, `tablet`,];

  return (
    <div>
      <ResponsiveContext.Consumer>
        {size => (
          <Box
            height={`auto`}
            pad={`small`}
            direction={!sizes.includes(size) ? 'row' : 'column'}
            gap={`large`}
          >
            <Box height="250px" margin={`small`}>
              <Image src={BookImage} fit="contain" />
            </Box>
            <Box height="auto" margin={`medium`} width={{max: !sizes.includes(size) ? '50%' : '100%'}}>
              <CustomHeading level={5} size={`small`}>
                Книга "Триатлон. История"
              </CustomHeading>
              <CustomParagraph>
                Это первая в России книга об истории триатлона. Как этот вид
                спорта зарождался в мире и как пришел к нам. В середине 80-х в
                СССР триатлоном загорелись сотни энтузиастов в разных городах и
                республиках сначала на любительском уровне, а в 1989 году
                появилась профессиональная сборная. В этом издании живые
                истории, множество фотографий и документов.
              </CustomParagraph>
              <CustomParagraph>
                Среди авторов люди, которые и сами когда-то стояли у истоков
                зарождения этого вида спорта у нас в стране. Иногда это взгляд
                со стороны, иногда рассказ от первого лица, может где-то
                субъективный и эмоциональный, но точно неравнодушный. Книга
                будет интересна самой широкой аудитории: от тех, кто
                только-только задумывает пройти свою первую гонку до
                состоявшихся спортсменов.
              </CustomParagraph>
              <CustomParagraph>
              Доставка книги (кроме доставок в пределах Москвы) осуществляется Почтой России.
              </CustomParagraph>
              <CustomParagraph>
                <b>Цена: 3500 рублей</b>
              </CustomParagraph>

              <CustomBtn
                type={`submit`}
                primary
                margin={{ top: `medium` }}
                onClick={setOpenPopup}
              >
                Купить
              </CustomBtn>

              {isOpenPopup && <BookPopup statePopupHandler={setOpenPopup} />}
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </div>
  );
};

export default Book;
