import {
  Box,
  Heading,
  Image,
  List,
  Paragraph,
  ResponsiveContext,
  Text,
} from 'grommet';
import { useEffect, useState } from 'react';
import { useLoads } from 'customHooks/useLoads';
import coverImg from '../../images/cover4.jpg';
import Container from '../../components/Container/Container';
import { useContext } from 'react';
import pdfIcon from 'images/pdf.png';
import { routes } from '../../const/routes';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { documentsLinks } from 'pages/Documents/Documents';
import Partners from 'components/Partners/Partners';
import Banner from 'components/Banner';

const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.3);
  text-decoration: none;
  font-weight: normal;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledDocLink = styled.a`
  color: rgb(219, 76, 76);
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const renderTitle = type => {
  return documentsLinks.filter(el => el.link === type)[0].title;
};

const DocumentsPage = ({ match }) => {
  const size = useContext(ResponsiveContext);
  const { type } = match.params;
  const [documents] = useLoads(`/server/document/list`);
  const [filteredDoc, setFilterDoc] = useState([]);

  useEffect(() => {
    if (documents) {
      const filteredDoc = documents.filter(doc => doc.document_type === type);
      setFilterDoc(filteredDoc);
    }
  }, [documents, type]);

  console.log(filteredDoc);
  if (filteredDoc.length === 0) return null;
  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Box
          margin={{ bottom: `64px` }}
          pad={
            [`desktop1900`, `medium`].includes(size)
              ? { top: `56px`, bottom: `80px`, horizontal: `80px` }
              : { top: `26px`, bottom: `20px`, horizontal: `20px` }
          }
          background={{ color: `#F8F8F8` }}
          round={`small`}
        >
          <Paragraph
            margin={{ bottom: `82px` }}
            color={`rgba(0, 0, 0, 0.3)`}
            style={{ maxWidth: `555px` }}
          >
            <StyledLink to={routes.main.path}>Главная</StyledLink>
            <Text> &gt; </Text>
            <StyledLink to={routes.documents.path}>Документы</StyledLink>
            <Text> &gt; </Text>
            <Text>{renderTitle(type)}</Text>
          </Paragraph>
          {console.log(size)}
          <Heading
            level={`1`}
            size={[`desktop1900`, `medium`].includes(size) ? `56px` : `28px`}
            style={{ textTransform: `uppercase`, color: `#000000` }}
          >
            {renderTitle(type)}
          </Heading>
        </Box>

        <Box style={{ maxWidth: `1190px` }}>
          <List data={filteredDoc} pad="medium" border={false}>
            {filteredDoc => (
              <Box direction="row-responsive" gap="medium" align="center">
                <Image src={pdfIcon} width={`55`} />
                <StyledDocLink
                  target="_blank"
                  rel="noreferrer"
                  href={filteredDoc.link}
                  style={{
                    fontSize: `16px`,
                    marginTop: `20px`,
                    marginBottom: `20px`,
                  }}
                >
                  {filteredDoc.title}
                </StyledDocLink>
              </Box>
            )}
          </List>
        </Box>

        <Partners />
      </Container>
    </>
  );
};

export default DocumentsPage;
