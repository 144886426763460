import { useEffect, useState, useContext } from 'react';
import api from 'api';
import {
  Box,
  Heading,
  Grid,
  Card,
  ResponsiveContext,
  Text,
  Button,
} from 'grommet';
import { columns } from 'grid-layout/layout';
import CardBackground1 from 'images/News/news-cover1.jpg';
import CardBackground2 from 'images/News/news-cover2.jpg';
import CardBackground3 from 'images/News/news-cover3.jpg';
import CardBackground4 from 'images/News/news-cover4.jpg';
import CardBackground5 from 'images/News/news-cover5.jpg';
import CardBackground6 from 'images/News/news-cover6.jpg';
import news10032101 from 'images/News/10032101.jpeg';
import news10032100 from 'images/News/10032100.webp';
import news150321 from 'images/News/100days.JPG';
import news220321 from 'images/News/220321.jpeg';
import news280321 from 'images/News/280321.jpeg';
import news070421 from 'images/News/070421.jpeg';
import news190421 from 'images/News/190421.jpeg';
import news200421 from 'images/News/200421.jpeg';
import { routes } from 'const/routes';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CustomLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin-top: auto;

  &:hover {
    text-decoration: underline;
  }
`;

const CustomLinkBlack = styled(Link)`
  color: black;
  text-decoration: none;
  margin-top: auto;

  &:hover {
    text-decoration: underline;
  }
`;

const CustomGrid = styled(Grid)`
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;

  & div:first-child {
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
  }

  @media (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 991px) {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

    & div:first-child {
      grid-row: auto;
      grid-column: auto;
    }
  }
`;

export const newsArr = [
  {
    id: 13,
    title: `Президиумом ФТР утверждены критерии и условия отбора кандидатов на международные соревнования в 2021 году`,
    text: ``,
    image: news200421,
    link: routes.news200421.path,
    date: `20.04.2021`,
  },
  {
    id: 12,
    title: `Открытие отделения по направлению «Триатлон»`,
    text: `Национальный Государственный университет им П.Ф. Лесгафта в Санкт-Петербурге совместно с Федерацией триатлона России объявляет об открытии отделения по направлению «Триатлон»`,
    image: news190421,
    link: routes.news190421.path,
    date: `19.04.2021`,
  },
  {
    id: 11,
    title: `Окончен набор слушателей на обучение «Тренер в избранном виде спорта. Триатлон»`,
    text: `Окончен набор слушателей на обучение по программе профессиональной переподготовки «Тренер в избранном виде спорта. Триатлон»`,
    image: news070421,
    link: routes.news070421.path,
    date: `07.04.2021`,
  },
  {
    id: 10,
    title: `Финал Кубка России по зимнему триатлону и соревнования среди любителей TRI59`,
    text: `27 марта в г. Березники (Пермский край) состоялся Финал Кубка России по зимнему триатлону и соревнования среди любителей TRI59`,
    image: news280321,
    link: routes.news280321.path,
    date: `28.03.2021`,
  },
  {
    id: 9,
    title: `Сборная России по зимнему триатлону завоевала больше всех медалей на Чемпионате мира в Андорре`,
    text: `Российские триатлонисты показали отличный результат на трассе вблизи городка Сан-Жулиа-де-Лориа, заняв первое место по количеству медалей на чемпионате мира по зимнему триатлону, обогнав конкурентов из 11 стран мира.`,
    image: news220321,
    link: routes.news220321.path,
    date: `22.03.2021`,
  },
  {
    id: 8,
    title: `Ровно 100 дней назад обновленный состав президиума Федерации триатлона России начал свою работу`,
    text: `Среди основных результатов: обновление системы образования тренеров по триатлону, старт работы комиссий при Федерации, формирование первых баз для подготовки спортсменов по всей России, подписание соглашений о сотрудничестве с Центральным спортивным клубом Армии и Федерацией велоспорта России и финализация формата Суперсерии при партнерстве с телеканалом Матч ТВ`,
    image: news150321,
    link: routes.news150321.path,
    date: `15.03.2021`,
  },
  {
    id: 7,
    title: `Президент Федерации триатлона России Ксения Шойгу представила план развития ФТР на 2021 год и подписала меморандум о сотрудничестве с ЦСКА`,
    text: `В пресс-центре МИЦ «Известия» 10 марта состоялась пресс-конференция, посвященная результатам работы ФТР за 3 месяца, дальнейшим планам по развитию триатлона в России и подписанию меморандума о сотрудничестве между ФТР и ЦСКА.`,
    image: news10032100,
    link: routes.news10032100.path,
    date: `10.03.2021`,
  },
  {
    id: 6,
    title: `Обучение по программе профессиональной переподготовки «Тренер в избранном виде спорта. Триатлон»`,
    text: `Поволжская Государственная Академия Физической культуры, спорта и туризма совместно с Федерацией Триатлона России объявляет набор на обучение по программе профессиональной переподготовки «Тренер в избранном виде спорта. Триатлон»`,
    image: news10032101,
    link: routes.news10032101.path,
    date: `10.03.2021`,
  },
  {
    id: 5,
    title: `Чемпионат Тюменской области по зимнему триатлону состоится 13 марта`,
    text: `Чемпионат и первенство Тюменской области по зимнему триатлона состоятся 13 марта 2021 года. Соревнования пройдут в городе Тобольск на стадионе "Тобол", где обустроены лыжероллерные трассы.`,
    image: CardBackground4,
    link: routes.championatTuymenskoyOblasti.path,
    date: `09.03.2021`,
  },
  {
    id: 4,
    title: `Генеральный секретарь ФТР Галина Шиповалова переизбрана в состав Исполкома Europe Triathlon`,
    text: `6 марта в режиме видео конференции состоялась Генеральная Ассамблея Европейского Союза триатлона (European Triathlon), в рамках которого проводилось голосование в состав руководства ET.`,
    image: CardBackground1,
    link: routes.electionResultsAnnual.path,
    date: `07.03.2021`,
  },
  {
    id: 3,
    title: `Президент ФТР Ксения Шойгу и президент ФВСР Вячеслав Екимов подписали меморандум о сотрудничестве`,
    text: `На велотреке «Локосфинкс» в Санкт-Петербурге президент Федерации триатлона России Ксения Шойгу и президент. Федерации велосипедного спорта России Вячеслав Екимов подписали меморандум о сотрудничестве между двумя организациями.`,
    image: CardBackground2,
    link: routes.ftrFvsrCollaboration.path,
    date: `04.03.2021`,
  },
  {
    id: 2,
    title: `Детский индор-триатлон пройдет в Crocus Fitness 13 марта`,
    text: `Федерация триатлона России и «Крокус-Фитнес»приглашают вас на детский индор-триатлон!`,
    image: CardBackground3,
    link: routes.indoorTriathlonJunior.path,
    date: `04.03.2021`,
  },
  {
    id: 1,
    title: `Чемпионат России по триатлону состоится в Кемеровской области в июле 2021 года`,
    text: `В период со 2 по 5 июля 2021 года в п.г.т. Инской, Беловского городского округа, Кемеровской области состоятся чемпионат и первенство России по триатлону среди элиты и юниоров.`,
    image: CardBackground5,
    link: routes.championatKemerovo2021.path,
    date: `03.03.2021`,
  },
  {
    id: 0,
    title: `Президент Федерации триатлона Ксения Шойгу провела первую встречу комиссии по паратриатлону`,
    text: `В рамках работы Федерации триатлона России Президент ФТР Ксения Шойгу провела первую встречу комиссии по паратриалону, обсудила основные ориентиры для развития и обеспечения необходимым оборудованием паратриатлетов, а также вручила значок и удостоверение Мастера спорта России международного класса паратриатлету Михаилу Колмакову.`,
    image: CardBackground6,
    link: routes.firstMeetingParatriathlon.path,
    date: `02.03.2021`,
  },
];

const allLink = full => {
  if (full) return null;
  else
    <Button
      href={routes.news.path}
      size={`medium`}
      label="Перейти ко всем новостям"
      primary
    />;
};

const newsArrlimit = (full, newsArr) => {
  if (full) return newsArr.length;
  else return 5;
};

const News = ({ full }) => {
  const size = useContext(ResponsiveContext);
  const [news, setNews] = useState([]);
  // const [isLoading, setLoading] = useState(false);
  // const [isError, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      // setLoading(true); // set loading
      try {
        const response = await api.get(`/server/list/news`);
        setNews(response.data);
      } catch (error) {
        // setError(true);
        console.log(error);
      }
      // setLoading(false); // remove loading
    };

    fetchData();
  }, [setNews]);

  if (news.length === 0) return null;
  return (
    <Box margin={{ bottom: `medium` }} as="section" align={`start`}>
      <Heading
        color={`dark-1`}
        level={2}
        size={`medium`}
        margin={{ bottom: `large` }}
        style={{ textTransform: `uppercase` }}
      >
        {full ? `` : `Новости триатлона России`}
      </Heading>
      <CustomGrid fill gap={`medium`} style={{ height: `auto` }}>
        {news.slice(0, 5).map((card, i) => {
          return (
            <Card
              key={card.part}
              height={{ min: `220px` }}
              pad={{ vertical: `28px`, horizontal: `medium` }}
              elevation={`none`}
              background={{
                image: `url('${card.picture || ``}')`,
                color: `rgba(0, 0, 0, 0.5)`,
              }}
              style={{ color: `white`, backgroundBlendMode: `multiply` }}
            >
              <Text size={`12px`}>{card.date}</Text>
              <CustomLink to={`/news/${card.part}`}>
                <Heading level={4} size={`small`} margin={{ top: `auto` }}>
                  {card.title}
                </Heading>
              </CustomLink>
            </Card>
          );
        })}
      </CustomGrid>
      {news.length > 2 && (
        <Grid
          fill
          gap={`medium`}
          columns={columns[size]}
          margin={{ top: `medium` }}
          style={{ height: `auto` }}
        >
          {news.slice(5, newsArrlimit(full, newsArr)).map(card => (
            <Card
              key={card.part}
              height={`170px`}
              pad={{ vertical: `medium`, horizontal: `34px` }}
              elevation={`none`}
              background={{ color: `light-1` }}
            >
              <Text size={`12px`}>{card.date}</Text>
              <CustomLinkBlack to={`/news/${card.part}`}>
                {card.title}
              </CustomLinkBlack>
            </Card>
          ))}
        </Grid>
      )}
      {allLink(full)}
    </Box>
  );
};

export default News;
