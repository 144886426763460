import moment from 'moment';

export const getFilteredData = (arr, filter, prop = `month`) => {
  if (filter && filter !== `all`) {
    return arr.filter((el => el[prop] === filter));
  } else {
    return arr;
  }
};

export const getEventCategoryName = (value, values) => {
  if (Array.isArray(value)) {
    const foundValue = value.map((item) => item && values.find(i => i.value === item)?.name).filter((el) => el);
    return foundValue.length > 0 ? ` • ` + foundValue.join(' • ') : ``
  } else {
    const foundValue = values.find(i => i.value === value)?.name;
    return foundValue ? `• ${foundValue}` : ``;
  }
};

export const formatEventDate = (event, format = 'DD.MM.YYYY') => {
  const start = event?.start_date;
  const end = event?.end_date;

  if (start && end) {
    if (moment(event.start_date).isSame(event.end_date)) {
      return moment(event.start_date).format(format);
    }

    return moment(event.start_date).format(format) + ' - ' + moment(event.end_date).format(format);
  } else if (start && !end) {
    return moment(event.start_date).format(format);
  }

  return '';
}
