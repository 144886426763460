import news200421 from 'images/News/200421.jpeg';
import Article from 'pages/Articles/Article';
import styled from 'styled-components';
import { Paragraph } from 'grommet';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const articte200421 = () => {
  return (
    <Article
      title={`Президиумом ФТР утверждены критерии и условия отбора кандидатов на международные соревнования в 2021 году.`}
      date={`20 Апреля 2021`}
      coverBack={news200421}
    >
      <CustomParagraph>
        <b>Данные критерии затрагивают отбор и квалификационные требования для следующих стартов и категорий:</b>
      </CustomParagraph>
      <CustomParagraph>
      <ul>
        <li>Чемпионат и первенство Европы (триатлон-спринт) в Китцбюэле (Австрия) 18-20.06.2021. Элита, Ю19, Ю23 Индивидуальная гонка,  смешанная эстафета</li>
        <li>Чемпионат мира (финал) в Эдмонтоне (Канада) 17-22.08.2021. Индивидуальная гонка. Элита, Ю23</li>
        <li>Чемпионат Европы в Валенсии (Испания) 25-26.09.2021. Элита. Индивидуальная гонка</li>
        <li>Первенство Европы в Алании (Турция) 30.09-3.10.2021. Индивидуальная гонка (суперспринт, полуфинал, финал), смешанная эстафета. Ю17</li>
        <li>Чемпионат мира в Гамильтоне (Бермудские острова) 15-17.10.2021. Триатлон-суперспринт (полуфиналы, финалы), смешанная эстафета</li>
        <li>С документом можно ознакомиться, пройдя по ссылке <a href='/files/kriterii_otbora.pdf'>"Критерии отбора"</a></li>
      </ul>
      </CustomParagraph>
    </Article>
  )
}

export default articte200421;
