import matyuhPhoto from '../../images/TeamMiddleDistance/matyuh.jpg';
import molyarovaPhoto from '../../images/TeamMiddleDistance/molyarova.jpg';
import ovsyannikovaPhoto from '../../images/TeamMiddleDistance/ovsyannikova.png';
import bryukhankovPhoto from '../../images/TeamMiddleDistance/bryukhankov.png';
import kaurovPhoto from '../../images/TeamMiddleDistance/kaurov.png';
import kuzminPhoto from '../../images/TeamMiddleDistance/kuzmin.jpg';
import tutukinPhoto from '../../images/TeamMiddleDistance/tutukin.png';
import AthleteCards from '../../components/AthleteCards/AthleteCards';
import Banner from 'components/Banner';
import coverImg from '../../images/cover4.jpg';
import Container from 'components/Container/Container';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const teamArr = [
  {
    id: 0,
    title: `МС`,
    name: `Матюх Екатерина Вадимовна`,
    photo: matyuhPhoto,
    dateBirth: '03/06/1998',
    about: [
      { id: 0, text: `Регион - Москва, Санкт-Петербург` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Никульшин А.В., Пахомова М.С.`,
      },
    ],
  },
  {
    id: 1,
    title: `МС`,
    name: `Молярова Мария Игоревна`,
    photo: molyarovaPhoto,
    dateBirth: '05/04/1994',
    about: [
      { id: 0, text: `Регион - Москва, Санкт-Петербург` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2, ЦОП КОР-3, ВС` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Зуев А.Г., Никульшин А.В., Павлов С.В.`,
      },
    ],
  },
  {
    id: 2,
    title: `МС`,
    name: `Овсянникова Маргарита Юрьевна`,
    photo: ovsyannikovaPhoto,
    dateBirth: '10/05/1990',
    about: [
      { id: 0, text: `Регион - Тюменская область` },
      { id: 1, text: `Организация - ФТТО` },
      { id: 2, text: `Тренер в настоящий момент - Сушкова Н.А.` },
    ],
  },
  {
    id: 3,
    title: `МСМК`,
    name: `Брюханков Андрей Александрович`,
    photo: bryukhankovPhoto,
    dateBirth: '27/02/1991',
    about: [
      { id: 0, text: `Регион - Москва, Санкт-Петербург` },
      { id: 1, text: `Организация - ГБПОУ МСС УОРNo2, ЦОП КОР-3, ВС` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Рогатин А.Е., Мещерякова В.Н.`,
      },
    ],
  },
  {
    id: 4,
    title: `МС`,
    name: `Кауров Георгий Владимирович`,
    photo: kaurovPhoto,
    dateBirth: '10/06/1993',
    about: [
      { id: 0, text: `Регион - Санкт-Петербург, Ленинградская область` },
      { id: 1, text: `Организация - УОР No1` },
      {
        id: 2,
        text: `Тренер в настоящий момент - Моторин В.Г., Парфенцева Т.М., Павлов С.В.`,
      },
    ],
  },
  {
    id: 5,
    title: `МС`,
    name: `Кузьмин Максим Викторович`,
    photo: kuzminPhoto,
    dateBirth: '22/07/1988',
    about: [
      { id: 0, text: `Регион - Республика Татарстан` },
      { id: 1, text: `Организация - МБУ"СШ "Олимп"" г. Казани` },
      { id: 2, text: `Тренер в настоящий момент - Яковлев С.В.` },
    ],
  },
  {
    id: 6,
    title: `МСМК`,
    name: `Тутукин Иван Владимирович`,
    photo: tutukinPhoto,
    dateBirth: '11/06/1986',
    about: [
      { id: 0, text: `Регион: Нижегородская область` },
      { id: 1, text: `Организация - ЦСП` },
      { id: 2, text: `Первый тренер триатлоне - Никитина М.Ю.` },
      {
        id: 3,
        text: `Тренер в настоящий момент - Карачаров С.А. Хобби - дегустация вин`,
      },
    ],
  },
];

const TeamMiddleDistance = () => {
  return (
    <>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />
        <AthleteCards athletesArr={teamArr} />
      </Container>
    </>
  );
};

export default TeamMiddleDistance;
