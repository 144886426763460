import CollapsibleCard from 'components/CollapsibleCard/CollapsibleCard';
import { theme } from 'components/Themes/theme';
import { Box, Grid, Grommet, Heading } from 'grommet';
import React from 'react';

const questions = [
  {
    title: 'С какого возраста можно отдавать ребенка в триатлон?',
    body: `Подготовка к триатлону может начинаться уже с 7-8 лет как у мальчиков, так и у девочек. С раннего возраста дети не только тренируются, но и готовятся к первым стартам и участвуют в соревнованиях детского триатлона.`,
  },
  {
    title: 'Из чего состоит детский триатлон?',
    body: `Детский триатлон — это комплексный и разнообразный подход к занятию спортом у детей. Обучение триатлону, как правило, включает в себя: правильную технику бега, плавание в закрытых бассейнах и открытых летом, езду на шоссейном велосипеде, лыжный спорт, подготовка и участие в триатлонных стартах.`,
  },
  {
    title: 'Какая ребенку нужна экипировка?',
    body: `Это зависит от спортивной школы и программы, по которой ребенок будет заниматься с тренером. Как правило, на каждый этап триатлона необходим свой комплект снаряжения, защиты и одежды.`,
  },
];

export default function FAQTab() {
  const [openID, setOpenID] = React.useState(null);

  return (
    <Grommet theme={theme}>
      <Box>
        <Box flex direction="row" justify="between">
          <Heading
            level={`3`}
            margin={{ top: `18px`, bottom: `18px` }}
            style={{ textTransform: `uppercase` }}
          >
            FAQ
          </Heading>
        </Box>
        <Grid
          margin={{ top: `20px` }}
          columns={{ count: `fit`, size: `280px` }}
          justify={`center`}
          gap={`medium`}
        >
          {questions.map((q, id) => (
            <Box width={{ min: '100%' }} key={q.title}>
              <CollapsibleCard
                onClick={() => setOpenID(id === openID ? null : id)}
                open={id === openID}
                title={q.title}
                body={q.body}
                text={`Вопрос №${id + 1}`}
              />
            </Box>
          ))}
        </Grid>
      </Box>
    </Grommet>
  );
}
