import Article from '../Article';
import newsCover from '../../../images/News/news-cover2.jpg';
import styled from 'styled-components';
import { Paragraph } from 'grommet';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const FtrFvsrCollaboration = () => {
  return (
    <Article
      title={`Президент ФТР Ксения Шойгу и президент ФВСР Вячеслав Екимов подписали меморандум о сотрудничестве`}
      date={`4 МАРТА 2021`}
      coverBack={newsCover}
    >
      <CustomParagraph>
        <strong>
          <em>
            На велотреке «Локосфинкс» в Санкт-Петербурге президент Федерации триатлона России Ксения Шойгу и президент
            Федерации велосипедного спорта России Вячеслав Екимов подписали меморандум о сотрудничестве между двумя организациями.
          </em>
        </strong>
      </CustomParagraph>
      <CustomParagraph>
        Меморандум, подписанный в знаковый год проведения олимпийских игр в Токио, предусматривает начало взаимодействия
        между тренерскими составами сборных, обмен опытом в сфере изучения медицинских показателей, сбор и изучение
        аналитики физического состояния спортсменов, а также взаимодействие в сфере спортивного законодательства.
        Также планируется провести комплексную работу по подготовке базы учебно-методических материалов для спортсменов.
      </CustomParagraph>
      <CustomParagraph>
        В ходе обсуждения президент федерации велоспорта России Вячеслав Екимов отметил, что уже в апреле в Сочи будет
        проведена велогонка при участии сборной России по велосипедному спорту и пригласил принять участие в ней представителей
        федерации триатлона. Помимо этого, обсуждался обмен опытом в сфере материально-технического обеспечения, в частности
        разработка программного обеспечения, которое позволило бы более быстро и удобно получать необходимую информацию о
        физическом состоянии спортсменов.
      </CustomParagraph>
      <CustomParagraph>
        <strong><em>«В рамках обмена опытом мы наметили огромное количество вещей, которые нам предстоит вместе реализовывать.
          Это как большая ответственность, так и огромные возможности, чтобы вывести Федерации на новый уровень»</em></strong>, – сказала
        президент федерации триатлона России Ксения Шойгу, отметив, что в будущем федерацию триатлона ожидает подписание
        подобного меморандума и с другими спортивными федерациями.
      </CustomParagraph>
      <CustomParagraph>
        <strong><em>"В силу специфики видов спорта у нас много точек пересечения с триатлоном и проблематика, например, в организации
          соревнований тоже общая. Рады сегодня объявить о сотрудничестве с Федерацией триатлона России. Надеемся, реализовать
          совместные проекты как в спорте высоких достижений, так и в массовом спорте — в частности, нами совместно будет
          прорабатываться идея проведения многодневной гонки для любителей"</em></strong>, – рассказал президент федерации
        велосипедного спорта Вячеслав Екимов
      </CustomParagraph>
      <CustomParagraph>
        <em>
          Подписание проходило на главном спортивном объекте велосипедного спорта в Санкт-Петербурге – центре велосипедного
          спорта «Локосфинкс». Это олимпийский велотрек, соответствующий всем мировым стандартам. Ширина полотна
          составляет 8,5 метров, а длина – 250 метров. На базе комплекса действует Школа Высшего Спортивного Мастерства.
          В центре «ЛОКОСФИНКС» регулярно проходят этапы Открытого Первенства Санкт-Петербурга,
          летние спартакиады и всероссийские чемпионаты.
        </em>
      </CustomParagraph>
    </Article>
  )
}

export default FtrFvsrCollaboration;