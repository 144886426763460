import newsCover from '../../../images/News/news-cover4.jpg';
import Article from '../Article';
import styled from 'styled-components';
import { Anchor, Box, Paragraph } from 'grommet';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`

const CustomLink = styled(Anchor)`
  display: inline-block;
  background-color: #DB4C4C;
  color: #f8f8f8;
  text-decoration: none;
  border-radius: 28px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 14px 38px;
  font-size: 12px;
  line-height: 2;
  transition: box-shadow 0.2s ease;
  letter-spacing: 0.5px;

  &:focus, &:hover {
    box-shadow: 0 0 2px 2px #DB4C4C;
  }
`

const ChampionatTuymenskoyOblasti = () => {
  return (
    <Article
      title={`Чемпионат Тюменской области по зимнему триатлону состоится 13 марта`}
      date={`9 МАРТА 2021`}
      coverBack={newsCover}
    >

      <CustomParagraph>
        Чемпионат и первенство Тюменской области по зимнему триатлона состоятся 13 марта 2021 года. Соревнования пройдут
        в городе Тобольск на стадионе "Тобол", где обустроены лыжероллерные трассы.
      </CustomParagraph>
      <CustomParagraph>
        К участию в соревнованиях допускаются спортсмены возрастных групп (с 10 лет), имеющие необходимую подготовку и
        инвентарь. В программе чемпионата и первенства области личные и эстафетные гонки.
      </CustomParagraph>
      <CustomParagraph>
        Предварительные заявки на участие подаются на электронную почту sales@skibike.ru и sport_tobolsk@mail.ru до 18 часов 10 марта.
        Онлайн регистрация проводится на сайте МАУ ДО "ДЮСШ №2" в группе <Anchor href={`https://vk.com/sport_tobolsk2`} label={`"ВКонтакте"`} /> до 12 марта.
      </CustomParagraph>
      <Box direction={`row`} justify={`between`} width={{max: `800px`}} margin={{left: `auto`, right: `auto`}} pad={{top: `30px`, bottom: `30px`}}>
        <CustomLink href={`https://vk.com/sport_tobolsk2`} label={`Онлайн регистрация`} />
        <CustomLink href={`https://disk.yandex.ru/i/mT4BLyWWDPXb1g`} label={`Регламент соревнований`} />
      </Box>

    </Article>
  )
}

export default ChampionatTuymenskoyOblasti;