export const returnSliderParams = (styles) => {
  return {
    wrapperClass: styles.wrapperClass,
    slideClass: styles.slideClass,
    spaceBetween: 24,
    slidesPerView: `auto`,
    threshold: 15,

    navigation: {
      nextEl: `.${styles.btnNext}`,
      prevEl: `.${styles.btnPrev}`,
    },
  }
};

export const customSliderParams = (styles, count) => {
  return {
    ...returnSliderParams(styles),
    slidesPerView: count,
    allowTouchMove: false,
    autoplay: true,
  }
}