import React from 'react';
import { Box, Grommet, Tab, Tabs, Text, ResponsiveContext } from 'grommet';
import DescriptionTab from './DescriptionTab';
import ApplicationTab from './ApplicationTab';
import SelectionTab from './SelectionTab';
import CoachTab from './CoachTab';
import FaqTab from './FaqTab';

const labelTabs = [
  {
    id: 0,
    label: 'Описание проекта',
    content: <DescriptionTab />,
  },
  { id: 1, label: 'Критерии отбора', content: <SelectionTab /> },
  {
    id: 2,
    label: 'Заявка на участие',
    content: <ApplicationTab />,
  },
  { id: 3, label: 'Тренерский состав', content: <CoachTab /> },
  { id: 4, label: 'FAQ, обратная связь', content: <FaqTab /> },
];

const theme = {
  global: {
    colors: {
      focus: `transparent`,
    },
  },
  tab: {
    margin: `0 16px`,
    pad: {
      bottom: `16px`,
      horizontal: 'small',
    },
    background: 'transparent',
    border: {
      side: `bottom`,
      size: `small`,
      color: {
        dark: ``,
        light: `#D8D8D8`,
      },
      active: {
        color: {
          light: `#DB4C4C`,
        },
      },
      hover: {
        color: {
          light: `#DB4C4C`,
        },
      },
    },
    color: '#D8D8D8',
    hover: {
      color: '#DB4C4C',
    },
    active: {
      background: 'transparent',
      color: '#DB4C4C',
    },
  },
};

const OlympicTabs = () => {
  const size = React.useContext(ResponsiveContext);
  const [activeTab, setActiveTab] = React.useState(0);

  const activeID = labelTabs[activeTab].id;

  const activeContent = labelTabs.find(tab => tab.id === activeID);

  return (
    <Grommet theme={theme}>
      <Box
        direction="column"
        style={{
          width: `max-content`,
          marginLeft: `auto`,
          marginRight: `auto`,
        }}
      >
        <Tabs onActive={setActiveTab}>
          {labelTabs.map(tab => (
            <Tab
              margin={{
                bottom: size !== `small` ? `large` : `medium`,
              }}
              title={
                <Text size="medium" weight="bold">
                  {tab.label}
                </Text>
              }
              key={tab.id}
            ></Tab>
          ))}
        </Tabs>
      </Box>

      <Box
        style={{
          maxWidth: `1190px`,
          marginRight: `auto`,
          marginBottom: `50px`,
        }}
      >
        <Box>{activeContent.content}</Box>
      </Box>
    </Grommet>
  );
};

export default OlympicTabs;
