import Container from 'components/Container/Container';
import { Box, Grommet, ResponsiveContext, Tab, Tabs, Text } from 'grommet';
import React from 'react';
import Contacts from './Contacts';

import Documents from './Documents';
import OtherLinks from './OtherLinks';

const labelTabs = [
  {
    id: 0,
    label: 'Документы',
    content: <Documents />,
  },
  {
    id: 1,
    label: 'Полезные ссылки',
    content: <OtherLinks />,
  },
  {
    id: 2,
    label: 'Контакты',
    content: <Contacts />,
  },
];

const theme = {
  global: {
    colors: {
      brand: `#DB4C4C`,
      focus: `transparent`,
    },
  },
  tab: {
    margin: `0 16px`,
    pad: {
      bottom: `16px`,
      horizontal: 'small',
    },
    background: 'transparent',
    border: {
      side: `bottom`,
      size: `small`,
      color: {
        dark: ``,
        light: `#D8D8D8`,
      },
      active: {
        color: {
          light: `#DB4C4C`,
        },
      },
      hover: {
        color: {
          light: `#DB4C4C`,
        },
      },
    },
    color: '#D8D8D8',
    hover: {
      color: '#DB4C4C',
    },
    active: {
      background: 'transparent',
      color: '#DB4C4C',
    },
  },
};

export default function AntidopingTabs() {
  const [activeTab, setActiveTab] = React.useState(0);

  const activeID = labelTabs[activeTab].id;

  const activeContent = labelTabs.find(tab => tab.id === activeID);
  return (
    <Grommet theme={theme}>
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <Box
              direction="column"
              style={{
                width: `max-content`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
            >
              <Tabs onActive={setActiveTab}>
                {labelTabs.map(tab => (
                  <Tab
                    margin={{
                      bottom: size !== `small` ? `large` : `medium`,
                    }}
                    title={
                      <Text size="medium" weight="bold">
                        {tab.label}
                      </Text>
                    }
                    key={tab.id}
                  ></Tab>
                ))}
              </Tabs>
            </Box>

            <Container>
              <Box
                style={{
                  marginBottom: `50px`,
                }}
              >
                <Box>{activeContent.content}</Box>
              </Box>
            </Container>
          </>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}
