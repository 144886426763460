import * as types from "../types/partners";

const initialState = {
  data: "",
  error: "",
};

const partners = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RESPONSE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case types.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default partners;
