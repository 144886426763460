import { useEffect, useRef } from 'react';
import Swiper, {Navigation, Pagination} from 'swiper';

Swiper.use([Navigation, Pagination]);


export const Slider = ({styles, params, children}) => {
  const swiper = useRef(null);

  useEffect(() => {
    swiper.current = new Swiper(`.${styles.container}`, params);
  }, [styles.container, params]);

  return (
    <div>
      {children}
    </div>)
};

export default Slider;
