// import CurrentMain from 'components/Calendar/CurrentMain';
// import CurrentSuper from 'components/Calendar/CalendarSuper';
// import CurrentCalendar from 'components/Calendar/CurrentCalendar';

import backImage from 'images/slider/slider1.jpg';
import backImage2 from 'images/slider/slider2.jpg';
import backImage3 from 'images/slider/slider3.jpg';
import backImage4 from 'images/slider/slider4.jpg';
import backImage6 from 'images/slider/slider6.jpg';
// import partnerLogo10 from '../images/partnerLogos/Gazprombank.png';
import partnerLogo0 from '../images/partnerLogos/uralchemLogo.svg';
import partnerLogo1 from '../images/partnerLogos/partnerLogo1.png';
import partnerLogo2 from '../images/partnerLogos/partnerLogo2.png';
// import partnerLogo3 from '../images/partnerLogos/partnerLogo3.png';
import partnerLogo4 from '../images/partnerLogos/partnerLogo4.png';
import partnerLogo5 from '../images/partnerLogos/partnerLogo5.png';
import partnerLogo6 from '../images/partnerLogos/partnerLogo6.png';
import partnerLogo7 from '../images/partnerLogos/partnerLogo7.png';
import partnerLogo8 from '../images/partnerLogos/partnerLogo8.png';
import partnerLogo9 from '../images/partnerLogos/partnerLogo9.png';

import partnerLogo11 from '../images/partnerLogos/partnerLogo11.png';
import partnerLogo12 from '../images/partnerLogos/partnerLogo12.png';
import partnerLogo13 from '../images/partnerLogos/partnerLogo13.png';
import partnerLogo15 from '../images/partnerLogos/partnerLogo15.png';
import partnerLogo16 from '../images/partnerLogos/partnerLogo16.png';

import partnerLogo18 from '../images/partnerLogos/partnerLogo18.svg';
import partnerLogo19 from '../images/partnerLogos/partnerLogo19.svg';
import partnerLogo23 from '../images/partnerLogos/partnerLogo23.png';
import Lukoil from '../images/partnerLogos/lukoil_logo.svg';
import GPB from '../images/partnerLogos/gpb_logo.svg';
import RZD from '../images/partnerLogos/rzd_logo.png';
import PSB from '../images/partnerLogos/psb_logo.svg';
import VTB from '../images/partnerLogos/vtb_logo.png';
import OCR from '../images/partnerLogos/ocr_logo.png';

import { routes } from 'const/routes';
import { Anchor } from 'grommet';

// export const tabsMain = [
//   {
//     title: `Мероприятия 2021`,
//     component: CurrentMain,
//   },
// ];

// export const tabsSuper = [
//   {
//     title: `Суперсерия 2021`,
//     component: CurrentSuper,
//   },
// ];

// export const tabsCalendar = [
//   {
//     title: `Мероприятия 2021`,
//     component: CurrentCalendar,
//   },
// ];

export const antidoping = [
  {
    title: 'Сообщить о допинге',
    path: `https://www.rusada.ru/doping-control/investigations/report-about-doping/`,
  },
  {
    title: 'Дисквалификация',
    path: `https://rusada.ru/doping-control/disqualifications/`,
  },
  {
    title: 'РУСАДА',
    path: `http://rusada.ru/documents/`,
  },
  {
    title: 'МИНСПОРТА',
    path: `https://minsport.gov.ru/sport/antidoping/`,
  },
  {
    title: 'Проверить лекарство',
    path: `https://list.rusada.ru/`,
  },
  {
    title: 'Обучение',
    path: `https://rusada.triagonal.net/files/rusada/landing/index.html`,
  },
];

export const mainPartnerLogos = [
  {
    id: 0,
    logo: GPB,
    width: 200,
    height: 53,
    title: `Генеральный партнёр`,
    path: `https://www.gazprombank.ru/`,
  },
  // {
  //   id: 1,
  //   logo: RZD,
  //   width: 150,
  //   title: `Официальный партнёр`,
  //   path: `https://www.rzd.ru/`,
  // },
  {
    id: 2,
    logo: PSB,
    width: 150,
    title: `Генеральный партнёр Детской лиги Триатлона`,
    path: 'https://www.psbank.ru/',
  },
  {
    id: 3,
    logo: Lukoil,
    width: 170,
    title: `Партнёр Суперсерии Триатлона`,
    path: `https://lukoil.ru/`,
  },
  {
    id: 4,
    logo: VTB,
    width: 100,
    title: `Официальный спонсор Кубка России`,
    path: `https://www.vtb.ru/`,
  },
];

export const partnerLogosArr = [
  {
    id: 2,
    logo: partnerLogo0,
    width: `136`,
  },
  {
    id: 0,
    logo: partnerLogo1,
    width: `136`,
  },
  {
    id: 1,
    logo: partnerLogo2,
    width: `150`,
  },
  // {
  //     id: 2,
  //     logo: partnerLogo3,
  //     width: `164`,
  //     height: `54`,
  // },
  {
    id: 3,
    logo: partnerLogo4,
    width: `159`,
  },
  {
    id: 4,
    logo: partnerLogo5,
    width: `212`,
  },
  {
    id: 5,
    logo: partnerLogo6,
    width: `170`,
  },
  {
    id: 6,
    logo: partnerLogo7,
    width: `133`,
  },
  {
    id: 7,
    logo: partnerLogo8,
    width: `150`,
  },
  {
    id: 8,
    logo: partnerLogo9,
    width: `150`,
  },
  {
    id: 10,
    logo: partnerLogo11,
    width: `182`,
  },
  {
    id: 11,
    logo: partnerLogo12,
    width: `150`,
  },
  {
    id: 12,
    logo: partnerLogo13,
    width: `163`,
  },
  {
    id: 14,
    logo: partnerLogo15,
    width: `110`,
  },
  {
    id: 15,
    logo: partnerLogo16,
    width: `110`,
  },
  {
    id: 16,
    logo: OCR,
    width: `60`,
  },
  {
    id: 18,
    logo: partnerLogo18,
    width: `159`,
  },
  // {
  //   id: 19,
  //   logo: partnerLogo19,
  //   width: `159`,
  // },
  {
    id: 23,
    logo: partnerLogo23,
    width: `180`,
  },
];

export const mainPageSlides = [
   {
    id: 0,
    date: `13.09.2022`,
    title: `Стартовая форма`,
    desc: `Стартовые костюмы для триатлона от Veter Cycling`,
    image: backImage6,
    link: `https://veter.cc/ftr`,
    btnText: `Перейти`,
    isButton: true,
    outside: true,
  }, 
  {
    id: 1,
    date: ``,
    title: `Новости`,
    desc: `Новости Федерации Триатлона России`,
    image: backImage4,
    link: `${routes.calendar.path}/news`,
    btnText: `Читать`,
    isButton: true,
    outside: false,
  },
  {
    id: 2,
    date: `05.03.2021`,
    title: `Как начать?`,
    desc: `Хотите пройти свой первый триатлон, но не знаете, с чего начать?`,
    image: backImage2,
    link: routes.whereToStart.path,
    btnText: `Читать`,
    isButton: true,
    outside: false,
  },
  {
    id: 3,
    date: `15.01.2022`,
    title: `Календарь стартов 2022`,
    desc: `Актуальный календарь триатлонных стартов на всей территории России и за её пределами`,
    image: backImage3,
    link: `${routes.calendar.path}/all`,
    btnText: `Посмотреть`,
    isButton: true,
    outside: false,
  },
  {
    id: 4,
    date: `05.03.2021`,
    title: `История триатлона`,
    desc: ``,
    image: backImage,
    link: routes.historyTria.path,
    btnText: `Читать`,
    isButton: true,
    outside: false,
  },
];
