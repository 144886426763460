import { Box, Paragraph } from "grommet";
import { useEffect } from 'react';
import React from "react";
import { useSelector } from 'react-redux';
import DocumentItem from '../DocumentItem';
import styled from "styled-components";
import { regionsDocuments } from 'store/action/documents';

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;

  max-width: 100%;
  font-size: 16px;
`;

const P = styled(Paragraph)`
  max-width: 100%;
  font-size: 16px;
  line-height: 30px;
`;

export default function DescriptionTab() {
  const items = useSelector(state => state.documents.items);

  useEffect(() => {
    regionsDocuments();
  }, []);
  return (
    <Box gap="medium">
      <Box>
        <CustomParagraph>
          <h2>3 шага по открытию федерации по триатлону</h2>
        </CustomParagraph>
        <CustomParagraph>
          <b>Шаг № 1 «Юридическая регистрация организации»:</b>
        </CustomParagraph>
        <P>
          - разработка Устава согласно требованиям Гражданского кодекса РФ,
          федеральным законам № 7-ФЗ «О некоммерческих организациях», № 82-ФЗ
          «Об общественных объединениях» и № 329-ФЗ «О физической культуре и
          спорте»;
        </P>
        <P>
          - подача документов для регистрации в региональном органе юстиции;
        </P>
        <P>- постановка на налоговый учёт; </P>
        <P>- постановка на учёт во внебюджетных фондах; </P>
        <P>- постановка на учёт в комитет по статистике; </P>
        <P>- получение банковского счёта. </P>
        <CustomParagraph>
          <b>Шаг № 2 «Согласование кандидатуры для аккредитации и подтверждение
          членства в ФТР»:</b>
        </CustomParagraph>
        <P>- разработка программы развития по виду спорта «триатлон»; </P>
        <P>- рабочая встреча с руководством Федерации триатлона России; </P>
        <P>
          - получение официального согласования и подтверждения членства от
          Федерации триатлона России. Важно знать: рекомендуемый срок
          аккредитации для действующих федераций – от 2 до 4 лет, для впервые
          созданных федераций – до 2 лет.
        </P>
        <CustomParagraph>
          <b>Шаг № 3 «Получение аккредитации региональной федерацией»:</b>
        </CustomParagraph>
        <P>
          - обращение в региональное министерство спорта для объявления
          государственной аккредитации по виду спорта «триатлон» (в случае, если
          государственная аккредитация по этому виду спорта ещё не объявлена,
          это можно проверить на сайте министерства);
        </P>
        <P>
          - подготовка и направление пакета документов в адрес регионального
          органа исполнительной власти в сфере физической культуры и спорта.
          Пакет документов должен быть подготовлен в соответствии с требованиями
          раздела III Приказа Минспорта России от 01.08.2014 № 663 «Об
          утверждении Порядка проведения государственной аккредитации
          региональных общественных организаций»;
        </P>
        <P>
          - получение приказа об официальной аккредитации региональной
          федерации.
        </P>
      </Box>
      {items.map(i => (
        <Box
          key={i.public_id}
          flex
          direction="column"
          margin={{ bottom: '20px' }}
        >
          <DocumentItem title={i.title} date={i.document_date} link={i.link} />
        </Box>
      ))}
    </Box>
  );
}
